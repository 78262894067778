<template>
  <application-header
    :title="appTitle"
    :isDatePicker="false"
    :isNowTime="false"
    :isEditBase="false"
    :isEditVehicle="false"
    :isCreatePlan="false"
    :isDriveLog="false"
    :isOutputCsv="false"
    :isDriveVue="true"
    @driveVue="onClickDriveVue"
  >
  </application-header>

    <!----------------------------------------------------
      運行スポット登録、運行スポット編集ボタン
    ------------------------------------------------------>
    <div class="d-flex button-area">

        
        <div class="col-sm-2">
            <base-button type="primary" block @click="onClickDriveSpot">スポット登録</base-button>
        </div>
        <div class="col-sm-2">
            <base-button type="primary" :disabled="true" block @click="onClickDriveSpotTypeEdit">スポットタイプ編集</base-button>
        </div>
        <div class="col-sm-2">
            <base-button type="primary" block @click="onClickDriveSpotSetting">スポット設定</base-button>
        </div>
    </div>

    <!----------------------------------------------------
      スポットタイプ編集
    ------------------------------------------------------>
    <div class="card">
        <div class="card-header border-0">
            <!-- メニュー 追加・削除・編集を有効 -->
            <div class="row ml-0">
                <table-tool-menu
                    :title="title"
                    :addTitle="addTitle"
                    :editTitle="editTitle"
                    :deleteTitle="deleteTitle"
                    :isAdd="isAdd"
                    :isDelete="true"
                    :isEdit="isEdit"
                    :isCopy="false"
                    :isSingleSelect="selectRow"
                    :isMultiSelect="false"
                    :processing="false"
                    :isOrderMove="isOrderMove" 
                    :disableMoveDown="disableMoveDown"
                    :disableMoveUp="disableMoveUp"
                    @addNew="onAddSpotType()"
                    @edit="onEditSpotType(selectedData)"
                    @delete="onDeleteSpotType()"
                    @moveRow="onClickRowMove"
                >
                </table-tool-menu>            
            </div>
        </div>

        <!-- スポットタイプ一覧テーブル -->
        <div class="spottype-list">
          <el-table
            ref="SpotTypeTable"
            class="table-responsive table-flush"
            header-row-class-name="app-th-row"
            select-on-indeterminate="true"
            :data="pagedData"
            @select="handleSelectRow"
            @select-all="handleSelectAllRow"
          >
            <el-table-column v-if="isAdd" type="selection" align="left" min-width="120px"></el-table-column>          
            <el-table-column label="アイコン" min-width="80px" prop="spottype.spottypeId">
              <template v-slot="{ row }">
                <img style="width:27px;height:27px;" :src="icomImageList[row.spottype.spottypeId]" />
              </template>
            </el-table-column>
            <el-table-column label="名前" min-width="100px" prop="spottype.name"></el-table-column>
            <el-table-column label="メッセージ" min-width="200px" prop="spottype.voicetext" ></el-table-column> 
            <el-table-column label="スポット区分" min-width="100px" prop="spotDivName" ></el-table-column>
          </el-table>

          <div class="row mt-1 d-flex flex-fill justify-content-center">
            <div class="col-auto">
              <div class="mt-1">
                <el-pagination
                  @current-change="setCurrent"
                  :page-size="pagination.perPage"
                  :current-page="pagination.currentPage"
                  layout="prev, pager, next"
                  :total="total">
                </el-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>

    <!-- スポットタイプ編集モーダル -->
    <modal v-model:show="modals.editSpotTypeDlg" size="md" >

      <div>        
        <el-form :model="spotTypeFormData" :rules="rules" ref="form">
          <h4>アイコン<require-tag /></h4>
          <!-- アップロード後のプレビュー -->
          <div v-if="spotTypeFormData.img" class="d-flex align-items-center mt-3 mb-2" >
            <el-image v-if="spotTypeFormData.img" style="width:32px;height:32px;margin-left:10px" :src="spotTypeFormData.img" :fit="cover" :key="spotTypeFormData.img" :preview-src-list="[spotTypeFormData.img]" />
            <base-button size="mini" type="primary" class="d-flex" @click="spotTypeFormData.img = null">削除</base-button>
          </div>
          
          <!-- アップロード前のプレビュー -->
          <div v-if="!spotTypeFormData.img && uploadIconFileList.length > 0" class="d-flex align-items-center mt-3 mb-2" >
            <el-image style="width:32px;height:32px;margin-left:10px" class="mr-2" :src="iconPreview" :fit="cover" :key="iconPreview" :preview-src-list="[iconPreview]" />
            <base-button size="mini" type="primary" class="d-flex" @click="handleRemove(uploadIconFileList[0])">削除</base-button>
          </div>
          <div v-if="!spotTypeFormData.img && uploadIconFileList.length == 0">
            <el-radio-group v-model="iconFileType" size="small">
              <el-radio-button label="0">オリジナル</el-radio-button>
              <el-radio-button label="1">ユーザー指定</el-radio-button>
            </el-radio-group>

            <el-select
                v-if="iconFileType == '0'"
                v-model="originalSpotType"
                readonly="true"
                reserve-keyword
                placeholder="選択してください"
                @change="changeSpotType"
              >
                <el-option
                  v-for="item in this.originalSpotTypeList"
                  :key="item.sk"
                  :label="item.name"
                  :value="item.sk"
                >
                  <img
                    class="statusIcon"
                    style="width:27px;height:27px;"
                    :src="originalIconImageList[item.sk]"
                  /><span class="">{{ item.name }}</span>
                </el-option>
                <template #prefix>
                  <img
                    v-if="iconUrl"
                    class="statusIcon"
                    style="width:32px;height:32px;"
                    :src="iconUrl"
                  />
                </template>
              </el-select><!-- オリジナル選択 -->


            <!-- アップロードフォーム -->
            <el-upload
              v-if="!spotTypeFormData.img && uploadIconFileList.length == 0 && iconFileType == '1'"
              class="upload-demo m-1"
              action=""
              :on-change="handleOnChange"
              :on-remove="handleRemove"
              :file-list="uploadIconFileList"
              :multiple="false"
              :auto-upload="false"
              :limit=1
              :show-file-list="true"
              list-type="picture-card"
              style="margin-left:40px"
            >
              <el-button size="small" type="primary" class="mb-0">アイコンを選択</el-button>
              <!-- <template #file="{ file }">
                <div v-if="disabled">
                  <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
                  <span class="el-upload-list__item-actions">
                    <span
                      class="el-upload-list__item-delete"
                      @click="handleRemove(file)"
                    >
                      <el-icon><Delete /></el-icon>
                    </span>
                  </span>
                </div>
              </template> -->
            </el-upload>
          </div>
          <h4>名称<require-tag /></h4>
          <el-form-item prop="name">
            <div class="d-flex align-items-center mb-3">
              <el-input
                style="width:320px"
                v-model="spotTypeFormData.name"
                placeholder="任意の運行スポット名称。"
            ></el-input>
            </div>
          </el-form-item>  

          <h4>メッセージ<require-tag /></h4>
          <el-form-item prop="voicetext">
            <div class="d-flex align-items-center mb-3">
              <el-input
                style="width:640px"
                v-model="spotTypeFormData.voicetext"
                placeholder="任意の運行スポットメッセージ。最大50文字まで。"
              ></el-input>
            </div>
          </el-form-item>          

          <h4 class="">スポット区分<require-tag /></h4>
          <el-select
            v-model="spotTypeFormData.spotType"
            @change="onChangeSpotDiv"
          >
            <el-option
              v-for="item in spotDivList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>        
        </el-form>
      </div>

      <div class="row mt-4 mb-2 ml-2 mr-2">
        <div class="col-sm-5">
          <base-button type="secondary" block @click="onClickCancel">キャンセル</base-button>
        </div>
        <div class="col-sm-2">
        </div>
        <div class="col-sm-5">
          <base-button  v-if="actionMode == 0" type="primary" block @click="onClickSubmit">追加</base-button>
          <base-button  v-if="actionMode != 0" type="primary" block @click="onClickSubmit">編集</base-button>
        </div>
      </div>
    </modal>

    <!-- スポットタイプ編集確認モーダル -->
    <modal v-model:show="modals.confirmDlg">
      <template v-slot:header>
          <h5 class="modal-title" id="modalLabel">{{confirmTitle}}</h5>
      </template>
      <div class="mb-5">
        {{confirmMessage}}
      </div>
      <div class="row mt-2 mb-2 ml-2 mr-2">
        <div class="col-sm-5">
          <base-button type="secondary" block @click="onClickConfirmCancel">キャンセル</base-button>
        </div>
        <div class="col-sm-2">
        </div>
        <div class="col-sm-5">
          <base-button v-if="actionMode != 1" type="primary" block @click="onClickConfirmOK">{{confirmOK}}</base-button>
          <base-button v-if="actionMode == 1" type="danger" block @click="onClickConfirmOK">{{confirmOK}}</base-button>
        </div>
      </div>
    </modal>

</template>

<style></style>
<style scoped>
/** このVueだけのスタイル */
.button-area {
  margin-top: 5px;
  width: 100%;
  height: 50px;
}
.spottype-list {
  width: 100%;
  height: 100%;
}
</style>

<script>
import ApplicationHeader from "../components/Menu/ApplicationHeader.vue";
import appLog from "@/appUtils/AppLog";
import SpotModel from "@/appModel/Spot/SpotModel";
import { useToast } from "vue-toastification";
import TableToolMenu from "../components/Menu/TableToolMenu";
import Modal from "@/components/Modal";
import ValidateUtil from "@/appUtils/ValidateUtil";
import SpotTypeModel from "@/appModel/Spot/SpotTypeModel";
import UserInfo from "@/appUtils/UserInfo.js";
import TenantStorage from "@/appUtils/TenantStorage";
import DateUtil from "@/appUtils/DateUtil";
import Mime from "@/appUtils/Mime.js";
import path from 'path'
import SpotTypeBaseModel from "@/appModel/Spot/SpotTypeBaseModel";
import clone from 'clone';
const { Storage } = require("aws-amplify");
import ValidSession from "../common/ValidSession.js";
import UseApps from "@/appViews/common/UseApps.js";
import Logger from "@/appViews/common/Logger.js";
// const { Storage } = require("aws-amplify");
// import CoordinateUtil from "@/appUtils/CoordinateUtil";
// import IntervalSettingModel from "@/appModel/Spot/IntervalSettingModel";
// import BaseAreaModel from "@/appModel/BaseArea/BaseAreaModel";
// import DriveSpotMap from "@/appViews/Projects/DriveSpotMap.vue";


export default {
  components: {
    ApplicationHeader,
    TableToolMenu,
    Modal,
  },
  mixins: [ValidSession, UseApps, Logger],
  data() {
    return {
      projectId: null,
      projectName: null,
      datestring: null,
      appTitle: "運行スポットタイプ編集",
      repeatedHitsFlg: false, // 連打防止フラグ （拠点編集画面等へ遷移する際に使用）
      spotTypeList: [],
      originalSpotType: "",
      originalSpotTypeList: [],
      originalIconImageList: {},
      // スポットタイプ編集タブ -----------------------------------------------------------
      title: "スポットタイプ一覧",
      addTitle: "スポットタイプを追加",
      editTitle: "スポットタイプを編集",
      deleteTitle: "スポットタイプを削除",
      uploadIconFileList: [],
      isAdd: true,
      isEdit: true,
      isOrderMove: true,
      spotTypeEditList: [],
      selectedData: null,
      selectedDatas: null,
      selectedDataCnt: 0, 
      pagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      }, 
      modals: {
        editSpotTypeDlg: false,
        confirmDlg: false,
      },
      rowId: null,
      icomImageList: {},
      spotTypeFormData: {
        img: null,
        name: null,
        voicetext: null,
        spotType: 0,
        order: 0
      },
      spotDivList: [
        {id: 0, name: '運行スポット'}, 
        {id: 1, name: '運行間隔'}, 
      ],
      rules: {
        name: [
          { required: false, validator: this.checkName, trigger: "blur" },
        ],
        voicetext: [
          { required: false, validator: this.checkVoicetext, trigger: "blur" },
        ],
      },
      confirmTitle: '',
      confirmMessage: '',
      confirmOK: '',
      actionMode: 0, // 0: 追加, 1: 削除, 2: 編集
      disableMoveDown: false,
      disableMoveUp: false,
      iconPreview: null,
      iconFileType: "0",
      iconUrl: ""
    };
  },

  // コンピュートプロパティ
  computed: {
    selectRow() {
      if (this.userAuthority == 0)
      {
        return false;
      }
      if (this.selectedDataCnt > 0) {
        return true;
      } else {
        return false;
      }
    },
    /***
     * ページング用のデータをかえす
     */
    pagedData() {
      return this.spotTypeEditList.slice(this.from, this.to)
    },
    /**
     * ページング制御用
     */
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    /**
     * ページング制御用
     */
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    /**
     * ページング制御用
     */
    total() {
      return this.spotTypeEditList.length;
    },
    vuename() {
      return "DriveSpotTypeEdit.vue"
    }
  },

  beforeCreate() {
    // インスタンスは生成されたがデータが初期化される前
  },

  created() {
    // インスタンスが生成され､且つデータが初期化された後
    appLog.infoLog(
      "BaseArea.vue",
      this.$store.state.user.userId,
      `Start Vuex：projectid(${this.$store.state.drivespot.projectid})、projectname(${this.$store.state.drivespot.projectname})、datestring(${this.$store.state.drivespot.datestring})`
    );

    this.init();
  },

  async beforeMount() {
    // インスタンスが DOM 要素にマウントされる前
  },

  mounted() {
    // インスタンスが DOM 要素にマウントされた後
  },

  beforeUpdate() {
    // データは更新されたが DOM に適用される前
  },

  updated() {
    // データが更新され､且つ DOM に適用された後
  },

  beforeUnmount() {
    // Vue インスタンスが破壊される前
  },

  unmounted() {
    // Vue インスタンスが破壊された後
  },

  setup() {
  },

  // vue3以前の記法
  beforeRouteLeave(to, from, next) {
    // Vue Rtouterでのページ離脱時
    next();
  },

  // ボタンイベントなどのメソッドはmethodsに
  methods: {
    /**
     * 処理中インジケーターを表示します。
     * @returns 表示したインジケーター
     */
    showLoader() {
      return this.$loading.show({
        container: null,
        canCancel: false,
        color: "#003C9C",
        width: 64,
        height: 64,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        isFullPage: true,
        zIndex: 9999,
      });
    },

    /**
     * 処理中インジケーターを閉じます。
     * @paramas {Object} loader 表示したインジケーター
     */
    hideLoader(loader) {
      loader.hide();
    },

    /**
     * ページ変更時のハンドラ
     */
    setCurrent(newPage) {
      this.pagination.currentPage = newPage
    },
    
    /**
     * 初期化処理
     */
    async init() {
      let loader = this.showLoader();
      try {
        // store(vuex)から値取得
        this.projectId = this.$store.state.drivespot.projectid;
        this.datestring = this.$store.state.drivespot.datestring;
        this.projectName = this.$store.state.drivespot.projectname;
        this.spotTypeList = this.$store.state.drivespot.spotTypeList;

        // タイトルをセット
        let navInfo = this.$store.state.navInfo;
        navInfo.title = `${this.projectName} - ${this.$route.meta.title}`;
        navInfo.description = "";
        this.$store.commit("setNavInfo", navInfo);

        // スポットタイプ一覧取得
        await this.getSpotTypeList();

        // オリジナルアイコン取得
        await this.getOriginalIconImage()
        

        // 連打防止
        this.repeatedHitsFlg = false;

      } catch (e) {
        this.errorLog("init", this.parseErrorObject(e))
        // appLog.errLog(
        //   "DriveSpotTypeEdit.vue",
        //   this.$store.state.user.userId,
        //   `${e.message}`
        // );
      } finally {
        this.hideLoader(loader);
      }
    },

    /**
     * 運行情報画面ボタンクリック
     */
    async onClickDriveVue() {
      // 連打防止
      if (this.repeatedHitsFlg) return;
      this.repeatedHitsFlg = true;

      // store(vuex)に値をセット
      let store = this.$store.state.timeline;
      store.projectid = this.projectId;
      store.projectname = this.projectName;
      store.datestring = this.datestring;

      await this.$store.commit("setTimeline", store);

      // 運行情報画面表示
      this.$router.push({
        path: this.getRoutePath('timeline'),
      });
    },
    
    /**
     * トーストでメッセージを表示
     */
    showBottomToast(message, type) {
      this.runToast(message, "bottom-center", type);
    },

    /**
     * トーストでメッセージを表示（処理）
     */
    runToast(message, pos, type) {
      const toast = useToast();
      toast[type](message, {
        hideProgressBar: true,
        icon: false,
        toastClassName: ["custome-toast-class"],
        closeButton: false,
        position: pos,
      });
    },


    // スポットタイプ編集 ------------------------------------------------------------------------------------------
    handleOnChange(file, fileList){
      console.log(`ファイルサイズ:${JSON.stringify(file.size, null, "\t")}`)      
      console.log(JSON.stringify(fileList, null, "\t"))

      // 拡張子をチェック
      Mime.getExt(file.raw).then(function(extension) {
        // extensionに拡張子が入ります。png,gif.jpg,bmp以外はnullを返します。
        console.log(`ext: ${extension}`)
        // 以下既存処理を書く
        if (extension == null) {
          this.$message.warning({
            duration: 5000,
            message: `アイコンファイルは画像ファイルを指定してください。`});
          this.uploadIconFileList = [];
          return;          
        }
      }.bind(this))

      // サイズオーバーか
      const sizeKb = file.size / 1024 ;
      const isOverSize = sizeKb > 500;

      if (isOverSize) {
        this.$message.warning({
          duration: 5000,
          message: `アイコンファイルサイズは500KB以下のファイルを使用してください。。
                  ${file.name}  ${Math.round(sizeKb)}MB`});
      }
      console.log(`handleOnChange :サイズチェック`)
      let result = fileList.filter((file) => {
        
        const sizeKb = file.size / 1024;
        console.log(`handleOnChange: ${sizeKb} KB`)
        return sizeKb <= 500;
      });
      

      this.uploadIconFileList = result
      console.log(`handleOnChange: リスト更新 ${this.uploadIconFileList.length}`)

      // this.uploadIconFileList = fileList;
      this.iconPreview = null
      if (this.uploadIconFileList.length > 0) {
        this.readFileAsDataURL(this.uploadIconFileList[0].raw).then(function(result){
          console.log(`handleOnChange: プレビュー作成`)
          this.iconPreview = result
        }.bind(this))
        
      }
    },
    /**
     * BLOBからBASE64文字列へ
     */
    async readFileAsDataURL(blob) {
      const base64 = await new Promise((resolve) => {
        const reader = new FileReader();

        // eslint-disable-next-line no-unused-vars
        reader.onload = (e) => {
          const result = reader.result;
          resolve(result);
        };

        reader.readAsDataURL(blob);
      });

      return base64;
    },
    handleRemove(file, fileList){
      this.uploadIconFileList = [];
    },

    /**
     * スポットタイプ一覧取得（spottype.orderカラム昇順でソート）
     */  
    async getSpotTypeList(){

      // テーブル関連編集初期化
      this.selectedData = null;      
      this.selectedDatas = null;      
      this.selectedDataCnt = 0; 
      this.spotTypeEditList = [];

      // アイコンのイメージを取得、スポットタイプのリスト取得
      let listdata = await this.getSpotTypeListAndmakeIconImage();

      // 取得した一覧を表示
      listdata.forEach(item => {    
        this.spotTypeEditList.push(item);
      });

      // メニューを非表示にする
      this.selectedData = null;      
    },    
    async getOriginalIconImage() {
      const config = {level: "public", download: true}
      // オリジナルスポットタイプリスト取得
      this.originalSpotTypeList = await SpotTypeBaseModel.getSpotTypeBaseList();
      for (const spot of this.originalSpotTypeList) {
        console.log(`spot ${JSON.stringify(spot)}`)
        // パブリックフォルダのアイコンをダウンロード
        let iconUrlOfBase64 = await this.getBase64Url(spot.iconpath)
        console.log(`iconUrlOfBase64 ${iconUrlOfBase64}`)
        // Base64に変換しイメージを保持
        this.originalIconImageList[spot.sk] = iconUrlOfBase64
      }
      
    },
    /**
     * スポットのタイプ変更イベント
     */
    changeSpotType() {
      let list = this.originalSpotTypeList.filter((list) => list.sk == this.originalSpotType);
      if (list.length > 0) {
        this.iconUrl = this.originalIconImageList[list[0].sk];
      }
    },
    /**
     * 画像ダウンロードとBase64エンコード
     */
    async getBase64Url(iconpath) {
      console.log(`[START] getBase64Url: ${iconpath}`);

      try {
        // バイナリとしてダウンロード
        const config = { level: "public", download: true };
        let data = await Storage.get(iconpath, config);

        // Base64にエンコードした値を返却する
        return new Promise((resolve, _) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(data.Body);
        });
      } finally {
        console.log(`[END] getBase64Url`);
      }
    },
    /**
     * アイコンのイメージを取得、スポットタイプ一覧取得
     */  
    async getSpotTypeListAndmakeIconImage() {

      const projectId = this.projectId;
      let listdata = await SpotTypeModel.getSpotTypeList(projectId); 

      // ソートして一覧に表示
      listdata = listdata.sort(function(a, b){
        if (a.spottype.order > b.spottype.order) return 1;
        if (a.spottype.order < b.spottype.order) return -1;
        return 0
      })

      // ユーザー情報取得
      const user = await UserInfo.getUserInfo()

      // テナント毎のフォルダにアップロードするユーティリティクラスを初期化
      let tenantStorage = new TenantStorage(user.group)

      // 一覧表示用にデータ加工
      for (let i=0; i<listdata.length; i++){

        let item = listdata[i];

        // スポット区分名を取得
        for (let i=0; i<this.spotDivList.length; i++) {
          if (this.spotDivList[i].id == item.spottype.spotType) {
            item.spotDivName = this.spotDivList[i].name;
            break;
          }
        }

        // アイコンイメージダウンロード
        let response = await tenantStorage.get(item.spottype.iconpath);
        // Base64に変換しイメージを保持
        this.icomImageList[item.spottype.spottypeId] = await response.toBase64();

      } 

      // 一覧を返却
      return listdata;
    },

    /**
     * スポットタイプ 追加ボタンクリック
     */
    async onAddSpotType() {
      this.uploadIconFileList = [];
      this.rowId = null;
      this.spotTypeFormData.img = null;
      this.spotTypeFormData.name = null;
      this.spotTypeFormData.voicetext = null;
      this.spotTypeFormData.spotType = 0;
      this.actionMode = 0;
      let orders = []
      for (const row of this.spotTypeEditList) {
        if (row.spottype.order) {
          orders.push(row.spottype.order)
        }
      }
      orders = orders.reverse()
      const newOrder = orders.length > 0 ? orders[0] + 1 : orders.length + 1

      this.spotTypeFormData.order = newOrder

      this.modals.editSpotTypeDlg = true;
    },
    /**
     * スポットタイプ 編集ボタンクリック
     */
    async onEditSpotType(row) {
      this.rowId = row.id;
      this.spotTypeFormData.img = this.icomImageList[row.spottype.spottypeId]; 
      this.spotTypeFormData.name = row.spottype.name;
      this.spotTypeFormData.voicetext = row.spottype.voicetext;
      this.spotTypeFormData.spotType = row.spottype.spotType;
      this.spotTypeFormData.order = row.spottype.order;
      this.actionMode = 2;
    
      this.modals.editSpotTypeDlg = true;
    },
    /**
     * スポットタイプ 削除ボタンクリック
     */
    async onDeleteSpotType() {
      // スポットタイプ処理確認モーダル表示
      let operation = this.deleteTitle;
      this.confirmTitle = operation;
      this.confirmMessage = "選択したスポットタイプを削除します。よろしいですか？";
      this.confirmOK = '削除';
      this.actionMode = 1;
      this.modals.confirmDlg = true;
    },
    /**
     * スポット区分セレクトボックスChangeイベント
     */
    onChangeSpotDiv() {
    },
    /**
     * フォームのバリデーション(名称（スポットタイプ）)
     */
    checkName(rule, value, callback) {
      if (
        ValidateUtil.checkBase(value, callback, 20, true, false, false, true)
      ) {
        callback();
      }
    },
    /**
     * フォームのバリデーション(メッセージ（音声テキスト）)
     */
    checkVoicetext(rule, value, callback) {
      if (
        ValidateUtil.checkBase(value, callback, 50, true, false, false, true)
      ) {
        callback();
      }
    },
    /**
     * スポットタイプ編集編集画面（モーダル）更新ボタン押下時の処理
     */       
    async onClickSubmit() {

      await this.$refs.form.validate(
        async function(result) {

          // フォーム入力基本チェック
          if (!result) {
            this.showBottomToast("入力内容にエラーがあります。", "error");
            return;
          }

          // 登録確認メッセージ
          let operation = null;
          let buttonLabel = '実行';
          if (this.rowId == null) {
            operation = this.addTitle;
            this.actionMode = 0;
            buttonLabel = '追加';
          } else {
            operation = this.editTitle;
            this.actionMode = 2;
            buttonLabel = '編集';
          }

          // スポットタイプ処理確認モーダル表示
          this.confirmTitle = operation;
          this.confirmMessage = `${operation}します。よろしいですか？`;
          this.confirmOK = buttonLabel;
          this.modals.confirmDlg = true;
        }.bind(this));
    },
    /**
     * スポットタイプ編集編集画面（モーダル）キャンセルボタン押下時の処理
     */      
    async onClickCancel()
    {
      this.modals.editSpotTypeDlg = false;
      this.uploadIconFileList = [];
    },
    /**
     * スポットタイプ処理確認（モーダル）更新ボタン押下時の処理
     */       
    async onClickConfirmOK() {
      // スポットタイプ処理確認モーダル非表示
      this.modals.confirmDlg = false;

      // 削除モードの場合は削除処理を呼び出す
      if (this.actionMode == 1) {
        this.deleteSpotType();
        return;
      }

      // ユーザー情報取得
      const user = await UserInfo.getUserInfo()

      // ------------------------------------------------------------------------
      // 登録、編集処理
      // ------------------------------------------------------------------------
      try {
        
        // 新規登録
        if (this.rowId == null){

          // アイコン画像指定しているかチェック
          
          if (this.iconFileType == "1" && this.uploadIconFileList.length < 1) {
            this.showBottomToast(`アイコン画像を指定してください。`, 'warning')  
            return;
          }
          if (this.iconFileType == "0" && this.originalSpotType == "") {
            this.showBottomToast(`アイコン画像を選択してください。`, 'warning')  
            return;
          }

          // 指定した名称が既に使用されていないかチェック
          let checkList = this.spotTypeEditList.filter((list) => list.spottype.name == this.spotTypeFormData.name);
          if (checkList.length > 0) {
            this.showBottomToast(`${this.spotTypeFormData.name}は、既に使用されています。別の名称を指定してください。`, 'warning')  
            return;
          } 

          // ダイアログ画面で指定した値をセット
          let regData = await SpotTypeModel.getNewData(this.projectId);
          regData.spottype.name = this.spotTypeFormData.name;
          regData.spottype.voicetext = this.spotTypeFormData.voicetext;
          regData.spottype.spotType = this.spotTypeFormData.spotType;
          regData.spottype.order = this.spotTypeEditList.length + 1;

          // アイコン画像をS3にアップロード 
          if (this.iconFileType == "0") {
            // オリジナル画像をpublicからダウンロードしてS3にアップロード
            let list = this.originalSpotTypeList.filter((list) => list.sk == this.originalSpotType);
            if (list.length > 0) {
              console.log(`list.iconpath ${list[0].iconpath}`)
              
              // パブリックフォルダのアイコンをダウンロード
              let iconData = await Storage.get(list[0].iconpath, {level: "public", download: true})
              const ext = await Mime.getExt(iconData.Body)
              // ファイル名を生成
              const iconFileName = `${DateUtil.dateStringBase(new Date(), `YYYY-MM-DD_HH-mm-ss-SSS`)}.${ext}`
              // テナント別のS3パスを作成
              const s3Key = `tenant/${user.group}/${this.projectId}/spottype/${iconFileName}`
              // アップロード
              let tenantStorage = new TenantStorage(user.group)
              const response = await tenantStorage.put(s3Key, iconData.Body)
              
              regData.spottype.iconpath = s3Key
            }
            
            
          } else {
            // ユーザー選択画像をアップロード
            const s3KeyList = await this.uploadIconFile(this.uploadIconFileList);
            s3KeyList.forEach(item => {
              regData.spottype.iconpath = item;  
            });
          }
          

          // DB更新
          await SpotTypeModel.addSpotType(regData);

        } else {
          
          // アイコン画像を一旦削除してから、新たにアイコン画像を指定していない場合はエラー
          if (this.iconFileType == "1" && this.spotTypeFormData.img == null && this.uploadIconFileList.length < 1) {
            this.showBottomToast(`アイコン画像を指定してください。`, 'warning')  
            return;
          }                

          // 名称が変更されているとき、指定した名称が既に使用されていないかチェック
          if (this.selectedData.spottype.name != this.spotTypeFormData.name){
            let checkList = this.spotTypeEditList.filter((list) => list.spottype.name == this.spotTypeFormData.name);
            if (checkList.length > 0) {
              this.showBottomToast(`${this.spotTypeFormData.name}は、既に使用されています。別の名称を指定してください。`, 'warning')  
              return;
            }
          }

          // ダイアログ画面で指定した値をセット            
          let editData = this.selectedData;
          editData.spottype.name = this.spotTypeFormData.name;
          editData.spottype.voicetext = this.spotTypeFormData.voicetext;
          editData.spottype.spotType = this.spotTypeFormData.spotType;
          editData.spottype.order = this.spotTypeFormData.spotType.order;

          // アイコン画像が変更されている場合は、元の画像をS3から削除、新しい画像をS3へアップロード
          // アイコン画像をS3にアップロード 
          if (this.iconFileType == "0") {
            // 元画像のパスを退避
            let orgIconPath = editData.spottype.iconpath;
            // オリジナル画像をpublicからダウンロードしてS3にアップロード
            let list = this.originalSpotTypeList.filter((list) => list.sk == this.originalSpotType);
            if (list.length > 0) {
              console.log(`list.iconpath ${list[0].iconpath}`)
              
              // パブリックフォルダのアイコンをダウンロード
              let iconData = await Storage.get(list[0].iconpath, {level: "public", download: true})
              const ext = await Mime.getExt(iconData.Body)
              // ファイル名を生成
              const iconFileName = `${DateUtil.dateStringBase(new Date(), `YYYY-MM-DD_HH-mm-ss-SSS`)}.${ext}`
              // テナント別のS3パスを作成
              const s3Key = `tenant/${user.group}/${this.projectId}/spottype/${iconFileName}`
              // アップロード
              let tenantStorage = new TenantStorage(user.group)
              const response = await tenantStorage.put(s3Key, iconData.Body)
              
              editData.spottype.iconpath = s3Key
              // 元の画像削除
              await this.deleteIconFile(orgIconPath);
              
              
            }
            
            
          } else {
            if (this.uploadIconFileList.length > 0){
              // 元画像のパスを退避
              let orgIconPath = editData.spottype.iconpath;
              // S3へアップロード
              const s3KeyList = await this.uploadIconFile(this.uploadIconFileList);
              s3KeyList.forEach(item => {
                editData.spottype.iconpath = item;  
              });
              // 元の画像削除
              await this.deleteIconFile(orgIconPath);
            }

          }
          
          // 一覧に表示するために追加したカラムを削除
          delete editData["spotDivName"]

          // DB更新
          await SpotTypeModel.updateSpotType(editData)
        }

        // モーダル画面閉じる
        this.modals.editSpotTypeDlg = false;

        // 一覧情報再取得
        await this.getSpotTypeList();

        // 完了メッセージ
        this.showBottomToast(`スポットタイプ情報更新処理が完了しました。`, 'info')

      } catch (e) {
        //エラー外メッセージ表示
        this.showBottomToast(`スポットタイプ情報更新処理に失敗しました。`, 'error')
        this.errorLog("onClickConfirmOK", this.parseErrorObject(e))
        // appLog.errLog("DriveSpot.vue", this.$store.state.user.userId, `${e}`)
        throw e
      }         
    },
    /**
     * アイコンアップロード
     */
    async uploadIconFile(files){
      // ユーザー情報取得
      const user = await UserInfo.getUserInfo()
      // テナント毎のフォルダにアップロードするユーティリティクラスを初期化
      let tenantStorage = new TenantStorage(user.group)

      const s3KeyList = []
      try {
        // ファイル数分繰り返し
        for (let i = 0; i < files.length;i++) {
          // ファイルのバイナリ先頭4バイトからファイルタイプを判定して拡張子取得
          const ext = await Mime.getExt(files[i].raw)
          if (!ext) {
            alert(`拡張子判定不可`)
            break
          }
          // ファイル名生成
          const fileName = `${DateUtil.dateStringBase(new Date(), `YYYY-MM-DD_HH-mm-ss-SSS`)}.${ext}`
          // S3キーを構成
          const s3Key = `tenant/${user.group}/${this.projectId}/spottype/${fileName}`
          // アップロード
          await tenantStorage.put(s3Key, files[i].raw)

          s3KeyList.push(s3Key)
        }
      } catch (e) {
        this.errorLog("uploadIconFile", this.parseErrorObject(e))
        // console.log(`アップロードエラー ${JSON.stringify(e)}`)
        throw e
      }
      return s3KeyList
    },   
    
    /**
     * スポットタイプ削除処理
     */
    async deleteSpotType()
    {
      try {

        // スポット取得
        const spotList = await SpotModel.getSpotList(this.projectId);

        for (let i=0; i<this.selectedDatas.length; i++){
          // 削除しようとしているアイコンが既に使用されていないかチェック
          let iconList = spotList.filter((list) => list.spot.spottypeid == this.selectedDatas[i].spottype.spottypeId);
          if (iconList.length > 0) {
            this.showBottomToast(`${this.selectedDatas[i].spottype.name}は、地図上に登録されています。地図上から${this.selectedDatas[i].spottype.name}を削除してから再度実行してください。`, 'warning')  
            return;
          }

        }
        for (let i=0; i<this.selectedDatas.length; i++){
          // アイコン削除
          await this.deleteIconFile(this.selectedDatas[i].spottype.iconpath);

          // 削除処理
          await SpotTypeModel.deleteSpotType(this.selectedDatas[i]);
        }
        
        // 一覧情報再取得
        await this.getSpotTypeList();

        // 完了メッセージ
        this.showBottomToast(`スポットタイプの削除処理が完了しました。`, 'info')

      } catch (e) {
        //エラー外メッセージ表示
        this.showBottomToast(`スポットタイプの削除処理に失敗しました。`, 'error')
        this.errorLog("deleteSpotType", this.parseErrorObject(e))
        // appLog.errLog("PlanEdit.vue", this.$store.state.user.userId, `${e.message}`)
      }       
    },
    /**
     * アイコン画像ファイル削除
     */
    async deleteIconFile(sKey){

      try {

        // ユーザー情報取得
        const user = await UserInfo.getUserInfo()
        // S3からアイコン削除するユーティリティクラスを初期化
        let tenantStorage = new TenantStorage(user.group);
        // アイコン削除
        await tenantStorage.delete(sKey);      

      } catch (e) {
        this.errorLog("deleteIconFile", this.parseErrorObject(e))
        // console.log(`アイコン画像ファイル削除エラー ${JSON.stringify(e)}`)
        throw e
      }
    },     
    /**
     * スポットタイプ処理確認モーダル キャンセルボタンクリックイベント
     */
    onClickConfirmCancel() {
      this.modals.confirmDlg = false;
    },    
    /**
     * テーブルの行選択時の処理
     */       
    handleSelectRow(selection, row) {

      if (this.userAuthority == 0)
      {
        this.$refs.AcceptanceTable.clearSelection()
        this.selectedData = null
        return 
      }

      // 選択行数をセット
      this.selectedDataCnt = selection.length  
      // 選択行数が1件の場合は、編集とコピーボタン表示 
      if (this.selectedDataCnt == 1){
        this.isEdit = true; 
        this.isOrderMove = true;
      } else {
        this.isEdit = false;
        this.isOrderMove = false;
      }

      // チェックＯＮ、ＯＦＦ
      if (this.selectedData == row) {
        // 同一業の場合はチェックOFF
        this.selectedData = null;
      } else {
        this.selectedData = row;
      }

      // 1件の時は、選択行にセット（チェックＯＦＦした結果、別の行の1件がカレント行になるように）
      if (selection.length == 1){
        this.selectedData = selection[0]
      }

      // 選択行保持
      this.selectedDatas = selection

      // 上下矢印の有効、無効を設定
      const nowIndex = this.getRowIndex(this.selectedData.id);
      this.controlMoveButton(nowIndex);

    },
    /**
     * テーブルの全チェックボックスをチェックした時の処理
     */       
    handleSelectAllRow(selection) {

      if (this.userAuthority == 0){
        this.$refs.PlanTable.clearSelection()
        this.selectedData = null
        return 
      }

      // 選択行数をセット
      this.selectedDataCnt = selection.length
      // 選択行数が1件の場合は、編集とコピーボタン表示 
      if (this.selectedDataCnt == 1){
        this.isEdit = true; 
        this.isOrderMove = true;
      } else {
        this.isEdit = false;
        this.isOrderMove = false;
      }
      // 選択行をセット
      this.selectedDatas = selection

    },
    /**
     * 上へ、下への矢印ボタン押下時の処理
     */       
    async onClickRowMove(direction) {
      // 連続押下防止
      if (this.processing) return;

      this.processing = true;

      await this.updateOrder(direction)
      setTimeout(function() {
        this.processing = false;
      }.bind(this), 100)      
    },
    /**
     * 上へ、下への矢印ボタン押下時の処理
     */       
    async updateOrder(direction) {
      // return new Promise((resolve) => {
      //   setTimeout(() => {
      // 対象のテーブル
      let dataList = this.spotTypeEditList;
      // 対象の行
      let currentRow =this.selectedData;

      // 現在の行インデックス
      const nowIndex = this.getRowIndex(currentRow.id);
      
      // 移動後のインデックス
      let afterIndex = nowIndex + 1;
      if (direction == 'up') {
        afterIndex = nowIndex - 1;
      }

      // 連打された時の対応
      if (afterIndex < 0) afterIndex = 0
      if (afterIndex > this.spotTypeEditList.length - 1) afterIndex = this.spotTypeEditList.length - 1

      // リストの中から2つのインデックスに該当する行を抽出し、
      // インデックスを入れ替えた値でorderを更新
      // ※order順で並んでいる想定
      // ※先頭、末尾行への考慮はボタンのdisableで制御済みの想定
      dataList[nowIndex].spottype.order = afterIndex;
      dataList[afterIndex].spottype.order = nowIndex;

      // 明示的に並び替える
      dataList.sort(function(a, b) {
        if (a.spottype.order < b.spottype.order) return -1;
        if (a.spottype.order > b.spottype.order) return 1;
        return 0;
      })        

      // DBを更新
      // チェックボックスの再チェック処理がもっさりするのでDB更新は非同期で実行する
      this.saveSpotType(nowIndex, afterIndex).then(() => {
      })
        
      // 上へ下へボタン活性、非活性にする
      this.controlMoveButton(afterIndex);

      // チェックが外れてしまうので、移動後のデータを再チェック
      this.$nextTick(function() {
        this.$refs.SpotTypeTable.toggleRowSelection(currentRow, true);
      }.bind(this))
      
    },
    /**
     * テーブル行から指定したidのインデックスを取得します。
     * @params {string} id ID
     * @returns {number} インデックス
     */
    getRowIndex(id) {
      // 対象のテーブル
      let dataList = this.spotTypeEditList;

      let currentIndex = dataList.findIndex(item => {
        return item.id == id
      })
    
      return currentIndex;
    },

    /**
     * 車両情報を保存する（変更行のソート番号更新）
     */
    async saveSpotType(nowIndex, afterIndex) {
      try {
        
        // レコード移動前のソート番号を更新
        let updateItem = clone(this.spotTypeEditList[nowIndex])
        delete updateItem["spotDivName"]
        
        await SpotTypeModel.updateSpotType(updateItem)

        // レコード移動後のソート番号を更新
        updateItem = clone(this.spotTypeEditList[afterIndex])
        delete updateItem["spotDivName"]

        await SpotTypeModel.updateSpotType(updateItem)

        // スポット区分名を設定
        // this.spotTypeEditList.forEach(item => {              
        //   for (let i=0; i<this.spotDivList.length; i++) {
        //     if (this.spotDivList[i].id == item.spottype.spotType) {
        //       item.spotDivName = this.spotDivList[i].name;
        //       break;
        //     }
        //   }
        // });

      } catch (e) {
        this.showBottomToast(`車両一覧のソート順番保存処理に失敗しました。`, 'error')
        appLog.errLog("DriveSpot.vue", this.$store.state.user.userId, `${e.message}`)
        throw e
      }
    },
    /**
     * 上へ下へボタンの活性、非活性の設定
     */ 
    controlMoveButton(index)
    {
      if (index == 0)
      {
        this.disableMoveDown = false;
        this.disableMoveUp = true;
      } else if (index == this.spotTypeEditList.length - 1)
      {
        this.disableMoveDown = true;
        this.disableMoveUp = false;
      } else 
      {
        this.disableMoveDown = false;
        this.disableMoveUp = false;
      }
    },

    /**
     * スポット登録ボタンクリック時の処理
     */
    async onClickDriveSpot() {

      // ストアに値セット
      let store = this.$store.state.drivespot;
      store.projectid = this.projectId;
      store.projectname = this.projectName;
      store.datestring = this.datestring;

      await this.refreshSpotTypeList();
      store.spotTypeList = this.spotTypeList;
      await this.$store.commit("setDriveSpot", store);

      // スポット登録画面起動
      this.$router.push({
        path: "/driveSpot",
      });
    },

    /**
     * スポット設定ボタンクリック時の処理
     */
    async onClickDriveSpotSetting() {

      // ストアに値セット
      let store = this.$store.state.drivespot;
      store.projectid = this.projectId;
      store.projectname = this.projectName;
      store.datestring = this.datestring;

      await this.refreshSpotTypeList();
      store.spotTypeList = this.spotTypeList;
      await this.$store.commit("setDriveSpot", store);

      // スポット設定画面起動
      this.$router.push({
        path: "/driveSpotSetting",
      });
    },

    /**
     * ストアに渡すようにスポットタイプリストを再作成
     */
    async refreshSpotTypeList() {

      // 初期化
      this.spotTypeList = [];

      // スポットタイプリスト再作成
      for (let i=0; i<this.spotTypeEditList.length; i++){
        let item = this.spotTypeEditList[i];
        this.spotTypeList.push({
          name: `${item.spottype.name}`,
          value: `${item.spottype.spottypeId}`,
          voicetext: `${item.spottype.voicetext}`,
          iconpath: `${item.spottype.iconpath}`,
          iconUrl: `${this.icomImageList[item.spottype.spottypeId]}`,
          spotType: `${item.spottype.spotType}`,
        });
      }
    },
    /**
     * コンソール出力のみ。
     */
    debugLog(funcName, message) {
      try {
        this.base_debugLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e)
      }
    },
    /**
     * AmplifyのAPI経由でS3にINFOログが残る
     */
    infoLog(funcName, message) {
      try {
        this.base_infoLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e)
      }
    },
    /**
     * AmplifyのAPI経由でS3にERRORログが残る
     */
    errorLog(funcName, message) {
      try {
        this.base_errorLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラー破棄
        console.log(e)
      }
    },
  },
};
</script>
