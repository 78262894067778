<template>
  <div ref="mapContainer" class="map-container" stlye="position:relative;">
    <div id="map" class="map">
      <base-button
        class="btn-white btn-icon-only overmap-bottom-right leaflet-bar leaflet-touch"
        size="sm"
        @click="resetLocation"
        icon
      >
        <i class="fa fa-home fa-lg"></i>
      </base-button>
    </div>
  </div>
</template>

<style scoped>
/** このVueだけのスタイル */
.map-container {
  width: 100%;
  height: 100%;
  /* border:1px solid green; */
}

.map {
  /* width: 500px;
    height: 500px; */

  width: 100%;
  height: 100%;
  /* border:1px solid blue; */
}

.overmap-bottom-right {
  position: absolute;
  right: 32px;
  bottom: 24px;
  width: 32px;
  height: 32px;
  padding: 0px 0px 0px 0px;
  z-index: 1001;
}
</style>
<style>
/** 全体に影響するスタイル */
/* :deep .leaflet-interactive {
  cursor: grab !important;
} */
</style>
<script>
import appLog from "@/appUtils/AppLog";
import LastLocationModel from "@/appModel/Location/LastLocationModel";
import BaseAreaModel from "@/appModel/BaseArea/BaseAreaModel";
import SpotTypeModel from "@/appModel/Spot/SpotTypeModel";
import SpotModel from "@/appModel/Spot/SpotModel";
import DateUtil from "@/appUtils/DateUtil";
import SortUtil from "@/appUtils/SortUtil";
import CoordinateUtil from "@/appUtils/CoordinateUtil";
import UserInfo from "@/appUtils/UserInfo.js";
import TenantStorage from "@/appUtils/TenantStorage";
import UseApps from "@/appViews/common/UseApps.js";
import Logger from "@/appViews/common/Logger.js";
import xss from 'xss';

// import UserInfo from "@/appUtils/UserInfo"
const { Storage } = require("aws-amplify");

// ジオフェンス図形種別: 円
const ID_SHAPE_TYPE_CIRCLE = "0";

// // data()内に保持するとLeafletの挙動がおかしくなります。
// let mlMap = null;

export default {
  components: {},
  mixins: [UseApps, Logger],
  data() {
    return {
      startAreaList: [
        // {
        //   id: '1',
        //   areaName: '〇〇工場',
        //   areaType: 1,
        //   x: 135.492572,
        //   y: 34.665383,
        //   radius: 200
        // },
        // {
        //   id: '2',
        //   areaName: '△△◇◇工場',
        //   areaType: 1,
        //   x: 135.499749,
        //   y: 34.667567,
        //   radius: 200
        // },
      ],
      endAreaList: [
        // {
        //   id: '3',
        //   areaName: 'A工区',
        //   areaType: 2,
        //   x: 135.496370,
        //   y: 34.679084,
        //   radius: 100
        // },
        // {
        //   id: '4',
        //   areaName: 'B工区',
        //   areaType: 2,
        //   x: 135.497582,
        //   y: 34.679464,
        //   radius: 100
        // },
      ],
      locationList: [
        {
          // 初回判定用ダミーデータ
          id: "",
          x: 0.0,
          y: 0.0,
          driveId: "hogehoge",
          vehicleId: "hogehoge",
          endAreaId: "",
          pouringPosition: "",
          carryType: 1,
          // 運行情報と紐づけて追加
          roadType: 0,
          // 出発順 or 車両表示順（動的更新）
          label: "",
          // 車両情報と紐づけて追加
          order: 0,
          // 渋滞フラグ
          congestionFlag: 0,
        },
        // {
        //   id: '1',
        //   x: 135.496241,
        //   y: 34.665348,
        //   driveId: 'hogehoge',
        //   vehicleId: 'hogehoge',
        //   endAreaId: '3',
        //   pouringPosition: '1F 床',
        //   carryType: 1,
        //   // 運行情報と紐づけて追加
        //   roadType: 1,
        //   // 出発順 or 車両表示順（動的更新）
        //   label: '1',
        //   // 車両情報と紐づけて追加
        //   order: 1
        // },
        // {
        //   id: '2',
        //   x: 135.500253,
        //   y: 34.675981,
        //   driveId: 'hogehoge',
        //   vehicleId: 'hogehoge',
        //   endAreaId: '3',
        //   pouringPosition: '1F 床',
        //   carryType: 1,
        //   // 運行情報と紐づけて追加
        //   roadType: 2,
        //   // 出発順 or 車両表示順（動的更新）
        //   label: '2',
        //   // 車両情報と紐づけて追加
        //   order: 2
        // },
        // {
        //   id: '3',
        //   x: 135.496450,
        //   y: 34.678824,
        //   driveId: 'hogehoge',
        //   vehicleId: 'hogehoge',
        //   endAreaId: '3',
        //   pouringPosition: '1F 床',
        //   carryType: 1,
        //   // 運行情報と紐づけて追加
        //   roadType: 0,
        //   // 出発順 or 車両表示順（動的更新）
        //   label: '3',
        //   // 車両情報と紐づけて追加
        //   order: 3
        // },
        // {
        //   id: '4',
        //   x: 135.492851,
        //   y: 34.664969,
        //   driveId: 'hogehoge',
        //   vehicleId: 'hogehoge',
        //   endAreaId: '3',
        //   pouringPosition: '1F 床',
        //   carryType: 1,
        //   // 運行情報と紐づけて追加
        //   roadType: 0,
        //   // 出発順 or 車両表示順（動的更新）
        //   label: '4',
        //   // 車両情報と紐づけて追加
        //   order: 4
        // },
      ],
      // 運行情報表示順(1:工場出発順、2:車両表示順)
      locationOrder: 1,
      projectId: "",
      endAreaId: "",
      // 車両ID、地図アイコン注記用表示順(1~)
      vehicleMapTextOrders: [
        // {
        //   vehicleId: 'hogehoge',
        //   iconTextOrder: 1,
        // }
      ],
      // selectedEndAreaPouringPosition: {
      //   endAreaId: "",
      //   pouringPosition: "",
      // },
      // beforeDrawBaseAreaID: "",
      // beforeDrawBaseAreaLayerName: "",
      selectedEndAreaPouringPosition: [],
      beforeDrawBaseAreaID: [],
      beforeDrawBaseAreaLayerName: [],
      spotTypeList: [],
      spotList: [],
      isMovingResetLocation: false,
      congestionTextIds: [],
    };
  },

  computed: {
    vuename() {
      return "TimeLineMap.vue";
    },
  },

  beforeCreate() {
    // インスタンスは生成されたがデータが初期化される前
  },

  created() {
    // インスタンスが生成され､且つデータが初期化された後

    // ※画面表示に関連しないメンバ変数を定義

    // MLMap
    // data()内に保持するとLeafletの挙動がおかしくなります。
    this.mlMap = null;

    // 地図コンテナリサイズ監視オブザーバー
    this.resizeObserver = null;

    // ----------
    // 走行位置スタイル
    // ----------

    // 走行位置アイコンスタイル
    this.driveLocationStyles = [
      {
        layerName: "工場",
        iconUrl: "./img/concrete/ic_circle_grey.png",
        iconSize: [48, 48],
      },
      {
        layerName: "現場",
        iconUrl: "./img/concrete/ic_circle_red.png",
        iconSize: [48, 48],
      },
      {
        layerName: "往路",
        iconUrl: "./img/concrete/ic_circle_yellow.png",
        iconSize: [48, 48],
      },
      {
        layerName: "復路",
        iconUrl: "./img/concrete/ic_circle_blue.png",
        iconSize: [48, 48],
      },
    ];

    // 走行位置ラベルスタイル
    this.driveLocationLabelStyle = {
      color: "#263238",
      weight: 2,
      fillColor: "#FFFFFF",
      fontSize: this.isZando ? 14 : 24,
      textBaseline: "middle",
    };

    // ----------
    // 拠点スタイル
    // ----------

    // 工場アイコンスタイル
    this.startAreaStyle = {
      iconUrl: "./img/concrete/icon_2y_64.png",
      iconSize: [48, 48],
      iconOffset: [-24, -46],
      interactive: false,
    };

    // 現場アイコンスタイル
    this.endAreaStyle = {
      iconUrl: "./img/concrete/icon_1r_64.png",
      iconSize: [48, 48],
      iconOffset: [-24, -48],
      interactive: false,
    };

    // 残土現場アイコンスタイル
    this.soilStartAreaStyle = {
      iconUrl: "./img/concrete/icon_1r_64.png",
      iconSize: [48, 48],
      iconOffset: [-24, -46],
      interactive: false,
    };

    // 残土土捨場アイコンスタイル
    this.soilEndAreaStyle = {
      iconUrl: "./img/concrete/soil_goal.png",
      iconSize: [48, 48],
      iconOffset: [-24, -48],
      interactive: false,
    };

    // 拠点ラベルスタイル
    this.areaLabelStyle = {
      color: "#FFFFFF",
      weight: 4,
      fillColor: "#263238",
      fontSize: 16,
      textBaseline: "top",
      // 非表示テスト
      // fillOpacity: 0.0,
      // opacity: 0.0,
    };

    // ジオフェンスポリゴンスタイル
    this.geofenceStyle = {
      // color: "#B71C1C",
      color: "#E65100",
      weight: 2.5,
      fill: true,
      // fillColor: "#B71C1C",
      fillColor: "#E65100",
      fillOpacity: 0.35,
      opacity: 0.9,
      interactive: false,
    };

    // ----------
    // 運行スポットスタイル
    // ----------

    // 運行スポットアイコンスタイル
    this.spotStyle = {
      iconSize: [38, 38],
      iconOffset: [-19, -38],
      interactive: false,
    };

    // 運行スポットラベルスタイル
    this.spotLabelStyle = {
      color: "#263238",
      weight: 2,
      fillColor: "#FFFFFF",
      fontSize: 24,
      textBaseline: "middle",
      fillOpacity: 0,
      opacity: 0,
    };

    // 運行スポットポリゴンスタイル
    this.spotPolygonStyle = {
      color: "#021aee",
      weight: 2.5,
      fill: true,
      fillColor: "#021aee",
      fillOpacity: 0.35,
      opacity: 0.9,
      interactive: false,
    };

    // 運行間隔スポットポリゴンスタイル
    this.intervalSpotPolygonStyle = {
      color: "#008A00",
      weight: 2.5,
      fill: true,
      fillColor: "#008A00",
      fillOpacity: 0.35,
      opacity: 0.9,
      interactive: false,
    };    
    this.roadStyle = {
      "0": {
        color: "#FF6F00",
        weight: 5,
        opacity: 0.9,
        fillOpacity: 0.15,
        interactive: false,
      },
      "1": {
        color: "#1565C0",
        weight: 5,
        opacity: 0.9,
        fillOpacity: 0.15,
        interactive: false,
      }
    }
  },

  beforeMount() {
    // インスタンスが DOM 要素にマウントされる前
  },

  mounted() {
    // インスタンスが DOM 要素にマウントされた後
    appLog.infoLog(`${this.vuename}`, `Start ${this.vuename}`);

    // // 地図生成
    // await this.init();

    // 地図コンテナのリサイズ監視開始
    this.addResizeObserver();

    // ポップアップコンテンツ内の閉じるボタン(Vue外)からメソッドを呼び出せるように外部へ
    window.closePopup = this.closePopup;
  },

  beforeUpdate() {
    // データは更新されたが DOM に適用される前
  },

  updated() {
    //データが更新され､且つ DOM に適用された後
  },

  beforeUnmount() {
    // Vue インスタンスが破壊される前

    // 地図コンテナのリサイズ監視終了
    this.removeResizeObserver();
  },

  unmounted() {
    //Vue インスタンスが破壊された後

    // マップリソース解放
    if (this.mlMap) {
      this.mlMap.off("click", this.onMapClick);
      this.mlMap.off("layerremove", this.onMapLayerRemove);
      this.mlMap.off("layeradd", this.onMapLayerAdd);

      this.mlMap.remove();
    }

    // 外部ポップアップクローズメソッドをクリア
    window.closePopup = null;
  },

  // ボタンイベントなどのメソッドはmethodsに
  methods: {
    /**
     * 地図コンテナのリサイズ監視を開始します。
     */
    addResizeObserver() {
      this.resizeObserver = new ResizeObserver(this.onResize);
      this.resizeObserver.observe(this.$refs.mapContainer);
    },

    /**
     * 地図コンテナのリサイズ監視を終了します。
     */
    removeResizeObserver() {
      this.resizeObserver.unobserve(this.$refs.mapContainer);
    },

    /**
     * 地図コンテナリサイズイベント
     */
    onResize() {
      console.log(`onResize`);

      // Leafletの地図領域を更新
      if (this.mlMap) {
        this.mlMap.invalidateSize();
      }
    },

    /**
     * 初期処理を実施します。
     */
    async init(listBaseArea) {
      console.log("[START] init");

      try {
        // 呼び出し元で指定されたプロジェクトID、日付を取得
        // this.projectId = this.$store.state.timeline.projectid;
        // const storeDateString = this.$store.state.timeline.datestring;
        // const sk = `${this.projectId}#${DateUtil.getFormatString(
        //   storeDateString,
        //   "YYYYMMDD"
        // )}`;

        // 拠点情報の初期化
        this.initBaseArea(listBaseArea);

        // // 運行データ（最終更新座標）※並び順は初回1固定
        // await this.initDriveLocation(sk, 1);

        ///////////////////////////////////
        // 高速化のためスポットタイプ、運行スポットを並列で取得
        //await Promise.all([this.initSpotType(), this.initSpot()]);

        // スポットタイプ取得
        await this.initSpotType();
        
        // 運行スポット取得
        await this.initSpot();
        ///////////////////////////////////

        // 地図生成
        this.mapCreate();
      } finally {
        console.log("[END] init");
      }
    },

    /**
     * 拠点情報データの初期化
     */
    initBaseArea(listBaseArea) {
      try {
        // console.log('projectId:' + this.projectId);

        // // DBから検査箇所情報のレコード取得
        // const baseAreaList = await BaseAreaModel.getBaseAreaList(
        //   this.projectId
        // );
        // console.log(baseAreaList);

        // Polygon描画機能追加前の既存円は
        // shapeTypeとgeofenceがない場合があるのでここで設定しておく
        listBaseArea.forEach((item) => {
          // 図形種別
          item.baseArea.shapeType = this.normalizeShapeType(
            item.baseArea.shapeType
          );

          // ジオフェンス座標圧縮文字列
          if (!item.baseArea.geofence) {
            item.baseArea.geofence = this.getCompressCoordsText(
              item.baseArea,
              null
            );
          }
        });

        const baseAreas = [];
        for (let i = 0; i < listBaseArea.length; i++) {
          baseAreas.push(listBaseArea[i].baseArea);
        }

        // 出発地
        this.startAreaList = baseAreas
          .filter((list) => list.areaType === 1)
          .slice()
          .sort(function(a, b) {
            if (a.id < b.id) return -1;
            if (a.id > b.id) return 1;
            return 0;
          });

        // 到着地
        this.endAreaList = baseAreas
          .filter((list) => list.areaType === 2)
          .slice()
          .sort(function(a, b) {
            if (a.id < b.id) return -1;
            if (a.id > b.id) return 1;
            return 0;
          });

        // console.log(
        //   `出発地点数：${this.startAreaList.length} 到着地点数：${this.endAreaList.length}`
        // );
      } catch (e) {
        alert(`${JSON.stringify(e, null, "\t")}`);
        throw e;
      }
    },

    /**
     * 正常なジオフェンス図形種別を取得します。
     * 図形種別が設定されていない場合は円の図形種別を返します。
     * @param {string} shapeType 図形種別
     * @returns {string} 図形種別
     */
    normalizeShapeType(shapeType) {
      // 設定されていない場合は円をデフォルト
      if (!shapeType) {
        return ID_SHAPE_TYPE_CIRCLE;
      }

      return shapeType;
    },

    /**
     * 拠点ジオフェンスの座標圧縮文字列を取得します。
     * @param {Object} baseArea 拠点情報
     * @param {Object} polygon Leaflet Polygon Layer(拠点形状がポリゴンの場合のみ)
     */
    getCompressCoordsText(baseArea, polygon) {
      // 設定されていない場合は円をデフォルト
      let shapeType = this.normalizeShapeType(xss(baseArea.shapeType));

      let latlngs;

      if (shapeType == ID_SHAPE_TYPE_CIRCLE) {
        // 中心座標、半径から、ポリゴン座標を取得
        latlngs = CoordinateUtil.getCirclePolygonPoints(
          new L.LatLng(xss(baseArea.y), xss(baseArea.x)),
          xss(baseArea.radius)
        );
      } else {
        // 外形の座標のみ
        latlngs = polygon.getLatLngs()[0];
      }

      // ポリゴン座標を座標圧縮文字列にする
      return MLCompressUtil.latLngArrayToBase64(latlngs);
    },

    /**
     * 運行位置データを初期化します。
     */
    async initDriveLocation(sk, orderType) {
      try {
        
        console.log(`initDriveLocation ${JSON.stringify(this.vehicleMapTextOrders, null, "\t")}`)
        // DBから運行位置レコード取得
        let newListAll = await LastLocationModel.getLastLocationMapList(
          sk,
          orderType,
          this.vehicleMapTextOrders
        );

        // 到着地点ID(現場ID)と打設箇所でフィルター
        // let newList = newListAll.filter((item) => {
        //   if (
        //     item.endAreaId === this.selectedEndAreaPouringPosition.endAreaId &&
        //     item.pouringPosition ===
        //       this.selectedEndAreaPouringPosition.pouringPosition
        //   ) {
        //     return true;
        //   }
        // });
        let newList = [];
        // console.log(`initDriveLocation ${JSON.stringify(this.selectedEndAreaPouringPosition, null, "\t")}`)
        // console.log(`initDriveLocation ${JSON.stringify(newListAll, null, "\t")}`)
        
        for (let i=0; i<this.selectedEndAreaPouringPosition.length; i++) {
          let workList = newListAll.filter((item) => {
            if (
              item.endAreaId === this.selectedEndAreaPouringPosition[i].endAreaId &&
              item.pouringPosition ===
                this.selectedEndAreaPouringPosition[i].pouringPosition
            ) {
              return true;
            }
          });

          for (let j=0; j<workList.length; j++) {
            newList.push(workList[j]);
          }
        }

        // 前回と一致していれば更新しない
        if (JSON.stringify(this.locationList) == JSON.stringify(newList)) {
          return false;
        }

        this.locationList = newList;

        console.log("運行位置数:" + this.locationList.length);

        // 種別ごとにサマリー
        let roundSummarys = this.getRoundSummarys(this.locationList);

        // 親vueへ通知
        this.$emit("updateSummarys", roundSummarys);

        return true;
      } catch (e) {
        alert(`${JSON.stringify(e.errors, null, "\t")}`);
        throw e;
      }
    },

    /**
     * 車両が現場にいるか判定する
     */
    isGenba(location) {
      try {
        return (
          // location.factoryStartTime &&
          location.siteEndTime && !location.factoryEndTime
        );
      } catch (e) {
        return false;
      }
    },

    /**
     * 運行位置リストからサマリ情報を取得します。
     *
     * @param {Object} locations 運行位置リスト
     * @returns 運行サマリ情報
     */
    getRoundSummarys(locations) {
      let goCount = 0;
      let comeCount = 0;
      let otherCount = 0;
      let genbaCount = 0;

      for (let i = 0; i < locations.length; i++) {
        const location = locations[i];
        switch (location.roadType) {
          case 1:
            goCount++;
            break;

          case 2:
            comeCount++;
            break;

          default:
            // roadTypeが1,2以外で現場到着かつ工場未着であれば、現場内としてカウント
            if (this.isGenba(location)) {
              genbaCount++;
            } else {
              otherCount++;
            }
            break;
        }
      }

      let roundSummarys = [];

      // 往路走行数
      roundSummarys.push({
        label: `往路`,
        img: "./img/concrete/ic_circle_yellow.png",
        count: goCount,
      });

      // 復路走行数
      roundSummarys.push({
        label: `復路`,
        img: "./img/concrete/ic_circle_blue.png",
        count: comeCount,
      });

      // 現場到着数
      roundSummarys.push({
        label: `現場`,
        img: "./img/concrete/ic_circle_red.png",
        count: genbaCount,
      });

      return roundSummarys;
    },

    /**
     * 運行スポットを取得します。
     */
    async initSpot() {
      let list = await SpotModel.getSpotList(this.projectId);

      for (let index = 0; index < list.length; index++) {
        let item = list[index];

        let spotType = this.spotTypeList.filter((ret) => ret.value == item.spot.spottypeid);
        if (spotType.length > 0){
          this.spotList.push({
            id: item.spot.id,
            //spotTypeName: item.spot.name,
            spotTypeName: xss(spotType[0].name),
            spotType: xss(spotType[0].spotType),
            range: xss(item.spot.range),
            x: xss(item.spot.x),
            y: xss(item.spot.y),
            shapeType: xss(item.spot.shapeType),
            geofence: xss(item.spot.geofence)
          });
        }
      }
    },

    /**
     * スポットタイプを取得します。
     */
    async initSpotType() {
      // スポットタイプ取得
      let list = await SpotTypeModel.getSpotTypeList(this.projectId);
      
      // orderでソート
      list = SortUtil.sort(list, "spottype.order", "ascending", false);

      // スポットタイプのリストにデータを追加
      let fn = async function(item) {
        this.spotTypeList.push({
          name: `${xss(item.spottype.name)}`,
          value: `${xss(item.spottype.spottypeId)}`,
          voicetext: `${xss(item.spottype.voicetext)}`,
          iconpath: `${item.spottype.iconpath}`,
          iconUrl: `${await this.getBase64Url(item.spottype.iconpath)}`,
          spotType: `${xss(item.spottype.spotType)}`,
        });
      }.bind(this)
      performance.mark('A1S')
      let promiseList = []
      if (list.length > 0) {
        for await (let item of list) {
          promiseList.push(fn(item))
          // this.spotTypeList.push({
          //   name: `${item.spottype.name}`,
          //   value: `${item.spottype.spottypeId}`,
          //   voicetext: `${item.spottype.voicetext}`,
          //   iconpath: `${item.spottype.iconpath}`,
          //   iconUrl: `${await this.getBase64Url(item.spottype.iconpath)}`,
          //   spotType: `${item.spottype.spotType}`,
          // });
        }
      }
      await Promise.all(promiseList)
      
      let result = performance.getEntriesByName('A1')
      
      this.$emit("updateSpotTypeList", this.spotTypeList);
    },

    /**
     * 画像ダウンロードとBase64エンコード
     */
    async getBase64Url(iconpath) {
      console.log(`[START] getBase64Url: ${iconpath}`);
      
      try {

        // ユーザー情報取得
        const user = await UserInfo.getUserInfo()
        // アイコンダウンロードするユーティリティクラスを初期化
        let tenantStorage = new TenantStorage(user.group)        
        // アイコンダウンロード
        let response = await tenantStorage.get(iconpath);
        // Base64にエンコードした値を返却する
        return await response.toBase64();

      } finally {
        console.log(`[END] getBase64Url`);
      }
    },

    /**
     * 地図の作成
     */
    mapCreate() {
      // MapletLeaflet関連出力ログレベル
      MLLog.level = MLLog.LOGLEVEL.INFO;

      // --------------------
      // レイヤ追加
      // --------------------

      let bounds = [];
      bounds = this.addLayers();

      // --------------------
      // 表示オブジェクト追加
      // --------------------

      // アイコン図形追加
      this.addPois();

      // --------------------
      // コントロール
      // --------------------

      this.mlMap.setLayerControlVisible(true);
      this.mlMap.setMapInfoControlVisible(true);
      //this.mlMap.setDrawControlVisible(true);

      // --------------------
      // 初期表示位置へ移動
      // --------------------
      // 図形からBBXを算出
      bounds = this.getPoiBounds();

      // // ローカルストレージに保存されているか(ToDo)
      // const skString = this.getSeekKeyString();
      // const lsBounds = JSON.parse(localStorage.getItem(skString));
      // console.log(lsBounds);

      // if (localStorage.getItem(skString)) {
      //   try {
      //     bounds = L.latLngBounds(
      //       [lsBounds._southWest.lat, lsBounds._southWest.lng],
      //       [lsBounds._northEast.lat, lsBounds._northEast.lng]);
      //   } catch (e) {
      //     localStorage.removeItem(skString);
      //   }
      // }

      // console.log(bounds)
      this.mlMap.fitBounds(bounds);

      // 図形の上下(Z-Order)を正しく描画
      this.mlMap.refreshZOrder();

      // --------------------
      // イベント
      // --------------------
      this.initMapEvent();
    },

    /**
     * ローカルストレージのキーを生成します。
     * @param {string} keyValue キー文字列
     * @returns ローカルストレージのキー文字列
     */
    createKeyString(keyValue) {
      return this.getSeekKeyString() + "#" + keyValue;
    },

    /**
     * sk文字列を取得します。
     */
    getSeekKeyString() {
      const prefix = `backImage`;
      return `${prefix}#${this.projectId}#${this.nodeId}`;
    },

    /**
     * レイヤ群を追加します。
     *
     * @returns 地図表示初期領域
     */
    addLayers() {
      // 初期表示領域
      let bounds = [];

      // 背景レイヤの追加
      bounds = this.addBaseLayers();

      // 拠点ジオフェンス範囲
      this.mlMap.addShapeLayer("拠点範囲");

      // アイコンレイヤ群追加
      this.addPoiLayers();

      return bounds;
    },

    /**
     * 背景レイヤを追加します。
     *
     * @returns 地図表示初期領域
     */
    addBaseLayers() {
      let bounds = [];
      // let ext = ``;

      // 地図
      bounds = this.addGsiLayers();

      return bounds;
    },

    /**
     * 国土地理院タイル地図レイヤを追加します。
     *
     * @returns 地図表示初期領域
     */
    addGsiLayers() {
      this.mlMap = null;
      this.mlMap = new MLMap("map", MLMap.VIEW_TYPE.MAP);

      this.mlMap.addGsiPaleLayer("地理院タイル 淡色地図", { opacity: 0.85 });
      this.mlMap.addGsiStdLayer("地理院タイル 標準地図", { opacity: 0.65 });
      this.mlMap.addGsiPhotoLayer("地理院タイル 写真", { opacity: 0.8 });

      this.mlMap.setView([35.68944, 139.69167], 12);

      const bounds = L.latLngBounds([25, 120], [45, 150]);
      // const bounds = L.latLngBounds([-90, -180], [90, 180]);
      this.mlMap.setMaxBounds(bounds.pad(0.25));

      return bounds;
    },

    /**
     * アイコンレイヤ群を追加します。
     */
    addPoiLayers() {
      // 工場・現場レイヤの追加
      this.addFactoryPlantLayers();

      // スポットタイプレイヤ追加
      this.addSpotTypeLayers();

      // 走行位置レイヤ追加
      this.addDriveLocationLayers();
    },

    /**
     * 工場・現場レイヤを追加します。
     */
    addFactoryPlantLayers() {
      try {
        // レイヤ数 = 工場(N) + 現場(N)

        // 工場(出発地点)
        this.addBaseAreaLayers(this.startAreaList);

        // 現場(到着地点)
        this.addBaseAreaLayers(this.endAreaList);
      } catch (e) {
        alert(`${JSON.stringify(e.errors, null, "\t")}`);
        throw e;
      }
    },

    /**
     * 拠点レイヤ群を追加します。
     */
    addBaseAreaLayers(baseAreas) {
      for (let i = 0; i < baseAreas.length; i++) {
        const area = baseAreas[i];

        // アイコン種別
        let poiStyle = null;

        if (area.areaType == 1) {
          // 工場
          poiStyle = this.isZando ? this.soilStartAreaStyle : this.startAreaStyle;
        } else {
          // 現場
          poiStyle = this.isZando ? this.soilEndAreaStyle : this.endAreaStyle;
        }

        let layerName = `${area.areaType}_${area.areaName}`;

        // 拠点レイヤ追加
        this.mlMap.addPoiLayer(xss(layerName), poiStyle, this.areaLabelStyle);
      }
    },

    /**
     * 走行位置レイヤ群を追加します。
     */
    addDriveLocationLayers() {
      for (let i = 0; i < this.driveLocationStyles.length; i++) {
        const style = this.driveLocationStyles[i];

        // アイコン種別
        let poiStyle = { iconUrl: style.iconUrl, iconSize: style.iconSize };

        let layerName = style.layerName;

        // 拠点レイヤ追加
        console.log(`TimeLineMap addDriveLocationLayers ${xss(layerName)}`)
        this.mlMap.addPoiLayer(
          xss(layerName),
          poiStyle,
          this.driveLocationLabelStyle
        );
      }
    },

    /**
     * スポットリストアイコンレイヤを追加します。
     */
    addSpotTypeLayers() {
      let types = [];
      this.spotTypeList.forEach((spotType) => {
        types.push({
          layerName: xss(spotType.name),
          iconUrl: spotType.iconUrl,
        });
      });

      for (let i = 0; i < types.length; i++) {
        const type = types[i];

        // アイコン種別
        let poiStyle = {
          iconUrl: type.iconUrl,
          iconSize: this.spotStyle.iconSize,
          iconOffset: this.spotStyle.iconOffset,
          interactive: this.spotStyle.interactive,
        };

        let layerName = type.layerName;

        // 拠点アイコンレイヤ追加
        this.mlMap.addPoiLayer(layerName, poiStyle, this.spotLabelStyle);
      }
    },

    /**
     * アイコン図形群を追加します。
     *
     */
    addPois() {
      // 拠点レイヤ
      this.addBaseAreaPois();

      // 運行位置レイヤ
      this.addLocationPois();
    },

    /**
     * DBから取得した拠点データを地図に追加します。
     */
    addBaseAreaPois() {
      // console.log(this.startAreaList);

      if (this.startAreaList.length > 0) {
        // 工場
        this.startAreaList.forEach((item) => {
          this.addBaseAreaPoi(item);
        });
      }

      // if (this.endAreaList.length > 0) {
      //   // 現場
      //   this.endAreaList.forEach(item => {
      //     this.addBaseAreaPoi(item);
      //   });
      // }
    },

    /**
     * 拠点図形を地図に追加します。
     *
     * @param {dbRowData} row 拠点情報DB登録行データ
     */
    addBaseAreaPoi(row) {
      // console.log(row);

      this.mlMap.clearTempPoi();

      // レイヤ名
      const layerName = `${xss(row.areaType)}_${xss(row.areaName)}`;

      // 仮
      let labelText = xss(row.areaName);

      // console.log(row);

      // 拠点範囲
      this.addGeofence(row);

      // 図形を追加
      this.mlMap.addPoi(layerName, {
        id: xss(row.id),
        x: xss(row.x),
        y: xss(row.y),
        label: xss(labelText),
        layerName: xss(layerName),
      });
    },
    /**
     * ルートの作図
     * @param {*} item 
     */
    addRoute(item) {
      console.log(`addRoute:${JSON.stringify(item)}`)
      let shapeItem = { id: item.routeId };

      this.mlMap.addShape(
        MLMap.SHAPE_TYPE.POLYLINE,
        item.routeGeometry,
        shapeItem,
        this.roadStyle[item.roadType]
      );

      
      // 図形の上下(Z-Order)を正しく描画
      this.mlMap.refreshZOrder();
    },
    /**
     * 拠点のジオフェンス作図
     */
    addGeofence(baseArea) {
      // ID
      let shapeItem = { id: baseArea.id };

      // addShape
      this.mlMap.addShape(
        MLMap.SHAPE_TYPE.POLYGON,
        baseArea.geofence,
        shapeItem,
        this.geofenceStyle
      );
    },

    /**
     * DBから取得した運行位置データを地図に追加します。
     */
    addLocationPois() {
      if (this.locationList.length == 0) {
        return;
      }

      this.locationList.forEach((item) => {
        this.addLocationPoi(item);
      });
    },

    /**
     * 運行位置図形を地図に追加します。
     *
     * @param {dbRowData} row 運行位置DB登録行データ
     */
    async addLocationPoi(row) {
      this.mlMap.clearTempPoi();

      console.log(`addLocationPoi ${JSON.stringify(row, null, "\t")}`);

      // レイヤ名
      const layerName = this.getLocationLayerName(row);

      // ラベル (生コンの場合は driveNumber、残土の場合はナンバープレート（ナンバープレートが未指定の場合は車両名）)
      let labelString = row.label;
      if (this.isZando) {
        labelString = row.numberPlate == null || row.numberPlate == "" ? row.vehicleName : row.numberPlate;
      }
      const labelText = labelString;

      // 往路復路名称
      const roadTypeText = this.getDriveLocationRoadTypeText(row);

      // 到着予想時刻
      const arrivaInfo = this.getArrivalInfo(row);

      // 図形を追加
      this.mlMap.addPoi(layerName, {
        id: row.id,
        x: row.x,
        y: row.y,
        label: xss(labelText),
        layerName: xss(layerName),
        vehicleName: xss(row.vehicleName),
        roadType: xss(row.roadType),
        roadTypeText: xss(roadTypeText),
        driveNumber: xss(row.driveNumber),
        maxCapacity: xss(row.maxCapacity),
        arrivalTime: xss(arrivaInfo.arrivalTime),
        color: xss(arrivaInfo.color),
        numberPlate: xss(row.numberPlate),
        loadCapacity: xss(row.loadCapacity),
      });

      // 渋滞フラグがONのアイコンは強調表示
      if (row.congestionFlag == 1){
        this.addTempPoi([xss(row.y), xss(row.x)]);
      }

      // 運行スポット一覧取得
      let list = await SpotModel.getSpotList(this.projectId);

      // 運行間隔スポットの渋滞フラグONのスポットは選択色に変更      
      let empFigures = [];
      let congestionTextFigures = [];
      for (let index = 0; index < list.length; index++) {
        let item = list[index];

        // 渋滞フラグがONかチェック
        if (item.spot.congestionFlag == "1") {
          // 図形情報取得            
          let figureInfo = this.getShapeById(item.spot.id);
          if (figureInfo != null){
            // 選択色変更対象として保持
            empFigures.push(figureInfo);
            
            // 渋滞中表示対象として保持
            congestionTextFigures.push({
              id: `${xss(item.spot.id)}_text`,
              x: xss(item.spot.x),
              y: xss(item.spot.y)
            })

          }        
        }
      }

      // 渋滞情報クリア（選択色、渋滞中テキスト）
      this.clearSelectedShape();
      this.deleteCongestionText();

      if (empFigures.length > 0) {
        // 渋滞中のスポットがあった場合は選択色に変更
        this.setSelectedShape(empFigures, '#D80073');
        // 渋滞中描画
        this.drawCongestionText(congestionTextFigures);
      }

    },

    /**
     * 指定されたIDの作図図形を取得します。
     * @param {any} id 作図図形のID
     * @returns {Object} 作図図形。みつからない場合はnull。
     */
    getShapeById(id){
      return this.mlMap.getShapeById(id);
    },

    /**
     * 作図図形の強調表示をクリアします。
     */
    clearSelectedShape(){
      this.mlMap.clearSelectedShape();
    },

    /**
     * 指定された作図図形の配列を強調表示します。
     * @param {Object[]} shapeItems 作図図形の配列
     * @param {string} selectedColor 強調表示色
     */
    setSelectedShape(shapeItems, selectedColor){
      this.mlMap.setSelectedShape(shapeItems, selectedColor);
    },

    /**
     * 渋滞中の運行スポット図形上に、渋滞中テキストを削除します。
     */
    deleteCongestionText() {
      // 削除
      for (let i=0; i<this.congestionTextIds.length; i++) {
        this.mlMap.removeShapeById(this.congestionTextIds[i]);
      }
      // 削除IDリスト初期化
      this.congestionTextIds = [];
    },

    /**
     * 渋滞中の運行スポット図形上に、渋滞中とテキストを描画します。
     * @param {Object[]} congestionTextFigures 作図対象のスポットID,X,Y座標
     */
    drawCongestionText(congestionTextFigures){
      for (let i=0; i<congestionTextFigures.length; i++){
        let item = congestionTextFigures[i];

        // テキストの挿入基点座標取得 (BASE64)
        let points = [];
        points.push(L.latLng(item.y, item.x));
        let compressCoordsText = MLCompressUtil.latLngArrayToBase64(points);

        // 渋滞中のテキスト挿入
        this.mlMap.addShape(
          MLMap.SHAPE_TYPE.TEXT,
          compressCoordsText,
          {
            id: item.id
          },
          {
            text: '渋滞中',
            color: '#FFFFFF',
            weight: 4,
            opacity: 0.8,
            fillColor: '#F50057',
            fillOpacity: 0.8,
            fontSize: 32,
            isBold: true,
            textAlign: 'center',
            textBaseline: 'top', 
          }
        );

        // 描画した図形ID保持（削除時使用）
        this.congestionTextIds.push(item.id);
      }
    },

    /**
     * 運行種別からレイヤ名称を取得します。
     *
     * @param {String} roadType 運行種別
     * @returns レイヤ名称
     */
    getLocationLayerName(location) {
      let layerName = "";
      let roadType = location.roadType;

      if (roadType == 1) {
        layerName = "往路";
      } else if (roadType == 2) {
        layerName = "復路";
      } else {
        if (this.isGenba(location)) {
          layerName = "現場";
        } else {
          layerName = "工場";
        }
      }

      return layerName;
    },

    /**
     * 往路復路種別から往路復路名称を取得します。
     * @param {Object} location 運行位置
     * @returns 往路復路名称
     */
    getDriveLocationRoadTypeText(location) {
      let layerName = "";
      let roadType = location.roadType;

      if (roadType == 1) {
        layerName = "往路走行中";
      } else if (roadType == 2) {
        layerName = "復路走行中";
      } else {
        if (this.isGenba(location)) {
          if (this.isZando){
            layerName = "土捨場";
          } else {
            layerName = "現場";
          }
        } else {
          if (this.isZando){
            layerName = "現場"
          } else {
            layerName = "工場";
          }
        }
      }

      return layerName;
    },

    /**
     * 到着予測時間を算出します。
     * @param {Object} location 運行位置
     * @returns 到着予測時刻、表示色
     */
    getArrivalInfo(location){
      let color = "blue";
      let arrivalTime = "--:--";

      let roadType = location.roadType;
      if (roadType == 1) {
        if (location.averageArrive > 0){
          // 往路（現場到着予想時刻算出 (工場出発時刻 + 平均到着時間)
          let date = DateUtil.addMinute(location.factoryStartTime, location.averageArrive);
          arrivalTime = DateUtil.getFormatString(date, "HH:mm")
          // 現在時刻と比較して過ぎていれば、文字色を赤にする
          if (DateUtil.isAfter(new Date(), date)){
            color = "red";
          }
        }
      } else if (roadType == 2) {
        if (location.averageReturn > 0){
          // 往路（工場到着予想時刻算出 (現場出発時刻 + 平均帰着時間)
          let wDateTime = DateUtil.addMinute(location.siteStartTime, location.averageReturn);
          arrivalTime = DateUtil.getFormatString(wDateTime, "HH:mm")
          // 現在時刻と比較して過ぎていれば、文字色を赤にする
          if (DateUtil.isAfter(new Date(), wDateTime)){
            color = "red";
          }
        }
      }
      
      // 戻り値生成
      let retValue = {
        color: color, 
        arrivalTime: xss(arrivalTime)
      }

      return retValue;

    },

    /**
     * 運行スポットを作図
     */
    addSpotPois() {
      try {
        for (let spot of this.spotList) {
          const x = spot.x;
          const y = spot.y;

          // -------------------------
          // ジオフェンスの作図
          // -------------------------
          let compressCoordsText;

          // 図形タイプがポリゴンの場合は、DB値をそのまま使用
          if (spot.shapeType == "1") {
            compressCoordsText = spot.geofence;
          } else {
            // 中心座標、半径から、ポリゴン座標を取得
            const latlngs = CoordinateUtil.getCirclePolygonPoints(
              new L.LatLng(y, x),
              spot.range
            );
            // ポリゴン座標をmlMap圧縮文字列にする
            compressCoordsText = MLCompressUtil.latLngArrayToBase64(
              latlngs
            );
          }

          // ID
          let shapeItem = { id: spot.id };

          // addShape
          if (spot.spotType == "1") {
            this.mlMap.addShape(
              MLMap.SHAPE_TYPE.POLYGON,
              compressCoordsText,
              shapeItem,
              this.intervalSpotPolygonStyle
            );
          } else {
            this.mlMap.addShape(
              MLMap.SHAPE_TYPE.POLYGON,
              compressCoordsText,
              shapeItem,
              this.spotPolygonStyle
            );
          }

          // -------------------------
          // アイコンの作図
          // -------------------------
          let layerName = spot.spotTypeName;

          // アイコン図形をプロットを追加
          this.mlMap.addPoi(layerName, {
            id: spot.id,
            x: xss(spot.x),
            y: xss(spot.y),
            layerName: xss(layerName),
          });

          this.spotList.push({ x: spot.x, y: spot.y });
        }
      } catch (e) {
        appLog.errLog("TimeLineMap.vue", ``, `${e.message}`);
      }

      // 図形の上下(Z-Order)を正しく描画
      this.mlMap.refreshZOrder();
    },

    /**
     * 往路レイヤー表示切替
     */
    setOuroLayerVisible(isShow) {
      this.mlMap.setVisiblePoiLayer(`往路`, isShow);
    },

    /**
     * 往路レイヤー表示後
     */
    didChangeLayerVisible(layerName, isShow) {
      // 往路レイヤー表示後の処理
      this.$emit("didChangeLayerVisible", layerName, isShow);
    },

    /**
     * 復路レイヤー表示切替
     */
    setFukuroLayerVisible(isShow) {
      this.mlMap.setVisiblePoiLayer(`復路`, isShow);
    },

    /**
     * 運行スポットを削除
     */
    deleteSpotPois() {
      for (let spot of this.spotList) {
        // ジオフェンス図形削除
        this.mlMap.removeShapeById(spot.id);
        // アイコン削除
        this.mlMap.removePoiById(spot.spotTypeName, spot.id);
      }
    },
    /**
     * 指定IDの図形を削除する
     * @param {*} idList 
     */
    deleteShape(idList) {
      for (let id of idList) {
        try {
          this.mlMap.removeShapeById(id);
        } catch (e) {
          // エラーでもハンドリングはしない
          console.log(e)
          console.log(`エラー: ${id}`)
        }
      }
    },
    /**
     * 図形情報からBBX領域を取得します。
     *
     * @returns BBX領域
     */
    getPoiBounds() {
      // 基準領域
      const worldBounds = L.latLngBounds([25, 120], [45, 150]);
      // console.log(worldBounds)

      let latlngs = [];

      // if (this.startAreaList.length > 0) {
      //   bounds = L.latLngBounds(
      //     [this.startAreaList[0].y, this.startAreaList[0].x],
      //     [this.startAreaList[0].y, this.startAreaList[0].x]);
      // }

      // console.log(bounds)

      // 工場
      this.startAreaList.forEach((item) => {
        const latlng = [item.y, item.x];
        latlngs.push(latlng);
      });
      // console.log(latlngs)
      // bounds = await this.getBbx(this.startAreaList, bounds);
      // console.log(bounds)
      // console.log(this.startAreaList)

      // 現場
      this.endAreaList.forEach((item) => {
        const latlng = [item.y, item.x];
        latlngs.push(latlng);
      });
      // console.log(latlngs)

      // bounds = await this.getBbx(this.endAreaList, bounds);
      this.locationList.forEach((item) => {
        if (item.y !== 0 && item.x !== 0) {
          const latlng = [item.y, item.x];
          latlngs.push(latlng);
        }
      });

      // 運行スポット
      this.spotList.forEach((item) => {
        if (item.y !== 0 && item.x !== 0) {
          const latlng = [item.y, item.x];
          latlngs.push(latlng);
        }
      });

      // 運行位置
      // bounds = await this.getBbx(this.locationList, bounds);

      // console.log(latlngs)

      // // 16px分バッファ
      // const min = bounds.getSouthWest();
      // const bufferMin = this.mlMap.latLngToBounds(min, 16);
      // const max = bounds.getNorthEast();
      // const bufferMax = this.mlMap.latLngToBounds(max, 16);

      // bounds = L.latLngBounds(bufferMin.getSouthWest(), bufferMax.getNorthEast());

      if (latlngs.length > 0) {
        const polygon = L.polygon(latlngs);
        const bounds = polygon.getBounds();
        // console.log(bounds)
        return bounds;
      } else {
        return worldBounds;
      }

      // return bounds;
    },

    // /**
    //  * 図形情報からBBX領域を取得します。
    //  *
    //  * @param {Array} points 座標リスト
    //  * @param {Object} bounds 比較元領域
    //  * @returns BBX領域
    //  */
    // getBbx(points, bounds) {
    //   let minX = bounds.getWest();
    //   let minY = bounds.getSouth();
    //   let maxX = bounds.getEast();
    //   let maxY = bounds.getNorth();

    //   points.forEach((item) => {
    //     if (item.x < minX) {
    //       minX = item.x;
    //     }

    //     if (item.y < minY) {
    //       minY = item.y;
    //     }

    //     if (item.x > maxX) {
    //       maxX = item.x;
    //     }

    //     if (item.y > maxY) {
    //       maxY = item.y;
    //     }
    //   });

    //   return L.latLngBounds([minY, minX], [maxY, maxX]);
    // },

    /**
     * 地図上の走行位置アイコン図形を再描画します。
     * @param {String} sk 取得キー(projectId#yyyyMMDD)
     * @param {Number} orderType 表示順序(1:工場出発順、2:車両番号順)
     */
    async refreshLocation(sk, orderType) {
      console.log(`[START] refreshLocation: ${sk}, ${orderType}`);

      try {
        if (!this.mlMap) {
          return;
        }

        // 地図上に表示されているポップアップを閉じる
        this.closePopup();

        // 現在の表示を退避
        // console.log(this.locationList);
        const oldList = this.locationList.slice();
        // console.log(oldList);

        // DBから最新情報の取得
        const isUpdate = await this.initDriveLocation(sk, orderType);

        // 更新されていない（前回と同じ）であれば再描画しない
        if (!isUpdate) {
          return;
        }

        // console.log(oldList);
        if (!oldList) {
          return;
        }

        // 前回表示のクリア
        oldList.forEach((item) => {
          this.mlMap.removePoiById(this.getLocationLayerName(item), item.id);
        });

        // 再描画
        this.addLocationPois();
      } finally {
        console.log(`[END] refreshLocation`);
      }
    },

    /**
     * 地図イベントの初期化処理です。
     */
    initMapEvent() {
      // // サイズ変更
      // this.initMapEventResize();

      // クリック
      // this.initMapEventClick();

      // 作図開始/終了
      // this.initMapEventShapeDrawStartEnd();

      // 作図完了
      // this.initMapEventShapeDrawFinish();

      // 図形削除
      // this.initMapEventShapeDelete();

      // 地図移動後
      // this.mlMap.on('moveend', (e) => {
      //   let bounds = e.target.getBounds();
      //   console.log(`[${e.type}] bounds: ${bounds}`);

      //   this.saveBounds(bounds);
      // });

      // 縮尺変更後
      // this.mlMap.on('zoomend', (e) => {
      //   let bounds = e.target.getBounds();
      //   console.log(`[${e.type}] bounds: ${bounds}`);

      //   this.saveBounds(bounds);
      // });

      this.mlMap.on("click", this.onMapClick);

      this.mlMap.on("layerremove", this.onMapLayerRemove);

      this.mlMap.on("layeradd", this.onMapLayerAdd);

      // 地図表示準備完了を通知
      setTimeout(
        function() {
          this.$emit("didEndShowMap");
        }.bind(this),
        0
      );
    },

    /**
     * レイヤ追加イベント
     * @param {Object} e イベントデータ
     */
    onMapLayerAdd(e) {
      this.didChangeLayerVisible(e.layer.layerName, true);
    },

    /**
     * レイヤ削除イベント
     * @param {Object} e イベントデータ
     */
    onMapLayerRemove(e) {
      this.didChangeLayerVisible(e.layer.layerName, false);
    },

    /**
     * 地図クリックイベント
     * @param {Object} e イベントデータ
     */
    onMapClick(e) {
      console.log(`onMapClick: ${e.latlng}`);

      if (this.isMovingResetLocation) {
        this.isMovingResetLocation = false;
        return;
      }
      
      // クリック位置の走行位置POIを取得
      const item = this.getDriveLocationPoi(e.latlng);

      if (!item) {
        return;
      }

      console.log(item);

      // 車両情報をポップアップ表示
      this.showDriveLocationPopup(item);
    },

    /**
     * 指定された位置に含まれる走行位置POIを取得します。
     * @param {Object} latlng 検索位置
     * @returns 検査箇所POI
     */
    getDriveLocationPoi(latlng) {
      for (let i = 0; i < this.driveLocationStyles.length; i++) {
        // レイヤ名
        const layerName = this.driveLocationStyles[i].layerName;

        // 非表示の場合は検索対象外
        if (!this.mlMap.isVisiblePoiLayer(layerName)) {
          continue;
        }

        // 指定された位置に含まれる走行位置POIを取得
        let items = this.mlMap.getPoiByLatLng(layerName, latlng);

        // 最初にみつかったPOIを返す。
        if (items.length > 0) {
          return items[0];
        }
      }

      // みつからない
      return null;
    },

    /**
     * 指定された走行位置POIの車両情報をポップアップ表示します。
     * @param {Object} item 走行位置POI
     */
    showDriveLocationPopup(item) {
      let driveNumber = item.driveNumber ? item.driveNumber : 0;

      // ポップアップコンテンツ
      let htmlText;
      if (this.isZando) {
        // 残土の場合
        let loadCapacity = item.loadCapacity
          ? Number(item.loadCapacity).toFixed(2)
          : "0";

        htmlText = `<div style="font-size: 16px">
          <h5 class="m-0 text-primary">車両情報</h5>
          <p class="m-0 lead">${xss(item.vehicleName)} [${xss(loadCapacity)}㎥]</p>
          <p class="m-0 small">ナンバー: ${xss(item.numberPlate)}</p>
          <p class="m-0 small">運行回数: ${xss(driveNumber)}</p>
          <p class="m-0 small">${xss(item.roadTypeText)}</p>
          <p class="m-0 small" style="color:${xss(item.color)}">到着予想: ${xss(item.arrivalTime)}</p>
          <div role="button" class="alert alert-light p-0 mt-2" onclick="window.closePopup();">
          <p class="text-center small p-0 m-0">閉じる</p>
          </div>
          </div>`;
      } else {
        // 生コンの場合
        let maxCapacityText = item.maxCapacity
          ? Number(item.maxCapacity).toFixed(2)
          : "0";

        htmlText = `<div style="font-size: 16px">
          <h5 class="m-0 text-primary">車両情報</h5>
          <p class="m-0 lead">${xss(item.vehicleName)} [${xss(maxCapacityText)}㎥]</p>
          <p class="m-0 small">運行回数: ${xss(driveNumber)}</p>
          <p class="m-0 small">${xss(item.roadTypeText)}</p>
          <p class="m-0 small" style="color:${xss(item.color)}">到着予想: ${xss(item.arrivalTime)}</p>
          <div role="button" class="alert alert-light p-0 mt-2" onclick="window.closePopup();">
          <p class="text-center small p-0 m-0">閉じる</p>
          </div>
          </div>`;
      }

      // ポップアップ表示位置
      const latlng = [item.y, item.x];

      // ポップアップオプション
      const options = {
        offset: [0, -10],
        closeButton: false,
      };

      // ポップアップ表示
      this.mlMap.map.openPopup(htmlText, latlng, options);
    },

    /**
     * 地図上に表示されているポップアップを閉じます。
     */
    closePopup() {
      this.mlMap.map.closePopup();
    },

    /**
     * 地図上の運行情報表示順を更新します。
     */
    updateLocationOrder(order) {},

    /**
     * 車両名と地図アイコン注記表示用番号のリストを設定します。
     * @param 車両名と地図アイコン注記表示用番号のリスト
     */
    setVehicleMapTextOrders(vehicleMapTextOrderList) {
      // console.log(`vehicleMapTextOrderList:${JSON.stringify(vehicleMapTextOrderList, null, "\t")}`);

      if (vehicleMapTextOrderList) {
        this.vehicleMapTextOrders = [];
        this.vehicleMapTextOrders = vehicleMapTextOrderList;
        // console.log(`vehicleMapTextOrderList:${JSON.stringify(vehicleMapTextOrderList, null, "\t")}`);
      }
    },

    // /**
    //  * 親vueで選択された到着拠点（現場/工区）IDと打設箇所を設定します。
    //  * @param {String} endAreaId 到着拠点（現場/工区）ID
    //  * @param {String} pouringPosition 打設箇所
    //  */
    // setSelectedEndAreaPouringPosition(endAreaId, pouringPosition) {
    //   // console.log(`setSelectedEndAreaPouringPosition:${endAreaId} ${pouringPosition}`)
    //   this.selectedEndAreaPouringPosition = {};
    //   this.selectedEndAreaPouringPosition = {
    //     endAreaId: endAreaId,
    //     pouringPosition: pouringPosition,
    //   };

    //   // 現場は親vueで選択されている現場のみ描画
    //   let targetArea = this.endAreaList.filter(
    //     (item) => item.id === this.selectedEndAreaPouringPosition.endAreaId
    //   );

    //   // 前回描画図形削除
    //   if (
    //     this.beforeDrawBaseAreaID != "" &&
    //     this.beforeDrawBaseAreaID != null
    //   ) {
    //     this.deleteBeforeDrawFigure();
    //   }

    //   // 拠点描画
    //   if (targetArea.length > 0 && this.mlMap) {
    //     // 拠点描画
    //     this.addBaseAreaPoi(targetArea[0]);

    //     // 前回作図分としてID、レイヤ名保持
    //     this.beforeDrawBaseAreaID = targetArea[0].id;
    //     this.beforeDrawBaseAreaLayerName = `${targetArea[0].areaType}_${targetArea[0].areaName}`;
    //   }
    // },
    /**
     * 親vueで選択された到着拠点（現場/工区）IDと打設箇所を設定します。
     * @param {String} endAreaId 到着拠点（現場/工区）ID
     * @param {String} pouringPosition 打設箇所
     */
    setSelectedEndAreaPouringPosition(targetEndAreaPouringPosition) {
      // console.log(`setSelectedEndAreaPouringPosition:${endAreaId} ${pouringPosition}`)
      this.selectedEndAreaPouringPosition = [];
      this.selectedEndAreaPouringPosition = targetEndAreaPouringPosition;

      // 現場は親vueで選択されている現場のみ描画
      let targetArea = [];
      for (let i=0; i< this.selectedEndAreaPouringPosition.length; i++) {
        let areaInfo = this.endAreaList.filter(
          (item) => item.id === this.selectedEndAreaPouringPosition[i].endAreaId);

        // 全て（残土）選択時、同じ土捨場を複数回書いてしまう為、既にある場合はリストに追加しない
        let duplicationCheck = targetArea.filter(
          (item) => item === areaInfo[0]);
        if (duplicationCheck.length == 0) {
          targetArea.push(areaInfo[0]);
        }                    
      }

      // 前回描画図形削除
      if (
        this.beforeDrawBaseAreaID.length > 0 &&
        this.beforeDrawBaseAreaLayerName.length > 0
      ) {
        this.deleteBeforeDrawFigure();
      }

      // 拠点描画
      this.beforeDrawBaseAreaID = [];
      this.beforeDrawBaseAreaLayerName = [];

      if (targetArea.length > 0 && this.mlMap) {

        for (let i=0; i<targetArea.length; i++) {
          // 拠点描画
          this.addBaseAreaPoi(targetArea[i]);

          // 前回作図分としてID、レイヤ名保持
          this.beforeDrawBaseAreaID.push(targetArea[i].id);
          this.beforeDrawBaseAreaLayerName.push(`${targetArea[i].areaType}_${targetArea[i].areaName}`);
        }
      }

    },

    /**
     * ジオフェンス、アイコン図形を削除します
     * @params 拠点情報
     */
    deleteBeforeDrawFigure() {

      for (let i=0; i<this.beforeDrawBaseAreaID.length; i++){
        // ジオフェンス図形削除
        this.mlMap.removeShapeById(this.beforeDrawBaseAreaID[i]);

        // アイコン削除
        this.mlMap.removePoiById(
          this.beforeDrawBaseAreaLayerName[i],
          this.beforeDrawBaseAreaID[i]
        );
      }
    },

    // /**
    //  * 運行スポットを作図します
    //  */
    // updateSpot(parameters) {
    //   try {
    //     for (let spot of parameters) {
    //       const x = spot.x;
    //       const y = spot.y;

    //       // -------------------------
    //       // ジオフェンスの作図
    //       // -------------------------

    //       // 中心座標、半径から、ポリゴン座標を取得
    //       const latlngs = CoordinateUtil.getCirclePolygonPoints(
    //         new L.LatLng(y, x),
    //         spot.range
    //       );

    //       // ポリゴン座標をmlMap圧縮文字列にする
    //       const compressCoordsText = MLCompressUtil.latLngArrayToBase64(
    //         latlngs
    //       );

    //       // ID
    //       let shapeItem = { id: spot.id };

    //       // addShape
    //       this.mlMap.addShape(
    //         MLMap.SHAPE_TYPE.POLYGON,
    //         compressCoordsText,
    //         shapeItem,
    //         this.spotPolygonStyle
    //       );

    //       // -------------------------
    //       // アイコンの作図
    //       // -------------------------
    //       let layerName = spot.spotTypeName;

    //       // アイコン図形をプロットを追加
    //       this.mlMap.addPoi(layerName, {
    //         id: spot.id,
    //         x: spot.x,
    //         y: spot.y,
    //         layerName: layerName,
    //       });

    //       this.spotList.push({ x: spot.x, y: spot.y });
    //     }
    //   } catch (e) {
    //     appLog.errLog("TimeLineMap.vue", ``, `${e.message}`);
    //   }
    // },

    // 表示位置をリセットする
    resetLocation() {
      this.isMovingResetLocation = true;
      let bounds = this.getPoiBounds();
      this.mlMap.fitBounds(bounds);
    },

    /**
     * 一時POI図形を作図します。
     * ＊渋滞フラグがONの時にアイコンを囲む赤〇を描画
     *
     * @param {latlng} latlng 座標YX
     */
    addTempPoi(latlng) {
      this.mlMap.clearTempPoi();

      this.mlMap.addTempPoi(latlng, {
        color: "#FF0000",
        opacity: 0.8,
        weight: 4,
        fillColor: "#FF0000",
        fillOpacity: 0,
        radius: 20,
      });
    },

    /**
     * 一時POI図形を削除します。
     */
    clearTempPoi() {
      this.mlMap.clearTempPoi();
    },
    /**
     * コンソール出力のみ。
     */
    debugLog(funcName, message) {
      try {
        this.base_debugLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e)
      }
    },
    /**
     * AmplifyのAPI経由でS3にINFOログが残る
     */
    infoLog(funcName, message) {
      try {
        this.base_infoLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e)
      }
    },
    /**
     * AmplifyのAPI経由でS3にERRORログが残る
     */
    errorLog(funcName, message) {
      try {
        this.base_errorLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラー破棄
        console.log(e)
      }
    },
  },
};
</script>
