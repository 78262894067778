<template>
  <div class="bg-white">
    <base-header class="mb-4" type="">
      <!-- <div class="row align-items-center py-2" > -->
      <div class="d-flex gradient mt-2">
        <el-page-header :icon="ArrowLeft" @back="onClickBack">
          <template #content>
            <div class="pb-2">
              <!-- <span class="text-large font-600 mr-3">契約情報登録</span> -->
              <!-- <h3 class="h2 d-inline-block mb-0 pb-2">契約情報登録</h3> -->
              <span
                v-if="editMode == 0"
                class="text-large font-600 mr-3 font-weight-bolder"
              >
                契約情報登録
              </span>
              <span v-else class="text-large font-600 mr-3 font-weight-bolder">
                契約情報編集
              </span>
            </div>
          </template>
        </el-page-header>
      </div>
      <!-- </div> -->
    </base-header>
    <div class="container-fluid mt-1 mb-4">
      <el-tabs
        v-model="activeName"
        type="card"
        class="demo-tabs"
        @tab-click="handleClick"
      >
        <el-tab-pane label="登録情報確認、変更" name="tab1">
          <div class="row">
            <div class="col-12">
              <!-- <card> -->
              <el-form
                ref="form"
                :rules="rules"
                :model="formData"
                label-position="top"
              >
                <div class="row">
                  <div class="col-12">
                    <div class="pl-lg-4">
                      <div class="d-flex align-items-center">
                        <h4>テナントID(自動設定)<require-tag /></h4>
                        <el-tooltip
                          class="box-item"
                          effect="dark"
                          content="ご契約毎に一意なIDを設定します。通常は初期値のままで変更不要です。"
                          placement="right"
                        >
                          <i
                            class="far fa-question-circle"
                            style="margin-bottom:8px; margin-left:5px;"
                          ></i>
                        </el-tooltip>
                      </div>
                      <el-form-item prop="tenantId">
                        <el-input
                          name="tenantId"
                          class="w400"
                          :disabled="editMode == 1"
                          autocomplete="off"
                          placeholder="ユニークなIDを指定"
                          v-model="formData.tenantId"
                        ></el-input>
                      </el-form-item>
                    </div>
                    <div class="pl-lg-4" v-if="editMode == 1">
                      <div class="d-flex align-items-center">
                        <h4>ジオフェンスコレクションID</h4>
                        <el-tooltip
                          class="box-item"
                          effect="dark"
                          content="ジオフェンス作成時に使用するIDです。"
                          placement="right"
                        >
                          <i
                            class="far fa-question-circle"
                            style="margin-bottom:8px; margin-left:5px;"
                          ></i>
                        </el-tooltip>
                      </div>
                      <el-form-item prop="geofenceCollectionId">
                        <el-input
                          name="geofenceCollectionId"
                          class="w400"
                          :disabled="true"
                          autocomplete="off"
                          placeholder="ユニークなIDを指定"
                          v-model="formData.geofenceCollectionId"
                        ></el-input>
                      </el-form-item>
                    </div>
                    <div class="pl-lg-4">
                      <div class="d-flex align-items-center">
                        <h4>契約者名<require-tag /></h4>
                      </div>
                      <el-form-item prop="tenantName">
                        <el-input
                          name="tenantName"
                          class="w400"
                          autocomplete="off"
                          placeholder="社名、契約現場名などを入力してください"
                          v-model="formData.tenantName"
                        ></el-input>
                      </el-form-item>
                    </div>
                    <div class="pl-lg-4">
                      <div class="d-flex align-items-center">
                        <h4>契約者メールアドレス<require-tag /></h4>
                        <el-tooltip
                          class="box-item"
                          effect="dark"
                          content="契約に関するご案内メールを送信致します。"
                          placement="right"
                        >
                          <i
                            class="far fa-question-circle"
                            style="margin-bottom:8px; margin-left:5px;"
                          ></i>
                        </el-tooltip>
                      </div>
                      <el-form-item prop="email">
                        <el-input
                          name="email"
                          class="w400"
                          autocomplete="off"
                          placeholder="メールアドレスを入力してください"
                          v-model="formData.email"
                        ></el-input>
                      </el-form-item>
                    </div>
                    <div class="pl-lg-4">
                      <h4>契約開始日<require-tag /></h4>
                      <el-form-item prop="contractStart">
                        <el-date-picker
                          v-if="editMode == 0"
                          name="value"
                          v-model="formData.contractStart"
                          type="date"
                          class="inline-input flex-fill"
                          placeholder="日付を選択"
                          :shortcuts="[{ text: '今日', value: new Date() }]"
                          value-format="YYYY-MM-DD"
                          :disabled-date="disabledDateStart"
                          :picker-options="contractStartOptions"
                          format="YYYY/MM/DD"
                          clearable
                        />
                        <el-date-picker
                          v-else
                          name="value"
                          v-model="formData.contractStart"
                          type="date"
                          class="inline-input flex-fill"
                          placeholder="日付を選択"
                          :shortcuts="[{ text: '今日', value: new Date() }]"
                          value-format="YYYY-MM-DD"
                          format="YYYY/MM/DD"
                          clearable
                        />
                      </el-form-item>
                    </div>
                    <div class="pl-lg-4">
                      <h4>契約終了日<require-tag /></h4>
                      <el-form-item prop="contractEnd">
                        <el-date-picker
                          v-if="editMode == 0"
                          name="value"
                          v-model="formData.contractEnd"
                          type="date"
                          class="inline-input flex-fill"
                          placeholder="日付を選択"
                          :shortcuts="[{ text: '今日', value: new Date() }]"
                          value-format="YYYY-MM-DD"
                          :disabled-date="disabledDateEnd"
                          :picker-options="contractEndOptions"
                          format="YYYY/MM/DD"
                          clearable
                        />
                        <el-date-picker
                          v-else
                          name="value"
                          v-model="formData.contractEnd"
                          type="date"
                          class="inline-input flex-fill"
                          placeholder="日付を選択"
                          :shortcuts="[{ text: '今日', value: new Date() }]"
                          value-format="YYYY-MM-DD"
                          format="YYYY/MM/DD"
                          clearable
                        />
                      </el-form-item>
                    </div>

                    <div class="pl-lg-4">
                      <div class="d-flex align-items-center">
                        <h4>初期ユーザーID<require-tag /></h4>
                      </div>
                      <el-form-item prop="userId">
                        <el-input
                          name="userId"
                          class="w400"
                          :disabled="editMode == 1"
                          autocomplete="off"
                          placeholder="ログインユーザーIDを入力してください"
                          v-model="formData.userId"
                        ></el-input>
                      </el-form-item>
                    </div>
                    <div class="pl-lg-4">
                      <div class="d-flex align-items-center">
                        <h4>初期ユーザー名<require-tag /></h4>
                      </div>
                      <el-form-item prop="userName">
                        <el-input
                          name="userName"
                          class="w400"
                          :disabled="editMode == 1"
                          autocomplete="off"
                          placeholder="ユーザー名を入力してください。"
                          v-model="formData.userName"
                        ></el-input>
                      </el-form-item>
                    </div>

                    <div v-if="editMode == 0">
                      <div class="pl-lg-4">
                        <div class="d-flex align-items-center">
                          <h4>初期ユーザーパスワード<require-tag /></h4>
                        </div>
                        <div class="d-flex">
                          <el-form-item prop="password">
                            <el-input
                              name="password"
                              class="w400"
                              autocomplete="off"
                              placeholder="パスワードを入力してください"
                              v-model="formData.password"
                            ></el-input>
                          </el-form-item>
                          <base-button
                            icon
                            type="primary"
                            @click="onClickGenPassword"
                            class="ml-3"
                            style="height:40px;"
                          >
                            <span class="btn-inner--icon"
                              ><i class="fas fa-lock"></i
                            ></span>
                            <span class="btn-inner--text">パスワード生成</span>
                          </base-button>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div class="pl-lg-4">
                        <el-checkbox
                          v-model="changePassword"
                          label="パスワードを変更する"
                          size="large"
                        />
                        <div
                          class="d-flex align-items-center"
                          v-if="changePassword"
                        >
                          <h4>新しいパスワード<require-tag /></h4>
                        </div>
                        <div class="d-flex" v-if="changePassword">
                          <el-form-item prop="password">
                            <el-input
                              name="password"
                              :disabled="!changePassword"
                              class="w400"
                              autocomplete="off"
                              placeholder="新しいパスワードを入力してください"
                              v-model="formData.password"
                            ></el-input>
                          </el-form-item>
                          <base-button
                            icon
                            type="primary"
                            :disabled="!changePassword"
                            @click="onClickGenPassword"
                            class="ml-3"
                            style="height:40px;"
                          >
                            <span class="btn-inner--icon"
                              ><i class="fas fa-lock"></i
                            ></span>
                            <span class="btn-inner--text">パスワード生成</span>
                          </base-button>
                        </div>
                      </div>
                    </div>
                    <!-- システム利用区分 -->
                    <div class="pl-lg-4">
                      <div class="d-flex align-items-center">
                        <h4>システム利用区分<require-tag /></h4>
                      </div>
                      <el-checkbox-group v-model="formData.useAppsSelected" :min="1" :max="2" class="pl-lg-4">
                        <el-checkbox v-for="item in useAppsItems" :key="item" :label="item">{{
                          useAppsNames[item]
                        }}</el-checkbox>
                      </el-checkbox-group>
                    </div>
                    
                    <div>
                      
                    </div>

                    <div class="pl-lg-4">
                      <div class="d-flex align-items-center">
                        <h4>メモ</h4>
                      </div>
                      <el-form-item prop="userId">
                        <el-input
                          v-model="formData.memo"
                          :rows="3"
                          type="textarea"
                          placeholder="メモ"
                        />
                      </el-form-item>
                    </div>
                  </div>
                </div>
              </el-form>
              <div class="d-flex mt-3">
                <button
                  type="button"
                  class="flex-fill btn btn-secondary"
                  @click="onClickCancel"
                >
                  キャンセル
                </button>
                <button
                  type="button"
                  class="flex-fill btn btn-primary "
                  @click="onClickSave"
                >
                  <span v-if="editMode == 0">テナント作成</span
                  ><span v-else>テナント更新</span>
                </button>
              </div>
              <!-- </card> -->
            </div>
          </div>
        </el-tab-pane>

        <el-tab-pane label="利用状況" name="tab2" v-if="editMode == 1">
          <div class="pl-4 mb-2">
            <h4>最終ログイン</h4>
            <div class="pl-4">
              <span>{{ lastLoginDate }}</span>
            </div>
          </div>

          <div class="pl-4">
            <h4>ジオフェンス利用状況</h4>
            <div class="pl-4">
              <h5>当月の合計</h5>
              <span></span>
              <div class="pl-4">
                <span v-if="geoLog.valid != null && geoLog.invalid != null">{{
                  geoLog.valid.length + geoLog.invalid.length
                }}</span>
                <span v-else>集計中</span>
              </div>
            </div>
            <div>
              <Bar
                v-loading="geoLog.valid == null || geoLog.invalid == null"
                :chart-options="geoLogChartOptions"
                :chart-data="geoLogChartData"
                chart-id="bar-chart"
                dataset-id-key="label"
                :height="geoLogChart.height"
                :width="geoLogChart.width"
              />
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="契約削除" name="tab3" v-if="editMode == 1">
          <div class="pl-4">
            <h4>契約情報の削除</h4>
            <div class="border p-4 d-flex mb-2">
              <i class="fas fa-exclamation-triangle text-danger mt-1"></i>
              <div class="ml-3">
                <ul class="lineH-right">
                  <li>
                    契約情報の削除を行なうと、対象ユーザのプロジェクト、ユーザー、運行情報、拠点設定（ジオフェンス）の削除を行います。
                  </li>
                  <li>契約期間内の場合は削除は行えません。</li>
                  <li>削除した情報は復元ができません。</li>
                  <li>
                    再契約の可能性があり、再開後に過去データを維持したい場合は削除を行わないで下さい。
                  </li>
                </ul>
              </div>
            </div>
            <div class="d-flex flex-column border p-4">
              <div class="d-flex">
                <h3>契約者名:</h3>
                <h3>{{ formData.tenantName }}</h3>
              </div>
              <div class="d-flex">
                <h4 class="text-danger align-self-center mr-4">
                  この契約を削除する場合は右の削除ボタンを押して下さい。
                </h4>
                <button type="button" class="btn btn-danger" @click="onClickDelete">
                  <span>契約情報の削除</span>
                </button>
              </div>
            </div>
          </div>
          <!-- テナント削除確認モーダル -->
          <modal v-model:show="modals.confirmDlg">
            <template v-slot:header>
                <h5 class="modal-title" id="modalLabel">{{confirmTitle}}</h5>
            </template>
            <div class="mb-5">
              {{confirmMessage}}
            </div>
            <div class="row mt-2 mb-2 ml-2 mr-2">
              <div class="col-sm-5">
                <base-button type="secondary" block @click="onClickConfirmCancel">キャンセル</base-button>
              </div>
              <div class="col-sm-2">
              </div>
              <div class="col-sm-5">
                <base-button type="danger" block @click="onClickConfirmOK">{{confirmOK}}</base-button>
              </div>
            </div>
          </modal>
        </el-tab-pane>
      </el-tabs>
    </div>
    
  </div>
</template>

<style scoped>
.gradient {
  border-bottom: 3px solid #003c9c;
  border-image: linear-gradient(to right, #003c9c 0%, #ffffff 100%);
  border-image-slice: 1;
}
</style>

<script>
import awsconfig from "../../aws-exports";
import TenantModel from "../../appModel/Tenant/TenantModel";
import UserModel from "../../appModel/users/UserModel";
import API from "@aws-amplify/api";
import { Auth } from "aws-amplify";
import appLog from "@/appUtils/AppLog";
import DateUtil from "@/appUtils/DateUtil";
import generator from "generate-password";
import UserInfo from "@/appUtils/UserInfo";
import ValidateUtil from "@/appUtils/ValidateUtil";
import settingModel from "@/appModel/settingModel";
import lodash from "lodash";
import { useToast } from "vue-toastification";
import location from "aws-sdk/clients/location";
import LogModel from "@/appModel/LogModel";
import GeoLogModel from "@/appModel/GeoLog/GeoLogModel";
import { Bar, Line } from "vue-chartjs";
import { Storage } from 'aws-amplify';
import Modal from "@/components/Modal";

export default {
  components: {
    Bar,
    Modal,
  },
  beforeCreate() {
    //インスタンスは生成されたがデータが初期化される前
  },
  created() {
    //インスタンスが生成され､且つデータが初期化された後
  },
  beforeMount() {
    //インスタンスが DOM 要素にマウントされる前
  },
  mounted() {
    //インスタンスが DOM 要素にマウントされた後
    window.addEventListener("popstate", this.handlePopstate);
    this.init();
  },
  beforeUpdate() {
    //データは更新されたが DOM に適用される前
  },
  updated() {
    //データが更新され､且つ DOM に適用された後
  },
  beforeUnmount() {
    //Vue インスタンスが破壊される前
  },
  unmounted() {
    //Vue インスタンスが破壊された後
    window.removeEventListener("popstate", this.handlePopstate);
  },
  data() {
    return {
      tenantData: {
        tenant: {},
      },
      userData: {
        user: {},
      },
      salerTenant: {
        name: "",
        tenantId: "",
      },
      formData: {
        tenantName: "",
        contractStart: "",
        contractEnd: "",
        tenantId: "",
        userId: "",
        userName: "",
        password: "",
        email: "",
        geofenceCollectionId: "",
        memo: "",
        useAppsSelected: ["namacon"],
      },
      useAppsItems: ["namacon", "zando"],
      useAppsNames: {namacon: "生コン運行管理", zando: "残土運搬管理"},
      mczoption: false,
      rebaroption: false,
      password: "",
      // isNewData: true,
      rules: {
        tenantName: [
          { required: true, validator: this.checkTenantName, trigger: "blur" },
        ],
        contractStart: [
          {
            required: true,
            validator: this.checkContractStart,
            trigger: "blur",
          },
        ],
        contractEnd: [
          { required: true, validator: this.checkContractEnd, trigger: "blur" },
        ],
        tenantId: [
          { required: true, validator: this.checkTenantId, trigger: "blur" },
        ],
        userId: [
          { required: true, validator: this.checkUserId, trigger: "blur" },
        ],
        userName: [
          { required: true, validator: this.checkName, trigger: "blur" },
        ],
        password: [
          { required: true, validator: this.checkPassword, trigger: "blur" },
        ],
        email: [
          { required: true, validator: this.checkEmail, trigger: "blur" },
        ],
        memo: [
          { required: false, validator: this.checkMemo, trigger: "blur" },
        ]
      },
      contractStartOptions: {
        disabledDate: this.disabledDateStart,
      },
      contractEndOptions: {
        disabledDate: this.disabledDateEnd,
      },
      loader: null,
      // 0: 新規 1: 編集
      editMode: 0,
      changePassword: false,
      locationService: null,
      credentials: null,
      lastLoginDate: "",
      activeName: "tab1",
      geoLog: {
        valid: null,
        invalid: null,
      },
      geoLogChart: {
        height: 250,
        width: window.innerWidth * 0.5,
      },
      geoLogChartData: {
        labels: [],
        datasets: [
          {
            label: "ジオフェンスカウント数",
            backgroundColor: "rgb(255, 99, 132)",
            borderColor: "rgb(255, 99, 132)",
            data: [],
          },
        ],
      },
      geoLogChartOptions: {
        responsive: false,
        scales: {
          x: {
            categoryPercentage: 0.5,
          },
          y: {
            ticks: {
              callback: function(value, index, ticks) {
                console.log(`callback: ${value}`);
                if (Math.floor(value) === value) {
                  return value;
                }
              },
            },
          },
        },
      },
      modals: {
        editDateDlg: false,
        confirmDlg: false,
      },
      confirmTitle: `削除`,
      confirmMessage: `削除します。よろしいですか？`,
      confirmOK: `削除`
    };
  },
  watch: {},
  /**
   * コンピュートプロパティ
   */
  computed: {
    vuename() {
      return this.$route.name;
    },
    getAppValue() {
      /*
      * 11：生コン、残土運搬管理両方使用可
      * 01：生コン管理のみ使用可
      * 10：残土運搬管理のみ使用可
      */
      try {
        let selectState = ["0", "0"]
        this.formData.useAppsSelected.forEach(item => {
          if (item == "namacon") {
            selectState[0] = "1"
          }
          if (item == "zando") {
            selectState[1] = "1"
          }
        })
        return selectState.reverse().join("")
      } catch (e) {
        return "01"
      }
    }
  },
  //ボタンイベントなどのメソッドはmethodsに
  methods: {
    async init() {
      try {
        // ログインユーザーの情報
        const user = await UserInfo.getUserInfo();
        // 販売店の情報
        const tenant = await TenantModel.getTenant(user.group);
        this.salerTenant.name = tenant.tenant.name;
        this.salerTenant.tenantId = user.group;

        let tenantId = this.$store.state.tenant.id;
        // テナントIDが指定されていれば編集モード
        this.editMode = tenantId ? 1 : 0;
        if (tenantId) {
          await this.getLastLogin(tenantId);
          // データ取得
          // this.isNewData = false;
          console.log(`更新`);
          this.tenantData = await TenantModel.getTenant(tenantId);

          // フォームにバインドしているオブジェクトへ値をコピー
          this.formData.tenantName = this.tenantData.tenant.name;
          this.formData.contractStart = this.tenantData.tenant.contractStart;
          this.formData.contractEnd = this.tenantData.tenant.contractEnd;
          this.formData.tenantId = this.tenantData.tenant.tenantId;
          this.formData.geofenceCollectionId = this.tenantData.tenant.geofenceCollectionId;
          this.formData.memo = this.tenantData.tenant.memo;

          this.formData.email = this.tenantData.tenant.email;
          let useAppsList = this.tenantData.tenant.useApps.split('')
          this.formData.useAppsSelected = []
          if (useAppsList[0] == "1") {
            this.formData.useAppsSelected.push("zando")
          }
          if (useAppsList[1] == "1") {
            this.formData.useAppsSelected.push("namacon")
          }

          
          // グラフ作成
          this.makeGraph();
          //ジオフェンス利用状況取得（非同期で実行）
          this.getValidGeoLog();
          this.getInvalidGeoLog();
          // グラフ作成
          this.makeGraph();

          // 初期ユーザー情報を取得

          let userData = await UserModel.getUserFromUserId(
            this.tenantData.tenant.tenantId,
            this.tenantData.tenant.initUserId
          );

          this.formData.userId = userData.user.userId;
          this.formData.userName = userData.user.name;
        } else {
          // 新規
          // this.isNewData = true;
          // console.log(`追加`);
          this.tenantData = await TenantModel.getNewData();
          this.userData = await UserModel.getNewData();
          this.userData.user.usertype = 2;
          this.formData.password = this.generatePassword();

          this.formData.tenantId = `${
            this.salerTenant.tenantId
          }${DateUtil.dateStringBase(new Date(), "YYYYMMDDHHmmss")}`;
        }
        // aws location関連初期化
        this.credentials = await Auth.currentCredentials();

        this.locationService = new location({
          credentials: this.credentials,
          region: awsconfig.aws_project_region,
        });

        // console.log(JSON.stringify(this.tenantData, null, "\t"));
      } catch (e) {
        this.errorLog(`error ${e.message}`);
      }
    },
    /**
     * 最終ログイン取得
     */
    async getLastLogin(tenantId) {
      let data = await LogModel.getLastLogin(tenantId);
      // console.log(JSON.stringify(data, null, "\t"))
      if (data) {
        this.lastLoginDate = DateUtil.dateStringBase(
          data.lastLoginDate,
          "YYYY年MM月DD日 HH:mm:ss"
        );
      }
    },
    /**
     * 有効ジオフェンスログ取得
     */
    getValidGeoLog() {
      let thisMonth = DateUtil.dateStringBase(new Date(), "YYYYMM");

      GeoLogModel.getGeoLogList("1", this.formData.tenantId, thisMonth).then(
        function(result) {
          if (result) {
            this.geoLog.valid = result;
            this.geoLog.valid.forEach(
              function(item) {
                let ymd = item.sk.split("#")[2];

                let day = ymd.substr(6, 2);
                let index = day - 1;
                console.log(`getValidGeoLog ${ymd} ${index}`);
                this.geoLogChartData.datasets[0].data[index]++;
              }.bind(this)
            );

            // console.log(JSON.stringify(result))
          }
        }.bind(this)
      );
    },
    /**
     * 無効ジオフェンスログ取得
     */
    getInvalidGeoLog() {
      let thisMonth = DateUtil.dateStringBase(new Date(), "YYYYMM");

      GeoLogModel.getGeoLogList("0", this.formData.tenantId, thisMonth).then(
        function(result) {
          if (result) {
            this.geoLog.invalid = result;
            this.geoLog.invalid.forEach(
              function(item) {
                let ymd = item.sk.split("#")[2];
                let day = ymd.substr(6, 2);
                let index = day - 1;
                this.geoLogChartData.datasets[0].data[index]++;
              }.bind(this)
            );
          }
        }.bind(this)
      );
    },
    makeGraph() {
      let list = DateUtil.dayArray(new Date());
      console.log(`makeGraph: ${JSON.stringify(list, null, "\t")}`);
      this.geoLogChartData.labels = [];
      this.geoLogChartData.datasets[0].data = [];
      list.forEach(
        function(item) {
          this.geoLogChartData.labels.push(item.format("MM/DD"));
          this.geoLogChartData.datasets[0].data.push(0);
        }.bind(this)
      );
    },
    /**
     * キャンセルボタン押下
     */
    onClickCancel() {
      this.$router.push({
        name: "TenantManage",
      });
    },
    /**
     * Backボタン押下
     */
    onClickBack() {
      this.$router.push({
        name: "TenantManage",
      });
    },
    /**
     * 保存ボタン押下
     */
    async onClickSave() {
      console.log(`save`);
      this.showLoader();
      this.$refs.form.validate(
        async function(result) {
          if (!result) {
            console.log(`error`);
            this.showBottomToast("入力内容にエラーがあります。", "error");
            this.hideLoader();
            return;
          }
          // フォーム入力値をDB登録用のオブジェクトへコピー

          this.tenantData.tenant.name = this.formData.tenantName;
          this.tenantData.tenant.contractStart = this.formData.contractStart;
          this.tenantData.tenant.contractEnd = this.formData.contractEnd;
          this.tenantData.tenant.tenantId = this.formData.tenantId;
          this.tenantData.tenant.initUserId = this.formData.userId;
          this.tenantData.tenant.memo = this.formData.memo;
          if (this.editMode == 1) {
            this.tenantData.tenant.geofenceCollectionId = this.formData.geofenceCollectionId;
          }
          this.userData.user.userId = this.formData.userId;
          this.userData.user.name = this.formData.userName;
          this.userData.user.email = this.formData.email;
          this.tenantData.tenant.email = this.formData.email;

          this.tenantData.tenant.useApps = this.getAppValue

          if (this.editMode == 1) {
            // 更新
            try {
              //
              console.log(this.tenantData.tenant.geofenceCollectionId);
              await this.makeGeofenceCollection(
                this.tenantData.tenant.geofenceCollectionId
              );

              // インデックスに契約開始日と終了日を追加
              let newlsiStr1 = TenantModel.createLsiStr1(this.salerTenant.tenantId,
                DateUtil.dateStringBase(this.formData.contractStart, "YYYYMMDD")
              );
              // 契約開始日が異なっていればlstStr1を更新
              if (this.tenantData.lsiStr1 != newlsiStr1) {
                this.tenantData.lsiStr1 = newlsiStr1
              }

              let newlsiStr2 = TenantModel.createLsiStr2(this.salerTenant.tenantId,
                DateUtil.dateStringBase(this.formData.contractEnd, "YYYYMMDD")
              );
              // 契約終了日が異なっていればlstStr2を更新
              if (this.tenantData.lsiStr2 != newlsiStr2) {
                this.tenantData.lsiStr2 = newlsiStr2
              }

              await TenantModel.updateTenant(this.tenantData);

              // パスワード変更を行う場合
              if (this.changePassword) {
                // REST-APIを呼び出し
                const APIName = "konoikeconcreteapi";
                const path = "/updateadminuser";
                const myInit = {
                  headers: {},
                  response: true,
                  body: {
                    userName: this.userData.user.userId,
                    password: this.formData.password,
                  },
                };

                const response = await API.post(APIName, path, myInit);
                if (response.data) {
                  if (response.data.success != "") {
                    let errorMessage = `パスワード変更エラー: ${response.data.message}`;
                    throw new Error(errorMessage);
                  }
                } else {
                  let errorMessage = `パスワード変更エラー`;
                  throw new Error("未定義のエラー");
                }
              }
            } catch (e) {
              console.log(e);
              this.showBottomToast(
                `パスワード変更時にエラーが発生しました  ${e.stack}`,
                "error"
              );
              this.hideLoader();
              return;
            }
          } else {
            let tenantDb = false;
            let userDb = false;
            let cognito = false;

            // DB登録時にsubに書き換わるので退避
            let userId = this.userData.user.userId;
            // Cognitoでの一意なID
            let sub = "";
            try {
              // 契約開始日を退避
              let startDateStore = this.tenantData.tenant.contractStart;
              // 契約開始日は当日を仮セット（認証が通らないので）
              this.tenantData.tenant.contractStart = DateUtil.dateStringDate(
                new Date()
              );

              // 追加
              // キーをセット
              this.tenantData.sk = TenantModel.createSk(
                this.tenantData.tenant.tenantId
              );
              // インデックスに契約開始日と終了日を追加
              this.tenantData.lsiStr1 = TenantModel.createLsiStr1(this.salerTenant.tenantId,
                DateUtil.dateStringBase(this.formData.contractStart, "YYYYMMDD")
              );
              this.tenantData.lsiStr2 = TenantModel.createLsiStr2(this.salerTenant.tenantId,
                DateUtil.dateStringBase(this.formData.contractEnd, "YYYYMMDD")
              );

              // テナントのユーザーとなるためpkはテナントIDをセット
              this.userData.pk = this.tenantData.tenant.tenantId;
              this.userData.sk = UserModel.createSk(this.userData.user.userId);

              // groupにテナントIDを追加する
              this.tenantData.groups.push(this.tenantData.tenant.tenantId);
              this.userData.groups.push(this.tenantData.tenant.tenantId);              
              // 販売店ユーザーをgroupに追加する
              this.tenantData.groups.push(this.salerTenant.tenantId);
              this.userData.groups.push(this.salerTenant.tenantId);

              // テナントレコードだけにはシステム管理者を追加する
              this.tenantData.groups.push(`administrators`);
              
              console.log(
                `tenantData ${JSON.stringify(this.tenantData, null, "\t")}`
              );
              try {
                await TenantModel.addTenant(this.tenantData);
                tenantDb = true;
              } catch (e) {
                this.errorLog(`テナント追加エラー`);
                throw e;
              }

              // REST-APIを呼び出し
              const APIName = "konoikeconcreteapi";
              const path = "/adminuser";
              const myInit = {
                headers: {},
                response: true,
                body: {
                  userName: this.userData.user.userId,
                  password: this.formData.password,
                  groupName: this.tenantData.tenant.tenantId,
                  userType: "2",
                  attributes: [
                    { Name: "email", Value: this.userData.user.email },
                    { Name: "name", Value: this.userData.user.name },
                    { Name: "email_verified", Value: "true" },
                  ],
                },
              };

              const response = await API.post(APIName, path, myInit);
              // console.log(`konoikeconcreteapi API Response: ${JSON.stringify(response, null, "\t")}`)
              const data = response.data;
              if (data.success != "") {
                const errorInfo = JSON.parse(data.message);
                switch (errorInfo.code) {
                  case "UsernameExistsException":
                    throw "すでに登録済みのメールアドレスです。";

                  default:
                    throw `${errorInfo.code} ${errorInfo.message}`;
                }
              }
              cognito = true;
              sub = data.sub;
              // this.userData.user.userId = data.sub
              this.userData.sk = UserModel.createSk(sub);

              try {
                // await UserModel.addUser(this.userData);
                userDb = true;
              } catch (e) {
                this.errorLog(`管理者追加エラー`);

                // バージョン指定が必要
                //await TenantModel.deleteTenant(this.tenantData);
                throw e;
              }

              // ジオフェンスID用のテナント連番を取得する。
              let geofenceCollectionId = await settingModel.incrementTenantIndex();
              // 0～950の値となるように951で剰余算
              geofenceCollectionId = geofenceCollectionId % 951;
              console.log(`geofenceCollectionId : ${geofenceCollectionId}`);
              // 登録したテナント情報を取得
              let savedTenantData = await TenantModel.getTenant(
                this.tenantData.tenant.tenantId
              );
              // ジオフェンスコレクションIDをセット
              savedTenantData.tenant.geofenceCollectionId = geofenceCollectionId;
              // 契約開始日をセットする
              savedTenantData.tenant.contractStart = startDateStore;
              console.log(`tenant ${JSON.stringify(savedTenantData)}`)
              // テナントレコードを更新
              await TenantModel.updateTenant(savedTenantData);
              // ジオフェンスコレクション作成
              await this.makeGeofenceCollection(
                geofenceCollectionId
              );
              // DB初期レコードを作成
              await this.createInitRecord(this.tenantData.tenant.tenantId)

            } catch (e) {
              console.log(`error ${JSON.stringify(e)}`);
              if (tenantDb) {
                // テナントレコード削除
                await TenantModel.deleteTenant(this.tenantData);
                console.log(`テナントレコード削除`);
              }
              if (userDb) {
                // ユーザーレコード削除
                await UserModel.deleteUser(this.userData);
                console.log(`ユーザーレコード削除`);
              }
              if (cognito) {
                // REST-APIを呼び出し
                const APIName = "konoikeconcreteapi";
                const path = "/user";
                const myInit = {
                  headers: {},
                  response: true,
                  body: {
                    userName: userId,
                    sub: sub,
                  },
                };

                const response = await API.del(APIName, path, myInit);
                console.log(`Cognitoユーザー削除 ${e.stack}`);
              }
              console.log(
                `テナント新規登録時にエラーが発生しました。 ${JSON.stringify(
                  e
                )}`
              );
              this.showBottomToast(
                `テナント新規登録時にエラーが発生しました。 ${JSON.stringify(
                  e
                )}`,
                "error"
              );
              this.hideLoader();
              return;
            }
          }
          this.hideLoader();
          this.$router.push({
            name: "TenantManage",
          });
        }.bind(this)
      );
    },
    /**
     * ジオフェンスコレクションを作成
     */
    async makeGeofenceCollection(geofenceCollectionId) {
      try {
        if (!this.locationService) {
          // console.log(this.locationService);
          return;
        }

        const geoParams = {
          CollectionName: `${geofenceCollectionId}`,
          PricingPlan: "RequestBasedUsage",
        };

        // console.log(geoParams);

        const result = await this.locationService
          .createGeofenceCollection(geoParams)
          .promise();
        return true;
      } catch (e) {
        console.log(`makeGeofenceCollection error ${e}`)
        if (e.code == "ConflictException") {
          // 重複作成はエラーとはしない
          return true;
        }

        return false;
      }
    },
    async createInitRecord(groupId) {
      // REST-APIを呼び出し
      const APIName = "konoikeconcreteapi";
      const path = "/tenant";
      const myInit = {
        headers: {},
        response: true,
        body: {
          groupId: groupId
        },
      };

      const response = await API.post(APIName, path, myInit);
      const data = response.data;
      if (!data.success) {
        throw new Error(`初期レコード作成失敗 ${data.message}`)
      }
      
    },
    /**
     * パスワード生成
     */
    onClickGenPassword() {
      this.formData.password = this.generatePassword();
    },
    /**
     * 契約削除
     */
    onClickDelete() {
      // 契約期限外でなければ削除は許可しない
      const today = DateUtil.dateStringBase(new Date(), "YYYYMMDD")
      const end = DateUtil.dateStringBase(this.tenantData.tenant.contractEnd, "YYYYMMDD")
      if (today <= end) {
        this.showBottomToast("契約期限内は削除できません", "error");
        return
      }
      // 削除確認
      this.confirmTitle = `削除`;
      this.confirmMessage = `削除します。よろしいですか？`;
      this.confirmOK = `削除`;
      this.modals.confirmDlg = true;
    },
    
    /**
     * 削除処理確認モーダル キャンセルボタンクリックイベント
     */
    onClickConfirmCancel() {
      this.modals.confirmDlg = false;
    },

    /**
     * 削除処理確認モーダル 実行ボタンクリックイベント
     */       
    async onClickConfirmOK() {
      try {
        this.showLoader();
        //S3にファイルを配置
        const s3Key = `endcontract/${this.formData.tenantId}`
        await Storage.put(s3Key, `${this.formData.tenantId}`)
        this.modals.confirmDlg = false;
        this.$router.push({
          name: "TenantManage",
        });
      } catch (e) {
        console.log(e)
        this.showBottomToast(
          `テナント削除時にエラーが発生しました。 ${JSON.stringify(
            e
          )}`,
          "error"
        );
      } finally {
        this.hideLoader();
        
      }
      
      
      

    },
    generatePassword() {
      const params = {
        length: 8, //文字長指定
        numbers: true, //数字含む
        lowercase: true, //英小文字含む
        uppercase: true, //英大文字含む
        strict: true, //各文字種類から最低1文字ずつ使用
      };

      return generator.generate(params);
    },
    checkTenantName(rule, value, callback) {
      if (
        ValidateUtil.checkBase(value, callback, 100, true, false, false, true)
      ) {
        callback();
      }
    },
    /**
     * 契約開始日バリデーション
     */
    checkContractStart(rule, value, callback) {
      if (ValidateUtil.checkBase(value, callback, 100, true, false, false)) {
        callback();
      }
    },
    /**
     * 契約終了日バリデーション
     */
    checkContractEnd(rule, value, callback) {
      if (ValidateUtil.checkBase(value, callback, 100, true, false, false)) {
        callback();
      }
    },
    /**
     * テナントIDバリデーション
     */
    checkTenantId(rule, value, callback) {
      if (
        ValidateUtil.checkBase(value, callback, 100, true, false, false, true)
      ) {
        if (!ValidateUtil.checkCustomWord(value, "_")) {
          callback(new Error("アンダーバーは使用出来ません。"));
        } else {
          callback();
        }
      }
    },
    /**
     * ユーザーIDバリデーション
     */
    checkUserId(rule, value, callback) {
      if (
        ValidateUtil.checkBase(value, callback, 100, true, false, false, true)
      ) {
        callback();
      }
    },
    /**
     * ユーザー名バリデーション
     */
    checkName(rule, value, callback) {
      if (
        ValidateUtil.checkBase(value, callback, 100, true, false, false, true)
      ) {
        callback();
      }
    },
    /**
     * パスワードバリデーション
     */
    checkPassword(rule, value, callback) {
      if (ValidateUtil.checkBase(value, callback, 100, true, false, false)) {
        callback();
      }
    },
    /**
     * メールアドレスバリデーション
     */
    checkEmail(rule, value, callback) {
      //  static checkBase(value, callback, length=0, isRequire=false, isMail=false, isNumber=false, isKinsoku=false) {
      if (ValidateUtil.checkBase(value, callback, 100, true, true, false)) {
        callback();
      }
    },
    /**
     * メモのバリデーション
     */
    checkMemo(rule, value, callback) {
      //  static checkBase(value, callback, length=0, isRequire=false, isMail=false, isNumber=false, isKinsoku=false) {
      if (ValidateUtil.checkBase(value, callback, 300, false, false, false, true)) {
        callback();
      }
    },
    /**
     * 今日より前の日付は選択不可。契約終了日以降も選択不可
     */
    disabledDateStart(time) {
      // 当日以降の日付は選択不可とする
      // 契約終了日が入力されていれば、終了日以降は選択不可とする

      // true: 契約終了日含む過去 false: 契約終了日より未来
      let isBeforeEnd = true;
      // true: 今日含む未来 false: 今日より過去
      let isAfterToday = true;

      let timeStr = DateUtil.dateStringBase(time, "YYYYMMDD");
      let todayStr = DateUtil.dateStringBase(new Date(), "YYYYMMDD");
      if (this.formData.contractEnd) {
        let endStr = DateUtil.dateStringBase(
          this.formData.contractEnd,
          `YYYYMMDD`
        );
        isBeforeEnd = timeStr <= endStr;
      }
      isAfterToday = timeStr >= todayStr;

      // trueを返す日は選択不可となるため、いずれかがfalseならfalseを返す
      return !isBeforeEnd || !isAfterToday;
    },
    disabledDateEnd(time) {
      // 当日以降の日付は選択不可とする
      // 契約終了日が入力されていれば、終了日以降は選択不可とする

      // true: 契約終了日含む過去 false: 契約終了日より未来
      let isAfterStart = true;
      // true: 今日含む未来 false: 今日より過去
      let isAfterToday = true;

      let timeStr = DateUtil.dateStringBase(time, "YYYYMMDD");
      let todayStr = DateUtil.dateStringBase(new Date(), "YYYYMMDD");
      if (this.formData.contractStart) {
        let startStr = DateUtil.dateStringBase(
          this.formData.contractStart,
          `YYYYMMDD`
        );
        isAfterStart = timeStr >= startStr;
      }
      isAfterToday = timeStr >= todayStr;

      // trueを返す日は選択不可となるため、いずれかがfalseならfalseを返す
      return !isAfterStart || !isAfterToday;
    },
    /**
     * PopStateEventハンドラー
     */
    handlePopstate() {
      // モーダルを非表示
      this.modals.confirmDlg = false;
    },
    /**
     * 処理中インジケーターを表示します。
     * @returns 表示したインジケーター
     */
    showLoader() {
      this.loader = this.$loading.show({
        container: null,
        canCancel: false,
        color: "#003C9C",
        width: 64,
        height: 64,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        isFullPage: true,
        zIndex: 9999,
      });
    },
    /**
     * 処理中インジケーターを閉じます。
     * @paramas {Object} loader 表示したインジケーター
     */
    hideLoader() {
      this.loader.hide();
    },
    /**
     * コンソール出力のみ。
     */
    debugLog(message) {
      appLog.debugLog(this.vuename, this.$store.state.user.userId, message);
    },
    /**
     * AmplifyのAPI経由でS3にINFOログが残る
     */
    infoLog(message) {
      appLog.infoLog(this.vuename, this.$store.state.user.userId, message);
    },
    /**
     * AmplifyのAPI経由でS3にERRORログが残る
     */
    errorLog(message) {
      appLog.errLog(this.vuename, this.$store.state.user.userId, message);
    },
    /**
     * トーストでメッセージを表示
     */
    showBottomToast(message, type) {
      this.runToast(message, "bottom-center", type);
    },
    /**
     * トーストでメッセージを表示（処理）
     */
    runToast(message, pos, type) {
      const toast = useToast();
      toast[type](message, {
        hideProgressBar: true,
        icon: false,
        toastClassName: ["custome-toast-class"],
        closeButton: false,
        position: pos,
      });
    },
  },
};
</script>
