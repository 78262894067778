<template>
  <!-- 
  <el-tabs type="card" v-model="activeTab" style="height:100%" @tab-click="tabSelect" >
    <el-tab-pane label="地図" name="tabMap">
  -->
  <!-- <div class="d-flex timeline-header"> -->
  <application-header
    ref="appHeader"
    :title="title"
    :dateStringProp="dateString"
    :refreshIntervalSecond="refreshIntervalSecond"
    :isDatePicker="true"
    :isNowTime="isNowTime"
    :isDriveSpot="isDriveSpot"
    :isCreateQRCode="isCreateQRCode"
    :isEditBase="isEditBase"
    :isEditVehicle="isEditVehicle"
    :isCreatePlan="isCreatePlan"
    :isDriveLog="isDriveLog"
    :isOutputCsv="isOutputCsv"
    :isDriveVue="isDriveVue"
    :isDriveBulkSetting="isDriveBulkSetting"
    :isAcceptanceList="isAcceptanceList && isNamacon"
    @refresh="onRefreshLocation"
    @editBase="onClickEditBase"
    @editVehicle="onClickEditVehicle"
    @createPlan="onClickCreatePlan"
    @driveLog="onClickDriveLog"
    @driveSpot="onClickDriveSpot"
    @createQRCode="onClickQRCode"
    @outputCsv="onClickOutputCsv"
    @showResult="onClickShowResult"
    @showAcceptanceList="onClickAcceptanceList"
    @changeDate="onChangeDate"
    @chengePouringPosition="onChangePouringPosition"
    @changeMapInterval="onChangeMapInterval"
    @showDriveBulkSetting="onshowDriveBulkSetting"
  >
  </application-header>

  <!-- <div class="my-box flex-fill primary">Flex 1</div> -->
  <!-- </div> -->
  <div class="d-flex timeline-main">
    <div v-if="isShowTimeline" class="d-flex flex-column timeline-left">
      <!-- <div v-if="isShowTimeline" class="my-box timeline-list overflow-auto" @resize="panelResized"> -->
      <div v-if="!selectedVehicleId" class="btn-group btn-group-toggle d-flex">
        <!-- <div class="btn-group btn-group-toggle d-flex" style="height: 32px;" > -->
        <label
          v-for="(item, index) in orderTypes"
          :key="index"
          class="btn border mx-1 my-1 mr-0 col-auto"
          style="border-radius: 2px;white-space: nowrap;"
          v-bind:class="{ active: orderType === item.value }"
        >
          <input
            :value="item.value"
            v-model="orderType"
            type="radio"
            id="'option' + index"
            autocomplete="off"
            @change="onChageOrder(orderType)"
          />
          {{ item.name }}
        </label>
      </div>
      <div
        v-else
        class="bg-white d-flex justify-content-between align-items-center my-0"
      >
        <el-link
          icon="fas fa-angle-left fa-lg"
          class="ml-2 text-primary"
          style="font-size:min(16px);font-weight:600"
          href="#!"
          @click.prevent="onClickBackLatestList()"
        >
          戻る
        </el-link>
        <div class="d-flex align-items-center">
          <div class="driveIconBox ml-0 mr-2">
            <img :src="selectedVehicleData[0].img" class="bg-white avatar-sm" />            
            <p v-if= !isZando class="stroke text-nowrap">{{ selectedVehicleData[0].order }}</p>
            <p v-if= isZando style="font-size:min(12px)" class="stroke text-nowrap">{{ selectedVehicleData[0].numberPlate == null || selectedVehicleData[0].numberPlate == "" ? selectedVehicleData[0].vehicleName : selectedVehicleData[0].numberPlate }}</p>
          </div>
          <b style="font-size:min(18px)">
            {{ selectedVehicleData[0].vehicleName }}
          </b>
          <span style="font-size:min(16px)" class="ml-3"
            >[ {{ selectedVehicleData[0].totalAmount }} m3 ]</span
          >
        </div>
        <div></div>
        <!-- <el-button plain :icon="el-icon-ArrowLeft">戻る</el-button> -->
      </div>
      <div class="my-box timeline-list overflow-auto">
        <el-table
          ref="timeLineTable"
          row-key="timelineId"
          class="table-timeline"
          header-row-class-name=""
          :data="timeLineData"
          :span-method="arraySpanMethod"
          :cell-style="{
            padding: '4px 0px 4px 0px',
            height: '36px',
            background: '#FFF',
            color: '#000',
          }"
          :cell-class-name="tableCellClassName"
          :header-cell-class-name="headerCellClassName"
          :header-cell-style="headerCellStyle"
          border
          size="mini"
          height="100%"
          style="width: 100%"
          empty-text="運行情報がありません"
          highlight-current-row
          @current-change="handleTimeLineCurrentChange"
        >
          <!--               
              <el-table-column prop="col1" label="" width="60px" align="center">
                <template v-slot="{ row }">
                  <div class="driveIconBox" v-if="row.type==='vehicle'">     
                    <img 
                    :src="row.img"
                    class="bg-white avatar-sm">
                    <p>{{row.order}}</p>
                  </div>
                  <span class="" v-else>{{row.col1}}</span>
                </template>                
              </el-table-column>
              -->
          <!-- 生コン用 -->
          <el-table-column
            v-if= !isZando
            prop="col1"
            label="工場発着"
            width="100px"
            align="center"
            :resizable="false"
          >
            <template #default="scope">
              <!-- <template v-slot="{ row }">          -->
              <!-- class="align-items-center row" -->
              <div
                v-if="scope.row.type === 'vehicle'"                
              >
                <!-- class="d-flex align-items-center fixed-col-center" -->
                <div
                  v-if="!selectedVehicleId"                  
                >
                  <div class="driveInfo">
                    <font style="font-size:min(12px);color:blue;">
                      車両番号：{{ scope.row.vehicleName }}
                    </font>
                    <font v-if="scope.row.congestionFlag==1" style="margin-left:10px">
                      <span style=”border:2px;color:#ffffff;font-size:12px;border-radius:10px;background-color:#ff0000;“>&emsp;渋滞しています&emsp;</span>
                    </font>                    
                  </div>  
                  <div class="d-flex align-items-center">
                    <div class="driveIconBox">
                      <img :src="scope.row.img" class="bg-white avatar-sm" />
                      <p style="font-size:min(16px)" class="stroke text-nowrap">
                        {{ scope.row.order }}
                      </p>
                    </div>
                    <font style="font-size:min(18px);color:black;font-weight:bold">
                      台目 累計{{ scope.row.totalAmount }} ㎥
                    </font>
                    <div v-if="scope.row.isDelivery" style="margin-left:10px;margin-top:10px">
                      <p class="fas fa-check-circle"></p>
                    </div>
                    <div class="ml-6">
                      <el-button
                        class="listButton"
                        size="mini"
                        @click="onClickEditVehicleDlg(scope.row)"
                        ><i class="fas fa-toolbox mr-1"></i><span class="font-weight-bold">設定</span></el-button
                      >                    
                    </div>
                    <div class="ml-1">
                      <el-button
                        class="listButton"
                        size="mini"
                        @click="onClickVehicle(scope.row)"
                        ><i class="fas fa-clipboard-list mr-1"></i><span class="font-weight-bold">履歴</span></el-button
                      >                    
                    </div>
                    <div v-if="scope.row.dispPouringStartBtn" class="ml-6">
                      <el-button
                        class="pouringStartButton"
                        size="mini"
                        @click="onClickPouringStart(scope.row)"
                        ><span class="font-weight-bold">打設開始</span></el-button
                      >                    
                    </div>
                    <div v-if="scope.row.dispPouringEndBtn" class="ml-6">
                      <el-button
                        class="pouringEndButton"
                        size="mini"
                        @click="onClickPouringEnd(scope.row)"
                        ><span class="font-weight-bold">打設終了</span></el-button
                      >                    
                    </div>
                  </div>
                </div>
                <div v-else class="ml-9 align-items-center d-flex">
                  <img
                    src="@/assets/mixer.png"
                    class="bg-white ml-7 mr-2"
                    style="width:32px;height:32px"
                  />
                  <span style="font-size:min(18px)"
                    ># {{ scope.row.driveNumber }}</span
                  >
                  <div v-if="scope.row.isDelivery" style="margin-left:10px;margin-top:10px">
                    <p class="fas fa-check-circle"></p>
                  </div>                  
                </div>
              </div>
              <!-- </div> -->
              <span class="" v-else>{{ scope.row.col1 }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if= !isZando
            prop="col2"
            label="現場発着"
            width="90px"
            align="center"
            :resizable="false"
          />
          <el-table-column
            v-if= !isZando
            prop="col3"
            label="所要時間"
            width="90px"
            align="center"
            :resizable="false"
          />
          <el-table-column
            v-if= !isZando
            prop="col4"
            label="打設開始"
            width="90px"
            align="center"
            :resizable="false"
          >
            <template #default="scope">
              <div
                v-if="scope.row.type === 'timeline1'"
                class="justify-content-center align-items-center row"
              >
                <div v-if="scope.row.pouringStartUser === 'system'">
                  <b
                    style="font-size:min(18px);color:red;background-color:yellow"
                    key="pouringStartTIme"
                  >
                    {{ scope.row.col4 }}
                  </b>
                </div>
                <span
                  class="justify-content-center align-items-center row"
                  key="pouringStartTIme"
                  v-else
                  >{{ scope.row.col4 }}</span
                >
              </div>
              <span
                class="justify-content-center align-items-center row"
                key="pouringStartTIme"
                v-else
                >{{ scope.row.col4 }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            v-if= !isZando
            prop="col5"
            label="打設終了"
            width="90px"
            align="center"
            :resizable="false"
          >
            <template #default="scope">
              <div
                v-if="scope.row.type === 'timeline1'"
                class="justify-content-center align-items-center row"
              >
                <div v-if="scope.row.pouringEndUser === 'system'">
                  <b
                    style="font-size:min(18px);color:red;background-color:yellow"
                  >
                    {{ scope.row.col5 }}
                  </b>
                </div>
                <span
                  class="justify-content-center align-items-center row"
                  v-else
                  >{{ scope.row.col5 }}</span
                >
              </div>
              <span
                class="justify-content-center align-items-center row"
                v-else
                >{{ scope.row.col5 }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            v-if= !isZando
            prop="col6"
            label="打設/経過時間"
            width="90px"
            align="center"
            :resizable="false"
          >
            <template #default="scope">
              {{ splitComma(scope.row.col6, 0) }}<br />
              {{ splitComma(scope.row.col6, 1) }}
            </template>
          </el-table-column>
          <el-table-column
            v-if= !isZando
            prop="col7"
            label="工区"
            width="90px"
            align="center"
            :resizable="false"
          />
          <el-table-column
            v-if= !isZando
            prop="col8"
            label="打設箇所"
            width="90px"
            align="center"
            :resizable="false"
          />
          <el-table-column
            v-if= !isZando
            prop="col9"
            label="積載物"
            width="90px"
            align="center"
            :resizable="false"
          />

          <!-- 残土用 -->
          <el-table-column
            v-if= isZando
            prop="col1"
            label="現場発着"
            width="100px"
            align="center"
            :resizable="false"
          > <template #default="scope">
              <div
                v-if="scope.row.type === 'vehicle'"                
              >
                <div
                  v-if="!selectedVehicleId"                  
                >
                  <div class="driveInfo">
                    <font style="font-size:min(12px);color:blue;">
                      車両番号：{{ scope.row.vehicleName }}
                    </font>
                    <font v-if="scope.row.congestionFlag==1" style="margin-left:10px">
                      <span style=”border:2px;color:#ffffff;font-size:12px;border-radius:10px;background-color:#ff0000;“>&emsp;渋滞しています&emsp;</span>
                    </font>                    
                  </div>  
                  <div class="d-flex align-items-center">
                    <div class="driveIconBox">
                      <img :src="scope.row.img" class="bg-white avatar-sm" />
                      <p style="font-size:min(12px)" class="stroke text-nowrap">
                        {{ scope.row.numberPlate == null || scope.row.numberPlate == "" ? scope.row.vehicleName : scope.row.numberPlate }}
                      </p>
                    </div>
                    <font style="font-size:min(18px);color:black;font-weight:bold">
                       累計{{ scope.row.totalAmount }} ㎥
                    </font>
                    <div v-if="scope.row.isDelivery" style="margin-left:10px;margin-top:10px">
                      <p class="fas fa-check-circle"></p>
                    </div>
                    <div class="ml-6">
                      <el-button
                        class="listButton"
                        size="mini"
                        @click="onClickEditVehicleDlg(scope.row)"
                        ><i class="fas fa-toolbox mr-1"></i><span class="font-weight-bold">設定</span></el-button
                      >                    
                    </div>
                    <div class="ml-1">
                      <el-button
                        class="listButton"
                        size="mini"
                        @click="onClickVehicle(scope.row)"
                        ><i class="fas fa-clipboard-list mr-1"></i><span class="font-weight-bold">履歴</span></el-button
                      >                    
                    </div>
                    <div v-if="scope.row.cargoStartBtn && isZando" class="ml-3">
                      <el-button
                        class="cargoStartButton"
                        size="mini"
                        @click="onClickCargoStart(scope.row)"
                        ><span class="font-weight-bold">積込開始</span></el-button
                      >                    
                    </div>
                    <div v-if="scope.row.cargoEndBtn && isZando" class="ml-3">
                      <el-button
                        class="cargoEndButton"
                        size="mini"
                        @click="onClickCargoEnd(scope.row)"
                        ><span class="font-weight-bold">積込終了</span></el-button
                      >                    
                    </div>
                    <div v-if="scope.row.unloadingTimeBtn && isZando" class="ml-3">
                      <el-button
                        class="unloadingTimeButton"
                        size="mini"
                        @click="onClickCargoUnloadingTime(scope.row)"
                        ><span class="font-weight-bold">荷降開始</span></el-button
                      >                    
                    </div>
                  </div>
                </div>
                <div v-else class="ml-9 align-items-center d-flex">
                  <img
                    src="@/assets/mixer.png"
                    class="bg-white ml-7 mr-2"
                    style="width:32px;height:32px"
                  />
                  <span style="font-size:min(18px)"
                    ># {{ scope.row.driveNumber }}</span
                  >
                  <div v-if="scope.row.isDelivery" style="margin-left:10px;margin-top:10px">
                    <p class="fas fa-check-circle"></p>
                  </div>                  
                </div>
              </div>
              <!-- </div> -->
              <span class="" v-else>{{ scope.row.col1 }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if= isZando
            prop="col2"
            label="土捨場発着"
            width="100px"
            align="center"
            :resizable="false"
          />
          <el-table-column
            v-if= isZando
            prop="col3"
            label="所要時間"
            width="90px"
            align="center"
            :resizable="false"
          />
          <el-table-column
            v-if= isZando
            prop="col4"
            label="積込開始"
            width="90px"
            align="center"
            :resizable="false"
          >
          <template #default="scope">
              <div
                v-if="scope.row.type === 'timeline1'"
                class="justify-content-center align-items-center row"
              >
                <div v-if="scope.row.pouringStartUser === 'system'">
                  <b
                    style="font-size:min(18px);color:red;background-color:yellow"
                    key="pouringStartTIme"
                  >
                    {{ scope.row.col4 }}
                  </b>
                </div>
                <span
                  class="justify-content-center align-items-center row"
                  key="pouringStartTIme"
                  v-else
                  >{{ scope.row.col4 }}</span
                >
              </div>
              <span
                class="justify-content-center align-items-center row"
                key="pouringStartTIme"
                v-else
                >{{ scope.row.col4 }}</span
              >
            </template>
          </el-table-column>
           <el-table-column
            v-if= isZando
            prop="col5"
            label="積込終了"
            width="90px"
            align="center"
            :resizable="false"
          >
            <template #default="scope">
              <div
                v-if="scope.row.type === 'timeline1'"
                class="justify-content-center align-items-center row"
              >
                <div v-if="scope.row.pouringEndUser === 'system'">
                  <b
                    style="font-size:min(18px);color:red;background-color:yellow"
                  >
                    {{ scope.row.col5 }}
                  </b>
                </div>
                <span
                  class="justify-content-center align-items-center row"
                  v-else
                  >{{ scope.row.col5 }}</span
                >
              </div>
              <span
                class="justify-content-center align-items-center row"
                v-else
                >{{ scope.row.col5 }}</span
              >
            </template>
          </el-table-column>
            <el-table-column
            v-if= isZando
            prop="col6"
            label="積込時間"
            width="90px"
            align="center"
            :resizable="false"
          >
            <template #default="scope">
              {{ splitComma(scope.row.col6, 0) }}<br />
              {{ splitComma(scope.row.col6, 1) }}
            </template>
          </el-table-column>
          <el-table-column
            v-if= isZando
            prop="co20"
            label="荷降時間"
            width="90px"
            align="center"
            :resizable="false"
          >
          <template #default="scope">
            <div
              v-if="scope.row.type === 'timeline1'"
              class="justify-content-center align-items-center row"
            >
              <div v-if="scope.row.unloadingUser === 'system'">
                <b
                  style="font-size:min(18px);color:red;background-color:yellow"
                  key="unloadingTime"
                >
                {{ scope.row.co20 }}
                </b>
              </div>
            <span
              class="justify-content-center align-items-center row"
              key="unloadingTime"
              v-else
              > {{ scope.row.co20 }}</span>
            </div>
          </template>
          </el-table-column>
          <el-table-column
            v-if= isZando
            prop="col7"
            label="土捨場"
            width="90px"
            align="center"
            :resizable="false"
          />
          <el-table-column
            v-if= isZando
            prop="col8"
            label="岩種"
            width="90px"
            align="center"
            :resizable="false"
          />

         

        </el-table>
      </div>
    </div>
    <div class="my-box timeline-map flex-fill">
      <application-sub-header
        ref="subHeader"
        :isSubheader="true"
        :isTimeline="true"
        :isTimelineShow="true"
        @closeTimeline="onCloseTimeline"
        @changeChkSpot="changeChkSpot"
        @changeChkOuro="changeChkOuro"
        @changeChkFukuro="changeChkFukuro"
      >
      </application-sub-header>
      <!-- 図面表示 -->
      <time-line-map
        ref="map"
        @didEndShowMap="didEndShowMap"
        @updateSummarys="updateSummarys"
        @updateSpotTypeList="updateSpotTypeList"
        @didChangeLayerVisible="didChangeLayerVisible"
      ></time-line-map>
      
      <div v-if="!isShowMoreInfoPanel" v-bind:class="{'moreinfo-button-folding': isShowTimeLine, 'moreinfo-wide-button': !isShowTimeLine}">  
        <el-tooltip
          class="item"
          effect="dark"
          content="実績表示、CO2排出量パネルを表示"
          placement="top"
        >
          <el-button size="mini" class="moreinfo-toggle-button bg-shadow1" @click="toggleMoreInfoPanel">
            <i v-bind:class="isShowMoreInfoPanel?'fa fa-angle-down':'fa fa-angle-up'"></i>
          </el-button>
        </el-tooltip>  
      </div>

      <!-------------------------------------------------- 実績表示、CO2排出量 -------------------------------------------------->
      <div v-if="isShowMoreInfoPanel" v-bind:class="{'moreinfo-box': isShowTimeline, 'moreinfo-widebox': !isShowTimeline}">
        <div class="d-flex justify-content-center">
          <div >  
            <el-tooltip
              class="item"
              effect="dark"
              v-bind:content="isShowMoreInfoPanel?'実績表示、CO2排出量パネルを閉じる':'実績表示、CO2排出量パネルを表示'"
              placement="top"
            >
              <el-button size="mini" class="bg-shadow1 moreinfo-toggle-button" @click="toggleMoreInfoPanel">
                <i v-bind:class="isShowMoreInfoPanel?'fa fa-angle-down':'fa fa-angle-up'"></i>
              </el-button>
            </el-tooltip>  
          </div>
        </div>
        <div class="d-flex moreinfo-contents">
          <el-menu 
            default-active="1" 
            class="moreinfo-menu"
            background-color="#000000"
            popper-effect="dark"
            text-color="#FFFFFF"
            @select="onClickSubMenu"
            >
            <el-menu-item  index="1">
              <template #title>
                
                <span :class="{'moreinfo-menu-active-text':isShowMoreInfoPanel_Actual, 'moreinfo-menu-text': !isShowMoreInfoPanel_Actual}"><i class="el-icon-tickets"></i>運行実績</span>
              </template>
            </el-menu-item >
            <el-menu-item  index="2">
              <template #title>
                <span :class="{'moreinfo-menu-active-text':isShowMoreInfoPanel_CO2, 'moreinfo-menu-text': !isShowMoreInfoPanel_CO2}"><i class="fas fa-chart-line ml-1 mr-2"></i>CO2排出量</span>
              </template>
            </el-menu-item >
          </el-menu>
          <div class="" v-if="false">
            <!-- 実績表示、CO2排出量ボタン -->
            <div>
              <el-button
                class="moreinfo-indexbutton"
                size="mini"
                @click="onClickActual"
                >実績表示</el-button
              > 
            </div>
            <div class="d-flex">
              <el-button
                class="moreinfo-indexbutton"
                size="mini"
                @click="onClickCO2"
                >CO2排出量</el-button
              > 
            </div>
          </div>

          <!------------------------ 生コン実績表示 ------------------------>
          <div v-if="isShowMoreInfoPanel_Actual && !isZando" class="flex-fill align-self-stretch" >
            <!-- 当日以外 グラフ表示なし-->
            <div v-if="!isNowToday" class="d-flex justify-content-center align-items-center" style="height:230px;">
              <div class="d-flex flex-column">
                <div class="d-flex " >
                  <img src="@/assets/note@3x.png" class="white ml-2 mr-2" style="margin-top:5px;width:30px;height:30px;object-fit: contain;-webkit-backface-visibility: hidden;">
                  <span style="font-size:16pt;color:white">予定</span>
                  <div class="text-right font-weight-bold"><span style="font-size:14pt;color:white">{{estimate.volume}}㎥</span></div>
                  <div class="ml-3 text-left font-weight-bold"><span style="font-size:14pt;color:white"></span></div>
                </div>
                <div class="d-flex" >
                  <img src="@/assets/hopper@3x.png" class="white ml-2 mr-2" style="margin-top:5px;width:30px;height:30px;object-fit: contain;-webkit-backface-visibility: hidden;">
                  <span style="font-size:16pt;color:white">実績</span>
                  <div class="text-right font-weight-bold"><span style="font-size:16pt;color:white">{{achive.volume}}㎥</span></div>
                  <div class="ml-3 text-left font-weight-bold"><span style="font-size:16pt;color:white">{{achive.number}}台</span></div>
                </div>
              </div>
            </div>
            
            <!-- 当日 グラフ表示あり-->
            <div v-if="isNowToday" style="height:230px">
              <div class="actual-results-box" v-if="isShowChart" style="top:50px;margin-left:50px"  > 
                <div class="d-flex">
                  <div v-if="isShowChart" style="height:145px">
                    <Doughnut 
                        style="height:145px;"
                        :chart-options="ChartOptions"
                        :chart-data="chartData"
                      />
                  </div>
                  <div style="margin-left:30px">
                    <div class="" style="border:0px solid red;">
                      <div class="d-flex" >
                        <img src="@/assets/note@3x.png" class="white ml-2 mr-2" style="width:20px;height:20px;object-fit: contain;-webkit-backface-visibility: hidden;">
                        <span class="font-weight-bold" style="font-size:14pt;color:white;width:100px;">予定</span>  
                        <div class="text-right font-weight-bold" style="width:100px;"><span style="font-size:14pt;color:white;">{{estimate.volume}}㎥</span></div>
                        <div class="ml-3 text-left font-weight-bold" style="width:100px;"><span style="font-size:14pt;color:white;"></span></div>
                      </div>
                      <div class="d-flex" >
                        <img src="@/assets/hopper@3x.png" class="red ml-2 mr-2" style="width:20px;height:20px;object-fit: contain;-webkit-backface-visibility: hidden;">
                        <span class="font-weight-bold blink" style="font-size:14pt;color:red;width:100px;">打設完了</span>
                        <div class="text-right font-weight-bold blink" style="width:100px;"><span style="font-size:14pt;">{{pouringEnd.volume}}㎥</span></div>
                        <div class="ml-3 text-left font-weight-bold blink" style="width:100px;"><span style="font-size:14pt;">{{pouringEnd.number}}台</span></div>
                      </div>
                      <div class="d-flex" >
                        <img src="@/assets/hopper@3x.png" class="red ml-2 mr-2" style="width:20px;height:20px;object-fit: contain;-webkit-backface-visibility: hidden;">
                        <span class="font-weight-bold blink" style="font-size:14pt;color:red;width:100px;">打設/待機</span>
                        <div class="text-right font-weight-bold blink" style="width:100px;"><span style="font-size:14pt;">{{pouringOrWaiting.volume}}㎥</span></div>
                        <div class="ml-3 text-left font-weight-bold blink" style="width:100px;"><span style="font-size:14pt;">{{pouringOrWaiting.number}}台</span><span style="font-size:10pt;" class="signage-subtext blink text-nowrap">打設開始({{pouringOrWaiting.lastTime}})</span></div>
                      </div>
                      <div class="d-flex" >
                        <img src="@/assets/mixer@3x.png" class="white ml-2 mr-2" style="width:20px;height:20px;object-fit: contain;-webkit-backface-visibility: hidden;">
                        <span class="font-weight-bold" style="font-size:14pt;color:white;width:100px;">運搬中</span>
                        <div class="text-right font-weight-bold" style="width:100px;"><span style="font-size:14pt;color:white;">{{transporting.volume}}㎥</span></div>
                        <div class="ml-3 text-left font-weight-bold" style="width:100px;"><span style="font-size:14pt;color:white;">{{transporting.number}}台</span></div>
                      </div>
                      <div class="d-flex" >
                        <img src="@/assets/sandtimer@3x.png" class="white ml-2 mr-2" style="width:20px;height:20px;object-fit: contain;-webkit-backface-visibility: hidden;">
                        <span class="font-weight-bold" style="font-size:14pt;color:white;width:100px;">残出荷数</span>
                        <div class="text-right font-weight-bold" style="width:100px;"><span style="font-size:14pt;color:white;">{{remain.volume}}㎥</span></div>
                        <div class="ml-3 text-left font-weight-bold" style="width:100px;"><span style="font-size:14pt;color:white;">{{remain.number}}台</span></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

         <!------------------------ 残土実績表示 ------------------------>
          <div v-if="isShowMoreInfoPanel_Actual && isZando" class="flex-fill align-self-stretch" >
             <!-- 当日以外 グラフ表示なし-->
              <div v-if="!isNowToday" class="d-flex justify-content-center align-items-center" style="height:230px;">
              <div class="d-flex flex-column">
                <div class="d-flex " >
                  <img src="@/assets/note@3x.png" class="white ml-2 mr-2" style="margin-top:5px;width:30px;height:30px;object-fit: contain;-webkit-backface-visibility: hidden;">
                  <span style="font-size:16pt;color:white">総搬出量</span>
                  <div class="text-right font-weight-bold"><span style="font-size:14pt;color:white">{{amountAll}}㎥</span></div>
                  <div class="ml-3 text-left font-weight-bold"><span style="font-size:14pt;color:white"></span></div>
                </div>
                <div class="d-flex" >
                  <img src="img/concrete/dump_upload_black.png" class="white ml-2 mr-2" style="margin-top:5px;width:30px;height:30px;object-fit: contain;-webkit-backface-visibility: hidden;">
                  <span style="font-size:16pt;color:white">搬出量</span>
                  <div class="text-right font-weight-bold"><span style="font-size:16pt;color:white">{{dateAllAmount}}㎥</span></div>
                </div>
              </div>
            </div>
          <!-- 当日 グラフ表示あり-->
            <div v-if="isNowToday" style="height:230px">
               <div class="actual-results-box" v-if="isShowChart" style="top:50px;margin-left:50px"  > 
               <div class="d-flex">
                <div v-if="isShowChart" style="height:145px">
                    <Doughnut 
                        style="height:145px;"
                        :chart-options="ZandoChartOptions"
                        :chart-data="zandoChartData"
                      />
                </div>
                <div style="margin-left:30px">
                  <div class="" style="border:0px solid red;">
                    <div class="d-flex">
                       <img src="@/assets/note@3x.png" class="white ml-2 mr-2" style="width:20px;height:20px;object-fit: contain;-webkit-backface-visibility: hidden;">
                        <span class="font-weight-bold" style="font-size:14pt;color:white;width:100px;">総搬出量</span>  
                        <div class="text-right font-weight-bold" style="width:100px;"><span style="font-size:14pt;color:white;">{{amountAll}}㎥</span></div>
                        <div class="ml-3 text-left font-weight-bold" style="width:100px;"><span style="font-size:14pt;color:white;"></span></div>
                    </div>
                     <div class="d-flex">
                      <img src="img/concrete/dump_upload_black.png" class="red ml-2 mr-2" style="width:20px;height:20px;object-fit: contain;-webkit-backface-visibility: hidden;">
                        <span class="font-weight-bold blink" style="font-size:14pt;color:red;width:100px;">搬出量</span>
                        <div class="text-right font-weight-bold blink" style="width:100px;"><span style="font-size:14pt;">{{dateAllAmount}}㎥</span></div>
                     </div>
                     <div class="d-flex" v-if="isDatePlanTotalVolume">
                      <img src="img/concrete/dump_black.png" class="white ml-2 mr-2" style="width:20px;height:20px;object-fit: contain;-webkit-backface-visibility: hidden;">
                        <span class="font-weight-bold" style="font-size:14pt;color:white;width:100px;">予定量</span>  
                        <div class="text-right font-weight-bold" style="width:100px;"><span style="font-size:14pt;color:white;">{{datePlanTotalVolume}}㎥</span></div>
                        <div class="ml-3 text-left font-weight-bold" style="width:100px;"><span style="font-size:14pt;color:white;"></span></div>
                     </div>
                      <div class="d-flex" v-if="isUnitNumber">
                      <img src="img/concrete/dump_enpty_black.png" class="white ml-2 mr-2" style="width:20px;height:20px;object-fit: contain;-webkit-backface-visibility: hidden;">
                        <span class="font-weight-bold" style="font-size:14pt;color:white;width:100px;">残必要台数</span>  
                        <div class="text-right font-weight-bold" style="width:100px;"><span style="font-size:14pt;color:white;">{{unitNumber}}台</span></div>
                        <div class="ml-3 text-left font-weight-bold" style="width:100px;"><span style="font-size:14pt;color:white;"></span></div>
                     </div>
                  </div>
                </div>
                

               </div>
               </div>
            </div>
          </div>


          <!------------------------ CO2表示 ------------------------>
          <div v-if="isShowMoreInfoPanel_CO2" style="height:230px">
            <div class="actual-results-box" v-if="isShowChart" style="top:50px;margin-left:50px"  > 
              <div class="d-flex">
                <div>
                  <Line 
                    style="height:200px;width:300px"
                    :chart-options="ChartLineOptions"
                    :chart-data="chartLineData"
                  />
                </div>
                <div style="margin-left:30px">
                  <span style="font-size:20px;color:white">本日のCO2排出量の削減率</span>
                  <div></div>
                  <div> 
                    <div v-if="reductionRate>=0"> 
                      <span style="font-size:36px;color:#89EAFE">{{reductionRate}}</span>
                      <span style="font-size:20px;color:#89EAFE">％</span>
                    </div>
                    <div v-if="reductionRate<0"> 
                      <span style="font-size:36px;color:#FFA4A4">{{reductionRate}}</span>
                      <span style="font-size:20px;color:#FFA4A4">％</span>
                    </div>
                    <div></div>
                    <div></div>
                    <div>
                      <span style="font-size:16px;color:white">測定値：{{actualTotalValue}} t-CO2</span>
                    </div>
                    <span style="font-size:16px;color:white">予測値：{{predictTotalValue}} t-CO2</span>
                  </div>
                </div>

                <div style="margin-left:auto;margin-top:auto">
                  <el-button
                    class="listButton"
                    size="mini"
                    @click="onClickDetail"
                    >詳細表示</el-button
                  > 
                </div>
              </div>
            </div>
          </div> 

        </div>  
      </div>        
    </div>
  </div>

  <!-- CSV出力用モーダル -->
  <modal v-model:show="modals.outputCSVDlg" size="sm" body-classes="p-0">
    <template v-slot:header>
      <h6 class="modal-title">CSV出力設定</h6>
    </template>
    <card
      type="secondary"
      header-classes="bg-transparent pb-5"
      body-classes="px-lg-2 py-lg-2"
      class="border-0 mb-0"
    >
      <div class="col-md-12">
        <base-input label="登録日" name="createdDay">
          <el-date-picker
            class="w-100"
            v-model="modalDate"
            type="date"
            format="YYYY/MM/DD"
            value-format="YYYY-MM-DD"
            placeholder="日付を選択"
            :disabled-date="disabledDate"
            :shortcuts="shortcuts"
            @change="onChangeDatePicker(modalDate)"
          >
          </el-date-picker>
        </base-input>
      </div>

      <div class="col-md-12">
        <base-input v-if="!isZando" name="endAreaId" label="工区">
          <el-select
            placeholder=""
            v-model="modalendAreaId"
            readonly="true"
            @change="onChangeAreaCSV(modalendAreaId)"
          >
            <el-option
              v-for="item in listCSVArea"
              class="select-primary"
              :value="item.id"
              :label="item.name"
              :key="item.name"
            >
            </el-option>
          </el-select>
        </base-input>

        <base-input v-if="isZando" name="endAreaId" label="土捨場">
          <el-select
            placeholder=""
            v-model="modalendAreaId"
            readonly="true"
            @change="onChangeAreaCSV(modalendAreaId)"
          >
            <el-option
              v-for="item in listCSVArea"
              class="select-primary"
              :value="item.id"
              :label="item.name"
              :key="item.name"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>

      <div class="col-md-12">
        <base-input v-if="!isZando" name="pouringPosition" label="打設箇所">
          <el-select
            v-model="modalpouringPosition"
            reserve-keyword
            placeholder="選択してください。"
          >
            <el-option
              v-for="item in listCSVPouringPosition"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </base-input>

        <base-input v-if="isZando" name="pouringPosition" label="岩種">
          <el-select
            v-model="modalpouringPosition"
            :disabled= "disableRock"
            reserve-keyword
            placeholder="選択してください。"
          >
            <el-option
              v-for="item in listCSVPouringPosition"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>
    </card>

    <!-- キャンセル、CSV出力ボタン -->
    <div class="row mt-2 mb-2 ml-2 mr-2">
      <div class="col-sm-5">
        <base-button type="secondary" block @click="modals.outputCSVDlg = false"
          >キャンセル</base-button
        >
      </div>
      <div class="col-sm-2"></div>
      <div class="col-sm-5">
        <base-button type="primary" block @click="onClickCSVBtn"
          >CSV出力</base-button
        >
      </div>
    </div>
  </modal>
  <!-- 編集用のモーダル(運行情報) -->
  <modal v-model:show="modals.editTimeLineDlg" size="lg" body-classes="p-0">
    <template v-slot:header>
      <h6 class="modal-title">
        [{{ editTimeLineVehicleName }}] 運行履歴の編集
      </h6>
    </template>

  <el-tabs
    v-if= !isZando
    type="card"
    v-model="acctivePane"
    @tab-click="tabClick"
  >
    <!----------------------------------------------------
      生コン1つめのタブ（受入検査項目）
      ---------------------------------------------------->
    <el-tab-pane v-if= !isZando name="0">
      <template #label>
        <span class="font-size-middle font-weight-bold">運行履歴</span>
      </template>


    <card
      v-if= !isZando
      type="secondary"
      header-classes="bg-transparent pb-5"
      body-classes="px-lg-2 py-lg-2"
      class="border-0 mb-0"
    >
      <div class="d-flex justify-content-center">
        <div class="flex-column">
          
          <div class="col-md-12">
            <base-input
              v-if= !isZando
              name="endAreaId"
              label="工区"
              input-classes="form-control-sm"
            >
              <el-select
                placeholder=""
                class="timeline-modal-input"
                size="mini"
                v-model="editTimeLineData.drive.endAreaId"
                readonly="true"
                @change="onChangeAreaDrive(editTimeLineData.drive.endAreaId)"
              >
                <el-option
                  v-for="item in listDriveArea"
                  class="select-primary"
                  :value="item.id"
                  :label="item.name"
                  :key="item.name"
                >
                </el-option>
              </el-select>
            </base-input>
          </div>

          <div class="col-md-12">
            <base-input v-if= !isZando name="factoryStartTime" label="工場出発時刻">
              <el-time-picker
                ref="timePickFactoryStartTime"
                class="w-100"
                v-model="editTimeLineData.drive.factoryStartTime"
                placeholder=""
                :clearable="false"
                size="medium"
                format="HH:mm"
              >
              </el-time-picker>
            </base-input>
          </div>

          <div class="col-md-12">
            <base-input v-if= !isZando name="pouringStartTime" label="打設開始時刻">
              <el-time-picker
                class="w-100"
                v-model="editTimeLineData.drive.pouringStartTime"
                placeholder=""
                :clearable="false"
                size="medium"
                format="HH:mm"
              >
              </el-time-picker>
            </base-input>
          </div>

          <div class="col-md-12">
            <base-input v-if= !isZando name="siteStartTime" label="現場出発時刻">
              <el-time-picker
                class="w-100"
                v-model="editTimeLineData.drive.siteStartTime"
                placeholder=""
                :clearable="false"
                size="medium"
                format="HH:mm"
              >
              </el-time-picker>
            </base-input>
          </div>

        </div>

        <div class="flex-column">

          <div class="col-md-12">
            <base-input v-if= !isZando name="pouringPosition" label="打設箇所">
              <el-select
                v-model="editTimeLineData.drive.pouringPosition"
                reserve-keyword
                placeholder="選択してください。"
              >
                <el-option
                  v-for="item in listDrivePouringPosition"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
            </base-input>
          </div>

          <div class="col-md-12">
            <base-input v-if= !isZando name="siteEndTime" label="現場到着時刻">
              <el-time-picker
                ref="timePickSiteEndTime"
                @focus="onFocusTimePickSiteEndTime"
                class="w-100"
                v-model="editTimeLineData.drive.siteEndTime"
                placeholder=""
                :clearable="false"
                size="medium"
                format="HH:mm"
              >
              </el-time-picker>
            </base-input>
          </div>

          <div class="col-md-12">
            <base-input v-if= !isZando name="pouringEndTime" label="打設終了時刻">
              <el-time-picker
                class="w-100"
                v-model="editTimeLineData.drive.pouringEndTime"
                placeholder=""
                :clearable="false"
                size="medium"
                format="HH:mm"
              >
              </el-time-picker>
            </base-input>
          </div>

          <div class="col-md-12">
            <base-input v-if= !isZando name="factoryEndTime" label="工場到着時刻">
              <el-time-picker
                class="w-100"
                v-model="editTimeLineData.drive.factoryEndTime"
                placeholder=""
                :clearable="false"
                size="medium"
                format="HH:mm"
              >
              </el-time-picker>
            </base-input>
          </div>

        </div>

        <div class="flex-column">
          
          <div class="col-md-12">
            <base-input v-if= !isZando name="carryType" label="積載物">
              <el-select
                placeholder=""
                v-model="editTimeLineData.drive.carryType"
                readonly="true"
              >
                <el-option
                  v-for="item in carryTypes"
                  class="select-primary"
                  :value="item.id"
                  :label="item.name"
                  :key="item.name"
                >
                </el-option>
              </el-select>
            </base-input>
          </div>

        </div>

      </div>
    </card>

    </el-tab-pane>

    <el-tab-pane v-if= !isZando name="1">
      <template #label>
        <span  class="font-size-middle font-weight-bold">納品書写真</span>
      </template>

        <card
          type="secondary"
          header-classes="bg-transparent pb-5"
          body-classes="px-lg-2 py-lg-2"
          class="border-0 mb-0"
        >
          <!-- 納入伝票がないとき-->
          <div v-if="thumbDeliveryPhoto == null">
            <div style="margin-left:50px;margin-top:10px">
              <h3>該当する納入伝票は存在しません。</h3>
            </div>
          </div>

          <!-- 納入伝票情報表示-->
          <div v-if="thumbDeliveryPhoto != null" class="d-flex justify-content-start">
            <div class="flex-column" style="margin-left:20px">
              <small>納品書写真</small>
              <div>
                <el-image style="width: 160px; height: 160px" :fit="`cover`" :src="thumbDeliveryPhoto" :key="thumbDeliveryPhoto" :preview-src-list="[thumbDeliveryPhoto]" />
              </div>
            </div>

            <div class="flex-column" style="margin-left:60px;margin-top:20px">
              <base-input name="factoryEndTime" label="出発時刻">
                <el-time-picker
                  class="w-100"
                  v-model="editDeliveryStartTime"
                  placeholder=""
                  :clearable="false"
                  size="medium"
                  format="HH:mm"
                >
                </el-time-picker>
              </base-input>

              <base-input name="factoryEndTime" label="到着時刻">
                <el-time-picker
                  class="w-100"
                  v-model="editDeliveryEndTime"
                  placeholder=""
                  :clearable="false"
                  size="medium"
                  format="HH:mm"
                >
                </el-time-picker>
              </base-input>
            </div>         
          </div>

        </card>

    </el-tab-pane>
  </el-tabs>
  <!----------------------------------------------------
    残土（運行履歴の編集）
    ---------------------------------------------------->
   <card
      v-if= isZando
      type="secondary"
      class="zando-card-body"
    >
      <div class="d-flex justify-content-center">
        <div class="flex-column">
          
          <div class="col-md-12">
            <base-input
              v-if= isZando
              name="endAreaId"
              label="土捨場"
              input-classes="form-control-sm"
            >
              <el-select
                placeholder=""
                class="timeline-modal-input"
                size="mini"
                v-model="editTimeLineData.drive.endAreaId"
                readonly="true"
                @change="onChangeAreaDrive(editTimeLineData.drive.endAreaId)"
              >
                <el-option
                  v-for="item in listDriveArea"
                  class="select-primary"
                  :value="item.id"
                  :label="item.name"
                  :key="item.name"
                >
                </el-option>
              </el-select>
            </base-input>
          </div>

          <div class="col-md-12">
            <base-input v-if= isZando name="pouringStartTime " label="積込開始時刻">
              <el-time-picker
                class="w-100"
                v-model="editTimeLineData.drive.pouringStartTime"
                placeholder=""
                :clearable="false"
                size="medium"
                format="HH:mm"
              >
              </el-time-picker>
            </base-input>
          </div>
          <div class="col-md-12">
            <base-input v-if= isZando name="factoryStartTime " label="現場出発時刻">
              <el-time-picker
                class="w-100"
                v-model="editTimeLineData.drive.factoryStartTime "
                placeholder=""
                :clearable="false"
                size="medium"
                format="HH:mm"
              >
              </el-time-picker>
            </base-input>
          </div>
          <div class="col-md-12">
            <base-input v-if= isZando name="siteStartTime " label="土捨場出発時刻">
              <el-time-picker
                class="w-100"
                v-model="editTimeLineData.drive.siteStartTime "
                placeholder=""
                :clearable="false"
                size="medium"
                format="HH:mm"
              >
              </el-time-picker>
            </base-input>
          </div>
        
        </div>
      
      <div class="flex-column">

        <div class="col-md-12">
            <base-input v-if= isZando name="pouringPosition" label="岩種">
              <el-select
                v-model="editTimeLineData.drive.pouringPosition"
                reserve-keyword
                placeholder="選択してください。"
              >
                <el-option
                  v-for="item in listDrivePouringPosition"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
            </base-input>
        </div>
        
        <div class="col-md-12">
            <base-input v-if= isZando name="pouringEndTime " label="積込終了時刻">
              <el-time-picker
                class="w-100"
                v-model="editTimeLineData.drive.pouringEndTime "
                placeholder=""
                :clearable="false"
                size="medium"
                format="HH:mm"
              >
              </el-time-picker>
            </base-input>
        </div>
        
        <div class="col-md-12">
            <base-input v-if= isZando name="siteEndTime " label="土捨場到着時刻">
              <el-time-picker
                class="w-100"
                v-model="editTimeLineData.drive.siteEndTime "
                placeholder=""
                :clearable="false"
                size="medium"
                format="HH:mm"
              >
              </el-time-picker>
            </base-input>
        </div>
        
        <div class="col-md-12">
            <base-input v-if= isZando name="factoryEndTime" label="現場到着時刻">
              <el-time-picker
                class="w-100"
                v-model="editTimeLineData.drive.factoryEndTime"
                placeholder=""
                :clearable="false"
                size="medium"
                format="HH:mm"
              >
              </el-time-picker>
            </base-input>
          </div>

      </div>
      <div class="flex-column" style="margin-top: 182px;">
          
          <div class="col-md-12">
            <base-input v-if= isZando name="unloadingTime" label="荷降時刻">
              <el-time-picker
                class="w-100"
                v-model="editTimeLineData.drive.unloadingTime"
                placeholder=""
                :clearable="false"
                size="medium"
                format="HH:mm"
              >
              </el-time-picker>
            </base-input>
          </div>

        </div>
      </div>
   </card>
    
    <!-- キャンセル、更新ボタン -->
    <div class="row mt-2 mb-2 ml-2 mr-2">
      <div class="col-sm-5">
        <base-button type="secondary" block @click="onClickCancelUpdateTimeline"
          >キャンセル</base-button
        >
      </div>
      <div class="col-sm-2"></div>
      <div class="col-sm-5">
        <base-button v-if= !isZando type="primary" block @click="onClickUpdateTimeline"
          >更新</base-button
        >
        <base-button v-if= isZando  type="success" block @click="onClickUpdateTimeline"
          >更新</base-button
        >
      </div>
    </div>
  </modal>
  <!-- 拠点未作成時モーダル -->
  <modal
    v-model:show="modals.noBaseArea"
    size="medium"
    modal-classes=""
    modal-content-classes=""
    body-classes="p-0 secondary"
  >
    <template v-slot:header>
      <h6 class="modal-title">拠点未登録</h6>
    </template>
    <card
      type="secondary"
      header-classes="pb-5"
      body-classes="px-lg-2 py-lg-2"
      class="border-0 mb-0"
    >
      <div class="col-md-12">
        <h3 class="mt-0 text-danger">拠点情報が登録されていません。</h3>
        <p class="ml-2 my-2">
          拠点編集画面より以下を登録してください。
        </p>
        <p v-if="listBaseArea.length - listArea.length == 0" class="ml-2 mb-0">
          ・出発地点（{{ isZando ? arrival : departure}}）
        </p>
        <p v-if="listArea.length == 0" class="ml-2 mb-0">・到着地点（{{ isZando ? soilArrival : arrival}}）</p>
      </div>
    </card>
    <div class="row mt-2 mb-2 ml-2 mr-2 d-flex justify-content-between">
      <div class="ml-2">
        <el-button type="text text-warning" @click="modals.noBaseArea = false"
          >後で登録する</el-button
        >
      </div>
      <!-- 
    <div class="col-sm-2">
    </div>
    -->
      <div class="mr-2">
        <el-button
          type="primary"
          icon="ni ni-square-pin"
          @click="onClickEditBase"
          >拠点編集へ →</el-button
        >
      </div>
    </div>
  </modal>

  <!-- 地図更新間隔設定（モーダル） -->
  <modal v-model:show="modals.editMapInterval" size="sm" body-classes="p-0">
    <template v-slot:header>
      <h6 class="modal-title">地図更新間隔の設定</h6>
    </template>
    <card
      type="secondary"
      header-classes="bg-transparent pb-5"
      body-classes="px-lg-2 py-lg-2"
      class="border-0 mb-0"
    >
      <div class="col-md-12">
        <base-input type="text" label="更新間隔（分）">
          <template></template>
          <el-slider v-model="mapInterval" show-input :min="1" :max="60">
          </el-slider>
        </base-input>
      </div>
    </card>
    <!-- キャンセル、更新ボタン -->
    <div class="row mt-2 mb-2 ml-2 mr-2">
      <div class="col-sm-5">
        <base-button
          type="secondary"
          block
          @click="modals.editMapInterval = false"
          >キャンセル</base-button
        >
      </div>
      <div class="col-sm-2"></div>
      <div class="col-sm-5">
        <base-button type="primary" block @click="onClickSetMapInterval"
          >更新</base-button
        >
      </div>
    </div>
  </modal>

  <!-- 運行用QRコード作成（モーダル） -->
  <modal
    v-if="modals.createQRCodeDlg"
    v-model:show="modals.createQRCodeDlg"
    size="xl"
    class="qrmodal"
    body-classes="p-0"
    closeOutsiteClick="false"
  >
    <template v-slot:header>
      <h6 class="modal-title">運行用QRコード作成</h6>
    </template>
    <card
      type="secondary"
      header-classes="bg-transparent pb-5"
      body-classes="px-lg-2 py-lg-2"
      class="border-0 mb-0"
    >
      <div class="row mb-2 ml-2 mr-2">
        <div class="col-sm-6 mb-5 qrform">
          <div class="row">
            <div class="col-sm-12 mb-5">
              {{ isZando ? soilExplanation : explanation}}、ユーザー名、パスワードを入力してQRコードを作成ボタンを押してください。<br />
              QRコードをスマートフォンアプリで読み込むことで車両設定を素早く行えます。
            </div>

            <div class="col-sm-9 ml-3">
              <h4>日付  {{ isZando ? soilDayExplanation : dayExplanation}}</h4>
              <base-input name="outputQRCodeDate">
                <el-date-picker
                  class="w-100 qrcode-date-picker"
                  v-model="modalQRCodeDate"
                  type="date"
                  format="YYYY/MM/DD"
                  value-format="YYYY-MM-DD"
                  placeholder="日付を選択"
                  :disabled-date="disabledDateForQRCode"
                  :shortcuts="shortcutsForQRCode"
                  @change="onChangeQRCodeDatePicker(modalQRCodeDate)"
                >
                </el-date-picker>
              </base-input>

              <h4>{{ isZando ? soilExplanation : explanation}}<require-tag /></h4>
              <base-input name="pouringPositionQRCode">
                <el-select
                  v-model="pouringPositionQRCode"
                  readonly="true"
                  reserve-keyword
                  placeholder="選択してください。"
                >
                  <el-option
                    v-for="item in this.pouringListforQRCode"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
              </base-input>

              <h4>ユーザー名</h4>
              <base-input name="userPosition">
                <el-select
                  v-model="userPosition"
                  readonly="true"
                  reserve-keyword
                  placeholder="選択してください。"
                >
                  <el-option
                    v-for="item in userList"
                    :key="item.userId"
                    :label="`${item.name}:${item.userId}`"
                    :value="item.userId"
                  >
                  </el-option>
                </el-select>
              </base-input>

              <el-form ref="form" label-position="top" @submit.prevent>
                <h4>パスワード <require-tag /></h4>
                <el-form-item prop="name">
                  <el-input
                    class="qr-password"
                    type="text"
                    placeholder="パスワード"
                    v-model="password"
                  ></el-input>
                </el-form-item>
              </el-form>

              <base-button type="primary" block @click="onClickCreateQRCode"
                >QRコードを作成</base-button
              >

              <base-button
                v-if="qrcodeText"
                style="margin-top: 30px"
                type="primary"
                block
                @click="onClickPrint"
                ><div class="fas fa-print">印刷する</div></base-button
              >
            </div>
          </div>
        </div>

        <div class="col-sm-6 qrmain">
          <div v-if="qrcodeText" class="mb-3 qr-info">
            <div>{{ modalQRCodeDateLabel }} 用のQRコード</div>
            <div>{{ projectName }}</div>
            <div>{{ pouringPositionQRCodeLabel }}</div>
          </div>

          <div
            style="width: 500px; height: 500px; border: 1px solid; border-radius: 6px; position: relative;"
          >
            <div
              style="margin: 0; position: absolute; top: 50%; left: 50%; margin-right: -50%; transform: translate(-50%, -50%);"
            >
              <div v-if="qrcodeText == ''">
                <div class="text-center">
                  <img src="img/icons/common/qrcode-solid.svg" width="50" />
                </div>
                <div><p>ここにQRコードが表示されます。</p></div>
              </div>
              <vue-qrcode
                v-if="qrcodeText"
                :value="qrcodeText"
                :options="{ width: 480 }"
              ></vue-qrcode>
            </div>
          </div>
          <div class="qrPrintLogo">
            <div class="d-flex justify-content-center align-items-center">
              <div
                style="width:100%;padding:10px auto 10px auto;"
                class="mt-2 text-center"
              >
                IMANANDAI
              </div>
            </div>
          </div>
        </div>
      </div>
    </card>
  </modal>

  <!-- 車両設定モーダル -->
  <modal v-model:show="modals.editVehicleDlg" size="sm" body-classes="p-0">
    <template v-slot:header>
      <h6 class="modal-title">車両番号設定</h6>
    </template>
    <card
      type="secondary"
      header-classes="bg-transparent pb-5"
      body-classes="px-lg-2 py-lg-2"
      class="border-0 mb-0"
    >

      <div class="col-md-12">
        <base-input type="text" label="車両番号">
          <template></template>
          <el-input
            placeholder=""
            v-model="modaleditVehicleName"
          ></el-input>
        </base-input>
      </div>
    </card>

    <!-- キャンセル、設定出力ボタン -->
    <div class="row mt-2 mb-2 ml-2 mr-2">
      <div class="col-sm-5">
        <base-button type="secondary" block @click="modals.editVehicleDlg = false"
          >キャンセル</base-button
        >
      </div>
      <div class="col-sm-2"></div>
      <div class="col-sm-5">
        <base-button type="primary" block @click="onClickUpdateVehicleInfo"
          >設定</base-button
        >
      </div>
    </div>
  </modal>

  <!-- 運行先一括設定モーダル -->
  <modal v-model:show="modals.driveBulkSettingDlg" size="lg" body-classes="p-0">
    <template v-slot:header>
      <h6 class="modal-title">運行先一括設定</h6>
    </template>
    <card
      type="secondary"
      header-classes="bg-transparent pb-5"
      body-classes="px-lg-2 py-lg-2"
      class="border-0 mb-0"
    >

      <div class="col-md-12">
        <p><font color="black">ログイン済み端末の運行設定を一括して行います。<br>
        iOSアプリはログインを行い、運行前設定画面を表示させておいてください。</font></p>
        <p><font color="red">※運行開始済みの端末には通知は行われません。</font></p>
      </div>

      <div class="col-md-12">
        <h3>運行情報設定</h3>
        <div style="margin-left:10px">
          <h4>日付<br>&nbsp;{{dateString}}</h4>            
          <h4 style="margin-top:10px"> {{ isZando ? soilExplanation : explanation }} </h4>          
          <el-select
            class="col-sm-8"
            :loading="isLoading"
            v-model="selectDriveSettingPouringPosition"
            filterable
            @change="chengePouringPosition"
            placeholder="予定数量未登録"
          >
            <el-option
              v-for="item in listDriveSettingPouringPosition"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>

    </card>

    <!-- 一括で運行設定出力ボタン -->
    <div class="row mt-2 mb-2 ml-2 mr-2">
      <div class="col-sm-3"></div>
      <div class="col-sm-6">
        <base-button type="primary" :disabled="disabledSettingBtn" block @click="onClickDriveBulkSetting"
          >一括で運行設定</base-button
        >
      </div>
    </div>
  </modal>

  <!-- 処理確認モーダル -->
  <modal v-model:show="modals.confirmDlg">
    <template v-slot:header>
        <h5 class="modal-title" id="modalLabel">{{confirmTitle}}</h5>
    </template>
    <div class="mb-5">
      {{confirmMessage}}
    </div>
    <div class="row mt-2 mb-2 ml-2 mr-2">
      <div class="col-sm-5">
        <base-button type="secondary" block @click="onClickConfirmCancel">キャンセル</base-button>
      </div>
      <div class="col-sm-2">
      </div>
      <div class="col-sm-5">
        <base-button type="primary" block @click="onClickConfirmOK">設定</base-button>
      </div>
    </div>
  </modal>

  <!-- CO2排出量詳細モーダル -->
  <modal v-model:show="modals.co2Detail" size="lg">
    <template v-slot:header>
      <h5 class="modal-title" id="modalLabel">CO2排出量</h5>
    </template>

    <el-tabs
      type="card"
      v-model="acctivePane"
    >
      <el-tab-pane name="0">
        <template #label>
          <span class="font-size-middle font-weight-bold">車両別表示</span>
        </template>      

        <el-table
          :data="co2DetailData"
        >
          <el-table-column label="車両番号" min-width="100px" prop="vehicleNo" ></el-table-column> 
          <el-table-column label="CO2排出量（測定値）" min-width="160px" prop="actualValue" ></el-table-column> 
          <el-table-column label="CO2排出量（予測値）" min-width="160px" prop="predictValue" ></el-table-column> 
          <el-table-column label="エコ運転状況" min-width="100px" prop="ecoDriveStatus" ></el-table-column> 

        </el-table>

      </el-tab-pane>

      <el-tab-pane name="1">
        <template #label>
          <span class="font-size-middle font-weight-bold">グラフ表示</span>
        </template>      
        
        <h3>集計期間</h3>
        <div class="d-flex">
          <el-radio-group v-model="rdoTotalPeriod" @change="changePeriod">
            <el-radio :label="0">全期間</el-radio>
            <el-radio :label="1">期間指定</el-radio>
          </el-radio-group>        

          <div style="margin-left:10px">
            <el-date-picker
              v-model="startTotalPeriod"
              type="month"
              :clearable="false"
              :style="`width:130px`"
              size="medium"
              format="YYYY/MM"
              :disabled-date="disabledDate"
              :disabled="disabledRdoPeriod"
            >
            </el-date-picker>
          </div>
          <div style="margin-top:10px">
            <h4>～</h4>
          </div>
          <div style="margin-left:10px">
            <el-date-picker
              v-model="endTotalPeriod"
              type="month"
              :clearable="false"
              :style="`width:130px`"
              size="medium"
              format="YYYY/MM"
              :disabled-date="disabledDate"
              :disabled="disabledRdoPeriod"
            >
            </el-date-picker>
          </div>
          <div>
            <el-button
              class="listButton"
              size="medium"
              @click="onClickUpdateTotalPeriod"
              >更新</el-button
            > 
          </div>
          <div style="margin-left:auto">
            <el-button
              class="listButton"
              size="medium"
              @click="onClickCSVTotalPeriod"
              >CSV出力</el-button
            > 
          </div>
        </div>
        <!-- 折れ線グラフ -->
        <div v-if="chartLineDetailData.labels.length > 0">
          <div class="" v-if="isShowChart" style="height:350px;">
            <Line 
              style="height:350px;width:600px;margin-left:50px"
              :chart-options="ChartLineDetailOptions"
              :chart-data="chartLineDetailData"
            />
          </div>
        </div>
        <!-- インデックス -->
        <div class="d-flex justify-content-between align-items-end">
          <div class="d-flex flex-column">
            <div class="d-flex align-items-center">
              <div>
                <h1>計測値：{{dispDetailActualTotalValue}}</h1>
              </div>
              <div class="ml-2">
                <h3>t-CO2</h3>
              </div>
            </div>
            <div>
              <h3>（予測値：{{dispDetailPredictlTotalValue}}t-CO2）</h3>
            </div>
          </div>
          <div>
            <h5>※CO2排出量は燃費法の算出を行っています。</h5>
          </div>
        </div>

      </el-tab-pane>
    </el-tabs>


  </modal>

</template>

<style scoped>
.red {
  filter: invert(15%) sepia(95%) saturate(6932%) hue-rotate(358deg) brightness(95%) contrast(112%);
}
.white {
  filter: invert(100%) sepia(95%) saturate(6932%) hue-rotate(180deg) brightness(150%) contrast(112%);
}
.signage-font-main {
  font-size:3em;
  font-weight: 800;
  color:#001437;
}
.signage-subtext {
  font-size:0.5em;
}
.row-line-height {
  line-height: 0.5;
}
.moreinfo-toggle-button {
  width: 100px;
  color: #fff;
  background-color: rgba(0,0,0,0.7);
  border: 0px solid rgba(0,0,0,0.7);
  font-size: 0.85rem;
  border-bottom-left-radius:0px !important;
  border-bottom-right-radius:0px !important;
}
.moreinfo-button {
  position:absolute;
  left:calc(50% + 225px);
  bottom:0;
  z-index:1001;
}
.moreinfo-wide-button {
  position:absolute;
  left:calc(50% - 50px);
  bottom:0;
  z-index:1001;
}
.moreinfo-button-folding {
  position:absolute;
  left:calc(50% + 550px);
  bottom:0;
  z-index:1001;
}
.moreinfo-contents {
  
  background-color: rgba(0,0,0,0.7);
}
.moreinfo-menu {
  
  background-color: rgba(0,0,0,0.3);
}

.moreinfo-menu-text {
  color: #eaeaea;
  font-size:0.95rem;
  font-weight:100;
}
.moreinfo-menu-active-text {
  color: #f9d74f;
  font-size:0.95rem;
  font-weight: 800;

}
.moreinfo-box {
  position:absolute;
  bottom:0;
  z-index: 1000;
  /* height: 185px; */
  width: calc(100% - 550px);
  /* opacity: 0.7; */
  /* background-color: rgba(0,0,0,0.7); */
}
.moreinfo-widebox {
  position:absolute;
  bottom:0;
  z-index: 1000;
  /* height: 185px; */
  width: calc(100%);
  /* opacity: 0.7; */
  /* background-color: rgba(0,0,0,0.8); */
}
.moreinfo-indexbutton {
  width: 100px;
  color: #fff;
  background-color: #ED7D31;
  border: 1px solid #AE5A21;
  font-size: 0.85rem;
}
.actual-results-box {
  position:absolute;
  z-index: 1000;
  top: 20px;
  left:calc(100px + 50% - 500px);
}
.dougnut {
  z-index: 1000;
  position: absolute;  
  top: 0px;
  left: 0px;
  /* width: 400px; */
}
.guide {
  z-index: 1000;
  position: absolute;  
  top: 50px;
  left: 400px;
  width: 600px;
}
.blink {
  animation: blinkAnime 0.5s infinite alternate;
}
@keyframes blinkAnime{
    0% { color: #ffaaaa; text-decoration: none; text-shadow: none; }
  100% { color: #ff2222; /*text-shadow: -1px 1px 8px #ff7777, 1px -1px 8px #ff7777;*/  }
}
/** このVueだけのスタイル */
/* .my-box {
    border:1px solid red;
  } */
/* .timeline-header {
    height:48px;
    background-color: #154ca3;
  } */

.timeline-main {
  /* height: calc(100% - 64px -48px -48px); */
  /* height: calc(100% - (64px + 64px)); */
  height: calc(100% - 96px);
  /* height: calc(100% - 72px); */
  /* height:100%; */
}

.timeline-left {
  width: 550px;
  /* width: 600px; */
  /* height: calc(100% - 8px); */
  height: 100%;
  flex: 0 0 auto;
}

.timeline-list {
  /* width: 600px; */
  /* height: calc(100% - 8px); */
  height: calc(100% - 36px);
  flex: 0 0 auto;
}

.timeline-map {
  /* height: calc(100% - 12px); */
  /* height: calc(100% - 36px); */
  height: 100%;
}

/* :deep .el-table th {
    background-color: #B9E3F9;
    color: #5676D9;
    font-weight: 600;
    text-align: center;
  } */

:deep .el-table__row {
  background-color: #fff;
}

:deep .el-table .cell .vehicle-cell td {
  background-color: #6d97f7 !important ;
  color: #fff !important;
}

:deep .el-table .vehicle-row td {
  background-color: #6d97f7 !important ;
  color: #fff !important;
}

:deep .el-table .warning-cell {
  --el-table-tr-background-color: var(--el-color-warning-lighter) !important;
  /* --el-table-tr-background-color: yellow;
    background-color: yellow !important ; */
  color: red !important;
  font-weight: bold !important;
}

:deep .el-table--border td:first-child .cell {
  padding-left: 0px !important;
}

:deep .el-table .el-table th {
  text-align: center !important;
}

.zando-card-body{
  background-color: #f3ffe8 !important;
}
.listButton {
  color: #fff;
  background-color: #ED7D31;
  border: 1px solid #AE5A21;
  font-size: 0.85rem;
}

.pouringStartButton {
  color: #fff;
  background-color: #146F00;
  border: 1px solid #5dee3c;
  font-size: 0.85rem;
}

.pouringEndButton {
  color: #fff;
  background-color: #f5127c;
  border: 1px solid #ec4a96;
  font-size: 0.85rem;
}
.cargoStartButton{
  color: #fff;
  background-color: #146F00;
  border: 1px solid #5dee3c;
  font-size: 0.85rem;
}

.cargoEndButton{
  color: #fff;
  background-color: #146F00;
  border: 1px solid #5dee3c;
  font-size: 0.85rem;
}

.unloadingTimeButton{
  color: #fff;
  background-color: #146F00;
  border: 1px solid #5dee3c;
  font-size: 0.85rem;
}

.driveInfo {
  position: relative;
  left: 5px;
  text-align: left;
}

.driveIconBox {
  position: relative;
}

.driveIconBox p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  /* top: 4px;
    left: 16px; */
  text-align: center;
  font-size: 16 !important;
  font-weight: 1000;
  -webkit-text-fill-color: white;
  -webkit-text-stroke-color: grey;
  /* -webkit-text-stroke-width: 1px; */
  /* -webkit-text-stroke-color: '#263238'; */
}

.fixed-col-center {
  position: sticky;
  left: 200 !important;
}

.el-button--primary {
  color: #fff;
  background-color: #003c9c;
  border-color: #154ca3;
}

.noBaseArea {
  background-color: #ffeab3 !important;
}
.noBaseAreaFont {
  color: #e1a050 !important;
  /* color: #F68E16 !important; */
}

.btn {
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.active {
  color: #fff;
  background-color: #003c9c;
  border-color: #154ca3;
}

.active:hover {
  color: white;
}

:deep .el-input__inner {
  height: 36px !important;
  padding-right: 0px !important;
}

:deep .el-input--small > .el-input__inner {
  height: 32px !important;
  padding-right: 39px !important;
}

:deep .el-input--prefix .el-input__inner {
  width: calc(100% - 13px) !important;
}

:deep .qrcode-date-picker > .el-input__inner {
  width: 100% !important;
}

/* :deep .leaflet-interactive {
  cursor: grab !important;
} */

.qr-info > div {
  font-size: 28px;
  font-weight: bold;
}

:deep .qr-password > input {
  -webkit-text-security: disc;
}
</style>
<style>
.qrPrintLogo {
  display: none;
}
/** 全体に影響するスタイル */
@media print {
  .qrmodal {
    background-color: white !important;
  }
  .main-content {
    margin-left:0px !important;
  }
  .router-content {
    overflow: hidden;
    width: 1px;
    height: 1px;
  }
  .sidenav  {
    display: none !important;
  }
  .modal {
    border:0px solid red;
    position: absolute !important;
    width:100% !important;
    height:100% !important;
    /* transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%); */
    /* overflow: visible; */
  }
  .qrmodal .modal-dialog {
    
    max-width: initial !important;
    min-height: 80% !important;
    margin: auto;
    
    /* margin: 20px !important;
    padding: 20px !important; */
  }
  .qrmodal .modal-content {
    
    position: absolute;
    width: auto;
    /* left: 0;
    top: 0;
    margin: 0 !important;
    width: 100% ;
    height: 100% !important; */

    /* top: 50%;
    left: 50%; */
    /* transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%); */
  }
  .moreinfo-box {
    display: none !important;
  }
  .qrmodal .qrform {
    display: none;
  }
  .qrmodal .qrmain {
    margin: auto;
  }
  .qrmodal .col-sm-6 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  /* .main-content:has(.qrmodal) {
    height: 1px;
    width: 1px;
  } */
  body:has(.qrmodal) {
    height: 1px;
    width: 1px;
    background-color: white;
  }
  .qrPrintLogo {
    display: block;
  }
  .Vue-Toastification__toast {
    display: none !important;
  }
}

.table-header-latest {
  background-color: #b9e3f9 !important;
  color: #5676d9 !important;
  font-weight: 600 !important;
  text-align: center !important;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.table-header-latest-zando {
  background-color: #f3ffe8 !important;
  color: #006400 !important;
  font-weight: 600 !important;
  text-align: center !important;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}


.table-header-vehicles {
  background-color: #606266 !important;
  color: #fff !important;
  font-weight: 600 !important;
  text-align: center !important;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.warning-cell {
  --el-table-tr-background-color: var(--el-color-warning-lighter) !important;
  /* --el-table-tr-background-color: yellow;
    background-color: yellow !important ; */
  color: red !important;
  font-weight: bold !important;
}
</style>
<style lang="scss" src="./css/TimeLine-scoped.scss" />
<style v-if="isZando" lang="scss" src="./css/TimeLineZando-scoped.scss" scoped />
<script>
// import { onBeforeRouteLeave } from 'vue-router';
import appLog from "@/appUtils/AppLog";
import DateUtil from "@/appUtils/DateUtil";
// import UserInfo from "@/appUtils/UserInfo"
import TimeLineMap from "@/appViews/Projects/TimeLineMap.vue";
import ApplicationHeader from "../components/Menu/ApplicationHeader.vue";
import ApplicationSubHeader from "../components/Menu/ApplicationSubHeader.vue";

const stringify = require("csv-stringify/lib/sync");
import { useToast } from "vue-toastification";
import PorjectModel from "@/appModel/project/ProjectModel";
import DriveModel from "@/appModel/Drive/DriveModel";
import VehicleModel from "@/appModel/Vehicle/VehicleModel";
import baseAreaModel from "@/appModel/BaseArea/BaseAreaModel";
import PlanModel from "@/appModel/Plan/PlanModel";
import VolumeModel from "@/appModel/Volume/VolumeModel";
import DriveSettingModel from "@/appModel/DriveSetting/DriveSettingModel";
import NoticeModel from "@/appModel/Notice/NoticeModel";
import Modal from "@/components/Modal";
import UserModel from "@/appModel/users/UserModel";
import SpotTypeBaseModel from "@/appModel/Spot/SpotTypeBaseModel";
import SpotModel from "@/appModel/Spot/SpotModel";
import lodash from "lodash";
import RouteModel from "@/appModel/Route/RouteModel";

const { Storage } = require("aws-amplify");

const CryptoJS = require("crypto-js");
const AES = require("crypto-js/aes");
const SHA256 = require("crypto-js/sha256");
const utf8 = require("crypto-js/enc-utf8");
import VueQrcode from "@chenfengyuan/vue-qrcode";
// import { Doughnut } from 'vue-chartjs'
// import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale} from 'chart.js'
// ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

// import { Line } from 'vue-chartjs'
// import { Chart as ChartJS, Title, Tooltip, Legend, PointElement, LineElement, CategoryScale, LinearScale } from 'chart.js';
// ChartJS.register(Title, Tooltip, Legend, PointElement, LineElement, CategoryScale, LinearScale);

import { Doughnut, Line } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, PointElement, LineElement, CategoryScale, LinearScale} from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, ArcElement, PointElement, LineElement, CategoryScale, LinearScale)
import MileageModel from "@/appModel/Mileage/MileageModel";
import CO2SettingModel from "@/appModel/CO2Setting/CO2SettingModel";
import Decimal from 'decimal.js';

import MixinTimeLine from "./MixinTimeLine.js";
import MixinActualResults from "./MixinActualResults.js";
import MixinSignage from "../Signage/MixinSignage.js";
import UserInfo from "@/appUtils/UserInfo.js";
import TenantStorage from "@/appUtils/TenantStorage"
import path from 'path'
import DeliveryLimitModel from "@/appModel/Temperature/DeliveryLimitModel";
import ValidSession from "../common/ValidSession.js";
import UseApps from "@/appViews/common/UseApps.js";
import Logger from "@/appViews/common/Logger.js";

export default {
  components: {    
    TimeLineMap,
    ApplicationHeader,
    ApplicationSubHeader,
    Modal,
    VueQrcode,
    Doughnut,
    Line
  },

  //mixins: [MixinTimeLine, MixinSignage],
  // mixins: [MixinTimeLine],
  mixins: [MixinTimeLine, MixinActualResults, ValidSession, UseApps, MixinSignage, Logger],

  data() {
    return {
      projectType: "0", // 0:生コン、1：残土
      acctivePane: "0",
      isNowToday: true,
      // chartData: null,
      chartData: {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: [],
          },
        ],
      },
      ChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        animation: false,
        plugins: {    
          legend: {
            display: true,
            position: "left",
            labels: {
              fontSize: 14,
              fontColor: 'rgb(0, 0, 0)',
              color: '#ffffff'
            }
          }
        }, 
      },
      zandoChartData: {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: [],
          },
        ],
      },
      ZandoChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        animation: false,
        plugins: {    
          legend: {
            display: true,
            position: "left",
            labels: {
              fontSize: 14,
              fontColor: 'rgb(0, 0, 0)',
              color: '#ffffff'
            }
          }
        }, 
      }, 
      chartLineData: {
        labels: [],
        datasets: [
          {
            label: null,
            data: [],
            fill: false,
            borderColor: 'rgb(255, 0, 0)',
            tension: 0.1,
            spanGaps: false,
          },
        ],
      },      
      ChartLineOptions: {
        responsive: true,
        maintainAspectRatio: false,
        animation: false,
        scales: {
          x: {
            ticks: {
              color: '#FFFFFF',
              backdropColor: '#000000',
              font: {
                size: 14
              }
            },
            grid: {
              color: '#FFFFFF',
            }
          },
          y: {
            title: {
              display: true,
              text: 'CO2排出量(t-CO2)',
              color: '#FFFFFF'
            },
            min: 0,
            ticks: {
              color: '#FFFFFF',
              backdropColor: '#000000',
              font: {
                size: 14
              }
            },
            grid: {
              color: '#FFFFFF',
            }
          }
        },        
        plugins: {
          title: {
            display: true,
            text: 'プロジェクトの本日の排出量推移',
            color: '#ffffff',
            padding: 0
          },
          legend: {
            display: true, 
            labels: {
              color: '#FFFFFF',
              usePointStyle: true,
              pointStyle: 'line',
            }
          },
        }, 
      },
      chartLineDetailData: {
        labels: [],
        responsive: true,
        maintainAspectRatio: false,
        datasets: [
          {
            label: null,
            data: [],
            fill: false,
            borderColor: 'rgb(255, 0, 0)',
            tension: 0.1
          },
        ],
      },       
      ChartLineDetailOptions: {
        responsive: true,
        maintainAspectRatio: false,
        animation: false,
        scales: {
          x: {
            ticks: {
              color: '#000000',
              backdropColor: '#FFFFFF',
              font: {
                size: 14
              }
            },
            grid: {
              color: '#000000',
            }
          },
          y: {
            title: {
              display: true,
              text: 'CO2排出量(t-CO2)'
            },
            min: 0,
            ticks: {
              color: '#000000',
              backdropColor: '#FFFFFF',
              font: {
                size: 14
              }
            },
            grid: {
              color: '#000000',
            }
          }
        },        
        plugins: {    
          legend: {
            boxWidth: 10,
            display: true, 
            labels: {
              color: '#000000',
              usePointStyle: true,
              pointStyle: 'line',
            }
          },
        },
      },
      co2DetailData: [],
      actualTotalValue: 0,
      predictTotalValue: 0,
      reductionRate: 0,
      estimate: {volume: 0 },
      pouringEnd: {volume: 0, number: 0 },
      pouringOrWaiting: {volume: 0, number: 0, lastTime: "-"},
      transporting: {volume: 0, number: 0 },
      remain: {volume: 0, number: 0 },
      average: {arrive: 0, return: 0},
      achive: {volume: 0, number: 0},      
      isShowMoreInfoPanel: true,
      isShowMoreInfoPanel_Actual: true,
      isShowMoreInfoPanel_CO2: false,         
      timeLineAllData: {},
      timeLineLatests: [],
      timeLineVehicles: [],
      selectedVehicleId: "",
      selectedVehicleData: [
        {
          img: "./img/concrete/ic_circle_yellow.png",
          order: "1",
          driveNumber: "2",
          maxCapacity: "5.0",
          vehicleName: "A-1234",
          vehicleId: "hogehoge",
          totalAmount: "24.0",
        },
      ],
      projectId: "",
      projectName: "",
      endAreaId: "",
      pouringPosition: "",
      dateString: DateUtil.dateStringBase(new Date(), "YYYY/MM/DD"),
      refreshIntervalSecond: 60,
      // 1:工場出発順、2:車両番号順
      orderType: 1,
      orderTypes: [
        {
          value: 1,
          name: "出発順で表示",
        },
        {
          value: 2,
          name: "車両表示順で表示",
        },
      ],
      isNowTime: true,
      isDriveSpot: true,
      isCreateQRCode: false,
      isEditBase: true,
      isEditVehicle: true,
      isCreatePlan: true,
      isDriveLog: true,
      isOutputCsv: true,
      isDriveVue: false,
      isShowTimeline: true,
      isAcceptanceList: true,
      isDriveBulkSetting: true,
      signagePlanId: "",
      volumeId: "",
      signagePlanList: [],
      signageListArea: [],
      signageVolumlist: [],
      // sample
      title: "",
      modals: {
        outputCSVDlg: false,
        editTimeLineDlg: false,
        noBaseArea: false,
        editMapInterval: false,
        createQRCodeDlg: false,
        editVehicleDlg: false,
        driveBulkSettingDlg: false,
        confirmDlg: false,
        co2Detail: false,
      },
      confirmTitle: '',
      confirmMessage: '',      
      confirmDlgMode: 0, // 0：運行先一括設定、1：打設開始時刻設定、2：打設終了時刻設定、3:積込開始時刻設定、4：積込終了時刻設定、５：荷降時刻設定
      skDrive: '', // 打設開始、終了時刻設定処理用
      mapInterval: 60 / 60,
      modalDate: null,
      modalendAreaId: null,
      modalpouringPosition: null,
      modaleditVehicleId: null,
      modaleditVehicleName: null,
      // // 到着地点（現場・工区）のリスト
      // listArea: [],
      // // 出発地点（工場）＋ 到着地点（現場・工区）のリスト
      // listBaseArea: [],
      // CSV出力用（工区、打設箇所）リスト
      listCSVAreaAndPouring: [],
      listCSVArea: [],
      listCSVPouringPosition: [],
      // 運行情報編集用（工区、打設箇所）リスト
      listDriveAreaAndPouring: [],
      listDriveArea: [],
      listDrivePouringPosition: [],
      // 運行先一括設定モーダル用
      selectDriveSettingPouringPosition: null,
      listDriveSettingPouringPosition: [],
      disabledSettingBtn: false,
      disableRock: false,
      editTimeLineVehicleName: ``,
      editTimeLineData: {
        drive: {
          factoryStartTime: "",
          siteEndTime: "",
          pouringStartTime: "",
          pouringEndTime: "",
          siteStartTime: "",
          factoryEndTime: "",
          endAreaId: "",
          pouringPosition: "",
          unloadingTime: "",
          rockTypeName: "",
          carryType: 1,
        },
        deliveryInfo: {
          delivery: {
            startTime: "",
            endTime: ""
          }
        }
      },
      thumbDeliveryPhoto: null,
      editDeliveryStartTime: "",
      editDeliveryEndTime: "",
      keepCurrentRow: null,
      timer: null,
      editTimeLineDataBefore: this.editTimeLineData,
      carryTypes: DriveModel.getCarryTypeList(),
      // 初期表示時はtab非選択状態(moute後に設定しないと正しいDOMサイズが適用されないことへの回避)
      activeTab: "",
      userStore: null,
      // 車両ID、地図アイコン注記用表示順(1~)
      vehicleMapTextOrders: [
        // {
        //   vehicleId: 'hogehoge',
        //   iconTextOrder: 1,
        // }
      ],
      driveList: [],
      deliveryList: [],
      // planId: "",
      // planList: [],
      volumList: [],
      pouringList: [],
      selectedVolumeId: "",
      datePickerOptions: {
        disabledDate: this.disabledDate,
        disabledDateForQRCode: this.disabledDateForQRCode,
      },
      // LoadingFlag: false,
      beforeDriveLastTime: "", // 運行情報変更モーダル表示時、編集対象の運行情報の１つ前の運行情報内で最終時間保持用
      afterDriveFirstTime: "", // 運行情報変更モーダル表示時、編集対象の運行情報の１つ後の運行情報内で最も最初の時間保持用
      repeatedHitsFlg: false, // 連打防止フラグ （拠点編集画面等へ遷移する際に使用）
      /**
       * 日付選択ショートカット
       */
      shortcuts: [
        {
          text: "今日",
          value: new Date(),
        },
        {
          text: "昨日",
          value: (() => {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            return date;
          })(),
        },
        {
          text: "1週間前",
          value: (() => {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            return date;
          })(),
        },
      ],
      shortcutsForQRCode: [
        {
          text: "今日",
          value: new Date(),
        },
        {
          text: "明日",
          value: (() => {
            const date = new Date();
            date.setTime(date.getTime() + 3600 * 1000 * 24);
            return date;
          })(),
        },
        {
          text: "1週間後",
          value: (() => {
            const date = new Date();
            date.setTime(date.getTime() + 3600 * 1000 * 24 * 7);
            return date;
          })(),
        },
      ],
      volumListforQRCode: [],
      pouringListforQRCode: [],
      pouringPositionAreaName: "",
      pouringPositionQRCode: "",
      pouringPositionQRCodeLabel: "",
      userList: [],
      userPosition: "",
      password: "",
      qrcodeText: "",
      modalQRCodeDate: null,
      modalQRCodeDateLabel: null,
      spotTypeList: [],
      spotList: [],
      deliveryLimitList: [],
      remainingTime: 30,
      rdoTotalPeriod: null,
      startTotalPeriod: null,
      endTotalPeriod: null,
      disabledRdoPeriod: false,
      dispDetailActualTotalValue: 0,
      dispDetailPredictlTotalValue: 0,
      csvCO2Info: {
        periodType: 0,
        startPeriod: null,
        endPeriod: null,
        mileageList: [ {
          targetMonth: null,
          mileageRecords: []
        }],
      },
      routeIdList: [],
      isChangePouringPosition: false,
      dumpSiteList: [],
      dumpSite: "",
      zandoVolumeList: [],
      amountAll: 0,
      dateAllAmount: 0,
      datePlanTotalVolume: 0,
      unitNumber: 0,
      explanation: "打設箇所",
      soilExplanation: "土捨場",
      dayExplanation: "※打設を行う日を指定してください",
      soilDayExplanation: "※土捨を行う日を指定してください",
      departure: "工場",
      soilArrival: "土捨場",
      arrival: "現場",
    };
  },

  computed: {
    vuename() {
      return "TimeLine.vue";
    },

    timeLineData() {
      if (this.selectedVehicleId) {
        return this.selectedVehicleData;
      } else {
        return this.timeLineLatests;
      }
    },
    isShowChart() {
      if (this.projectType == "0"){
        if (this.chartData.datasets) {
          if (this.chartData.datasets.length > 0) {
            if (this.chartData.datasets[0].data) {
              if (this.chartData.datasets[0].data.length > 0) {
                return true
              }
            }
          }
        }
      } else if (this.projectType == "1"){
        if (this.zandoChartData.datasets) {
          if (this.zandoChartData.datasets.length > 0) {
            if (this.zandoChartData.datasets[0].data) {
              if (this.zandoChartData.datasets[0].data.length > 0) {
                return true
              } 
            }
          }
        }
      }
      
      return false
    }
  },

  beforeCreate() {
    // インスタンスは生成されたがデータが初期化される前
  },

  created() {
    // インスタンスが生成され､且つデータが初期化された後
    this.infoLog(
      `Start Vuex：projectid(${this.$store.state.timeline.projectid})、projectname(${this.$store.state.timeline.projectname})、datestring(${this.$store.state.timeline.datestring})`
    );
  },

  beforeMount() {
    // インスタンスが DOM 要素にマウントされる前

    // console.log("beforeMount")

    // 地図の更新間隔設定
    this.refreshIntervalSecond = this.getMapInterval();

    // // 呼び出し元で指定されたプロジェクトID、日付を取得
    // this.projectId = this.$store.state.timeline.projectid;
    // const storeDateString = this.$store.state.timeline.datestring;
    // console.log(`store指定日付：${storeDateString}`);

    // if (!storeDateString) {
    //   this.dateString = storeDateString;
    // }
  },

  async mounted() {
    // インスタンスが DOM 要素にマウントされた後
    // appLog.infoLog(`${this.vuename}`, `Start ${this.vuename}`)
    console.log(`mounted`)
    await this.init();
  },

  beforeUpdate() {
    // データは更新されたが DOM に適用される前
  },

  updated() {
    // データが更新され､且つ DOM に適用された後
    // console.log(`updated`);
  },

  beforeUnmount() {
    // Vue インスタンスが破壊される前
  },

  unmounted() {
    // Vue インスタンスが破壊された後
  },

  beforeRouteLeave() {
    // モーダルを非表示
    this.modals.outputCSVDlg = false;
    this.modals.editTimeLineDlg = false;
    this.modals.noBaseArea = false;
    this.modals.editMapInterval = false;
    this.modals.createQRCodeDlg = false;
    this.modals.editVehicleDlg = false;
    this.modals.driveBulkSettingDlg = false;
    this.modals.confirmDlg = false;
    this.modals.co2Detail = false;

    this.infoLog(`End`);
  },

  setup() {},

  // ボタンイベントなどのメソッドはmethodsに
  methods: {
    async init() {
      console.log("[START] init");

      // 処理中
      let loader = this.showLoader();

      // システム利用区分チェック
      if (!await this.isValidProjectType()) {
        return
      }

      try {
        // プロジェクトタイプ取得
        if (this.isZando) {
          this.projectType = "1"; // 残土
        } else {
          this.projectType = "0"; // 生コン
        }        
        // 呼び出し元で指定されたプロジェクトID、プロジェクト名、日付、選択中のVolume IDを取得
        this.projectId = this.$store.state.timeline.projectid;
        this.projectName = this.$store.state.timeline.projectname;
        const storeDateString = this.$store.state.timeline.datestring;
        console.log(`store指定日付：${storeDateString}`);

        if (this.$store.state.timeline.volumeid != undefined) {
          console.log(`init ${this.$store.state.timeline.volumeid}`)
          this.selectedVolumeId = this.$store.state.timeline.volumeid;
        }

        // タイトルをセット
        this.setNavInfo();

        // ユーザー情報の取得
        this.userStore = await this.$store.state.user;
        // console.log(`ユーザー情報：${JSON.stringify(this.userStore, null, "\t")}`);

        // 拠点一覧取得(判定用に保持)
        this.listBaseArea = await baseAreaModel.getBaseAreaList(this.projectId);

        // 子Map初期化
        this.$refs.map.projectId = this.projectId;
        await this.$refs.map.init(this.listBaseArea);

        // モーダル画面のセレクトボックス設定
        this.getAreaList();

        if (storeDateString) {
          this.dateString = storeDateString;

          // 日付変更イベントを発火
          await this.onChangeDate(new Date(this.dateString));
        }

        // 拠点作成確認
        await this.checkBaseArea();

        // 温度毎の運搬時間（分）の設定リスト取得
        this.deliveryLimitList = await DeliveryLimitModel.getDeliveryLimitList();

        // 残時間（閾値）取得
        this.remainingTime = await this.getProjectRemainingTime();

        this.repeatedHitsFlg = false;
        // // 初期タブを設定
        // this.activeTab = 'tabMap';
      } finally {
        this.hideLoader(loader);
        console.log("[END] init");
      }
    },

    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      // row = 表示するデータが入っている（行番号ではない）値で判定するならこれを使う
      // column = カラムのプロパティが入っている

      if (row.type === "vehicle") {
        // 車両情報
        //return [1, 9]
        return {
          rowspan: 1,
          colspan: 9,
        };
        // if (columnIndex === 1) {
        //   // console.log(`arraySpanMethod ${column.property} 横9スパン`)
        //   // return [0, 9]
        //   // return [1, 9]
        // }
      } else if (row.type === "timeline1") {
        // // ()を外す
        // let col1Number = row.col1.replace('(', '');
        // col1Number = col1Number.replace(')', '');
        // console.log(`arraySpanMethod ${col1Number} `)

        // if (col1Number > 0) {
        // if (row.col1 > 0) {
        // console.log(`arraySpanMethod ${col1Number} 正の値`)

        if (
          column.property !== "col1" &&
          column.property !== "col2" &&
          column.property !== "col3"
        ) {
          // console.log(`arraySpanMethod ${column.property} 縦2スパン`)
          return [2, 1];
        } else {
          // console.log(`arraySpanMethod ${column.property} スパンなし`)
          return;
        }

        // } else {
        //   if (columnIndex == 0) {
        //     return [0, 0]
        //   }
        //   // console.log(`arraySpanMethod ${row.col1} 負の値`)
        //   // console.log(`arraySpanMethod ${column.property} スパンなし`)
        //   return
        // }
      } else {
        return;
      }
    },

    tableCellClassName({ row, column, rowIndex, columnIndex }) {
      // console.log(row)

      if (row.type === "vehicle") {
        // 車両情報
        // 基本スタイル
        return `{padding: '4px', height: '24px', background: '#FFF', color: '#000'}`;
      } else if (row.type === "timeline1") {
        if (
          column.property === "col4" &&
          column.property !== "col5" &&
          column.property !== "col6" &&
          column.property !== "co20"
        ) {
          if (column.property === "col4") {
            if (row.pouringStartUser === "system") {
              return ".warning-cell";
            }
          } else if (column.property === "col5") {
            if (row.pouringEndUser === "system") {
              return ".warning-cell";
            }
          } else if (column.property === "co20"){
            if (row.unloadingUser === "system"){
              return ".warning-cell";
            }
          }
        }

        // 基本スタイル
        return `{padding: '4px 0px 4px 0px', height: '24px', background: '#FFF', color: '#000'}`;
      }
    },

    headerCellClassName({ row, column, rowIndex, columnIndex }) {
      if (this.selectedVehicleId) {
        return "table-header-vehicles";
      } else {
        //生コンのとき
        if (this.projectType == "0"){
          return "table-header-latest";
        //残土のとき
        } else if (this.projectType == "1"){
          return "table-header-latest-zando";
        }    
      }
    },

    headerCellStyle() {
      return "text-align:center";
    },

    // tableRowClassName({ row, rowIndex }) {
    //   // console.log(row);

    //   // if (row.type === 'vehicle') {
    //   //   return 'vehicle-row'
    //   // }
    //   return ''
    // },

    highLight(text, user) {
      // console.log(`value:${text} user:${user}`)
      if (user !== "system") {
        return text;
      }

      // システム（ジオフェンスが登録していたら強調表示
      const re = new RegExp(text, "ig");

      return text.replace(re, function(text) {
        return (
          '<span style="background-color:yellow;font-weight:bold">' +
          text +
          "</span>"
        );
      });
    },

    /**
     * 運行表示開閉時の処理です。
     */
    onCloseTimeline(value) {
      // console.log(value);
      this.isShowTimeline = !value;

      // if (!value) {
      //   // 地図表示更新を明示発行
      //   this.panelResized();
      // }
    },

    /**
     * 運行スポット表示チェックボックス変更時の処理です。
     */
    changeChkSpot(isChecked) {
      if (isChecked) {
        // 運行スポットを表示
        this.$refs.map.addSpotPois();
      } else {
        // 運行スポットを非表示
        this.$refs.map.deleteSpotPois();
      }
    },

    /**
     * 運行スポット表示チェックボックス変更時の処理です。
     */
    changeChkOuro(isChecked) {
      this.$refs.map.setOuroLayerVisible(isChecked);
    },

    /**
     * 運行スポット表示チェックボックス変更時の処理です。
     */
    changeChkFukuro(isChecked) {
      this.$refs.map.setFukuroLayerVisible(isChecked);
    },

    /**
     * 位置情報更新要求時の処理です。
     */
    async onRefreshLocation() {
      console.log(`[START] onRefreshLocation`);

      try {

        // システム利用区分チェック
        if (!this.isValidProjectType()) {
          return
        }
        // リフレッシュボタンを非活性にする
        this.$refs.appHeader.updateRefleshButton(true);
        this.timer = setTimeout(this.freeReflesh, 3000);

        // console.log("onRefreshLocation")
        if (!this.$refs.map) {
          return;
        }

        if (!this.$refs.map.refreshLocation) {
          return;
        }

        const skMap = `${this.projectId}#${DateUtil.getFormatString(
          this.dateString,
          "YYYYMMDD"
        )}`;
        const sk = `${skMap}#${this.endAreaId}`;

        // 先に車両名：工場出発順のリストを取得し、地図側にも渡しておく
        // 更にメソッド内部で運行情報リストを取得、保持
        console.log(`onRefreshLocation ${this.selectedVolumeId}`)
        this.vehicleMapTextOrders = {};
        this.vehicleMapTextOrders = await this.getVehicleMapTextOrders(sk, this.selectedVolumeId);
        this.$refs.map.setVehicleMapTextOrders(this.vehicleMapTextOrders);

        // 高速化のためawaitで待たない
        this.$refs.map.refreshLocation(skMap, this.orderType);

        // 高速化のためawaitで待たない
        // 運行表示テーブルの更新
        this.refreshTimeLine(sk);

        // 高速化のためawaitで待たない
        // 運行情報更新
        if (this.isShowMoreInfoPanel){
          if (this.projectType == "0"){
            this.dispActualResults();
            // 工区・打設箇所変更時はCO2グラフは更新しない
            if (!this.isChangePouringPosition){
              this.dispCO2Results();
            }
          } else if (this.projectType == "1"){
            this.zandoDispActualResults();
            if (!this.isChangePouringPosition){
              this.dispCO2Results();
            }
          }
        }

        // ヘッダーの時刻を更新
        this.$refs.appHeader.updateTime();
      } finally {
        console.log(`[END] onRefreshLocation`);
      }
    },

    /**
     * リフレッシュボタンを活性化する
     */
    freeReflesh() {
      clearTimeout(this.timer);
      this.$refs.appHeader.updateRefleshButton(false);
    },

    /**
     * 運行表示テーブルを更新します。
     * @param {String} sk 取得キー(projectId#yyyyMMDD)
     */
    async refreshTimeLine(sk) {
      console.log(`[START] refreshTimeLine: ${sk}`);
      // const sk = `${this.projectId}#${DateUtil.getFormatString(this.dateString, 'YYYYMMDD')}`;

      try {
        // 運搬時間取得
        let deliveryTime = await this.getDeliveryTime();
       
        // DBからテーブル情報を取得（model内でテーブル用に成型済み）
        const newTimeLine = await DriveModel.getDriveTableList(
          sk,
          this.orderType,
          this.driveList,
          this.vehicleMapTextOrders,
          this.pouringPosition,
          this.listBaseArea,
          this.deliveryList,
          deliveryTime,
          this.remainingTime
        );
        // console.log(`timeLineList: ${JSON.stringify(newTimeLine, null, "\t")}`);

        // 前回と一致していれば更新しない
        if (
          JSON.stringify(this.timeLineAllData) == JSON.stringify(newTimeLine)
        ) {
          // if (JSON.stringify(this.timeLineData) == JSON.stringify(newTimeLine)) {
          return;
        }

        // console.log(`timelineテーブルの更新: ${JSON.stringify(newTimeLine, null, "\t")}`);

        // 最新表示用
        this.timeLineLatests = newTimeLine.latestVehicleDrives;

        // 車両別全運行表示用
        this.timeLineVehicles = newTimeLine.vehicleAllDrives;

        // 車両別全運行表示時
        if (this.selectedVehicleId) {
          const id = this.selectedVehicleId;
          // いったんクリアし、新しいリストに同じ車両IDがあれば設定
          this.selectedVehicleId = "";
          const item = this.timeLineVehicles.find(
            (vehicle) => vehicle.vehicleId === id
          );

          if (item) {
            this.selectedVehicleId = item.vehicleId;
          }
        }

        // 比較用
        this.timeLineAllData = newTimeLine;
      } finally {
        console.log(`[END] refreshTimeLine`);
      }
    },

    /**
     * 日付変更時の処理です。
     */
    async onChangeDate(dateAfter) {
      console.log(`[START] onChangeDate: ${dateAfter}`);

      // 処理中表示
      let loader = this.showLoader();

      try {
        const dateAfterString = DateUtil.dateStringBase(
          dateAfter,
          "YYYY/MM/DD"
        );
        // console.log(`onChangeDate:${dateAfterString}`);

        // 当日かどうか
        //const isToday = this.isToday(dateAfterString);
        this.isNowToday = this.isToday(dateAfterString);

        const isShow = this.isNowToday;
        this.isNowTime = isShow;
        this.isEditBase = isShow;
        this.isEditVehicle = isShow;
        this.isCreatePlan = isShow;
        this.isCreateQRCode = isShow;
        this.isDriveBulkSetting = isShow;


        // 現在選択日付更新
        this.dateString = dateAfterString;

        // 到着地点（現場/工区)、打設箇所セレクタ更新
        await this.getPlan();
        // 打設計画のリスト取得(this.volumeListが更新される)
        await this.getVolumeList();
        // リスト選択用に値と表示名のリストを作成します
        this.makePouringList();
        // 運行情報用（カレンダーで日付変更時の処理）工区、打設箇所を再設定
        
        console.log(`onChangeDate this.selectedVolumeId:${this.selectedVolumeId}`)
        await this.getAreaAndPouringListforDrive(dateAfter);

        // 到着拠点(現場/工区)リスト設定
        let pouringListIndex = -1;
        // this.selectedVolumeIdからthis.pouringListのインデックスを取得。-1は未選択
        // pouringListIndex = this.getPouringListIndex();
        let selectedPouring = this.pouringList.filter(item => {
          return item.volumeId == this.selectedVolumeId
        })
        if (selectedPouring.length > 0) {
          console.log(`onChangeDate ${selectedPouring[0].id}`)
          pouringListIndex = selectedPouring[0].id
        }
        


        // console.log(`onChangeDate pouringListIndex:${pouringListIndex} this.selectedVolumeId: ${this.selectedVolumeId}`)
        
        // 打設箇所が未選択の場合
        if (pouringListIndex == -1) {
          this.$refs.appHeader.setSignagePouringList(this.pouringList);
          // 生コンの場合、volumeIdはリスト先頭を選択
          if (!this.isZando) {
            this.selectedVolumeId = this.pouringList[0].volumeId
          }
        } else {
          this.$refs.appHeader.setSignagePouringList(
            this.pouringList,
            pouringListIndex
          );
          await this.onChangePouringPosition(pouringListIndex);
        }

        // 運行位置再描画
        console.log(`onChangeDate : ${this.selectedVolumeId}`)
        await this.onRefreshLocation();

        // 日付変更時は車両選択をクリア
        this.selectedVehicleId = "";

        // 打設箇所のカレント情報クリア
        // #12594 運行情報で過去を選択した状態で運行実績を表示すると、打設箇所リストにIDが表示される で追加
        // this.selectedVolumeId = "";

      } catch (e) {
        this.errorLog(`onChangeDate`, `${this.parseErrorObject(e)}`);
      } finally {
        this.hideLoader(loader);
        console.log(`[END] onChangeDate`);
      }
    },

    /**
     * 運行情報の中から、最終時刻を取得
     * @param {Object} driveinfo 運行情報
     * @return 運行情報より、最も遅い時間を返却
     */
    getDriveLastTime(driveinfo) {
      let retValue = null;

      // 工場出発時間
      if (driveinfo.factoryStartTime) {
        retValue = driveinfo.factoryStartTime;
      }

      // 工場到着時間
      if (driveinfo.factoryEndTime) {
        if (retValue == null) {
          retValue = driveinfo.factoryEndTime;
        } else {
          if (DateUtil.isBefore(retValue, driveinfo.factoryEndTime)) {
            retValue = driveinfo.factoryEndTime;
          }
        }
      }

      // 現場出発時間
      if (driveinfo.siteStartTime) {
        if (retValue == null) {
          retValue = driveinfo.siteStartTime;
        } else {
          if (DateUtil.isBefore(retValue, driveinfo.siteStartTime)) {
            retValue = driveinfo.siteStartTime;
          }
        }
      }

      // 現場到着時間
      if (driveinfo.siteEndTime) {
        if (retValue == null) {
          retValue = driveinfo.siteEndTime;
        } else {
          if (DateUtil.isBefore(retValue, driveinfo.siteEndTime)) {
            retValue = driveinfo.siteEndTime;
          }
        }
      }

      // 打設開始時間
      if (driveinfo.pouringStartTime) {
        if (retValue == null) {
          retValue = driveinfo.pouringStartTime;
        } else {
          if (DateUtil.isBefore(retValue, driveinfo.pouringStartTime)) {
            retValue = driveinfo.pouringStartTime;
          }
        }
      }

      // 打設終了時間
      if (driveinfo.pouringEndTime) {
        if (retValue == null) {
          retValue = driveinfo.pouringEndTime;
        } else {
          if (DateUtil.isBefore(retValue, driveinfo.pouringEndTime)) {
            retValue = driveinfo.pouringEndTime;
          }
        }
      }

      return retValue;
    },

    /**
     * 運行情報の中から、最初の時刻を取得
     * @param {Object} driveinfo 運行情報
     * @return 運行情報より、最も早い時間を返却
     */
    getDriveFirstTime(driveinfo) {
      let retValue = null;

      // 工場出発時間
      if (driveinfo.factoryStartTime) {
        retValue = driveinfo.factoryStartTime;
      }

      // 工場到着時間
      if (driveinfo.factoryEndTime) {
        if (retValue == null) {
          retValue = driveinfo.factoryEndTime;
        } else {
          if (DateUtil.isAfter(retValue, driveinfo.factoryEndTime)) {
            retValue = driveinfo.factoryEndTime;
          }
        }
      }

      // 現場出発時間
      if (driveinfo.siteStartTime) {
        if (retValue == null) {
          retValue = driveinfo.siteStartTime;
        } else {
          if (DateUtil.isAfter(retValue, driveinfo.siteStartTime)) {
            retValue = driveinfo.siteStartTime;
          }
        }
      }

      // 現場到着時間
      if (driveinfo.siteEndTime) {
        if (retValue == null) {
          retValue = driveinfo.siteEndTime;
        } else {
          if (DateUtil.isAfter(retValue, driveinfo.siteEndTime)) {
            retValue = driveinfo.siteEndTime;
          }
        }
      }

      // 打設開始時間
      if (driveinfo.pouringStartTime) {
        if (retValue == null) {
          retValue = driveinfo.pouringStartTime;
        } else {
          if (DateUtil.isAfter(retValue, driveinfo.pouringStartTime)) {
            retValue = driveinfo.pouringStartTime;
          }
        }
      }

      // 打設終了時間
      if (driveinfo.pouringEndTime) {
        if (retValue == null) {
          retValue = driveinfo.pouringEndTime;
        } else {
          if (DateUtil.isAfter(retValue, driveinfo.pouringEndTime)) {
            retValue = driveinfo.pouringEndTime;
          }
        }
      }

      return retValue;
    },

    /**
     * 運行情報一覧のカレント行が変更された時に呼び出されます。
     */
    async handleTimeLineCurrentChange(currentRow) {
      // console.log(`currentRow: ${JSON.stringify(currentRow, null, "\t")}`);

      if (!currentRow) {
        return;
      }

      if (currentRow.type == "vehicle") {
        // 選択行を解除するのみで処理しない
        this.$refs.timeLineTable.setCurrentRow();
        return;
      }

      // ToDo 参照権限ユーザーは何もしない
      if (this.userStore.userType === "0") {
        this.infoLog(`参照権限ユーザー運行情報モーダル非表示`);
        // console.log(`参照権限ユーザー運行情報モーダル非表示: ${this.userStore.userName}`);
        return;
      }

      // モーダルの値セット
      let editBeforeInfo = JSON.parse(JSON.stringify(currentRow.rawData));
      const setPouringPosition = editBeforeInfo.drive.pouringPosition;
      const setCaryyType = editBeforeInfo.drive.carryType;

      this.editTimeLineVehicleName = currentRow.vehicleName;
      currentRow.rawData = editBeforeInfo;
      this.editTimeLineData = null;
      this.editTimeLineData = currentRow.rawData;
      this.editTimeLineData.deliveryInfo = null;
      this.editTimeLineData.deliveryInfo = currentRow.rowDeliveryData;

      // 納入タブ値セット
      this.thumbDeliveryPhoto = null;
      if (Object.keys(currentRow.rowDeliveryData).length){
        if (currentRow.rowDeliveryData.delivery.deliveryPhoto != null){

          // 出発、到着時刻設定
          this.editDeliveryStartTime = currentRow.rowDeliveryData.delivery.startTime;
          this.editDeliveryEndTime = currentRow.rowDeliveryData.delivery.endTime;

          // 納入写真設定
          const user = await UserInfo.getUserInfo();
          const tenantStorage = new TenantStorage(user.group);
          const photoFileFullPath = `tenant/${user.group}/${this.projectId}/${DateUtil.getFormatString(this.dateString, 'YYYYMMDD')}/${editBeforeInfo.drive.id}/${currentRow.rowDeliveryData.delivery.deliveryPhoto}`
          const photoKey = `${path.dirname(photoFileFullPath)}/${path.basename(photoFileFullPath)}`
          const result = await tenantStorage.get(photoKey);
          this.thumbDeliveryPhoto = await result.toBase64();
        }
      } 

      // 打設箇所（選択されている工区から）、積載物のリストを再設定
      this.onChangeAreaDrive(currentRow.rawData.drive.endAreaId);
      this.editTimeLineData.drive.pouringPosition = null;
      this.editTimeLineData.drive.pouringPosition = setPouringPosition;
      this.editTimeLineData.drive.carryType = null;
      this.editTimeLineData.drive.carryType = setCaryyType;

      this.keepCurrentRow = currentRow;

      // 比較用に保持
      this.editTimeLineDataBefore = JSON.parse(
        JSON.stringify(this.editTimeLineData)
      );

      // 運行情報から、対象の工区・打設箇所で絞り込み
      let filterDrive = this.driveList
        .filter(
          (item) =>
            item.drive.vehicleId === currentRow.rawData.drive.vehicleId &&
            item.drive.endAreaId === currentRow.rawData.drive.endAreaId &&
            item.drive.pouringPosition ===
              currentRow.rawData.drive.pouringPosition
        )
        .slice()
        .sort(function(a, b) {
          if (a.drive.driveNumber < b.drive.driveNumber) return -1;
          if (a.drive.driveNumber > b.drive.driveNumber) return 1;
        });

      // 時間の整合性をチェックするために前の運行情報と後の運行情報の時間取得
      this.beforeDriveLastTime = "";
      this.afterDriveFirstTime = "";

      for (let i = 0; i < filterDrive.length; i++) {
        if (currentRow.rawData.drive.id == filterDrive[i].drive.id) {
          if (i == 0) {
            // 最初のレコードの為、次の運行情報の最初の時間を取得
            if (filterDrive.length > 1) {
              this.afterDriveFirstTime = this.getDriveFirstTime(
                filterDrive[1].drive
              );
            }
          } else if (i == Number(filterDrive.length - 1)) {
            // 最後のレコードの為、前の運行情報の最後の時間を取得
            this.beforeDriveLastTime = this.getDriveLastTime(
              filterDrive[i - 1].drive
            );
          } else {
            // 前後の運行情報の時間を取得（前の運行情報からは最後の時間、後の運行情報からは最初の時間を取得）
            this.beforeDriveLastTime = this.getDriveLastTime(
              filterDrive[i - 1].drive
            );
            this.afterDriveFirstTime = this.getDriveFirstTime(
              filterDrive[i + 1].drive
            );
          }

          break;
        }
      }
      //console.log(`---------------- beforeDriveLastTime: ${this.beforeDriveLastTime}`);
      //console.log(`---------------- afterDriveFirstTime: ${this.afterDriveFirstTime}`);

      this.acctivePane = "0";
      this.modals.editTimeLineDlg = true;

      // 選択行を解除しておく
      this.$refs.timeLineTable.setCurrentRow();
    },

    /**
     * 運行スポット登録クリック時の処理です。
     */
    async onClickDriveSpot() {
      // 連打防止
      if (this.repeatedHitsFlg) return;
      this.repeatedHitsFlg = true;

      // store(vuex)に値をセット
      let store = this.$store.state.drivespot;
      store.projectid = this.projectId;
      store.projectname = this.projectName;
      store.datestring = this.dateString;
      store.spotTypeList = this.spotTypeList;

      await this.$store.commit("setDriveSpot", store);

      // ToDo 拠点編集画面表示

      // 車両一覧画面表示
      this.$router.push({
        path: "/driveSpot",
      });

      // // モダール非表示
      // this.modals.noBaseArea = false;
    },

    /**
     * 運行用QRコードクリック時の処理です。
     */
    async onClickQRCode() {
      // 日付に現在の日付を設定
      this.modalQRCodeDate = this.$store.state.timeline.datestring;

      // 変数初期化
      this.pouringListforQRCode = [];
      this.pouringPositionQRCode = "";

      for (let i=0; i< this.pouringList.length; i++){
        // 土捨場名称
        let name = this.pouringList[i].name;
        //予定数量ID
        let list = this.volumList.filter((item) =>
          item.volume.endAreaId === this.pouringList[i].endAreaId && 
          `${item.volume.endAreaName} ${item.volume.pouringPosition}` === name
        );
        if (list.length > 0){
          // 名称、ID追加
          let id = list[0].volume.id;
          this.pouringListforQRCode.push({id: id, name: name});
        }
      }

      //予定数量リスト
      this.volumListforQRCode = this.volumList;

      // ユーザー情報を取得
      const result = await UserModel.getUserList();

      // プロジェクトに紐づくユーザーのIDを取得
      let idList;
      const user = await UserInfo.getUserInfo();
      let data = {
        pk: user.group,
        sk: `project#${this.projectId}`
      }      
      const project = await PorjectModel.getProject(data);
      if (project) {
        idList = project.project.users;
      }

      // ユーザー名用リストに格納、初期位置を設定
      this.userList = [];
      this.userPosition = "";

      result.forEach((data) => {
        // プロジェクトに紐づくユーザーであるかチェック
        let isHit = false;
        const usrid = data.sk.split("#")[1];
        for (let index = 0; index < idList.length; index++) {
          if (usrid == idList[index]) {
            isHit = true;
            break;
          }
        }

        // 削除ユーザとプロジェクトに紐づかないユーザは一覧に含めない
        if (!data.user.deleted && isHit) {
          let item = Object.assign({}, data.user);
          item.cognitoUserId = data.sk.split("#")[1];
          this.userList.push(item);

          // リスト初期位置が未設定の場合かつユーザ区分が3：iPhone(ドライバー）の場合、リスト初期位置に設定する
          if (this.userPosition == "" && item.usertype == "3") {
            this.userPosition = item.userId;
          }
        }
      });

      if (this.userPosition == "") {
        this.userPosition = this.userList[0].userId;
      }

      //パスワードテキストボックス初期化
      this.password = "";

      // QRコード生成用変数を初期化
      this.qrcodeText = "";

      // モーダル画面表示
      this.modalDate = null;
      this.modalendAreaId = null;
      this.modalpouringPosition = null;
      this.modals.outputCSVDlg = false;
      this.modals.createQRCodeDlg = true;
    },

    /**
     * モーダル画面カレンダー選択時イベント
     */
    async onChangeQRCodeDatePicker(selectDate) {
      let ret = await this.getAreaAndPouringListforQRCode(selectDate);
      if (!ret) {
        this.pouringListforQRCode = [];
        this.pouringPositionQRCode = "";
      }
    },

    /**
     * QRコード作成用（カレンダーで日付変更時の処理）工区＋打設箇所を再設定
     */
    async getAreaAndPouringListforQRCode(targetDate) {
      //------- ------- -------
      // 予定ID取得
      //------- ------- -------
      targetDate = DateUtil.getFormatString(targetDate, "YYYYMMDD");
      let planList = await PlanModel.getPlanListFromYMD(
        this.projectId,
        targetDate
      );

      if (planList.length < 1) return false;

      let planId = planList[0].plan.id;

      //------- ------- -------
      // 工区一覧取得
      //------- ------- -------
      // 拠点一覧取得
      let lists = await baseAreaModel.getBaseAreaList(this.projectId);

      // 拠点の種別到着地のみ取得（IDでソート）
      lists = lists
        .filter((list) => list.baseArea.areaType === 2)
        .slice()
        .sort(function(a, b) {
          if (a.baseArea.id < b.baseArea.id) return -1;
          if (a.baseArea.id > b.baseArea.id) return 1;
        });

      // 工区リストに追加
      let listArea = [];
      lists.forEach(
        function(list) {
          let item = {
            id: list.baseArea.id,
            name: list.baseArea.areaName,
          };
          listArea.push(item);
        }.bind(this)
      );

      if (listArea.length < 1) return false;

      //------- ------- -------
      // 予定数量リスト取得
      //------- ------- -------
      this.volumListforQRCode = [];

      // プロジェクトID、予定IDを指定して取得
      const searchKey = `${this.projectId}#${planId}`;
      let listVolum = await VolumeModel.getVolumeList(searchKey);

      // 到着拠点IDを名称に変更
      listVolum.forEach((Item) => {
        for (let i = 0; i < listArea.length; i++) {
          if (listArea[i].id == Item.volume.endAreaId) {
            Item.volume.endAreaName = listArea[i].name;
            break;
          }
        }
      });

      // ソートして一覧に表示（工区名称でソート）
      let volumSortedList = listVolum.sort(function(a, b) {
        return a.volume.endAreaName < b.volume.endAreaName ? -1 : 1;
      });

      // 重複を削除（工区IDと打設箇所（名称）)
      this.volumListforQRCode = volumSortedList.filter(
        (element, index, self) =>
          self.findIndex(
            (e) =>
              e.volume.endAreaId === element.volume.endAreaId &&
              e.volume.pouringPosition === element.volume.pouringPosition
          ) === index
      );

      if (this.volumListforQRCode.length < 1) return false;

      //------- ------- -------
      // 表示用リストを作成
      //------- ------- -------
      let pouringList = [];
      if (this.volumListforQRCode.length > 0) {
        this.volumListforQRCode.forEach(
          function(item, index) {
            pouringList.push({
              id: index,
              name: `${item.volume.endAreaName} ${item.volume.pouringPosition}`,
              endAreaId: `${item.volume.endAreaId}`,
              pouringPosition: `${item.volume.pouringPosition}`,
            });
          }.bind(this)
        );
      }

      if (pouringList.length < 1) return false;

      // 表示用リストにコピー
      this.pouringListforQRCode = pouringList;

      // 初期表示の値を設定
      this.pouringPositionQRCode = pouringList[0].name;

      return true;
    },

    /**
     * QRコード作成ボタンクリック時の処理です。
     */
    onClickCreateQRCode() {
      // 打設箇所/土捨場の選択チェック
      if (
        this.pouringPositionQRCode == "" ||
        this.pouringPositionQRCode == null
      ) {
        if (this.projectType == "0"){
          this.showBottomToast(`打設箇所が選択されていません`, `warning`);
          return;
        } else if (this.projectType == "1"){
          this.showBottomToast(`土捨場が選択されていません`, `warning`);
          return;
        }
        
      }

      // パスワード入力チェック
      if (this.password == "" || this.password == null) {
        this.showBottomToast(`パスワードを入力してください`, `warning`);
        return;
      }

      // 打設箇所インデックス取得
      let index = 0;
      for (index = 0; index < this.pouringList.length; index++) {
        if (
          this.pouringPositionQRCode == this.pouringListforQRCode[index].name
        ) {
          break;
        }
      }

      // ユーザID取得
      const paramUserId = this.userPosition;

      // パスワード取得
      const paramPassword = this.password;

      // プロジェクトID取得
      const paramProjectId = this.$store.state.timeline.projectid;

      // 拠点ID取得
      const paramAreaID = this.volumListforQRCode[index].volume.endAreaId;

      // 予定数量ID取得
      const paramVolumeId = this.volumListforQRCode[index].volume.id;

      // パラメータをCSV形式で作成
      const param = `${paramUserId},${paramPassword},${paramProjectId},${paramAreaID},${paramVolumeId},${this.projectType}`;

      // 暗号化
      const passphrase = utf8.parse("x4YVMsncqxRETVD9rQBfzSiG6jSA4a5J");
      const iv = utf8.parse("8s6gsXc58hQGqzJn");
      const utf8_plain = utf8.parse(param);
      const options = {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      };
      const encrypted = AES.encrypt(utf8_plain, passphrase, options);

      // QRコード生成用変数に代入
      this.qrcodeText = String(encrypted);

      // 表示用日付を作成
      this.modalQRCodeDateLabel = DateUtil.getFormatString(
        this.modalQRCodeDate,
        "YYYY/MM/DD"
      );

      // 表示用打設箇所を設定
      this.pouringPositionQRCodeLabel = this.pouringPositionQRCode;

      // // 復号チェック
      // let decrypted = AES.decrypt(this.qrcodeText, passphrase, options)
      // let txt_dexrypted = decrypted.toString(utf8)
      // console.log(`★★★パラメータ：${param}`) // パラメータ
      // console.log(`★★★暗号化：${this.qrcodeText}`) // 暗号化されたパラメータ
      // console.log(`★★★復号化：${txt_dexrypted}`) // 復号化されたパラメータ
    },

    /**
     * 印刷するボタンクリック時の処理です。
     */
    onClickPrint() {
      window.print();
    },

    /**
     * 拠点編集クリック時の処理です。
     */
    async onClickEditBase() {
      // 連打防止
      if (this.repeatedHitsFlg) return;
      this.repeatedHitsFlg = true;

      // store(vuex)に値をセット
      let store = this.$store.state.basearea;
      store.projectid = this.projectId;
      store.projectname = this.projectName;
      store.datestring = this.dateString;

      await this.$store.commit("setBaseArea", store);

      // ToDo 拠点編集画面表示

      // 車両一覧画面表示
      
      this.$router.push({
        path: this.getRoutePath(`baseArea`),
      });

      // モダール非表示
      this.modals.noBaseArea = false;
    },

    /**
     * 車両編集クリック時の処理です。
     */
    async onClickEditVehicle(dateString) {
      // 連打防止
      if (this.repeatedHitsFlg) return;
      this.repeatedHitsFlg = true;

      // store(vuex)に値をセット
      let store = this.$store.state.vehicle;
      store.projectid = this.projectId;
      store.projectname = this.projectName;
      store.datestring = DateUtil.dateStringBase(dateString, "YYYY/MM/DD");

      await this.$store.commit("setVehicle", store);

      // 車両一覧画面表示
      
      this.$router.push({
        path: this.getRoutePath(`vehiclelist`),
      });
    },

    /**
     * 予定数量登録クリック時の処理です。
     */
    async onClickCreatePlan(dateString) {
      // 連打防止
      if (this.repeatedHitsFlg) return;
      this.repeatedHitsFlg = true;

      // store(vuex)に値をセット
      let store = this.$store.state.plan;
      store.projectid = this.projectId;
      store.projectname = this.projectName;
      store.datestring = DateUtil.dateStringBase(dateString, "YYYY/MM/DD");

      await this.$store.commit("setPlan", store);

      // 車両一覧画面表示
      this.$router.push({
        path: this.getRoutePath(`planList`),
      });      
    },

    /**
     * 走行履歴クリック時の処理です。
     */
    async onClickDriveLog(dateString) {
      // 連打防止
      if (this.repeatedHitsFlg) return;
      this.repeatedHitsFlg = true;

      // store(vuex)に値をセット
      let store = this.$store.state.drivinghistory;
      store.projectid = this.projectId;
      store.projectname = this.projectName;
      store.datestring = DateUtil.dateStringBase(dateString, "YYYY/MM/DD");

      await this.$store.commit("setPlan", store);

      
      this.$router.push({
        path: this.getRoutePath(`drivingHistory`),
      });

      // 予定数量ID、打設箇所名を取得
      // let volumeId = null;
      // let areaName = null;

      // if (this.volumList.length > 0){
      //   volumeId = this.selectedVolumeId;
      //   if (!volumeId) {
      //     volumeId = this.volumList[0].volume.id;
      //   }
      //   areaName = this.listDriveAreaAndPouring[0].areaName
      // }

      // // 受入検査結果画面呼出
      // let store = this.$store.state.acceptance;
      // store.projectid = this.projectId;
      // store.projectname = this.projectName;
      // store.datestring = DateUtil.dateStringBase(dateString, "YYYY/MM/DD");
      // store.volumeid = volumeId;
      // store.pouringPosition = areaName + " " + this.pouringPosition;

      // await this.$store.commit("setAcceptance", store);

      // // 車両一覧画面表示
      // this.$router.push({
      //   path: "/AcceptanceList",
      // });  

    },

    /**
     * CSV出力クリック時の処理です。
     */
    onClickOutputCsv() {
      // プロジェクトID取得
      let projectID = this.$store.state.timeline.projectid;
      if (projectID == "" || projectID == null) {
        this.showBottomToast(
          `CSV出力準備に失敗しました。（プロジェクトIDが未設定）`,
          `error`
        );
        return;
      }

      // モーダル画面表示
      this.modalDate = null;
      this.modalendAreaId = null;
      this.modalpouringPosition = null;
      this.modals.outputCSVDlg = true;
    },

    /**
     * モーダル画面カレンダー選択時イベント
     */
    onChangeDatePicker(selectDate) {
      this.getAreaAndPouringListforCSV(selectDate);
    },

    /**
     * モーダル画面CSV出力ボタンクリック時の処理です。
     */
    async onClickCSVBtn() {
      // 入力チェック (年月日、工区、打設箇所)
      if (this.modalDate == "" || this.modalDate == null) {
        this.showBottomToast(`年月日を指定してください。`, `warning`);
        return;
      }

      if (this.modalendAreaId == "" || this.modalendAreaId == null) {
        if (this.projectType == "0"){
          this.showBottomToast(`工区を指定してください。`, `warning`);
          return;
        } else if (this.projectType == "1"){
          this.showBottomToast(`土捨場を指定してください。`, `warning`);
          return;
        }        
      }

      if (this.modalpouringPosition == "" || this.modalpouringPosition == null) {
        if (this.projectType == "0"){
          this.showBottomToast(`打設箇所を指定してください。`, `warning`);
          return;
        } else if (this.projectType == "1"){
          this.showBottomToast(`岩種を指定してください。`, `warning`);
          return;
        }        
      }

      // CSV出力処理へ
      if (confirm("運行情報のＣＳＶファイルを作成しますか？")) {
        this.showBottomToast(
          `CSV作成中です。このままでしばらくお待ちください。`,
          `info`
        );

        // 指定された日付、工区、打設箇所を取得
        let targetdate = DateUtil.dateStringBase(this.modalDate, "YYYYMMDD");
        let projectName = await this.getProjectName(this.projectId);
        let targetBaseAreaId = this.modalendAreaId;
        let targetBaseAreaNmae = this.getAreaName(this.modalendAreaId);
        let targetPouringPosition = this.modalpouringPosition;

        if (this.projectType == "0"){
          this.downloadCsv(
            this.projectId,
            projectName,
            targetdate,
            targetBaseAreaId,
            targetBaseAreaNmae,
            targetPouringPosition
          );
        } else if (this.projectType == "1"){
          this.zandoDownloadCsv(
            this.projectId,
            projectName,
            targetdate,
            targetBaseAreaId,
            targetBaseAreaNmae,
            targetPouringPosition
          )
        }
        
        this.modals.outputCSVDlg = false;
      }
    },

    /**
     * 生コンCSVダウンロード
     * @paramas searchProjectID プロジェクトID
     * @paramas projectName プロジェクト名
     * @paramas targetdate CSV出力対象年月日
     * @paramas targetBaseAreaId CSV出力対象の工区ID
     * @paramas targetBaseAreaNmae CSV出力対象の工区名称
     * @paramas targetPouringPosition CSV出力対象の打設箇所
     */
    async downloadCsv(
      projectId,
      projectName,
      targetdate,
      targetBaseAreaId,
      targetBaseAreaNmae,
      targetPouringPosition
    ) {
      // 処理中
      let loader = this.showLoader();

      // CSVファイル名初期化
      let fileName = null;

      try {
        // 運行情報取得
        let searchKey = `${projectId}#${targetdate}#${targetBaseAreaId}`;
        let driveList = await DriveModel.getDriveList(searchKey);

        // 納入伝票取得
        searchKey = `${projectId}#${targetdate}`;
        let deliveryList = await DriveModel.getDelivaryList(searchKey);

        // 車両マスター取得
        const vehicleList = await VehicleModel.getVehicleList(projectId);

        // 運行情報に車両表示順を追加
        for (let i = 0; i < driveList.length; i++) {
          let vehicleId = driveList[i].drive.vehicleId;
          let vehicle = VehicleModel.getVehicleData(vehicleId, vehicleList);
          let order = vehicle.order;
          driveList[i].drive.vehicleOrder = order;
        }

        // 工区マスター取得
        //const baseAreaList = await baseAreaModel.getBaseAreaList(projectId)

        // 積載物マスタ-取得
        const carryList = DriveModel.getCarryTypeList();

        // CSV用データ生成
        let csvDatas = this.createCsvData(
          driveList,
          vehicleList,
          projectName,
          targetdate,
          targetBaseAreaNmae,
          targetPouringPosition,
          carryList,
          deliveryList,
          projectId
        );
        let columns = this.createCsvColumns();

        const csv = stringify(csvDatas, {
          header: true,
          quoted: true,
          record_delimiter: "windows",
          columns: columns,
        });
        // console.log(csv);

        // BOM
        let bom = new Uint8Array([0xef, 0xbb, 0xbf]);

        // CSVフォーマットの文字列をblobに
        let csvBlob = new Blob([bom, csv], { type: "text/csv" });

        // ブラウザにダウンロードダイアログを表示する処理
        const a = document.createElement("a");
        a.href = URL.createObjectURL(csvBlob);

        // ファイル名生成 (yyyymmdd_工区名打設箇所.csv)
        fileName =
          targetdate +
          "_" +
          targetBaseAreaNmae +
          targetPouringPosition +
          ".csv";

        a.download = fileName;

        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        this.showBottomToast(`CSVを出力しました。`, `info`);
        this.infoLog(`outputed csv : ${fileName}`);
      } catch (e) {
        this.showBottomToast(`CSVの作成に失敗しました`, `error`);
        this.errorLog(`downloadCsv`, `${this.parseErrorObject(e)}`);
        throw e;
      } finally {
        this.hideLoader(loader);
      }
    },

    /**
     * CSV用データ生成
     * @paramas [] driveList 運行一覧
     * @paramas [] vehicleList 車両一覧
     * @paramas projectName プロジェクト名称
     * @paramas targetdate CSV出力対象年月日
     * @paramas targetBaseAreaNmae 工区名称
     * @paramas targetPouringPosition 打設箇所
     * @paramas carryList 積載物マスタ
     * @paramas deliveryList 納入伝票リスト
     * @paramas projectId プロジェクトID
     */
    createCsvData(
      driveList,
      vehicleList,
      projectName,
      targetdate,
      targetBaseAreaNmae,
      targetPouringPosition,
      carryList,
      deliveryList,
      projectId
    ) {
      let csvDatas = [];

      // null考慮比較（第3引数未指定時は昇順、true指定で降順）
      const compare = (a, b, desc = false) => {
        if (a !== a && b !== b) return 0;
        if (a !== a) return 1;
        if (b !== b) return -1;

        if (a == null && b == null) return 0;
        if (a == null) return 1;
        if (b == null) return -1;

        if (a === "" && b === "") return 0;
        if (a === "") return 1;
        if (b === "") return -1;

        const sig = desc ? 1 : -1;
        return a < b ? sig : a > b ? -sig : 0;
      };

      // 累計積算用の運行情報リストをディープコピーしておく
      let totalVolumeList = lodash.cloneDeep(driveList);
      let totalVolumeValueList = {};
      let maxCapacityTemp = 0.0;
      // 打設完了時間でソート
      totalVolumeList = totalVolumeList.sort(
        function(a, b) {
          let result = 0;
          // 打設完了時間を比較
          result = compare(
            a.drive.pouringEndTime,
            b.drive.pouringEndTime,
            false
          );
          if (result != 0) return result;
          // 車両表示順
          return compare(a.drive.vehicleOrder, b.drive.vehicleOrder, false);
        }.bind(this)
      );

      totalVolumeList.forEach(function(item) {
        // 対象の打設箇所のみ
        if (item.drive.pouringPosition == targetPouringPosition) {
          // 最大積載量と打設完了時間チェック
          if (
            item.drive.maxCapacity &&
            item.drive.pouringEndTime &&
            String(item.drive.carryType) == "1"
          ) {
            maxCapacityTemp += item.drive.maxCapacity;
            totalVolumeValueList[item.drive.id] = maxCapacityTemp;
            console.log(`maxCapacityTemp:${maxCapacityTemp}`);
          } else {
            totalVolumeValueList[item.drive.id] = null;
          }
        }
      });

      // 並び替え（往路工事発の昇順、車両表示順の昇順）→打設完了時間で並べる(2期開発で仕様変更)
      driveList = driveList.sort(function(a, b) {
        // let result = 0;

        // // 往路工事発
        // result = compare(
        //   a.drive.factoryStartTime,
        //   b.drive.factoryStartTime,
        //   false
        // );

        // if (result != 0) return result;

        let result = 0;
        // 打設完了時間を比較
        result = compare(a.drive.pouringEndTime, b.drive.pouringEndTime, false);
        if (result != 0) return result;

        // 車両表示順
        return compare(a.drive.vehicleOrder, b.drive.vehicleOrder, false);
      });

      let index = 1;
      driveList.forEach((item) => {
        // 打設箇所が一致しているレコードが対象
        if (item.drive.pouringPosition == targetPouringPosition) {
          let dateStart;
          let dateEnd;

          // 往路 （出発時刻、到着時刻、到着時間）
          let factoryStartTime = null;
          if (
            item.drive.factoryStartTime != null &&
            item.drive.factoryStartTime != ""
          ) {
            dateStart = new Date(String(item.drive.factoryStartTime));
            factoryStartTime =
              dateStart
                .getHours()
                .toString()
                .padStart(2, "0") +
              ":" +
              dateStart
                .getMinutes()
                .toString()
                .padStart(2, "0");
          }

          let siteEndTime = null;
          if (item.drive.siteEndTime != null && item.drive.siteEndTime != "") {
            dateEnd = new Date(String(item.drive.siteEndTime));
            siteEndTime =
              dateEnd
                .getHours()
                .toString()
                .padStart(2, "0") +
              ":" +
              dateEnd
                .getMinutes()
                .toString()
                .padStart(2, "0");
          }

          let roadTravelTime = null;
          if (factoryStartTime != null && siteEndTime != null) {
            roadTravelTime = DateUtil.getTimeDifference(dateStart, dateEnd);
          }

          // 復路（出発時刻、到着時刻、到着時間）
          let siteStartTime = null;
          if (
            item.drive.siteStartTime != null &&
            item.drive.siteStartTime != ""
          ) {
            dateStart = new Date(String(item.drive.siteStartTime));
            siteStartTime =
              dateStart
                .getHours()
                .toString()
                .padStart(2, "0") +
              ":" +
              dateStart
                .getMinutes()
                .toString()
                .padStart(2, "0");
          }

          let factoryEndTime = null;
          if (
            item.drive.factoryEndTime != null &&
            item.drive.factoryEndTime != ""
          ) {
            dateEnd = new Date(String(item.drive.factoryEndTime));
            factoryEndTime =
              dateEnd
                .getHours()
                .toString()
                .padStart(2, "0") +
              ":" +
              dateEnd
                .getMinutes()
                .toString()
                .padStart(2, "0");
          }

          let returnRoadTravelTime = null;
          if (siteStartTime != null && factoryEndTime != null) {
            returnRoadTravelTime = DateUtil.getTimeDifference(
              dateStart,
              dateEnd
            );
          }

          // 打設（出発時刻、到着時刻、到着時間）
          let pouringStartTime = null;
          if (
            item.drive.pouringStartTime != null &&
            item.drive.pouringStartTime != ""
          ) {
            dateStart = new Date(String(item.drive.pouringStartTime));
            pouringStartTime =
              dateStart
                .getHours()
                .toString()
                .padStart(2, "0") +
              ":" +
              dateStart
                .getMinutes()
                .toString()
                .padStart(2, "0");
          }

          let pouringEndTime = null;
          if (
            item.drive.pouringEndTime != null &&
            item.drive.pouringEndTime !== ""
          ) {
            dateEnd = new Date(String(item.drive.pouringEndTime));
            pouringEndTime =
              dateEnd
                .getHours()
                .toString()
                .padStart(2, "0") +
              ":" +
              dateEnd
                .getMinutes()
                .toString()
                .padStart(2, "0");
          }

          let pouringTravelTime = null;
          if (pouringStartTime != null && pouringEndTime != null) {
            pouringTravelTime = DateUtil.getTimeDifference(dateStart, dateEnd);
          }

          // 積載物
          let carryTypeName = "モルタル";
          carryList.forEach((carryTypeSet) => {
            if (item.drive.carryType == carryTypeSet.id) {
              carryTypeName = carryTypeSet.name;
            }
          });

          // 車両ID → 車両名称
          let vehiclName = null;
          for (let i = 0; i < vehicleList.length; i++) {
            if (vehicleList[i].vehicle.id == item.drive.vehicleId) {
              vehiclName = vehicleList[i].vehicle.name;
              break;
            }
          }

          // 累計
          let totalVolume = totalVolumeValueList[item.drive.id];
          console.log(`totalVolume: ${totalVolume}`);

          // // 工区ID → 工区名称
          // let baseAreaName = null
          // for (let i=0; i<baseAreaList.length; i++)
          // {
          //   if (baseAreaList[i].baseArea.id == item.drive.endAreaId)
          //   {
          //     baseAreaName = baseAreaList[i].baseArea.areaName
          //     break
          //   }
          // }

          // 最大積載量（小数第二位まで表示）
          let fixedMaxCapacity = item.drive.maxCapacity.toFixed(2);

          // 納入伝票（出発時刻、到着時刻）
          let startTime = null;
          let endTime = null;

          // 運行情報と紐づく納入伝票取得
          let deliveryItem = deliveryList.find((element) => {
            return element.lsiStr1 == `delivery#${projectId}#${item.drive.id}`
          })
          if (deliveryItem != null){
            // 取得できれば、出発時刻、到着時刻をセット
            startTime = deliveryItem.delivery.startTime;
            endTime = deliveryItem.delivery.endTime;
          }

          // 渋滞有無
          let congestionLog = "無し"
          if (item.drive.congestionLog == "1") {
            congestionLog = "有り"
          }

          // CSV1行分生成
          let csvRow = {
            projectNmae: projectName,
            targetDate: targetdate,
            baseAreaName: targetBaseAreaNmae,
            pouringPosition: targetPouringPosition,
            No: index,
            vehicleId: vehiclName,
            maxCapacity: fixedMaxCapacity,
            factoryStartTime: factoryStartTime,
            siteEndTime: siteEndTime,
            RoadTravelTime: roadTravelTime,
            siteStartTime: siteStartTime,
            factoryEndTime: factoryEndTime,
            ReturnRoadTravelTime: returnRoadTravelTime,
            pouringStartTime: pouringStartTime,
            pouringEndTime: pouringEndTime,
            pouringTravelTime: pouringTravelTime,
            carryType: carryTypeName,
            totalVolume: totalVolume,
            startTime: startTime,
            endTime: endTime,
            congestionLog: congestionLog,
          };
          csvDatas.push(csvRow);

          // カウントアップ
          index = index + 1;
        }
      });

      return csvDatas;
    },
    /**
     * CSV列情報（ヘッダー）生成
     */
    createCsvColumns() {
      // 固定部
      let columns = [
        { key: "projectNmae", header: "プロジェクト名" },
        { key: "targetDate", header: "日付" },
        { key: "baseAreaName", header: "工区" },
        { key: "pouringPosition", header: "打設箇所" },
        { key: "No", header: "No" },
        { key: "vehicleId", header: "車両番号" },
        { key: "maxCapacity", header: "最大積載量(㎥)" },
        { key: "factoryStartTime", header: "往路工場発" },
        { key: "siteEndTime", header: "往路現場着" },
        { key: "RoadTravelTime", header: "往路所要時間" },
        { key: "siteStartTime", header: "復路現場発" },
        { key: "factoryEndTime", header: "復路工場着" },
        { key: "ReturnRoadTravelTime", header: "復路所要時間" },
        { key: "pouringStartTime", header: "打設開始時間" },
        { key: "pouringEndTime", header: "打設終了時間" },
        { key: "pouringTravelTime", header: "打設時間" },
        { key: "carryType", header: "積載物" },
        { key: "totalVolume", header: "累計(㎥)" },
        { key: "startTime", header: "出発時刻(納入伝票)" },
        { key: "endTime", header: "到着時刻(納入伝票)" },
        { key: "congestionLog", header: "渋滞有無" },
      ];

      return columns;
    },

    /**
     * 残土CSVダウンロード
     * @paramas searchProjectID プロジェクトID
     * @paramas projectName プロジェクト名
     * @paramas targetdate CSV出力対象年月日
     * @paramas targetBaseAreaId CSV出力対象の土捨場ID
     * @paramas targetBaseAreaNmae CSV出力対象の土捨場名称
     * @paramas targetPouringPosition CSV出力対象の岩種
     */
    async zandoDownloadCsv(
      projectId,
      projectName,
      targetdate,
      targetBaseAreaId,
      targetBaseAreaNmae,
      targetPouringPosition
    ) {
      // 処理中
      let loader = this.showLoader();

      // CSVファイル名初期化
      let fileName = null;

      try {
        // 運行情報取得
        let searchKey = null;
        if (targetBaseAreaId == "0" && targetPouringPosition == "全て" || targetBaseAreaId == "0" && targetPouringPosition == this.modalpouringPosition) {
          // 全て選択時は、到着拠点IDを指定しない
          // 土捨場全て×岩種全て
          // 土捨場全て×岩種指定
          searchKey = `${projectId}#${targetdate}`;
        } else if (targetBaseAreaId == this.modalendAreaId && targetPouringPosition == "全て" || targetBaseAreaId == this.modalendAreaId && targetPouringPosition == this.modalpouringPosition){
          // 土捨場指定×岩種全て
          // 土捨場指定×岩種指定
          searchKey = `${projectId}#${targetdate}#${targetBaseAreaId}`; 
        } 
        let driveList = await DriveModel.getDriveList(searchKey);

        // 車両マスター取得
        const vehicleList = await VehicleModel.getVehicleList(projectId);

        // 運行情報に車両表示順を追加
        for (let i = 0; i < driveList.length; i++) {
          let vehicleId = driveList[i].drive.vehicleId;
          let vehicle = VehicleModel.getVehicleData(vehicleId, vehicleList);
          let order = vehicle.order;
          driveList[i].drive.vehicleOrder = order;
        }

        // CSV用データ生成
        let zandoCsvDatas = this.zandoCreateCsvData(
          driveList,
          vehicleList,
          projectName,
          targetdate,
          targetBaseAreaNmae,
          targetPouringPosition,
          projectId
        );
        let columns = this.createZandoCSVColumns();

        const csv = stringify(zandoCsvDatas, {
          header: true,
          quoted: true,
          record_delimiter: "windows",
          columns: columns,
        });

        // BOM
        let bom = new Uint8Array([0xef, 0xbb, 0xbf]);

        // CSVフォーマットの文字列をblobに
        let csvBlob = new Blob([bom, csv], { type: "text/csv" });

        // ブラウザにダウンロードダイアログを表示する処理
        const a = document.createElement("a");
        a.href = URL.createObjectURL(csvBlob);

        // ファイル名生成 (yyyymmdd_土捨場名 + 岩種.csv)
        if (targetBaseAreaId == "0" && targetPouringPosition == "全て") {
          // 土捨場全て × 岩種全ての時のファイル名(yyyymmdd_全て.csv)
          fileName = 
            targetdate + 
            "_全て.csv";
        } else if (targetBaseAreaId == this.modalendAreaId && targetPouringPosition == "全て"){
          //土捨場指定 × 岩種全ての時のファイル名(yyyymmdd_土捨て場名 + 全て.csv)
          fileName = 
            targetdate + 
            "_" + 
            targetBaseAreaNmae + 
            "全て.csv";
        } else if (targetBaseAreaId == "0" && targetPouringPosition == this.modalpouringPosition){
          //土捨場全て × 岩種指定の時のファイル名(yyyymmdd_全て + 岩種.csv)
          fileName = 
            targetdate + 
            "_全て" + 
            targetPouringPosition + 
            ".csv";
        }
        else {
          //土捨場指定 × 岩種指定の時のファイル名(yyyymmdd_土捨場名 + 岩種.csv)
          fileName =
            targetdate +
            "_" +
            targetBaseAreaNmae +
            targetPouringPosition +
            ".csv";
        }

        a.download = fileName;

        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        this.showBottomToast(`CSVを出力しました。`, `info`);
        this.infoLog(`outputed csv : ${fileName}`);



      } catch (e) {
        this.showBottomToast(`CSVの作成に失敗しました`, `error`);
        this.errorLog(`zandoDownloadCsv`, `${this.parseErrorObject(e)}`);
        throw e;
      } finally {
        this.hideLoader(loader);
      }
    },
    /**
     * CSV用データ生成
     * @paramas [] driveList 運行一覧
     * @paramas [] vehicleList 車両一覧
     * @paramas projectName プロジェクト名称
     * @paramas targetdate CSV出力対象年月日
     * @paramas targetBaseAreaNmae 土捨場名称
     * @paramas targetPouringPosition 岩種名
     */
    zandoCreateCsvData(
      driveList,
      vehicleList,
      projectName,
      targetdate,
      targetBaseAreaNmae,
      targetPouringPosition,
    ) {
      let zandoCsvDatas = [];

      // null考慮比較（第3引数未指定時は昇順、true指定で降順）
      const compare = (a, b, desc = false) => {
        if (a !== a && b !== b) return 0;
        if (a !== a) return 1;
        if (b !== b) return -1;

        if (a == null && b == null) return 0;
        if (a == null) return 1;
        if (b == null) return -1;

        if (a === "" && b === "") return 0;
        if (a === "") return 1;
        if (b === "") return -1;

        const sig = desc ? 1 : -1;
        return a < b ? sig : a > b ? -sig : 0;
      };
      
      // 累計積算用の運行情報リストをディープコピーしておく
      let zandoTotalVolumeList = lodash.cloneDeep(driveList);
      let zandoTotalVolumeValueList = {};
      let maxLoadCapacityTemp = 0.0;
      // 打設完了時間でソート
      zandoTotalVolumeList = zandoTotalVolumeList.sort(
        function(a, b) {
          let result = 0;
          // 打設完了時間を比較
          result = compare(
            a.drive.pouringEndTime,
            b.drive.pouringEndTime,
            false
          );
          if (result != 0) return result;
          // 車両表示順
          return compare(a.drive.vehicleOrder, b.drive.vehicleOrder, false);
        }.bind(this)
      );

      zandoTotalVolumeList.forEach(function(item) {
        // 対象の打設箇所のみ (or 全て選択時)
        if (item.drive.pouringPosition == targetPouringPosition || targetPouringPosition == "全て") {
          // 最大積載量と打設完了時間チェック
          if (
            item.drive.loadCapacity &&
            item.drive.pouringEndTime
          ) {
            maxLoadCapacityTemp += item.drive.loadCapacity;
            zandoTotalVolumeValueList[item.drive.id] = maxLoadCapacityTemp;
          } else {
            zandoTotalVolumeValueList[item.drive.id] = null;
          }
        }
      });

      // 並び替え（往路工事発の昇順、車両表示順の昇順）→打設完了時間で並べる(2期開発で仕様変更)
      driveList = driveList.sort(function(a, b) {
        let result = 0;
        // 打設完了時間を比較
        result = compare(a.drive.pouringEndTime, b.drive.pouringEndTime, false);
        if (result != 0) return result;

        // 車両表示順
        return compare(a.drive.vehicleOrder, b.drive.vehicleOrder, false);
      });

      let index = 1;
      driveList.forEach((item) => {

        // 打設箇所が一致しているレコードが対象（or 全て選択時）
        if (item.drive.pouringPosition == targetPouringPosition || targetPouringPosition == "全て") {
          let dateStart;
          let dateEnd;

          // 土捨場名、岩種名をセット
          let setBaseAreaNmae = null;
          let setPouringPosition = null; 
          if (targetPouringPosition == "全て" || targetPouringPosition == this.modalpouringPosition)
          { // 全て選択時
            setBaseAreaNmae = this.getAreaName(item.drive.endAreaId);
            setPouringPosition = item.drive.pouringPosition; 
          } else {
            setBaseAreaNmae = targetBaseAreaNmae;
            setPouringPosition = targetPouringPosition; 
          }

          // 往路 （出発時刻、到着時刻、到着時間）
          //現場出発時刻
          let factoryStartTime = null;
          if (
            item.drive.factoryStartTime != null &&
            item.drive.factoryStartTime != ""
          ) {
            dateStart = new Date(String(item.drive.factoryStartTime));
            factoryStartTime =
              dateStart
                .getHours()
                .toString()
                .padStart(2, "0") +
              ":" +
              dateStart
                .getMinutes()
                .toString()
                .padStart(2, "0");
          }
          //土捨場到着時刻
          let siteEndTime = null;
          if (item.drive.siteEndTime != null && item.drive.siteEndTime != "") {
            dateEnd = new Date(String(item.drive.siteEndTime));
            siteEndTime =
              dateEnd
                .getHours()
                .toString()
                .padStart(2, "0") +
              ":" +
              dateEnd
                .getMinutes()
                .toString()
                .padStart(2, "0");
          }
          //往路所要時間
          let roadTravelTime = null;
          if (factoryStartTime != null && siteEndTime != null) {
            roadTravelTime = DateUtil.getTimeDifference(dateStart, dateEnd);
          }

          // 復路（出発時刻、到着時刻、到着時間）
          //復路土捨場発
          let siteStartTime = null;
          if (
            item.drive.siteStartTime != null &&
            item.drive.siteStartTime != ""
          ) {
            dateStart = new Date(String(item.drive.siteStartTime));
            siteStartTime =
              dateStart
                .getHours()
                .toString()
                .padStart(2, "0") +
              ":" +
              dateStart
                .getMinutes()
                .toString()
                .padStart(2, "0");
          }
          //復路現場着
          let factoryEndTime = null;
          if (
            item.drive.factoryEndTime != null &&
            item.drive.factoryEndTime != ""
          ) {
            dateEnd = new Date(String(item.drive.factoryEndTime));
            factoryEndTime =
              dateEnd
                .getHours()
                .toString()
                .padStart(2, "0") +
              ":" +
              dateEnd
                .getMinutes()
                .toString()
                .padStart(2, "0");
          }
          //復路所要時間
          let returnRoadTravelTime = null;
          if (siteStartTime != null && factoryEndTime != null) {
            returnRoadTravelTime = DateUtil.getTimeDifference(
              dateStart,
              dateEnd
            );
          }

          // 打設（出発時刻、到着時刻、到着時間）
          //積込開始時間
          let pouringStartTime = null;
          if (
            item.drive.pouringStartTime != null &&
            item.drive.pouringStartTime != ""
          ) {
            dateStart = new Date(String(item.drive.pouringStartTime));
            pouringStartTime =
              dateStart
                .getHours()
                .toString()
                .padStart(2, "0") +
              ":" +
              dateStart
                .getMinutes()
                .toString()
                .padStart(2, "0");
          }
          //積込終了時間
          let pouringEndTime = null;
          if (
            item.drive.pouringEndTime != null &&
            item.drive.pouringEndTime !== ""
          ) {
            dateEnd = new Date(String(item.drive.pouringEndTime));
            pouringEndTime =
              dateEnd
                .getHours()
                .toString()
                .padStart(2, "0") +
              ":" +
              dateEnd
                .getMinutes()
                .toString()
                .padStart(2, "0");
          }
          //積込時間
          let pouringTravelTime = null;
          if (pouringStartTime != null && pouringEndTime != null) {
            pouringTravelTime = DateUtil.getTimeDifference(dateStart, dateEnd);
          }


          //荷降時間
          let unloadingTime = null;
          if (item.drive.unloadingTime != null &&
              item.drive.unloadingTime !=""
          ){
            dateStart = new Date(String(item.drive.unloadingTime));
            unloadingTime =
              dateStart
                .getHours()
                .toString()
                .padStart(2, "0") +
              ":" +
              dateStart
                .getMinutes()
                .toString()
                .padStart(2, "0");
          }


          // 車両ID → 車両名称
          let vehiclName = null;
          for (let i = 0; i < vehicleList.length; i++) {
            if (vehicleList[i].vehicle.id == item.drive.vehicleId) {
              vehiclName = vehicleList[i].vehicle.name;
              break;
            }
          }

          // 累計
          let zandoTotalVolume = zandoTotalVolumeValueList[item.drive.id];

          // 積載量（㎥）（小数第二位まで表示）
          let zandoLoadCapacity = item.drive.loadCapacity.toFixed(2);

          // CSV1行分生成
          let csvRow = {
            projectName: projectName,
            targetDate: targetdate,
            baseAreaName: setBaseAreaNmae,
            pouringPosition: setPouringPosition,
            No: index,
            vehicleId: vehiclName,
            loadCapacity: zandoLoadCapacity,
            factoryStartTime: factoryStartTime,
            siteEndTime: siteEndTime,
            RoadTravelTime: roadTravelTime,
            siteStartTime: siteStartTime,
            factoryEndTime: factoryEndTime,
            ReturnRoadTravelTime: returnRoadTravelTime,
            pouringStartTime: pouringStartTime,
            pouringEndTime: pouringEndTime,
            pouringTravelTime: pouringTravelTime,
            unloadingTime: unloadingTime,
            zandoTotalVolume: zandoTotalVolume,
          };
          zandoCsvDatas.push(csvRow);

          //カウントアップ
          index = index +1;
        }
      });

      return zandoCsvDatas;
    },
    /**
     * CSV列情報（ヘッダー）生成
     */
    createZandoCSVColumns() {
      // 固定部
      let columns = [
        { key: "projectName", header: "プロジェクト名" },
        { key: "targetDate", header: "日付" },
        { key: "baseAreaName", header: "土捨場名" },
        { key: "pouringPosition", header: "岩種" },
        { key: "No", header: "No" },
        { key: "vehicleId", header: "車両番号" },
        { key: "loadCapacity", header: "積載量(㎥)" },
        { key: "pouringStartTime", header: "積込開始" },
        { key: "pouringEndTime", header: "積込終了" },
        { key: "pouringTravelTime", header: "積込時間" },
        { key: "factoryStartTime", header: "往路現場発" },
        { key: "siteEndTime", header: "往路土捨場着" },
        { key: "RoadTravelTime", header: "往路所要時間" },
        { key: "unloadingTime", header: "荷降時間" }, 
        { key: "siteStartTime", header: "復路土捨場発" },
        { key: "factoryEndTime", header: "復路現場着" },
        { key: "ReturnRoadTravelTime", header: "復路所要時間" },
        { key: "zandoTotalVolume", header: "累計(㎥)" },
      ];

      return columns;
    },

    /**
     * 実績表示クリック時の処理です。
     */
    onClickShowResult() {
      // 連打防止
      if (this.repeatedHitsFlg) return;
      this.repeatedHitsFlg = true;

      console.log("onClickShowResult9999" + this.selectedVolumeId);

      let signage = this.$store.state.signage;
      signage.projectId = this.projectId;
      signage.projectName = this.projectName;
      signage.targetDate = this.dateString;
      signage.volumeId = this.selectedVolumeId;
      console.log(`onClickShowResult ${signage.volumeId}`)
      this.$store.commit("setSignage", signage);

      let timeline = this.$store.state.timeline;
      timeline.datestring = this.dateString;
      this.$store.commit("setTimeline", timeline);

      // ToDo 実績表示
      const ua = navigator.userAgent.toLowerCase();
      if (
        /android|ipod|ipad|iphone|macintosh/.test(ua) &&
        "ontouchend" in document
      ) {
        this.$router.push({
          path: this.getRoutePath(`mobilesignage`),
        });
      } else {
        this.$router.push({
          path: this.getRoutePath(`signage`),
        });
      }
    },
    /**
     * 受入検査結果リスト表示
     */
    async onClickAcceptanceList(dateString) {
      // 連打防止
      if (this.repeatedHitsFlg) return;
      this.repeatedHitsFlg = true;
      // 予定数量ID、打設箇所名を取得
      let volumeId = null;
      let areaName = null;

      if (this.volumList.length > 0){
        volumeId = this.selectedVolumeId;
        if (!volumeId) {
          volumeId = this.volumList[0].volume.id;
          areaName = this.volumList[0].volume.endAreaName;
        } else {
          let target = this.volumList.find((item) => item.volume.id === volumeId);
          volumeId = target.volume.id;
          areaName = target.volume.endAreaName;
        }
      }

      // 受入検査結果画面呼出
      let store = this.$store.state.acceptance;
      store.projectid = this.projectId;
      store.projectname = this.projectName;
      store.datestring = DateUtil.dateStringBase(dateString, "YYYY/MM/DD");
      store.volumeid = volumeId;
      store.pouringPosition = areaName + " " + this.pouringPosition;

      await this.$store.commit("setAcceptance", store);

      // 車両一覧画面表示
      this.$router.push({
        path: "/AcceptanceList",
      });  

    },
    /**
     * 運行先一括設定モーダル表示
     */
    async onshowDriveBulkSetting() {

      // 変数初期化
      this.selectDriveSettingPouringPosition = null;
      this.listDriveSettingPouringPosition = [];

      for (let i=0; i < this.pouringList.length; i++){
        // 打設箇所名称
        let name = this.pouringList[i].name;
        // 予定数量ID
        let list = this.volumList.filter((item) =>
          item.volume.endAreaId === this.pouringList[i].endAreaId && 
          `${item.volume.endAreaName} ${item.volume.pouringPosition}` === name
        );
        if (list.length > 0) {
          // 名称、ID追加
          let id = list[0].volume.id;
          this.listDriveSettingPouringPosition.push({id: id, name: name});
        }
      }

      // 打設箇所が0件の時はボタンを非活性にする
      if (this.listDriveSettingPouringPosition.length > 0) {
        this.disabledSettingBtn = false;
      } else {
        this.disabledSettingBtn = true;
      }

      // モーダル画面表示
      this.modals.driveBulkSettingDlg = true;
    },

    /**
     * 一括で運行設定ボタンクリック時の処理
     */
    async onClickDriveBulkSetting() {
      // 確認ダイアログ表示
      this.confirmTitle = "運行設定一括通知処理確認";
      this.confirmMessage = "運行設定一括通知処理を実施しますか？";
      
      this.confirmDlgMode = 0;
      this.modals.confirmDlg = true;
    },
    /**
     * 確認ダイアログでOKボタンクリック時の処理
     */
    async onClickConfirmCancel() {
      this.modals.confirmDlg = false;
    },
    /**
     * 確認ダイアログでOKボタンクリック時の処理
     */
    async onClickConfirmOK() {

      // 確認ダイアログ非表示  
      this.modals.confirmDlg = false;

      // 確認ダイアログモード毎で処理分岐
      if (this.confirmDlgMode == 0) {
        // 運航先一括設定
        this.setDriveBatch();
      } else if (this.confirmDlgMode == 1){
        // 打設開始時刻セット
        this.setPouringTime(this.confirmDlgMode);
      } else if (this.confirmDlgMode == 2) {
        // 打設終了時刻セット
        this.setPouringTime(this.confirmDlgMode);
      } else if (this.confirmDlgMode ==3){
        //積込開始時刻セット
        this.setCargoTime(this.confirmDlgMode);
      } else if (this.confirmDlgMode ==4){
        //積込終了時刻セット
        this.setCargoTime(this.confirmDlgMode);
      } else if (this.confirmDlgMode ==5){
        //荷降時刻セット
        this.setCargoTime(this.confirmDlgMode);
      }
    },

    /**
     * 運行先一括設定処理
     */
    async setDriveBatch() {
      // 入力チェック
      if (this.selectDriveSettingPouringPosition == "" || this.selectDriveSettingPouringPosition == null) {
        this.showBottomToast(`打設箇所を指定してくださ。`, `warning`);
        return;
      } 

      // 処理中表示
      let loader = this.showLoader();

      try {

        // ---------------------------------------------------  
        // 運行一括通知設定レコード登録
        // ---------------------------------------------------  
        let regData = await DriveSettingModel.getNewData(this.projectId);
        let driveingSettingId = regData.drivesetting.driveSettingId;

        // lsiStr1設定 ※drivesetting#プロジェクトID#yyyyMMDD
        regData.lsiStr1 = `drivesetting#${this.projectId}#${DateUtil.getFormatString(this.dateString, "YYYYMMDD")}`;

        // drivesetting 設定
        regData.drivesetting.projectId = this.projectId;
        regData.drivesetting.planId = DateUtil.getFormatString(this.dateString, "YYYYMMDD");
        regData.drivesetting.volumeId = this.selectDriveSettingPouringPosition;
        let list = this.listDriveSettingPouringPosition.filter((item) =>
          item.id === this.selectDriveSettingPouringPosition
        );
        regData.drivesetting.pouringPosition = list[0].name;
        regData.drivesetting.projectType = this.projectType

        await DriveSettingModel.addDriveSetting(regData);

        // ---------------------------------------------------  
        // 通知情報（数量発注、運行一括通知設定）レコード登録
        // ---------------------------------------------------  
        regData = await NoticeModel.getNewData(this.projectId, DateUtil.getFormatString(this.dateString, "YYYYMMDD"));

        // lsiStr1設定 ※notice#プロジェクトID#yyyyMMDD#予定数量ID
        //regData.lsiStr1 = `notice#${this.projectId}#${DateUtil.getFormatString(this.dateString, "YYYYMMDD")}#${this.selectDriveSettingPouringPosition}`;

        // notice 設定
        regData.notice.noticeType = 2;
        regData.notice.title = `IMANANDAI`;
        regData.notice.content = `${list[0].name}の運行設定が行われました。続行する場合はタップしてください。`;
        regData.notice.argment = `${this.projectId}#${driveingSettingId}`;
        regData.notice.completedPush = false;

        await NoticeModel.addNotice(regData);

        // ダイアログ非表示
        this.modals.driveBulkSettingDlg = false;

        // 完了メッセージ表示
        this.showBottomToast(`運行設定一括通知処理が完了しました。`, `info`);

      } catch (e) {
        this.errorLog(`setDriveBatch`, `${this.parseErrorObject(e)}`);
        this.showBottomToast(`運行設定一括通知処理に失敗しました。`, `error`);
        throw e
      } finally {
        this.hideLoader(loader);
      }
    },

    /**
     * 到着拠点（現場/工区） 打設箇所選択変更時処理です。
     * @param index 選択したリストのインデックス
     */
    async onChangePouringPosition(index) {
      console.log(`onChangePouringPosition : ${index}`)
      // 処理中
      let loader = this.showLoader();
      try {

        // 残土対応
        if (this.isZando) {
          if (index == 0) {
            // index0は、全て選択時
            this.selectedVolumeId = ``
          } else {
            // 全てを追加しているため、セレクトボックスの選択行-1の値を参照
            // this.selectedVolumeId = this.volumList[index-1].volume.id;
            this.selectedVolumeId = this.pouringList[index].volumeId;
          }         
        } else {
          this.selectedVolumeId = this.pouringList[index].volumeId;
        }

        // console.log(`onChangePouringPosition ${JSON.stringify(this.volumList[index])}`)
        const selectedList = this.pouringList[index];
        
        this.endAreaId = selectedList.endAreaId;
        this.pouringPosition = selectedList.pouringPosition;
        console.log(`onChangePouringPosition ${selectedList.endAreaId} : ${selectedList.pouringPosition} : ${this.selectedVolumeId}`)

        let endAreaName = "";
        for (let i = 0; i < this.listArea.length; i++) {
          if (this.listArea[i].id == selectedList.endAreaId) {
            endAreaName = this.listArea[i].name;
            break;
          }
        }

        this.pouringPositionAreaName = `${endAreaName} ${selectedList.pouringPosition}`;          


        // 地図更新
        if (this.selectedVolumeId == ``) {

          // 全て選択時の地図への反映
          let endAreaPouringPositions = [];

          // 全ての到着拠点ID、打設箇所（岩種）を地図側に渡す
          for (let i=1; i<this.pouringList.length; i++)
          {
            endAreaPouringPositions.push({
              endAreaId: this.pouringList[i].endAreaId,
              pouringPosition: this.pouringList[i].pouringPosition});
          }
          // 地図描画（到着拠点、走行位置）
          this.$refs.map.setSelectedEndAreaPouringPosition(
            endAreaPouringPositions
          );
          // ルート描画（全て）
          this.drawAllRoute();

        } else {
          // 選択した到着拠点（土捨場）、打設箇所（岩種）の地図表示  
          let endAreaPouringPositions = [];
          endAreaPouringPositions.push({
            endAreaId: this.endAreaId,
            pouringPosition: this.pouringPosition});

          // 地図描画（到着拠点、走行位置）
          this.$refs.map.setSelectedEndAreaPouringPosition(
            endAreaPouringPositions
          );
          // ルート描画
          this.drawRoute();
        }
        
        // CO2折れ線グラフは打設箇所のコンボボックス変更時、更新対象外にする
        this.isChangePouringPosition = true;
        // 更新
        await this.onRefreshLocation();

        // フラグを戻す
        this.isChangePouringPosition = false;

        // 到着拠点（現場/工区） 打設箇所変更時は車両選択をクリア
        this.selectedVehicleId = "";
      } catch (e) {
        this.showBottomToast(
          `工区、打設箇所の切り替え処理に失敗しました。`,
          `error`
        );
        this.errorLog(`onChangePouringPosition`, `${this.parseErrorObject(e)}`);
      } finally {
        this.hideLoader(loader);
      }
    },
    async getRouteDatas() {
      this.currentRouteData = null
      // プロジェクトに紐づくデータをすべて取得
      let routeList = await RouteModel.getRouteList(this.projectId)
      if (routeList.length > 0) {
        return routeList
      }
      return []
    },
    getRouteDrawParameters(route) {
      // 描画用パラメータセット
      let parameters = {
        routeId: route.routeId,
        routeGeometry: route.routeGeometry,
        roadType: route.roadType
      };

      return parameters;
    },    
    async drawRoute(){
      // ルート描画
      this.getRouteDatas().then(routeList => {
        console.log(`ルート: ${routeList.length}`)
        // 一旦すべてのルートを削除する
        this.$refs.map.deleteShape(this.routeIdList)
        console.log(`makePouringList: ${this.routeIdList.length}`)
        this.routeIdList = []

        if (routeList.length > 0 && this.endAreaId != "") {
          for (let route of routeList) {
            let startAreaList = this.listBaseArea.filter(item => {
              return item.baseArea.areaType == "1"
            })

            if (startAreaList.length > 0) {
              for (let startArea of startAreaList) {
                let startAreaId = startArea.baseArea.id
                let endAreaId = this.endAreaId
                let skPrefix = `route#${this.projectId}#${startAreaId}#${endAreaId}`

                console.log(`ルート:${skPrefix}`)
                if (route.sk.includes(skPrefix)) {
                  console.log(`ルート:${skPrefix}`)
                  let param = this.getRouteDrawParameters(route.route)
                  this.$refs.map.addRoute(param)
                  this.routeIdList.push(route.route.routeId)
                }
              }   
            }
          }
        }
      })      
    },
    async drawAllRoute(){
      // ルート描画
      this.getRouteDatas().then(routeList => {

        // 一旦すべてのルートを削除する
        this.$refs.map.deleteShape(this.routeIdList)
        this.routeIdList = []

        if (routeList.length > 0 && this.pouringList.length > 0) {
          for (let route of routeList) {
            let startAreaList = this.listBaseArea.filter(item => {
              return item.baseArea.areaType == "1"
            })

            if (startAreaList.length > 0) {
              for (let startArea of startAreaList) {
                let startAreaId = startArea.baseArea.id

                let isDraw = false;
                for (let i=0; i<this.pouringList.length; i++){
                  if (this.pouringList[i].endAreaId != ``){
                    let skPrefix = `route#${this.projectId}#${startAreaId}#${this.pouringList[i].endAreaId}`
                    if (route.sk.includes(skPrefix)) {
                      isDraw = true;
                    }

                    if (isDraw) {
                      let param = this.getRouteDrawParameters(route.route)
                      this.$refs.map.addRoute(param)
                      this.routeIdList.push(route.route.routeId)
                      break;
                    }
                  }
                }
              }   
            }
          }
        }
      })      
    },

    /**
     * 拠点が存在するか確認し、なければモーダルを表示します。
     * ※事前にthis.listAreaを初期化済みである必要があります。
     */
    async checkBaseArea() {
      // 出発＋到着全体数
      let allAreaCount = 0;
      if (this.listBaseArea) {
        if (this.listBaseArea.length > 0) {
          allAreaCount = this.listBaseArea.length;
        }
      }

      // 到着拠点（現場・工区）数
      let endAreaCount = 0;
      if (this.listArea) {
        if (this.listArea.length > 0) {
          endAreaCount = this.listArea.length;
        }
      }

      // 出発拠点（工場）数
      const startAreaCount = allAreaCount - endAreaCount;

      if (startAreaCount < 1 || endAreaCount < 1) {
        // 拠点が無い→モーダル表示
        this.modals.noBaseArea = true;
      }
    },

    /**
     * プロジェクトIDからプロジェクト名称取得
     */
    async getProjectName(projectId) {
      // プロジェクトIDからレコード取得
      let retVal = null;

      const user = await UserInfo.getUserInfo();
      let data = {
        pk: user.group,
        sk: `project#${this.projectId}`
      }      
      const project = await PorjectModel.getProject(data);
      if (project) {
        retVal = project.project.name;
      }

      return retVal;
    },

    /**
     * リスト選択用に値と表示名のリストを作成します
     */
    makePouringList() {
      // リスト選択用に値と表示名の単純なリストを作成する
      // ※地図、タイムラインフィルター用の要素も追加しておく
      // タイムラインではIDにはインデックスを設定
      this.pouringList = [];

      if (this.volumList.length > 0) {
        if (this.isZando){
          this.pouringList.push({
            id: 0,
            name: `全て`,
            endAreaId: ``,
            pouringPosition: ``,
            volumeId: ``
          });

          this.volumList.forEach(
            function(item, index) {
              this.pouringList.push({
                id: index + 1,
                name: `${item.volume.endAreaName} ${item.volume.pouringPosition}`,
                endAreaId: `${item.volume.endAreaId}`,
                pouringPosition: `${item.volume.pouringPosition}`,
                volumeId: item.volume.id
              });
            }.bind(this)
          );

        } else {
          this.volumList.forEach(
            function(item, index) {
              this.pouringList.push({
                id: index,
                name: `${item.volume.endAreaName} ${item.volume.pouringPosition}`,
                endAreaId: `${item.volume.endAreaId}`,
                pouringPosition: `${item.volume.pouringPosition}`,
                volumeId: item.volume.id
              });
            }.bind(this)
          );

        }

        // } else if (this.listArea.length > 0) {
        //   // 予定数量が無い→到着拠点（現場/工区）のみでリストを作成(打設箇所名は空文字)
        //   this.listArea.forEach(function(item, index){
        //     this.pouringList.push({"id": index, "name": `${item.name}`, "endAreaId": `${item.id}`, "pouringPosition": ``})
        //   }.bind(this))
      } else {
        // 予定も拠点も未作成時
        this.pouringList.push({
          id: "",
          name: ``,
          endAreaId: ``,
          pouringPosition: ``,
        });
      }

      // console.log(this.pouringList)
      this.endAreaId = "";
      this.pouringPosition = "";

      // 先頭を初期値で設定
      this.endAreaId = this.pouringList[0].endAreaId;
      this.pouringPosition = this.pouringList[0].pouringPosition;
      this.pouringPositionAreaName = this.pouringList[0].name;

      // 地図へ変更内容を設定
      // this.$refs.map.setSelectedEndAreaPouringPosition(
      //   this.endAreaId,
      //   this.pouringList[0].pouringPosition
      // );

      // 地図更新
      if (this.endAreaId == ``) {

        // 全て選択時の地図への反映
        let endAreaPouringPositions = [];

        // 全ての到着拠点ID、打設箇所（岩種）を地図側に渡す
        for (let i=1; i<this.pouringList.length; i++)
        {
          endAreaPouringPositions.push(
            {
              endAreaId: this.pouringList[i].endAreaId,
              pouringPosition: this.pouringList[i].pouringPosition
            });            
        }
        // 地図描画（到着拠点、走行位置）
        this.$refs.map.setSelectedEndAreaPouringPosition(
          endAreaPouringPositions
        );
        // ルート描画（全て）
        this.drawAllRoute();

      } else {
        // 選択した到着拠点（土捨場）、打設箇所（岩種）の地図表示
        let endAreaPouringPositions = [];
        endAreaPouringPositions.push(
          {
            endAreaId: this.endAreaId,
            pouringPosition: this.pouringList[0].pouringPosition
          });

        // 地図描画（到着拠点、走行位置）  
        this.$refs.map.setSelectedEndAreaPouringPosition(
          endAreaPouringPositions
        );
        // ルート描画
        this.drawRoute();
      }

    },

    // async updateSpot() {
    //   this.spotList = await SpotModel.getSpotList(this.projectId);
    //   let parameters = [];
    //   let item = null;

    //   for (let index = 0; index < this.spotList.length; index++) {
    //     item = this.spotList[index];

    //     parameters.push({
    //       id: item.spot.id,
    //       spotTypeName: item.spot.name,
    //       range: item.spot.range,
    //       x: item.spot.x,
    //       y: item.spot.y,
    //     });
    //   }

    //   this.$refs.map.updateSpot(parameters);
    // },

    /**
     * スポットタイプリストを更新
     */
    updateSpotTypeList(list) {
      this.spotTypeList = list;
    },

    /**
     * 運行表示編集モーダルキャンセルボタンクリック時
     */
    onClickCancelUpdateTimeline() {
      // 編集内容を破棄し、timelineの一覧に戻の値をセットする
      this.keepCurrentRow.rawData = this.editTimeLineDataBefore;
      this.keepCurrentRow.rowDeliveryData = this.editTimeLineDataBefore.deliveryInfo;

      // モーダルを閉じる
      this.modals.editTimeLineDlg = false;
    },

    /**
     * 運行表示編集モーダル更新ボタンクリック時
     */
    async onClickUpdateTimeline() {
      let loader = null;

      try {
        // 入力チェック
        let isValid = await this.validateTimeLine();
        if (!isValid) {
          return;
        }

        // 処理中
        loader = this.showLoader();

        // ユーザーを更新
        const loginUserId = this.userStore.userId;
        this.editTimeLineData.updatedUser = loginUserId;

        const beforeDrive = this.editTimeLineDataBefore.drive;
        const editDrive = this.editTimeLineData.drive;

        // console.log(beforeDrive);
        // console.log(editDrive);

        // 更新ユーザーの設定
        if (beforeDrive.pouringStartTime != editDrive.pouringStartTime) {
          // 打設開始時間
          this.editTimeLineData.drive.pouringStartUser = loginUserId;
        }

        if (beforeDrive.pouringEndTime != editDrive.pouringEndTime) {
          // 打設終了時間
          this.editTimeLineData.drive.pouringEndUser = loginUserId;
        }

        if (beforeDrive.unloadingTime != editDrive.unloadingTime){
          //荷降時間
          this.editTimeLineData.drive.unloadingUser = loginUserId;
        }

        // DB更新
        await this.updateDrive(this.editTimeLineData);

        // 運行表示を更新
        this.driveList = [];
        this.deliveryList = [];
        const sk = `${this.projectId}#${DateUtil.getFormatString(
          this.dateString,
          "YYYYMMDD"
        )}`;
        this.driveList = await DriveModel.getDriveList(sk);
        
        this.deliveryList = await DriveModel.getDelivaryList(sk);
        await this.refreshTimeLine(sk);

        // vueの更新用に再設定
        if (this.selectedVehicleId) {
          // 一致する車両IDのリスト
          const id = this.selectedVehicleId;
          this.selectedVehicleId = "";
          const item = this.timeLineVehicles.find(
            (vehicle) => vehicle.vehicleId === id
          );
          this.selectedVehicleData = item.list;

          // 選択車両IDを設定して一覧ビューを切り替え
          this.selectedVehicleId = id;
        }

        // モーダルを閉じる
        this.modals.editTimeLineDlg = false;
      } finally {
        if (loader) {
          this.hideLoader(loader);
        }
      }
    },

    /**
     * 運行表示編集モーダルの入力をチェックします。
     * @returns 入力エラーが無い場合true、さもなくばfalse
     */
    async validateTimeLine() {
      let result = false;
      let timeEditFlg = false;
  
      // 先に時刻フォーマットを変換（El-UIで変更した時刻がUTC表示になるため）
      Object.keys(this.editTimeLineData.drive).forEach((key) => {
        if (
          key === "factoryStartTime" ||
          key === "siteEndTime" ||
          key === "pouringStartTime" ||
          key === "pouringEndTime" ||
          key === "siteStartTime" ||
          key === "factoryEndTime" ||
          key === "unloadingTime"
        ) {
          const toString = Object.prototype.toString;
          let value = this.editTimeLineData.drive[key];

          if (
            toString
              .call(value)
              .slice(8, -1)
              .toLowerCase() !== "string"
          ) {
            // console.log(value);
            if (value) {
              // 日付が当日になってしまうので、選択日付に置き換える
              value = `${DateUtil.dateStringDate(
                new Date(this.dateString)
              )} ${DateUtil.dateStringBase(value, "HH:mm:ss")}`;
              // value = DateUtil.dateStringDateTime(value);
              // console.log(value);
              this.editTimeLineData.drive[key] = value;

              // 時間の変更あり
              timeEditFlg = true;
            }
          }

          // ※時間を保持している全てのデータの秒を00にする
          let ssValue = this.editTimeLineData.drive[key];
          if (ssValue) {
            // 秒を00にする
            ssValue = `${DateUtil.dateStringDate(
              new Date(this.dateString)
            )} ${DateUtil.dateStringBase(ssValue, "HH:mm:00")}`;

            // 値を更新する
            this.editTimeLineData.drive[key] = ssValue;
          }
        }
      });

      // 時間のカラムが一つでも変更されていた場合は時間の整合性チェック
      if (timeEditFlg == true) {
        // 工場出発時刻
        if (!this.editTimeLineData.drive.factoryStartTime) {
          if (this.projectType == "0"){
            this.showBottomToast(`工場出発時刻を指定してください。`, `warning`);
            return result;
          } else if (this.projectType == "1"){
            this.showBottomToast(`現場出発時刻を指定してください。`, `warning`);
            return result;
          }
        }

        // 現場到着時刻
        if (!this.editTimeLineData.drive.siteEndTime) {
          if (this.projectType == "0"){
            this.showBottomToast(`現場到着時刻を指定してください。`, `warning`);
            // this.$refs.timePickSiteEndTime.select();
            return result;
          } else if (this.projectType == "1"){           
            this.showBottomToast(`土捨場到着時刻を指定してください。`, `warning`);
            return result;
          }         
        }

        // 1つ前の時刻が新しければ不正
        if (
          DateUtil.isAfter(
            this.editTimeLineData.drive.factoryStartTime,
            this.editTimeLineData.drive.siteEndTime
          )
        ) {
          if (this.projectType == "0"){
            this.showBottomToast(
              `現場到着時刻は、工場出発時刻よりも後の時間を指定してください。`,
              `warning`
            );
            return result;
          } else if (this.projectType == "1"){
            this.showBottomToast(`土捨場到着時刻は、現場出発時刻よりもあとの時間を指定してください。`,
              `warning`
            );
            return result;
          }
        }

        // 1つ前の運行情報の最終時間より、前の時間を指定していないかチェック
        if (this.beforeDriveLastTime != "") {
          if (
            DateUtil.isAfter(
              this.beforeDriveLastTime,
              this.editTimeLineData.drive.factoryStartTime
            )
          ) {
            if (this.projectType == "0"){
              this.showBottomToast(
                `工場出発時刻が１つ前の運行情報の最終時刻より、前の時間が指定されています。\n${DateUtil.dateStringBase(
                  this.beforeDriveLastTime,
                  "HH:mm"
                )}より、後の時間を指定してください。`,
                `warning`
              );
              return result;
            } else if (this.projectType == "1"){
              this.showBottomToast(`現場出発時刻が１つ前の運行情報の最終時刻より、前の時間に設定されています。\n${DateUtil.dateStringBase(
                this.beforeDriveLastTime,
                "HH:mm"
              )}より、後の時間を設定してください。`,
              `warning`
              );
              return result;
            }
          }
        }

        // 1つ後の運行情報の最新時間より、後の時間を指定していないかチェック
        if (this.afterDriveFirstTime != "") {
          if (
            DateUtil.isBefore(
              this.afterDriveFirstTime,
              this.editTimeLineData.drive.siteEndTime
            )
          ) {
            if (this.projectType == "0"){
              this.showBottomToast(
                `現場到着時刻が１つ後の運行情報の最新時刻より、後の時間が指定されています。\n${DateUtil.dateStringBase(
                  this.afterDriveFirstTime,
                  "HH:mm"
                )}より、前の時間を指定してください。`,
                `warning`
              );
              return result;
            } else if (this.projectType == "1"){
              this.showBottomToast(
                `土捨場到着時刻が１つ後の運行情報の最新時刻より、後の時間が指定されています。\n${DateUtil.dateStringBase(
                  this.afterDriveFirstTime,
                  "HH:mm"
                )}より、前の時間を指定してください。`,
                `warning`
              );
              return result;
            }            
          }
        }

        // 打設開始時刻
        if (!this.editTimeLineData.drive.pouringStartTime) {
          if (this.projectType == "0"){
            this.showBottomToast(`打設開始時刻を指定してください。`, `warning`);
            return result;
          } else if (this.projectType == "1"){
            this.showBottomToast(`積込開始時刻を指定してください。`, `warning`);
            return result;
          }
        }

        // 1つ前の時刻が新しければ不正
        if (this.projectType === "0") {
          if (
            DateUtil.isAfter(
              this.editTimeLineData.drive.siteEndTime, 
              this.editTimeLineData.drive.pouringStartTime)) {
            this.showBottomToast(
              `打設開始時刻は、現場到着時刻よりも後の時間を指定してください。`,
              `warning`
            );
            return result;
          }
        } else if (this.projectType === "1") {
          if (
            DateUtil.isBefore(
              this.editTimeLineData.drive.siteEndTime,
              this.editTimeLineData.drive.pouringStartTime)) {
            this.showBottomToast(
              `積込開始時刻は、土捨場到着時刻よりも前の時間を指定してください。`,
              `warning`
            );
            return result;
          }
        }
        

        // 打設終了時刻
        if (!this.editTimeLineData.drive.pouringEndTime) {
          if (this.projectType == "0"){
            this.showBottomToast(`打設終了時刻を指定してください。`, `warning`);
            return result;
          } else if (this.projectType == "1"){
            this.showBottomToast(`積込終了時刻を指定してください。`, `warning`);
            return result;
          }         
        }

        // 1つ前の時刻が新しければ不正
        if (
          DateUtil.isAfter(
            this.editTimeLineData.drive.pouringStartTime,
            this.editTimeLineData.drive.pouringEndTime
          )
        ) {
          if (this.projectType == "0"){
            this.showBottomToast(
              `打設終了時刻は、打設開始時刻よりも後の時間を指定してください。`,
              `warning`
            );
            return result;
          } else if (this.projectType == "1"){
            this.showBottomToast(
              `積込終了時刻は、積込開始時刻よりも後の時間を指定してください。`,
              `warning`
            );
            return result;
          }
        }

        // 1つ前の運行情報の最終時間より、前の時間を指定していないかチェック
        if (this.beforeDriveLastTime != "") {
          if (
            DateUtil.isAfter(
              this.beforeDriveLastTime,
              this.editTimeLineData.drive.pouringStartTime
            )
          ) { if (this.projectType == "0"){
            this.showBottomToast(
              `打設開始時刻が１つ前の運行情報の最終時刻より、前の時間が指定されています。\n${DateUtil.dateStringBase(
                this.beforeDriveLastTime,
                "HH:mm"
              )}より、後の時間を指定してください。`,
              `warning`
            );
            return result;
          } else if (this.projectType == "1"){
            this.showBottomToast(
              `積込開始時刻が１つ前の運行情報の最終時刻より、前の時間が指定されています。\n${DateUtil.dateStringBase(
                this.beforeDriveLastTime,
                "HH:mm"
              )}より、後の時間を指定してください。`,
              `warning`
            );
            return result;
          }
          }
        }

        // 1つ後の運行情報の最新時間より、後の時間を指定していないかチェック
        if (this.afterDriveFirstTime != "") {
          if (
            DateUtil.isBefore(
              this.afterDriveFirstTime,
              this.editTimeLineData.drive.pouringEndTime
            )
          ) { 
            if (this.projectType == "0"){
              this.showBottomToast(
                `打設終了時刻が１つ後の運行情報の最新時刻より、後の時間が指定されています。\n${DateUtil.dateStringBase(
                  this.afterDriveFirstTime,
                  "HH:mm"
                )}より、前の時間を指定してください。`,
                `warning`
              );
              return result;
            } else if (this.projectType == "1"){
              this.showBottomToast(
                `積込終了時刻が１つ後の運行情報の最新時刻より、後の時間が指定されています。\n${DateUtil.dateStringBase(
                  this.afterDriveFirstTime,
                  "HH:mm"
                )}より、前の時間を指定してください。`,
                `warning`
              );
              return result;
            }
           
          }
        }

        // 現場出発時刻
        if (!this.editTimeLineData.drive.siteStartTime) {
          if (this.projectType == "0"){
            this.showBottomToast(`現場出発時刻を指定してください。`, `warning`);
            return result;
          } else if (this.projectType == "1"){
            this.showBottomToast(`土捨場到着時刻を指定してください。`, `warning`);
            return result;
          }     
        }

        // 1つ前の時刻が新しければ不正
        if (
          DateUtil.isAfter(
            this.editTimeLineData.drive.pouringEndTime,
            this.editTimeLineData.drive.siteStartTime
          )
        ) {
          if (this.projectType == "0"){
            this.showBottomToast(
              `現場出発時刻は、打設終了時刻よりも後の時間を指定してください。`,
              `warning`
            );
            return result
          } else if (this.projectType == "1"){
            this.showBottomToast(
              `土捨場出発時刻は、積込終了時刻よりも後の時間を指定してください。`,
              `warning`
            );
            return result
          }
        }

        // 工場到着時刻
        if (!this.editTimeLineData.drive.factoryEndTime) {
          if (this.projectType == "0"){
            this.showBottomToast(`工場到着時刻を指定してください。`, `warning`);
            return result;
          } else if (this.projectType == "1"){
            this.showBottomToast(`現場到着時刻を指定してください。`, `warning`);
            return result;
          }         
        }

        // 1つ前の時刻が新しければ不正
        if (
          DateUtil.isAfter(
            this.editTimeLineData.drive.siteStartTime,
            this.editTimeLineData.drive.factoryEndTime
          )
        ) {
          if (this.projectType == "0"){
            this.showBottomToast(
              `工場到着時刻は、現場出発時刻よりも後の時間を指定してください。`,
              `warning`
            );
            return result;
          } else if (this.projectType == "1"){
            this.showBottomToast(
              `現場到着時刻は、土捨場出発時刻よりも後の時間を指定してください。`,
              `warning`
            );
            return result;
          }          
        }

        // 1つ前の運行情報の最終時間より、前の時間を指定していないかチェック
        if (this.beforeDriveLastTime != "") {
          if (
            DateUtil.isAfter(
              this.beforeDriveLastTime,
              this.editTimeLineData.drive.siteStartTime
            )
          ) {
            if (this.projectType == "0"){
              this.showBottomToast(
                `現場出発時刻が１つ前の運行情報の最終時刻より、前の時間が指定されています。\n${DateUtil.dateStringBase(
                  this.beforeDriveLastTime,
                  "HH:mm"
                )}より、後の時間を指定してください。`,
                `warning`
              );
              return result;
            } else if (this.projectType == "1"){
              this.showBottomToast(
                `土捨場出発時刻が１つ前の運行情報の最終時刻より、前の時間が指定されています。\n${DateUtil.dateStringBase(
                  this.beforeDriveLastTime,
                  "HH:mm"
                )}より、後の時間を指定してください。`,
                `warning`
              );
              return result;
            }          
          }
        }

        // 1つ後の運行情報の最新時間より、後の時間を指定していないかチェック
        if (this.afterDriveFirstTime != "") {
          if (
            DateUtil.isBefore(
              this.afterDriveFirstTime,
              this.editTimeLineData.drive.factoryEndTime
            )
          ) {
            if (this.projectType == "0"){
              this.showBottomToast(
                `工場到着時刻が１つ後の運行情報の最新時刻より、後の時間が指定されています。\n${DateUtil.dateStringBase(
                  this.afterDriveFirstTime,
                  "HH:mm"
                )}より、前の時間を指定してください。`,
                `warning`
              );
              return result;
            } else if (this.projectType == "1"){
              this.showBottomToast(
                `現場到着時刻が１つ後の運行情報の最新時刻より、後の時間が指定されています。\n${DateUtil.dateStringBase(
                  this.afterDriveFirstTime,
                  "HH:mm"
                )}より、前の時間を指定してください。`,
                `warning`
              );
              return result;
            }          
          }
        }
      }

      if (!this.editTimeLineData.drive.endAreaId) {
        if (this.projectType == "0"){
          this.showBottomToast(`工区を指定してください。`, `warning`);
          return result;
        } else if (this.projectType == "1"){
          this.showBottomToast(`土捨場を指定してください。`, `warning`);
          return result;
        }       
      }
      // console.log(this.editTimeLineData.drive.pouringPosition)

      if (!this.editTimeLineData.drive.pouringPosition) {
        if (this.projectType == "0"){
          this.showBottomToast(`打設箇所を指定してください。`, `warning`);
          return result;
        } else if (this.projectType == "1"){
          this.showBottomToast(`岩種を指定してください。`, `warning`);
          return result;
        }
      }

      if (this.projectType == "0"){
        if (!this.editTimeLineData.drive.carryType) {
          this.showBottomToast(`積載物を指定してください。`, `warning`);
          return result;
        }
        return true;
      }

      if (this.projectType == "1"){
        if (!this.editTimeLineData.drive.unloadingTime){
          this.showBottomToast(`荷降時間を指定してください`, `warning`);
          return result;
        }
        return true;
      }
    },

    /**
     * 運行情報を更新します。
     * @param {Object} driveRow 運行情報データ
     */
    async updateDrive(driveRow) {
      const pk = driveRow.pk;
      const sk = driveRow.sk;
      const user = this.$store.state.user.userId;

      try {
        // 時刻を更新
        const exceptedTimeStamp = driveRow.updatedAt;

        let condition = {};
        condition.and = [
          {
            updatedAt: { eq: exceptedTimeStamp },
          },
        ];
        //console.log(`★${JSON.stringify(driveRow)}`)
        // 納入伝票更新
        if (Object.keys(driveRow.deliveryInfo).length){

          // 出発時刻（日付を外して時間だけにする）
          if (this.editDeliveryStartTime){
            driveRow.deliveryInfo.delivery.startTime = DateUtil.dateStringDateTime(this.editDeliveryStartTime).substr(11, 5);
          } else {
            driveRow.deliveryInfo.delivery.startTime = null;
          }

          // 到着時刻（日付を外して時間だけにする）
          if (this.editDeliveryEndTime) {
            driveRow.deliveryInfo.delivery.endTime = DateUtil.dateStringDateTime(this.editDeliveryEndTime).substr(11, 5);
          } else {
            driveRow.deliveryInfo.delivery.endTime = null;
          }

          // 納入伝票情報更新
          const updataDelivery = driveRow.deliveryInfo;
          await DriveModel.updateDelivery(updataDelivery);

        }

        // 新しい値
        // driveRow.updatedAt = DateUtil.getUnixTimeStampOfMilliSecond();

        // 第2引数に指定 ※単独更新でもエラーとなるのでコメント
        // await DriveModel.updateDrive(driveRow, condition);
        
        delete driveRow["deliveryInfo"];
        await DriveModel.updateDrive(driveRow);

        this.showBottomToast(`運行情報を更新しました。`, `info`);

        // ※driveを渡すと空文字になってしまう（文字数制限越え？）ので、pk、skを出力
        // appLog.infoLog(`${this.vuename}`, user, this.getUpdateDriveInfo(driveRow));
        this.infoLog(`【Update】 Drive : pk[${pk}] sk[${sk}]`);
      } catch (ex) {
        if (ex.errors) {
          if (ex.errors.length > 0) {
            switch (ex.errors[0].errorType) {
              // このメッセージであれば排他制御エラー
              case "DynamoDB:ConditionalCheckFailedException":
                this.showBottomToast(
                  `先に他のユーザーが更新しているため、新しい内容を確認してください。`,
                  `warning`
                );
                this.errorLog(`updateDrive`, `${this.parseErrorObject(ex)}`);
                // this.errorLog(
                //   `【confrict】 : pk[${pk}] sk[${sk}] error[${JSON.stringify(
                //     ex
                //   )}]`
                // );
                // 運行情報呼び出し側でエラー時も一覧を更新している
                // 必要に応じて競合時だけ更新する場合はここに処理を追加
                break;

              default:
                this.showBottomToast(`運行情報の更新に失敗しました`, `error`);
                this.errorLog(`updateDrive`, `${this.parseErrorObject(ex)}`);
                // this.errorLog(`pk[${pk}] sk[${sk}] error[${JSON.stringify(ex)}]`);
                break;
            }
          } else {
            this.showBottomToast(`運行情報の更新に失敗しました`, `error`);
            this.errorLog(`pk[${pk}] sk[${sk}] error[${JSON.stringify(ex)}]`);
          }
        }

        throw ex;
      }
    },

    // /**
    //  * 運行の更新情報文字列を取得します（ログ出力用）
    //  * @param {Object} driveRow 更新する運行情報
    //  * @returns 運行情報文字列
    //  */
    // async getUpdateDriveInfo(driveRow) {
    //   //console.log(`【Edit】${JSON.stringify(driveRow, null, "\t")}`)
    //   return `【Edit】${JSON.stringify(driveRow, null, "\t")}`;
    // },

    onFocusTimePickSiteEndTime(focusedPicker) {
      // console.log(focusedPicker)
      // test
      // this.$refs.timePickSiteEndTime.select();
      // focusedPicker.select();
    },

    /**
     * 運行サマリ情報更新時の処理です。
     */
    updateSummarys(summarys) {
      console.log("updateSummarys");

      if (this.$refs.subHeader) {
        if (this.$refs.subHeader.updateSummarys) {
          this.$refs.subHeader.updateSummarys(summarys);
        }
      }
    },

    headerClass() {
      return "text-align:center";
    },

    // /**
    //  * 運行情報リストから、車両IDと地図アイコン注記用表示順のリストを取得します。
    //  * @param {String} sk プロジェクトID#yyyyMMDD#到着拠点ID
    //  * @returns 車両IDと地図アイコン注記用表示順のリスト
    //  */
    // async getVehicleMapTextOrders(sk) {
    //   console.log(`[START] getVehicleMapTextOrders: ${sk}`);

    //   try {
    //     // 運行情報リスト(以降の処理用に保持)
    //     this.driveList = [];
    //     this.driveList = await DriveModel.getDriveList(sk);

    //     // 運行ID(タイムスタンプ)で昇順ソート
    //     let numberingList = this.driveList.sort(function(a, b) {
    //       return a.drive.id > b.drive.id ? 1 : -1;
    //     });

    //     // 車両IDで重複削除
    //     numberingList = numberingList.filter(
    //       (element, index, self) =>
    //         self.findIndex(
    //           (e) => e.drive.vehicleId === element.drive.vehicleId
    //         ) === index
    //     );

    //     // 車両ID毎に採番のうえリスト化
    //     let vehicleNumberList = [];
    //     for (let j = 0; j < numberingList.length; j++) {
    //       const drive = numberingList[j].drive;
    //       const order = j + 1;
    //       const item = { vehicleId: drive.vehicleId, iconTextOrder: order };
    //       vehicleNumberList.push(item);
    //     }

    //     // 運行ID(タイムスタンプ)で降順でソート
    //     let driveList = this.driveList.sort(function(a, b) {
    //       return a.drive.id < b.drive.id ? 1 : -1;
    //     });

    //     // 車両IDで重複を削除
    //     driveList = driveList.filter(
    //       (element, index, self) =>
    //         self.findIndex(
    //           (e) => e.drive.vehicleId === element.drive.vehicleId
    //         ) === index
    //     );

    //     let vehicleMapTextOrders = [];

    //     for (let i = 0; i < driveList.length; i++) {
    //       const drive = driveList[i].drive;
    //       const order = vehicleNumberList.find(
    //         (vehicle) => vehicle.vehicleId === drive.vehicleId
    //       ).iconTextOrder;
    //       const item = { vehicleId: drive.vehicleId, iconTextOrder: order };
    //       vehicleMapTextOrders.push(item);
    //     }

    //     // console.log(`vehicleMapTextOrders:${JSON.stringify(vehicleMapTextOrders, null, "\t")}`);
    //     return vehicleMapTextOrders;
    //   } finally {
    //     console.log(`[END] getVehicleMapTextOrders`);
    //   }
    // },

    /**
     * 運行情報一覧で車両名をクリックしたときの処理です。
     */
    async onClickVehicle(timeLineRow) {
      // console.log(`onClickTimelineVehicleLink:${JSON.stringify(timeLineRow, null, "\t")}`);

      // 一致する車両IDのリスト
      const id = timeLineRow.vehicleId;
      const item = this.timeLineVehicles.find(
        (vehicle) => vehicle.vehicleId === id
      );

      // 一覧表示時の、車両の横の数字を1から採番しなおす
      let index = 1;
      item.list.forEach((element) => {
        if (element.type == "vehicle") {
          // アイコンセット（1つ目のアイコンイメージは引数で渡ってきた行のアイコンと同じにする）
          if (index == 1) {
            element.img = timeLineRow.img;
          }
          // 運行番号を1から再セット
          element.driveNumber = index;
          index = index + 1;
        }
      });

      this.selectedVehicleData = item.list;
      this.selectedVehicleData[0].totalAmount = timeLineRow.totalAmount;

      // 選択車両IDを設定して一覧ビューを切り替え
      this.selectedVehicleId = id;
      // console.log(`selectedVehicleData:${JSON.stringify(this.selectedVehicleData, null, "\t")}`);
    },

    /**
     * 運行情報一覧で設定ボタンをクリックしたときの処理です。
     */
    async onClickEditVehicleDlg (timeLineRow) {

      // 編集対象の車両ID、車両名をセットする
      this.modaleditVehicleId = timeLineRow.vehicleId;
      this.modaleditVehicleName = timeLineRow.vehicleName;

      // 車両番号設定ダイアログを表示する
      this.modals.editVehicleDlg = true

    },

    /**
     * 車両番号設定ダイアログから車両番号の保存処理です。
     */
    async onClickUpdateVehicleInfo() {

      let loader = null;

      try {
        // 入力チェック
        if (!this.modaleditVehicleName){
          this.showBottomToast(`車両番号を指定してください。`, `warning`);
          return;
        }

        // 処理中
        loader = this.showLoader();

        // 車両マスター取得
        const vehicleList = await VehicleModel.getVehicleList(this.projectId);

        // 車両マスターから、更新対象の車両情報を特定
        let updateVehicleInfo = null;
        for (let i = 0; i < vehicleList.length; i++) {
          const vehicle = vehicleList[i];
          if (vehicle.vehicle.id == this.modaleditVehicleId) {
            updateVehicleInfo = vehicle;
            break
          }
        }

        // 車両番号をダイアログで設定した値に更新
        updateVehicleInfo.vehicle.name = this.modaleditVehicleName;

        // 車両情報更新（DBに登録）
        await VehicleModel.updateVehicle(updateVehicleInfo);

        // 運行表示を更新
        this.driveList = [];
        const sk = `${this.projectId}#${DateUtil.getFormatString(this.dateString, "YYYYMMDD")}`;
        this.driveList = await DriveModel.getDriveList(sk);
        this.deliveryList = await DriveModel.getDelivaryList(sk);
        await this.refreshTimeLine(sk);

        // メッセージ表示
        this.showBottomToast(`車両情報を更新しました。`, `info`);

        // 車両番号設定ダイアログを非表示する
        this.modals.editVehicleDlg = false;

      } finally {
        if (loader) {
          this.hideLoader(loader);
        }
      }        

    },

    /**
     * 車両別全当日運行情報一覧で戻るをクリックしたときの処理です。
     */
    async onClickBackLatestList() {
      // 選択車両をクリアして一覧ビューを切り替え
      this.selectedVehicleId = "";
    },

    // /**
    //  * 地図更新間隔設定モーダル表示
    //  * @returns 地図更新間隔（秒）
    //  */
    // getMapInterval() {
    //   // ローカルストレージから取得
    //   let value = localStorage.getItem(this.$store.state.user.cognitoSub);

    //   if (!value) {
    //     value = 60;
    //   }

    //   return value;
    // },

    /**
     * 地図更新間隔設定モーダル表示
     */
    onChangeMapInterval() {
      // 設定時間取得
      let value = this.getMapInterval();

      // モーダル表示
      this.mapInterval = value / 60;
      this.modals.editMapInterval = true;
    },

    /**
     * 地図更新間隔設定モーダルの更新ボタンクリックイベント
     */
    onClickSetMapInterval() {
      try {
        // ローカルストレージに保存
        let setValue = this.mapInterval * 60;
        localStorage.setItem(this.$store.state.user.cognitoSub, setValue);

        // 変更後の間隔が最初の1回目に反映されていなかったので変更
        this.$refs.appHeader.updateRefreshIntervalSecond(setValue);

        // // 画面ヘッダー部の値更新
        // this.refreshIntervalSecond = setValue

        // 地図に反映 タイマーを再開する必要があるのでヘッダ側へ通知
        // this.onRefreshLocation();
      } catch (ex) {
        this.showBottomToast(`地図更新間隔の更新に失敗しました`, `error`);
        this.errorLog(`onClickSetMapInterval`, `${this.parseErrorObject(ex)}`);
        
        throw ex;
      } finally {
        this.modals.editMapInterval = false;
      }
    },

    /**
     * ラジオボタン（出発順、車両表示順）チェンジイベント
     *  運行情報一覧の並び順を選択された順にする
     * @param orderTyp ： 1、出発順 2、車両表示順
     */
    async onChageOrder(orderType) {
      // ソート結果格納用
      let sortedList = this.timeLineLatests;

      if (orderType == 1) {
        // 工場出発順
        sortedList = sortedList.sort(function(a, b) {
          // 運行順(yyyyMMdd-n)の「-」より前を降順ソート
          if (a.driveOrder.split("-")[0] < b.driveOrder.split("-")[0]) return 1;
          if (a.driveOrder.split("-")[0] > b.driveOrder.split("-")[0])
            return -1;

          // 運行順(yyyyMMdd-n)の「-」より前を昇順ソート
          if (a.driveOrder.split("-")[1] < b.driveOrder.split("-")[1])
            return -1;
          if (a.driveOrder.split("-")[1] > b.driveOrder.split("-")[1]) return 1;

          return 0;
        });

        // 工場出発、現場出発時間が未設定の場合は、一覧の一番下にする
        let reSortedList = [];
        let underList = []; 
        for (let i=0; i<sortedList.length; i++){
          // 3レコードワンセット
          // console.log("▲▲ ：" + JSON.stringify(sortedList[i]));
          // console.log("▲▲ ：" + JSON.stringify(sortedList[i+1].col1));
          // console.log("▲▲ ：" + JSON.stringify(sortedList[i+2].col2));

          // 工場出発、現場出発時刻取得
          let startFactory = sortedList[i+1].col1;
          let startSite = sortedList[i+2].col2;

          // 両時刻未設定の場合は、再配置用のリストに保持
          if (startFactory == "" && startSite == ""){
            underList.push(sortedList[i]);
            underList.push(sortedList[i+1]);
            underList.push(sortedList[i+2]);
          } else {
            reSortedList.push(sortedList[i]);
            reSortedList.push(sortedList[i+1]);
            reSortedList.push(sortedList[i+2]);

          }          
          i = i + 2;
        }

        // 再配置用リストの値を追加
        for (let i=0; i<underList.length; i++)
        {
          reSortedList.push(underList[i]);
        }

        // 並び替えた分を再セット
        sortedList = reSortedList;

      } else {
        // 車両順
        sortedList = sortedList.sort(function(a, b) {
          return a.vehicleOrder < b.vehicleOrder ? -1 : 1;
        });
      }

      // 変更後の値をセット
      this.timeLineLatests = sortedList;
    },

    /**
     * 日付選択で選択不可の日を指定します。
     */
    disabledDateForQRCode(time) {
      // 当日より前の日付は選択不可とする
      const targetDate = new Date();
      targetDate.setTime(targetDate.getTime() - 3600 * 1000 * 24);
      return time.getTime() < targetDate;
    },

    /**
     * CSV出力用（カレンダーで日付変更時の処理）工区、打設箇所を再設定
     */
    async getAreaAndPouringListforCSV(targetDate) {
      // 予定情報から予定ID取得
      targetDate = DateUtil.getFormatString(targetDate, "YYYYMMDD");
      let planList = await PlanModel.getPlanListFromYMD(
        this.projectId,
        targetDate
      );

      if (planList.length < 1) {
        this.listCSVAreaAndPouring = [];
        this.modalendAreaId = "";
        this.listCSVArea = [];
        this.modalpouringPosition = "";
        this.listCSVPouringPosition = [];
        return;
      }

      // 予定数量のテーブルに問合せ
      let searchKey = String(planList[0].sk).replace("plan#", "");
      let volumeList = await VolumeModel.getVolumeList(searchKey);

      // 工区、打設箇所のリストセット
      this.listCSVAreaAndPouring = [];
      volumeList.forEach((element) => {
        let item = {
          areaId: element.volume.endAreaId,
          areaName: this.getAreaName(element.volume.endAreaId),
          pouringPosition: element.volume.pouringPosition,
        };

        this.listCSVAreaAndPouring.push(item);
      });

      // 工区のセレクトボックスにセット
      this.listCSVArea = [];
      if (this.isZando){
        this.listCSVArea.push({
          id: "0",
          name: `全て`,
        });
        this.listCSVAreaAndPouring.forEach((element) => {
          let item = {
            id: element.areaId,
            name: element.areaName,
          };

          // 重複チェック
          let chkValue = this.listCSVArea.filter((list) => list.id === item.id);
          if (chkValue.length == 0) {
            this.listCSVArea.push(item);
          }
        });

      } else {
        this.listCSVAreaAndPouring.forEach((element) => {
          let item = {
            id: element.areaId,
            name: element.areaName,
          };

          // 重複チェック
          let chkValue = this.listCSVArea.filter((list) => list.id === item.id);
          if (chkValue.length == 0) {
            this.listCSVArea.push(item);
          }
        });
      }
      
      // 打設箇所のセレクトボックス初期化
      this.listCSVPouringPosition = [];
    },

    /**
     * CSV出力用（工区のセレクトボックス変更イベント）
     */
    onChangeAreaCSV(selectData) {

      // if (selectData == 0){
      //   this.disableRock = true;
      // } else {
      //   this.disableRock = false;
      // }
      
      // 工区に紐づく打設箇所をセレクトボックスにセット
      let targetList = this.listCSVAreaAndPouring.filter(
        (list) => list.areaId === selectData
      );

      this.listCSVPouringPosition = [];
      this.editTimeLineData.drive.pouringPosition = null;
      this.modalpouringPosition = null;

      // セレクトボックスに追加
      if (this.isZando) {
        // 残土の場合は全てを追加
        this.listCSVPouringPosition.push({
          id: "0",
          name: `全て`,
        });
        // 土捨場が全て選択時は、岩種は全部表示する
        if (selectData == 0){
          targetList = this.listCSVAreaAndPouring;
        }
      }

      targetList.forEach((element) => {
        let item = {
          id: element.pouringPosition,
          name: element.pouringPosition,
        };
        //重複チェック
        let chkValue = this.listCSVPouringPosition.filter((list) => list.id === item.id);
        if (chkValue.length == 0){
          this.listCSVPouringPosition.push(item);
        }

      });
    },

    /**
     * 運行情報用（カレンダーで日付変更時の処理）工区、打設箇所を再設定
     */
    async getAreaAndPouringListforDrive(targetDate) {
      if (!this.planId) {
        this.listDriveAreaAndPouring = [];
        this.listDriveArea = [];
        return;
      }

      // // 予定情報から予定ID取得
      // targetDate = DateUtil.getFormatString(targetDate, "YYYYMMDD");
      // let planList = await PlanModel.getPlanListFromYMD(
      //   this.projectId,
      //   targetDate
      // );

      // if (planList.length < 1) {
      //   this.listDriveAreaAndPouring = [];
      //   this.listDriveArea = [];
      //   return;
      // }

      // 予定数量のテーブルに問合せ
      const searchKey = `${this.projectId}#${this.planId}`;
      // let searchKey = String(planList[0].sk).replace("plan#", "");
      let volumeList = await VolumeModel.getVolumeList(searchKey);

      // 工区、打設箇所のリストセット
      this.listDriveAreaAndPouring = [];
      volumeList.forEach((element) => {
        let item = {
          areaId: element.volume.endAreaId,
          areaName: this.getAreaName(element.volume.endAreaId),
          pouringPosition: element.volume.pouringPosition,
        };

        this.listDriveAreaAndPouring.push(item);
      });

      // 工区のセレクトボックスにセット
      this.listDriveArea = [];
      this.listDriveAreaAndPouring.forEach((element) => {
        let item = {
          id: element.areaId,
          name: element.areaName,
        };

        // 重複チェック
        let chkValue = this.listDriveArea.filter((list) => list.id === item.id);

        if (chkValue.length == 0) {
          this.listDriveArea.push(item);
        }
      });

      // 打設箇所のセレクトボックス初期化
      this.listDrivePouringPosition = [];
    },

    /**
     * 運行情報用（工区/土捨場のセレクトボックス変更イベント）
     */
    onChangeAreaDrive(selectData) {
      // 工区に紐づく打設箇所をセレクトボックスにセット
      let targetList = this.listDriveAreaAndPouring.filter(
        (list) => list.areaId === selectData
      );

      this.listDrivePouringPosition = [];
      this.editTimeLineData.drive.pouringPosition = null;
      this.modalpouringPosition = null;

      // セレクトボックスに追加
      targetList.forEach((element) => {
        let item = {
          id: element.pouringPosition,
          name: element.pouringPosition,
        };
        this.listDrivePouringPosition.push(item);
      });  
    },

    /**
     * レイヤー表示が変更されたときのイベントハンドラ
     */
    didChangeLayerVisible(layerName, isShow) {
      if (!layerName) {
        return;
      }

      console.log(
        `didChangeLayerVisible layerName:${layerName} isShow:${isShow}`
      );
      this.$refs.subHeader.setSummaryVisible(layerName, isShow);
    },

    didEndShowMap() {
      this.$refs.subHeader.init();
    },

    /**
     * パネル表示、非表示ボタンクリック時の処理
     */
    async toggleMoreInfoPanel(){
      // パネルの表示、非表示設定（true：表示、false：非表示）
      this.isShowMoreInfoPanel = !this.isShowMoreInfoPanel;

      // パネル表示時は、パネル内の値を更新する
      if (this.isShowMoreInfoPanel){
        if (this.projectType == "0"){
          this.dispActualResults();
          this.dispCO2Results();
        } else if (this.projectType == "1"){
          this.zandoDispActualResults();
          this.dispCO2Results();
        }
      }      
    },

    /**
     * 生コン実績情報を更新
     */
    async dispActualResults() {

      // 打設箇所リストが0件の場合は、初期化のみ実施
      if (this.volumList.length == 0){
        await this.initCalcValue();

      } else {
        // 打設箇所セレクトボックスが選択されていない場合は、最初の値を採用（画面起動直後）
        let volumeId = this.selectedVolumeId;
        if (!volumeId) {
          volumeId = this.volumList[0].volume.id;
        }

        // 予定実績の値を更新
        await this.calcActualResults(
          this.projectId, 
          this.dateString.replaceAll("/", ""),
          volumeId);
      }

      // 残出荷数の値がマイナスの場合は0に置き換える （※グラフに渡す値がマイナスの場合は0に置き換える）
      if (this.pouringEnd.volume < 0) this.pouringEnd.volume = 0;
      if (this.pouringOrWaiting.volume < 0) this.pouringOrWaiting.volume = 0;
      if (this.transporting.volume < 0) this.transporting.volume = 0;
      if (this.remain.volume < 0) this.remain.volume = 0;

      // 円（ドーナツ）グラフを再表示
      this.chartData.labels = ['打設完了', '打設/待機', '運搬中', '残量'];
      this.chartData.datasets = [{ 
        backgroundColor: ['#0068FF', '#FF2200', '#FDDD00', '#AAAAAA'],
        data: [this.pouringEnd.volume, this.pouringOrWaiting.volume, this.transporting.volume, this.remain.volume]}]
    },

    /**
     * 残土実績情報を更新
     */
    async zandoDispActualResults(){

      this.targetDate = DateUtil.getFormatString(this.dateString, "YYYYMMDD");      
      await this.getSignagePlan()
      await this.getSignageAreaList()
      await this.getSignageVolumeList()
      await this.makeDumpSiteList();
      await this.getAllVolumeList();
      this.updateZandoUI();
      this.makeZandograph();

    },

    /**
     * 残土のグラフを作成
     */
    async makeZandograph(){
      
      // 打設箇所リストが0件の場合は、初期化のみ実施
      if (this.volumList.length == 0){
        await this.updateZandoUI();

      } else {
        // 打設箇所セレクトボックスが選択されていない場合は、最初の値を採用（画面起動直後）
        let volumeId = this.selectedVolumeId;
        if (!volumeId) {
          volumeId = this.volumList[0].volume.id;
        }

        // 予定実績の値を更新
        await this.updateZandoUI(
          this.projectId, 
          this.dateString.replaceAll("/", ""),
          volumeId);
      }
      // 残出荷数の値がマイナスの場合は0に置き換える （※グラフに渡す値がマイナスの場合は0に置き換える）
      if (this.restDateTotalLoadCapacity < 0) this.restDateTotalLoadCapacity = 0;
      if (this.dateAllAmount < 0) this.dateAllAmount = 0;
      // 小数点1桁に四捨五入する
      if (this.dateAllAmount) {
        this.dateAllAmount = Number(this.dateAllAmount).toFixed(1);
      }

      // 円（ドーナツ）グラフを再表示
      this.zandoChartData.labels = ['搬出量', '残量'];
      this.zandoChartData.datasets = [{
        backgroundColor: ['#FF2200', '#AAAAAA'],
        data: [this.dateAllAmount, this.restDateTotalLoadCapacity]
      }]
    },

    // ---------------- CO2排出量-折れ線グラフ表示処理 ------------------------------------------------------------------------------------------------------------------------------------
    /**
     * CO2排出量情報を更新
     */
    async dispCO2Results() {

      // 折れ線グラフの初期化
      this.chartLineData.labels = [];
      this.chartLineData.datasets = [];
      this.actualTotalValue = 0;
      this.predictTotalValue = 0;
      this.reductionRate = 0;
      // 車両別一覧の値初期化
      this.co2DetailData = [];

      // 予定数量の取得
      // 運行ルート取得
      // ------------------------------------------------------------------------
      // CO2排出量情報の実測値、予測値算出
      // ------------------------------------------------------------------------
      // 累積走行距離の取得 (lsiStr1：mileage#プロジェクトID#yyyyMMdd#予定数量ID)
      // let volumeId = this.selectedVolumeId;
      // if (!volumeId) {
      //   if (this.volumList.length > 0) {
      //     volumeId = this.volumList[0].volume.id;
      //   } else {
      //     // 空の折れ線グラフ表示 (打設箇所がない時)
      //     this.dispLineGraphtoTimeLine(1, [], []);
      //     return;
      //   }
      // }

      let yyyyMM = this.dateString.replaceAll("/", ""); 
      const mileageInfos = await MileageModel.getMileageListFromlsiStr3(this.projectId, yyyyMM, "");
      if (mileageInfos.length < 1) {
        // 空の折れ線グラフ表示 (累積走行距離レコードがない時)
        this.dispLineGraphtoTimeLine(0, null, null)
        return;
      }

      // 燃費、エコ運転判定の値取得
      const co2SettingInfo = await CO2SettingModel.getCO2SettingbyProjectId(this.projectId);

      // 輸送距離から最大積載量取得し、予測値の基準となる燃費を取得
      // let fuelValue = this.getFuel(co2SettingInfo, mileageInfos[0].mileage.maxCapacity);

      // 時間毎の輸送距離算出
      let sumMileage00=0, sumMileage01=0, sumMileage02=0, sumMileage03=0, sumMileage04=0, sumMileage05=0;
      let sumMileage06=0, sumMileage07=0, sumMileage08=0, sumMileage09=0, sumMileage10=0, sumMileage11=0;
      let sumMileage12=0, sumMileage13=0, sumMileage14=0, sumMileage15=0, sumMileage16=0, sumMileage17=0;
      let sumMileage18=0, sumMileage19=0, sumMileage20=0, sumMileage21=0, sumMileage22=0, sumMileage23=0;
      // 実績値
      let actualValue00=0, actualValue01=0, actualValue02=0, actualValue03=0, actualValue04=0, actualValue05=0;
      let actualValue06=0, actualValue07=0, actualValue08=0, actualValue09=0, actualValue10=0, actualValue11=0;
      let actualValue12=0, actualValue13=0, actualValue14=0, actualValue15=0, actualValue16=0, actualValue17=0;
      let actualValue18=0, actualValue19=0, actualValue20=0, actualValue21=0, actualValue22=0, actualValue23=0;

      // 予測値
      let predictValue00 = 0, predictValue01 = 0, predictValue02 = 0, predictValue03 = 0, predictValue04 = 0, predictValue05 = 0;
      let predictValue06 = 0, predictValue07 = 0, predictValue08 = 0, predictValue09 = 0, predictValue10 = 0, predictValue11 = 0;
      let predictValue12 = 0, predictValue13 = 0, predictValue14 = 0, predictValue15 = 0, predictValue16 = 0, predictValue17 = 0; 
      let predictValue18 = 0, predictValue19 = 0, predictValue20 = 0, predictValue21 = 0, predictValue22 = 0, predictValue23 = 0; 

      for (let i=0; i<mileageInfos.length; i++){
        let fuelValue = this.getFuel(co2SettingInfo, mileageInfos[i].mileage.maxCapacity);

        // 時間毎の合計を算出
        sumMileage00 = sumMileage00 + mileageInfos[i].mileage.dayAt00h;
        sumMileage01 = sumMileage01 + mileageInfos[i].mileage.dayAt01h;
        sumMileage02 = sumMileage02 + mileageInfos[i].mileage.dayAt02h;
        sumMileage03 = sumMileage03 + mileageInfos[i].mileage.dayAt03h;
        sumMileage04 = sumMileage04 + mileageInfos[i].mileage.dayAt04h;
        sumMileage05 = sumMileage05 + mileageInfos[i].mileage.dayAt05h;
        sumMileage06 = sumMileage06 + mileageInfos[i].mileage.dayAt06h;
        sumMileage07 = sumMileage07 + mileageInfos[i].mileage.dayAt07h;
        sumMileage08 = sumMileage08 + mileageInfos[i].mileage.dayAt08h;
        sumMileage09 = sumMileage09 + mileageInfos[i].mileage.dayAt09h;
        sumMileage10 = sumMileage10 + mileageInfos[i].mileage.dayAt10h;
        sumMileage11 = sumMileage11 + mileageInfos[i].mileage.dayAt11h;
        sumMileage12 = sumMileage12 + mileageInfos[i].mileage.dayAt12h;
        sumMileage13 = sumMileage13 + mileageInfos[i].mileage.dayAt13h;
        sumMileage14 = sumMileage14 + mileageInfos[i].mileage.dayAt14h;
        sumMileage15 = sumMileage15 + mileageInfos[i].mileage.dayAt15h;
        sumMileage16 = sumMileage16 + mileageInfos[i].mileage.dayAt16h;
        sumMileage17 = sumMileage17 + mileageInfos[i].mileage.dayAt17h;
        sumMileage18 = sumMileage18 + mileageInfos[i].mileage.dayAt18h;
        sumMileage19 = sumMileage19 + mileageInfos[i].mileage.dayAt19h;
        sumMileage20 = sumMileage20 + mileageInfos[i].mileage.dayAt20h;
        sumMileage21 = sumMileage21 + mileageInfos[i].mileage.dayAt21h;
        sumMileage22 = sumMileage22 + mileageInfos[i].mileage.dayAt22h;
        sumMileage23 = sumMileage23 + mileageInfos[i].mileage.dayAt23h;

        // 実績値はレコード毎で算出
        // 1：エコ運転判定 （急発進、急停止、速度超過の回数を取得）
        const penaltyCnt = mileageInfos[i].mileage.suddenStartCount + mileageInfos[i].mileage.suddenStopCount + mileageInfos[i].mileage.speedViolationCount;
        const fuelParam = this.getFuelParam(co2SettingInfo, penaltyCnt);
        
        // CO2排出力の実績値取得
        actualValue00 = actualValue00 + this.calcCO2(mileageInfos[i].mileage.dayAt00h, fuelValue, fuelParam);
        actualValue01 = actualValue01 + this.calcCO2(mileageInfos[i].mileage.dayAt01h, fuelValue, fuelParam);
        actualValue02 = actualValue02 + this.calcCO2(mileageInfos[i].mileage.dayAt02h, fuelValue, fuelParam);
        actualValue03 = actualValue03 + this.calcCO2(mileageInfos[i].mileage.dayAt03h, fuelValue, fuelParam);
        actualValue04 = actualValue04 + this.calcCO2(mileageInfos[i].mileage.dayAt04h, fuelValue, fuelParam);
        actualValue05 = actualValue05 + this.calcCO2(mileageInfos[i].mileage.dayAt05h, fuelValue, fuelParam);
        actualValue06 = actualValue06 + this.calcCO2(mileageInfos[i].mileage.dayAt06h, fuelValue, fuelParam);
        actualValue07 = actualValue07 + this.calcCO2(mileageInfos[i].mileage.dayAt07h, fuelValue, fuelParam);
        actualValue08 = actualValue08 + this.calcCO2(mileageInfos[i].mileage.dayAt08h, fuelValue, fuelParam);
        actualValue09 = actualValue09 + this.calcCO2(mileageInfos[i].mileage.dayAt09h, fuelValue, fuelParam);
        actualValue10 = actualValue10 + this.calcCO2(mileageInfos[i].mileage.dayAt10h, fuelValue, fuelParam);
        actualValue11 = actualValue11 + this.calcCO2(mileageInfos[i].mileage.dayAt11h, fuelValue, fuelParam);
        actualValue12 = actualValue12 + this.calcCO2(mileageInfos[i].mileage.dayAt12h, fuelValue, fuelParam);
        actualValue13 = actualValue13 + this.calcCO2(mileageInfos[i].mileage.dayAt13h, fuelValue, fuelParam);
        actualValue14 = actualValue14 + this.calcCO2(mileageInfos[i].mileage.dayAt14h, fuelValue, fuelParam);
        actualValue15 = actualValue15 + this.calcCO2(mileageInfos[i].mileage.dayAt15h, fuelValue, fuelParam);
        actualValue16 = actualValue16 + this.calcCO2(mileageInfos[i].mileage.dayAt16h, fuelValue, fuelParam);
        actualValue17 = actualValue17 + this.calcCO2(mileageInfos[i].mileage.dayAt17h, fuelValue, fuelParam);
        actualValue18 = actualValue18 + this.calcCO2(mileageInfos[i].mileage.dayAt18h, fuelValue, fuelParam);
        actualValue19 = actualValue19 + this.calcCO2(mileageInfos[i].mileage.dayAt19h, fuelValue, fuelParam);
        actualValue20 = actualValue20 + this.calcCO2(mileageInfos[i].mileage.dayAt20h, fuelValue, fuelParam);
        actualValue21 = actualValue21 + this.calcCO2(mileageInfos[i].mileage.dayAt21h, fuelValue, fuelParam);
        actualValue22 = actualValue22 + this.calcCO2(mileageInfos[i].mileage.dayAt22h, fuelValue, fuelParam);
        actualValue23 = actualValue23 + this.calcCO2(mileageInfos[i].mileage.dayAt23h, fuelValue, fuelParam);

        // 予測値取得
        predictValue00 = predictValue00 + this.calcCO2(mileageInfos[i].mileage.dayAt00h, fuelValue);
        predictValue01 = predictValue01 + this.calcCO2(mileageInfos[i].mileage.dayAt01h, fuelValue);
        predictValue02 = predictValue02 + this.calcCO2(mileageInfos[i].mileage.dayAt02h, fuelValue);
        predictValue03 = predictValue03 + this.calcCO2(mileageInfos[i].mileage.dayAt03h, fuelValue);
        predictValue04 = predictValue04 + this.calcCO2(mileageInfos[i].mileage.dayAt04h, fuelValue);
        predictValue05 = predictValue05 + this.calcCO2(mileageInfos[i].mileage.dayAt05h, fuelValue);
        predictValue06 = predictValue06 + this.calcCO2(mileageInfos[i].mileage.dayAt06h, fuelValue);
        predictValue07 = predictValue07 + this.calcCO2(mileageInfos[i].mileage.dayAt07h, fuelValue);
        predictValue08 = predictValue08 + this.calcCO2(mileageInfos[i].mileage.dayAt08h, fuelValue);
        predictValue09 = predictValue09 + this.calcCO2(mileageInfos[i].mileage.dayAt09h, fuelValue);
        predictValue10 = predictValue10 + this.calcCO2(mileageInfos[i].mileage.dayAt10h, fuelValue);
        predictValue11 = predictValue11 + this.calcCO2(mileageInfos[i].mileage.dayAt11h, fuelValue);
        predictValue12 = predictValue12 + this.calcCO2(mileageInfos[i].mileage.dayAt12h, fuelValue);
        predictValue13 = predictValue13 + this.calcCO2(mileageInfos[i].mileage.dayAt13h, fuelValue);
        predictValue14 = predictValue14 + this.calcCO2(mileageInfos[i].mileage.dayAt14h, fuelValue);
        predictValue15 = predictValue15 + this.calcCO2(mileageInfos[i].mileage.dayAt15h, fuelValue);
        predictValue16 = predictValue16 + this.calcCO2(mileageInfos[i].mileage.dayAt16h, fuelValue);
        predictValue17 = predictValue17 + this.calcCO2(mileageInfos[i].mileage.dayAt17h, fuelValue);
        predictValue18 = predictValue18 + this.calcCO2(mileageInfos[i].mileage.dayAt18h, fuelValue);
        predictValue19 = predictValue19 + this.calcCO2(mileageInfos[i].mileage.dayAt19h, fuelValue);
        predictValue20 = predictValue20 + this.calcCO2(mileageInfos[i].mileage.dayAt20h, fuelValue);
        predictValue21 = predictValue21 + this.calcCO2(mileageInfos[i].mileage.dayAt21h, fuelValue);
        predictValue22 = predictValue22 + this.calcCO2(mileageInfos[i].mileage.dayAt22h, fuelValue);
        predictValue23 = predictValue23 + this.calcCO2(mileageInfos[i].mileage.dayAt23h, fuelValue);
      }

      // CO2排出力の予測値の算出
      // let predictValue00 = this.calcCO2(sumMileage00, fuelValue);
      // let predictValue01 = predictValue00 + this.calcCO2(sumMileage01, fuelValue);
      // let predictValue02 = predictValue01 + this.calcCO2(sumMileage02, fuelValue);
      // let predictValue03 = predictValue02 + this.calcCO2(sumMileage03, fuelValue);
      // let predictValue04 = predictValue03 + this.calcCO2(sumMileage04, fuelValue);
      // let predictValue05 = predictValue04 + this.calcCO2(sumMileage05, fuelValue);
      // let predictValue06 = predictValue05 + this.calcCO2(sumMileage06, fuelValue);
      // let predictValue07 = predictValue06 + this.calcCO2(sumMileage07, fuelValue);
      // let predictValue08 = predictValue07 + this.calcCO2(sumMileage08, fuelValue);
      // let predictValue09 = predictValue08 + this.calcCO2(sumMileage09, fuelValue);
      // let predictValue10 = predictValue09 + this.calcCO2(sumMileage10, fuelValue);
      // let predictValue11 = predictValue10 + this.calcCO2(sumMileage11, fuelValue);
      // let predictValue12 = predictValue11 + this.calcCO2(sumMileage12, fuelValue);
      // let predictValue13 = predictValue12 + this.calcCO2(sumMileage13, fuelValue);
      // let predictValue14 = predictValue13 + this.calcCO2(sumMileage14, fuelValue);
      // let predictValue15 = predictValue14 + this.calcCO2(sumMileage15, fuelValue);
      // let predictValue16 = predictValue15 + this.calcCO2(sumMileage16, fuelValue);
      // let predictValue17 = predictValue16 + this.calcCO2(sumMileage17, fuelValue);
      // let predictValue18 = predictValue17 + this.calcCO2(sumMileage18, fuelValue);
      // let predictValue19 = predictValue18 + this.calcCO2(sumMileage19, fuelValue);
      // let predictValue20 = predictValue19 + this.calcCO2(sumMileage20, fuelValue);
      // let predictValue21 = predictValue20 + this.calcCO2(sumMileage21, fuelValue);
      // let predictValue22 = predictValue21 + this.calcCO2(sumMileage22, fuelValue);
      // let predictValue23 = predictValue22 + this.calcCO2(sumMileage23, fuelValue);

      // 測定値、予測値
      this.actualTotalValue = actualValue00 + actualValue01 + actualValue02 + actualValue03 + actualValue04 + actualValue05
                            + actualValue06 + actualValue07 + actualValue08 + actualValue09 + actualValue10 + actualValue11 
                            + actualValue12 + actualValue13 + actualValue14 + actualValue15 + actualValue16 + actualValue17
                            + actualValue18 + actualValue19 + actualValue20 + actualValue21 + actualValue22 + actualValue23;
      this.predictTotalValue = predictValue00 + predictValue01 + predictValue02 + predictValue03 + predictValue04 + predictValue05
                            + predictValue06 + predictValue07 + predictValue08 + predictValue09 + predictValue10 + predictValue11 
                            + predictValue12 + predictValue13 + predictValue14 + predictValue15 + predictValue16 + predictValue17
                            + predictValue18 + predictValue19 + predictValue20 + predictValue21 + predictValue22 + predictValue23;   
      this.actualTotalValue = Number(this.actualTotalValue).toFixed(6);
      this.predictTotalValue = Number(this.predictTotalValue).toFixed(6);
      
      // 削減率：(1 - 測定値/予測値) x 100       
      this.reductionRate = this.predictTotalValue <= 0 ? 0 : (1 - this.actualTotalValue / this.predictTotalValue) * 100;
      this.reductionRate = Number(this.reductionRate).toFixed(2);


      console.log(`reductionRate: ${this.reductionRate} ${this.actualTotalValue} ${this.predictTotalValue}`)
      // 折れ線グラフ表示
      // Y軸のマックス値設定
      let scaleYmaxValue = 0;
      if (this.actualTotalValue > this.predictTotalValue) {
        scaleYmaxValue = this.actualTotalValue * 1.6;
      } else {
        scaleYmaxValue = this.predictTotalValue * 1.6;
      }

      // 現在選択されている日付が今日なら、現在時刻まで折れ線グラフを表示
      let nowHour = 0;
      if (this.isToday(this.dateString)) {
        nowHour = new Date().getHours();
      } else {
        // 過去日の場合は24時間分表示
        nowHour = 23;
      }

      // 折れ線グラフ（実測値、測定値の値セット）
      let predictValues = [];
      let actualValues = [];

      // 時間毎に値をプッシュ
      if (nowHour >= 0) {
        predictValues.push(predictValue00);
        actualValues.push(actualValue00);
      }
      if (nowHour >= 1) {
        predictValues.push(predictValue00 + predictValue01);
        actualValues.push(actualValue00 + actualValue01);
      }
      if (nowHour >= 2) {
        predictValues.push(predictValue00 + predictValue01 + predictValue02);
        actualValues.push(actualValue00 + actualValue01 + actualValue02);
      }
      if (nowHour >= 3) {
        predictValues.push(predictValue00 + predictValue01 + predictValue02 + predictValue03);
        actualValues.push(actualValue00 + actualValue01 + actualValue02 + actualValue03);
      }
      if (nowHour >= 4) {
        predictValues.push(predictValue00 + predictValue01 + predictValue02 + predictValue03 + predictValue04);
        actualValues.push(actualValue00 + actualValue01 + actualValue02 + actualValue03 + actualValue04);
      }
      if (nowHour >= 5) {
        predictValues.push(predictValue00 + predictValue01 + predictValue02 + predictValue03 + predictValue04 + predictValue05);
        actualValues.push(actualValue00 + actualValue01 + actualValue02 + actualValue03 + actualValue04 + actualValue05);
      }
      if (nowHour >= 6) {
        predictValues.push(predictValue00 + predictValue01 + predictValue02 + predictValue03 + predictValue04 + predictValue05 + predictValue06);
        actualValues.push(actualValue00 + actualValue01 + actualValue02 + actualValue03 + actualValue04 + actualValue05 + actualValue06);
      }
      if (nowHour >= 7) {
        predictValues.push(predictValue00 + predictValue01 + predictValue02 + predictValue03 + predictValue04 + predictValue05 + predictValue06 + predictValue07);
        actualValues.push(actualValue00 + actualValue01 + actualValue02 + actualValue03 + actualValue04 + actualValue05 + actualValue06 + actualValue07);
      }
      if (nowHour >= 8) {
        predictValues.push(predictValue00 + predictValue01 + predictValue02 + predictValue03 + predictValue04 + predictValue05 + predictValue06 + predictValue07
         + predictValue08);
        actualValues.push(actualValue00 + actualValue01 + actualValue02 + actualValue03 + actualValue04 + actualValue05 + actualValue06 + actualValue07
          + actualValue08);
      }
      if (nowHour >= 9) {
        predictValues.push(predictValue00 + predictValue01 + predictValue02 + predictValue03 + predictValue04 + predictValue05 + predictValue06 + predictValue07
         + predictValue08 + predictValue09);
        actualValues.push(actualValue00 + actualValue01 + actualValue02 + actualValue03 + actualValue04 + actualValue05 + actualValue06 + actualValue07
          + actualValue08 + actualValue09);
      }
      if (nowHour >= 10) {
        predictValues.push(predictValue00 + predictValue01 + predictValue02 + predictValue03 + predictValue04 + predictValue05 + predictValue06 + predictValue07
         + predictValue08 + predictValue09 + predictValue10);
        actualValues.push(actualValue00 + actualValue01 + actualValue02 + actualValue03 + actualValue04 + actualValue05 + actualValue06 + actualValue07
          + actualValue08 + actualValue09 + actualValue10);
      }
      if (nowHour >= 11) {
        predictValues.push(predictValue00 + predictValue01 + predictValue02 + predictValue03 + predictValue04 + predictValue05 + predictValue06 + predictValue07
         + predictValue08 + predictValue09 + predictValue10 + predictValue11);
        actualValues.push(actualValue00 + actualValue01 + actualValue02 + actualValue03 + actualValue04 + actualValue05 + actualValue06 + actualValue07
          + actualValue08 + actualValue09 + actualValue10 + actualValue11);
      }
      if (nowHour >= 12) {
        predictValues.push(predictValue00 + predictValue01 + predictValue02 + predictValue03 + predictValue04 + predictValue05 + predictValue06 + predictValue07
         + predictValue08 + predictValue09 + predictValue10 + predictValue11 + predictValue12);
        actualValues.push(actualValue00 + actualValue01 + actualValue02 + actualValue03 + actualValue04 + actualValue05 + actualValue06 + actualValue07
          + actualValue08 + actualValue09 + actualValue10 + actualValue11 + actualValue12);
      }
      if (nowHour >= 13) {
        predictValues.push(predictValue00 + predictValue01 + predictValue02 + predictValue03 + predictValue04 + predictValue05 + predictValue06 + predictValue07
         + predictValue08 + predictValue09 + predictValue10 + predictValue11 + predictValue12 + predictValue13);
        actualValues.push(actualValue00 + actualValue01 + actualValue02 + actualValue03 + actualValue04 + actualValue05 + actualValue06 + actualValue07
          + actualValue08 + actualValue09 + actualValue10 + actualValue11 + actualValue12 + actualValue13);
      }
      if (nowHour >= 14) {
        predictValues.push(predictValue00 + predictValue01 + predictValue02 + predictValue03 + predictValue04 + predictValue05 + predictValue06 + predictValue07
         + predictValue08 + predictValue09 + predictValue10 + predictValue11 + predictValue12 + predictValue13 + predictValue14);
        actualValues.push(actualValue00 + actualValue01 + actualValue02 + actualValue03 + actualValue04 + actualValue05 + actualValue06 + actualValue07
          + actualValue08 + actualValue09 + actualValue10 + actualValue11 + actualValue12 + actualValue13 + actualValue14);
      }
      if (nowHour >= 15) {
        predictValues.push(predictValue00 + predictValue01 + predictValue02 + predictValue03 + predictValue04 + predictValue05 + predictValue06 + predictValue07
         + predictValue08 + predictValue09 + predictValue10 + predictValue11 + predictValue12 + predictValue13 + predictValue14 + predictValue15);
        actualValues.push(actualValue00 + actualValue01 + actualValue02 + actualValue03 + actualValue04 + actualValue05 + actualValue06 + actualValue07
          + actualValue08 + actualValue09 + actualValue10 + actualValue11 + actualValue12 + actualValue13 + actualValue14 + actualValue15);
      } 
      if (nowHour >= 16) {
        predictValues.push(predictValue00 + predictValue01 + predictValue02 + predictValue03 + predictValue04 + predictValue05 + predictValue06 + predictValue07
         + predictValue08 + predictValue09 + predictValue10 + predictValue11 + predictValue12 + predictValue13 + predictValue14 + predictValue15
          + predictValue16);
        actualValues.push(actualValue00 + actualValue01 + actualValue02 + actualValue03 + actualValue04 + actualValue05 + actualValue06 + actualValue07
          + actualValue08 + actualValue09 + actualValue10 + actualValue11 + actualValue12 + actualValue13 + actualValue14 + actualValue15
          + actualValue16);
      }
      if (nowHour >= 17) {
        predictValues.push(predictValue00 + predictValue01 + predictValue02 + predictValue03 + predictValue04 + predictValue05 + predictValue06 + predictValue07
         + predictValue08 + predictValue09 + predictValue10 + predictValue11 + predictValue12 + predictValue13 + predictValue14 + predictValue15
          + predictValue16 + predictValue17);
        actualValues.push(actualValue00 + actualValue01 + actualValue02 + actualValue03 + actualValue04 + actualValue05 + actualValue06 + actualValue07
          + actualValue08 + actualValue09 + actualValue10 + actualValue11 + actualValue12 + actualValue13 + actualValue14 + actualValue15
          + actualValue16 + actualValue17);
      }
      if (nowHour >= 18) {
        predictValues.push(predictValue00 + predictValue01 + predictValue02 + predictValue03 + predictValue04 + predictValue05 + predictValue06 + predictValue07
         + predictValue08 + predictValue09 + predictValue10 + predictValue11 + predictValue12 + predictValue13 + predictValue14 + predictValue15
          + predictValue16 + predictValue17 + predictValue18);
        actualValues.push(actualValue00 + actualValue01 + actualValue02 + actualValue03 + actualValue04 + actualValue05 + actualValue06 + actualValue07
          + actualValue08 + actualValue09 + actualValue10 + actualValue11 + actualValue12 + actualValue13 + actualValue14 + actualValue15
          + actualValue16 + actualValue17 + actualValue18);
      }      
      if (nowHour >= 19) {
        predictValues.push(predictValue00 + predictValue01 + predictValue02 + predictValue03 + predictValue04 + predictValue05 + predictValue06 + predictValue07
         + predictValue08 + predictValue09 + predictValue10 + predictValue11 + predictValue12 + predictValue13 + predictValue14 + predictValue15
          + predictValue16 + predictValue17 + predictValue18 + predictValue19);
        actualValues.push(actualValue00 + actualValue01 + actualValue02 + actualValue03 + actualValue04 + actualValue05 + actualValue06 + actualValue07
          + actualValue08 + actualValue09 + actualValue10 + actualValue11 + actualValue12 + actualValue13 + actualValue14 + actualValue15
          + actualValue16 + actualValue17 + actualValue18 + actualValue19);
      } 
      if (nowHour >= 20) {
        predictValues.push(predictValue00 + predictValue01 + predictValue02 + predictValue03 + predictValue04 + predictValue05 + predictValue06 + predictValue07
         + predictValue08 + predictValue09 + predictValue10 + predictValue11 + predictValue12 + predictValue13 + predictValue14 + predictValue15
          + predictValue16 + predictValue17 + predictValue18 + predictValue19 + predictValue20);
        actualValues.push(actualValue00 + actualValue01 + actualValue02 + actualValue03 + actualValue04 + actualValue05 + actualValue06 + actualValue07
          + actualValue08 + actualValue09 + actualValue10 + actualValue11 + actualValue12 + actualValue13 + actualValue14 + actualValue15
          + actualValue16 + actualValue17 + actualValue18 + actualValue19 + actualValue20);
      } 
      if (nowHour >= 21) {
        predictValues.push(predictValue00 + predictValue01 + predictValue02 + predictValue03 + predictValue04 + predictValue05 + predictValue06 + predictValue07
         + predictValue08 + predictValue09 + predictValue10 + predictValue11 + predictValue12 + predictValue13 + predictValue14 + predictValue15
          + predictValue16 + predictValue17 + predictValue18 + predictValue19 + predictValue20 + predictValue21);
        actualValues.push(actualValue00 + actualValue01 + actualValue02 + actualValue03 + actualValue04 + actualValue05 + actualValue06 + actualValue07
          + actualValue08 + actualValue09 + actualValue10 + actualValue11 + actualValue12 + actualValue13 + actualValue14 + actualValue15
          + actualValue16 + actualValue17 + actualValue18 + actualValue19 + actualValue20 + actualValue21);
      }
      if (nowHour >= 22) {
        predictValues.push(predictValue00 + predictValue01 + predictValue02 + predictValue03 + predictValue04 + predictValue05 + predictValue06 + predictValue07
         + predictValue08 + predictValue09 + predictValue10 + predictValue11 + predictValue12 + predictValue13 + predictValue14 + predictValue15
          + predictValue16 + predictValue17 + predictValue18 + predictValue19 + predictValue20 + predictValue21 + predictValue22);
        actualValues.push(actualValue00 + actualValue01 + actualValue02 + actualValue03 + actualValue04 + actualValue05 + actualValue06 + actualValue07
          + actualValue08 + actualValue09 + actualValue10 + actualValue11 + actualValue12 + actualValue13 + actualValue14 + actualValue15
          + actualValue16 + actualValue17 + actualValue18 + actualValue19 + actualValue20 + actualValue21 + actualValue22);
      }              
      if (nowHour >= 23) {
        predictValues.push(predictValue00 + predictValue01 + predictValue02 + predictValue03 + predictValue04 + predictValue05 + predictValue06 + predictValue07
         + predictValue08 + predictValue09 + predictValue10 + predictValue11 + predictValue12 + predictValue13 + predictValue14 + predictValue15
          + predictValue16 + predictValue17 + predictValue18 + predictValue19 + predictValue20 + predictValue21 + predictValue22 + predictValue23);
        actualValues.push(actualValue00 + actualValue01 + actualValue02 + actualValue03 + actualValue04 + actualValue05 + actualValue06 + actualValue07
          + actualValue08 + actualValue09 + actualValue10 + actualValue11 + actualValue12 + actualValue13 + actualValue14 + actualValue15
          + actualValue16 + actualValue17 + actualValue18 + actualValue19 + actualValue20 + actualValue21 + actualValue22 + actualValue23);
      }   

      // 折れ線グラフ表示
      this.dispLineGraphtoTimeLine(scaleYmaxValue, predictValues, actualValues);

      // --------------------------------------------------
      // 詳細ダイアログ - 車両別一覧用データ作成
      // --------------------------------------------------
      // 累積走行距離レコードにある車両idのリスト生成
      let vehicleIds = [];
      for (let i=0; i<mileageInfos.length; i++) {
        
        if (i==0) {
          vehicleIds.push(mileageInfos[0].mileage.vehicleId);
        } else {
          const info = vehicleIds.find(
            (item) => item === mileageInfos[i].mileage.vehicleId
          );
          if (!info){
            vehicleIds.push(mileageInfos[i].mileage.vehicleId);
          }
        }
      }
      // 車両マスター取得（車両idから車両名取得用）
      const vehicleList = await VehicleModel.getVehicleList(this.projectId);

      // 車両毎のCO2排出量算出
      this.co2DetailData = [];
      for (let i=0; i<vehicleIds.length; i++) {

        const mileageList = mileageInfos.filter(
          (item) => item.mileage.vehicleId === vehicleIds[i]
        );

        let calcActualValue = 0, calcPredictValue = 0;
        let isSuddenStart = false, isSuddenStop = false, isSpeedViolation = false;

        for (let j=0; j < mileageList.length; j++) {
          let fuelValue = this.getFuel(co2SettingInfo, mileageList[j].mileage.maxCapacity);
          // 測定値
          calcActualValue = calcActualValue + this.calcActualCO2(mileageList[j], fuelValue, co2SettingInfo);
          // 予測値
          calcPredictValue = calcPredictValue + this.calcPredictCO2(mileageList[j], fuelValue); 
          // 急発進有無
          if (mileageList[j].mileage.suddenStartCount > 0) isSuddenStart = true;
          // 急停止有無
          if (mileageList[j].mileage.suddenStopCount > 0) isSuddenStop = true;
          // 速度超過有無
          if (mileageList[j].mileage.speedViolationCount > 0) isSpeedViolation = true;
        }

        // 車両IDから車両名取得
        const vehicleInfo = vehicleList.find(
          (item) => item.vehicle.id === vehicleIds[i]
        );

        // エコ運転状況
        let ecoStatusString = "";
        if (isSuddenStart){
          ecoStatusString = "急発進あり";
        }
        if (isSuddenStop) {
          ecoStatusString==""? ecoStatusString = "急停止あり" : ecoStatusString = ecoStatusString + " / 急停止あり";
        }
        if (isSpeedViolation) {
          ecoStatusString==""? ecoStatusString = "速度超過あり" : ecoStatusString = ecoStatusString + " / 速度超過あり";
        }

        // 車両毎一覧データとして追加
        this.co2DetailData.push(
          {
            vehicleNo: vehicleInfo.vehicle.name,
            actualValue: `${Number(calcActualValue).toFixed(6)} t-CO2`, 
            predictValue: `${Number(calcPredictValue).toFixed(6)} t-CO2`, 
            ecoDriveStatus: ecoStatusString             
          }
        );
      }

    },

    /**
    * 折れ線グラフ表示
    */
    dispLineGraphtoTimeLine(scaleYmaxValue, predictValues, actualValues){

      // Y軸の最大値背設定
      this.ChartLineOptions.scales.y.max = scaleYmaxValue;

      // X軸のラベル設定
      this.chartLineData.labels = [
        '0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00',
        '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'
      ];
      // 折れ線グラフの値設定
      this.chartLineData.datasets = [{
        label: '予測値',
        data: predictValues,
        fill: false,
        borderColor: 'rgb(0, 255, 0)',
        borderDash: [4, 2],
        tension: 0.1,
        legend: {
          display: false,
        }
      },
      {
        label: '測定値',
        data: actualValues, 
        fill: false,
        borderColor: 'rgb(248, 171, 166)',
        tension: 0.1,
        legend: {
          display: 'none',
        }
      }]
    },

    /**
    * 燃費取得
    */
    getFuel(co2SettingInfo, maxCapacity) {

      // 初期値設定 (燃費5)
      let fuelValue = co2SettingInfo.co2setting.fuelMileage5;

      if (maxCapacity <= 1.3) {
        // 燃費1(約0.8～1.3㎥)
        fuelValue = co2SettingInfo.co2setting.fuelMileage1;
      } else if (maxCapacity <= 2.3) {
        // 燃費1(約1.3～2.3㎥)
        fuelValue = co2SettingInfo.co2setting.fuelMileage2;  
      } else if (maxCapacity <= 3.1) {
        // 燃費1(約2.4～3.1㎥)
        fuelValue = co2SettingInfo.co2setting.fuelMileage3;  
      } else if (maxCapacity <= 3.9) {
        // 燃費1(約3.2～3.9㎥)
        fuelValue = co2SettingInfo.co2setting.fuelMileage4;  
      }  
      return fuelValue;
    },

    /**
    * エコ運転の燃費係数取得
    */
    getFuelParam(co2SettingInfo, penaltyCnt) {

      // 初期値設定 (C判定)
      let fuelParam = co2SettingInfo.co2setting.CClassFuelParam;

      if (penaltyCnt <= co2SettingInfo.co2setting.AClassCount) {
        // A判定
        fuelParam = co2SettingInfo.co2setting.AClassFuelParam;
      } else if (penaltyCnt <= co2SettingInfo.co2setting.BClassCount) {
        // B判定
        fuelParam = co2SettingInfo.co2setting.BClassFuelParam;
      }
      return fuelParam;
    },

    /**
    * 累積走行距離レコードから実績（測定）CO2排出量を算出
    */
    calcActualCO2(mileageRecord, fuelValue, co2SettingInfo){

      let retValue = 0;

      // 燃費異数取得
      const penaltyCnt = mileageRecord.mileage.suddenStartCount + mileageRecord.mileage.suddenStopCount + mileageRecord.mileage.speedViolationCount;
      const fuelParam = this.getFuelParam(co2SettingInfo, penaltyCnt);

      // retValue = this.calcCO2(mileageRecord.mileage.dayAt07h, fuelValue, fuelParam);
      // retValue = retValue + this.calcCO2(mileageRecord.mileage.dayAt08h, fuelValue, fuelParam);
      // retValue = retValue + this.calcCO2(mileageRecord.mileage.dayAt09h, fuelValue, fuelParam);
      // retValue = retValue + this.calcCO2(mileageRecord.mileage.dayAt10h, fuelValue, fuelParam);
      // retValue = retValue + this.calcCO2(mileageRecord.mileage.dayAt11h, fuelValue, fuelParam);
      // retValue = retValue + this.calcCO2(mileageRecord.mileage.dayAt12h, fuelValue, fuelParam);
      // retValue = retValue + this.calcCO2(mileageRecord.mileage.dayAt13h, fuelValue, fuelParam);
      // retValue = retValue + this.calcCO2(mileageRecord.mileage.dayAt14h, fuelValue, fuelParam);
      // retValue = retValue + this.calcCO2(mileageRecord.mileage.dayAt15h, fuelValue, fuelParam);
      // retValue = retValue + this.calcCO2(mileageRecord.mileage.dayAt16h, fuelValue, fuelParam);
      // retValue = retValue + this.calcCO2(mileageRecord.mileage.dayAt17h, fuelValue, fuelParam);
      retValue = this.calcCO2(mileageRecord.mileage.totalMileage, fuelValue, fuelParam);

      return retValue;
    },

    /**
    * 累積走行距離レコードから予測CO2排出量を算出
    */
    calcPredictCO2(mileageRecord, fuelValue){
      
      let retValue = 0;

      // retValue = this.calcCO2(mileageRecord.mileage.dayAt07h, fuelValue);
      // retValue = retValue + this.calcCO2(mileageRecord.mileage.dayAt08h, fuelValue);
      // retValue = retValue + this.calcCO2(mileageRecord.mileage.dayAt09h, fuelValue);
      // retValue = retValue + this.calcCO2(mileageRecord.mileage.dayAt10h, fuelValue);
      // retValue = retValue + this.calcCO2(mileageRecord.mileage.dayAt11h, fuelValue);
      // retValue = retValue + this.calcCO2(mileageRecord.mileage.dayAt12h, fuelValue);
      // retValue = retValue + this.calcCO2(mileageRecord.mileage.dayAt13h, fuelValue);
      // retValue = retValue + this.calcCO2(mileageRecord.mileage.dayAt14h, fuelValue);
      // retValue = retValue + this.calcCO2(mileageRecord.mileage.dayAt15h, fuelValue);
      // retValue = retValue + this.calcCO2(mileageRecord.mileage.dayAt16h, fuelValue);
      // retValue = retValue + this.calcCO2(mileageRecord.mileage.dayAt17h, fuelValue);
      retValue = this.calcCO2(mileageRecord.mileage.totalMileage, fuelValue);

      return retValue;
    },

    /**
    * CO2排出量算出
    */
    calcCO2(mileage, fuel, fuelParam=1) {

      // 移動距離が0の場合は処理しない
      if (mileage == 0) {
        return 0;
      } 

      // 燃費係数を考慮して燃費計算
      fuel = fuel * fuelParam;

      // 1：燃料使用料 (輸送距離 / 燃費 × (1 / 1000))
      let calcValue = mileage / fuel * 1 / 1000; 
      
      // CO2排出量(t-CO2) (燃料使用料 × 単位発熱量(固定値：38.2) × 排出係数(tC/GJ)(固定値：0.0187) × 係数(t-CP2/t-C)(固定値：44/12))
      return calcValue * 38.2 * 0.0187 * 44 / 12;

    },

    /**
    * CO2詳細情報表示ボタンクリック時
    */
    changePeriod(){
      if (this.rdoTotalPeriod == 0){
        this.disabledRdoPeriod = true;
      } else {
        this.disabledRdoPeriod = false;
      }
    },
    
    /**
    * CO2詳細情報表示ボタンクリック時
    */
    onClickDetail() {
      // 折れ線グラフの初期化  
      this.chartLineDetailData.labels = [];
      this.chartLineDetailData.datasets = [];
      this.dispDetailActualTotalValue = 0;
      this.dispDetailPredictlTotalValue = 0;

      // 期間の初期値設定
      this.rdoTotalPeriod = 1;
      this.disabledRdoPeriod = false;
      this.startTotalPeriod = DateUtil.addMonth(this.datestring, -3);
      this.endTotalPeriod = DateUtil.dateStringDate(this.datestring);

      // モーダル画面表示
      this.acctivePane = "0";
      this.modals.co2Detail = true;

      this.$nextTick(function() {
        this.onClickUpdateTotalPeriod()
      }.bind(this))
    },

    /**
    * CO2詳細ダイアログのCSV出力ボタンクリック時
    */
    async onClickCSVTotalPeriod() {

      if (confirm("ＣＯ２排出量のＣＳＶファイルを作成しますか？")) {

        // 折れ線グラフ更新
        let retValue = await this.makeLineGraph();
        if (!retValue) return;

        try {
          // 燃費、エコ運転判定の値取得
          const co2SettingInfo = await CO2SettingModel.getCO2SettingbyProjectId(this.projectId);

          // 車両一覧取得
          const vehicleList = await VehicleModel.getVehicleList(this.projectId);

          let csvDatas = [];
          let alreadyProcessedList = [];
          // 折れ線グラフ作成時に、保持したCSV出力用データから車両毎で集計しCSVデータ作成
          for (let i=0; i<this.csvCO2Info.mileageList.length; i++){
            let targetList = this.csvCO2Info.mileageList[i];

            // 月単位で取得したレコード数分処理する
            alreadyProcessedList = [];
            for (let j=0; j<targetList.mileageRecords.length; j++) {
              
              // 日付、車両毎でCSV一行に出力する
              // 既に処理済みかチェック
              // lsiStr1の値でチェック：mileage#プロジェクトID#yyyyMMdd#車両ID
              let checkValue = false;
              for (let k=0; k<alreadyProcessedList.length; k++){
                if (alreadyProcessedList[k] == targetList.mileageRecords[j].lsiStr1){
                  checkValue = true;
                  break;
                }
              }

              // 未処理の日付、車両IDの場合はCSVに出力
              if (!checkValue){
                // CSV出力対象（日付、車両IDが同じレコード取得）
                const targeMileageRecord = targetList.mileageRecords.filter(
                  (item) => item.lsiStr1 === targetList.mileageRecords[j].lsiStr1
                );

                // lsiStr1を分解(mileage#プロジェクトID#yyyyMMdd#車両ID)
                let lsiStr1Values = targetList.mileageRecords[j].lsiStr1.split("#");

                // 車両IDから車両名取得
                const vehicleName = vehicleList.find(
                  (item) => item.vehicle.id === lsiStr1Values[3]
                );

                // CSV出力データ作成      
                if (targeMileageRecord.length > 0) {
                  // CSV出力データ1行分作成
                  let csvRecord = this.createCO2CSVRecord(
                    co2SettingInfo, 
                    lsiStr1Values[2], 
                    vehicleName.vehicle.name, 
                    targeMileageRecord);
                  // CSV出力用として登録
                  csvDatas.push(csvRecord);
                }

                // 処理済みリストに登録
                alreadyProcessedList.push(targetList.mileageRecords[j].lsiStr1);
              }
            }
          
            // for (let j=0; j<vehicleList.length; j++){
            //   const vehicle = vehicleList[j];

            //   // 車両で絞り込む
            //   const targeMileageRecord = targetList.mileageRecords.filter(
            //     (item) => item.mileage.vehicleId === vehicle.vehicle.id
            //   );
          
            //   // CSV出力データ作成      
            //   if (targeMileageRecord.length > 0) {
            //     // CSV出力データ1行分作成
            //     let csvRecord = this.createCO2CSVRecord(
            //       co2SettingInfo, 
            //       this.csvCO2Info.mileageList[i].targetMonth, 
            //       vehicle.vehicle.name, 
            //       targeMileageRecord);
            //     // CSV出力用として登録
            //     csvDatas.push(csvRecord);
            //   }

            // }
          }

          // CSVヘッダー行作成
          let columns = this.createCO2CSVColumns();

          // CSVファイル作成
          const csv = stringify(csvDatas, {
            header: true,
            quoted: true,
            record_delimiter: "windows",
            columns: columns,
          });

          // BOM
          let bom = new Uint8Array([0xef, 0xbb, 0xbf]);

          // CSVフォーマットの文字列をblobに
          let csvBlob = new Blob([bom, csv], { type: "text/csv" });

          // ブラウザにダウンロードダイアログを表示する処理
          const a = document.createElement("a");
          a.href = URL.createObjectURL(csvBlob);

          // ファイル名生成 (yyyymmdd_工区名打設箇所.csv)
          let fileName = "";
          if (this.csvCO2Info.periodType == 0){
            fileName = `${this.projectName}_全期間_CO2排出量.csv`; 
          } else {
            fileName = `${this.projectName}_${this.csvCO2Info.startPeriod}-${this.csvCO2Info.endPeriod}_CO2排出量.csv`; 
          }
          a.download = fileName;

          a.style.display = "none";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);

        } catch (e) {
          this.showBottomToast(`CSV出力処理に失敗しました。${e.message}`, `warning`);
        }        
      }

    },

    /**
     * CO2排出量CSVレコード生成（1行分）
     */
    createCO2CSVRecord(co2SettingInfo, yyyyMM, vehicleName, mileageRecords){

      let predictValue = 0;
      let measuredValue = 0;
      let totalMileage = 0;
      let reductionRate = 0;
      let suddenStartCount = 0;
      let suddenStopCount = 0;
      let speedViolationCount = 0;

      // 輸送距離から最大積載量取得し、予測値の基準となる燃費を取得
      let fuelValue = this.getFuel(co2SettingInfo, mileageRecords[0].mileage.maxCapacity);

      for (let i=0; i<mileageRecords.length; i++) {        
        // 予測値
        predictValue = predictValue + this.calcPredictCO2(mileageRecords[i], fuelValue); 
        console.log(`createCO2CSVRecord ${predictValue}`)
        // 測定値
        measuredValue = measuredValue + this.calcActualCO2(mileageRecords[i], fuelValue, co2SettingInfo);
        console.log(`createCO2CSVRecord ${measuredValue}`)
        // 距離
        totalMileage = totalMileage + mileageRecords[i].mileage.totalMileage;
        // 急発進回数
        suddenStartCount = suddenStartCount + mileageRecords[i].mileage.suddenStartCount;
        // 急停止回数
        suddenStopCount = suddenStopCount + mileageRecords[i].mileage.suddenStopCount;
        // 速度超過回数
        speedViolationCount = speedViolationCount + mileageRecords[i].mileage.speedViolationCount;
      }

      // 削減率：(1 - 測定値/予測値) x 100 
      reductionRate = (1 - measuredValue / predictValue) * 100;

      let csvRow = {
        yearMonth: yyyyMM,
        vehicleName: vehicleName,
        // predictValue: predictValue.toFixed(2),
        // measuredValue: measuredValue.toFixed(2),
        // totalMileage: totalMileage.toFixed(2),
        // reductionRate: reductionRate.toFixed(2),
        predictValue: predictValue,
        measuredValue: measuredValue,
        totalMileage: totalMileage,
        reductionRate: reductionRate,
        suddenStartCount: suddenStartCount,
        suddenStopCount: suddenStopCount,
        speedViolationCount: speedViolationCount,
      };

      return csvRow;

    },

    /**
     * CO2排出量CSV列情報（ヘッダー）生成
     */
    createCO2CSVColumns() {
      let columns = [
        { key: "yearMonth", header: "年月日" },
        { key: "vehicleName", header: "車両名" },
        { key: "predictValue", header: "予定値(t-CO2)" },
        { key: "measuredValue", header: "測定値(t-CO2)" },
        { key: "totalMileage", header: "走行距離(km)" },
        { key: "reductionRate", header: "削減率(%)" },
        { key: "suddenStartCount", header: "急発進回数" },
        { key: "suddenStopCount", header: "急停車回数" },
        { key: "speedViolationCount", header: "速度超過回数" },
      ];

      return columns;
    },

    /**
    * 全期間指定時の折れ線グラフ作成
    */
    async dispAllPeriodLineGraph() {
      this.csvCO2Info.periodType = 0;
      this.csvCO2Info.mileageList = [];
      
      // 全期間の累積走行距離取得
      let mileageAllInfos = await MileageModel.getMileageListFromlsiStr1(this.projectId, "");
      console.log(`dispAllPeriodLineGraph ${mileageAllInfos.length}`)
      if (mileageAllInfos.length == 0) {
        // this.showBottomToast(`表示データがありません。`, `info`);
        // 暫定で当月と前月の横軸メモリを作成
        let today = new Date()
        let previousMonth = DateUtil.getFormatString(DateUtil.addMonth(today, -1), 'YYYY年MM月');
        let xscales = []
        xscales.push(DateUtil.getFormatString(DateUtil.addMonth(today, -1), 'YYYY年MM月'));        
        xscales.push(DateUtil.getFormatString(today, 'YYYY年MM月'));
        // 空のグラフを作成
        this.dispLineGraph(xscales, [], []);
        return true
      }

      // lsiStr1の値で昇順にソード (mileage#プロジェクトID#yyyyMMdd)
      mileageAllInfos = mileageAllInfos.sort(function(a, b) {
        return a.lsiStr1 < b.lsiStr1 ? -1 : 1;        
      });
    
      // 全期間の開始月、終了月を取得
      this.startTotalPeriod = DateUtil.getFormatString(mileageAllInfos[0].lsiStr1.split("#")[2], 'YYYYMM');
      this.endTotalPeriod = DateUtil.getFormatString(mileageAllInfos[mileageAllInfos.length - 1].lsiStr1.split("#")[2], 'YYYYMM');

      // 燃費、エコ運転判定の値取得
      const co2SettingInfo = await CO2SettingModel.getCO2SettingbyProjectId(this.projectId);

      // 期間のラベル設定 （期間を算出）
      const periodValue = DateUtil.diff(this.endTotalPeriod, this.startTotalPeriod, "M");

      // CSV出力情報セット
      
      this.csvCO2Info.startPeriod = this.startTotalPeriod;
      this.csvCO2Info.endPeriod = this.endTotalPeriod;
      

      let xScaleValue = [];
      let actualValueList = [];
      let predictlValueList = [];
      let acturlTotalValue = 0;
      let predictlTotalValue = 0;
      let currentValue = null;
      for (let i=0; i<=periodValue; i++){
        // ---------------------------------
        // X方向の目盛り
        // ---------------------------------
        if (i==0) {
          currentValue = this.startTotalPeriod;
          xScaleValue.push(`${String(currentValue).substr(0, 4)}年${String(currentValue).substr(4, 2)}月`);
        } else {
          currentValue = DateUtil.addMonth(currentValue, 1);
          currentValue = DateUtil.getFormatString(currentValue, 'YYYYMM');
          xScaleValue.push(`${String(currentValue).substr(0, 4)}年${String(currentValue).substr(4, 2)}月`);
        }
        
        // ---------------------------------
        // CO2排出値値算(実測（測定）値、予測値)
        // ---------------------------------
        // 累積走行距離の全レコードから該当月のレコード取得
        let mileageInfos = mileageAllInfos.filter(item => {
          return item.lsiStr1.includes(`mileage#${this.projectId}#${currentValue}`);
        })
        // 実績（測定）値        
        let actualValue = this.calcPeriodActualCO2(mileageInfos, co2SettingInfo);
        // 予測値
        let predictlValue = this.calcPeriodPredictCO2(mileageInfos, co2SettingInfo);

        // 実績（測定）値、予測値の累積
        acturlTotalValue = acturlTotalValue + actualValue;
        predictlTotalValue = predictlTotalValue + predictlValue;

        // 折れ線グラフ表示データとして格納
        actualValueList.push(acturlTotalValue);
        predictlValueList.push(predictlTotalValue);
        
        // CSV出力用
        this.csvCO2Info.mileageList.push({targetMonth: currentValue, mileageRecords: mileageInfos});
      }

      // 折れ線グラフ表示
      this.dispLineGraph(xScaleValue, actualValueList, predictlValueList);

      // 結果返却
      return true;

    },

    /**
    * 折れ線グラフを表示する
    */
    dispLineGraph(xScaleValue, actualValueList, predictlValueList) {
      // Y軸のマックス値設定
      let scaleYmaxValue = 0;
      if (actualValueList && predictlValueList ){
        if (actualValueList.length > 0 && predictlValueList.length > 0 ){
          // 折れ線グラフ下部に表示（計測値、予測値）
          this.dispDetailActualTotalValue = actualValueList[actualValueList.length - 1].toFixed(6);
          this.dispDetailPredictlTotalValue = predictlValueList[predictlValueList.length - 1].toFixed(6);

          // 折れ線グラフ設定
          
          if (this.dispDetailActualTotalValue > this.dispDetailPredictlTotalValue) {
            scaleYmaxValue = this.dispDetailActualTotalValue * 1.6;
          } else {
            scaleYmaxValue = this.dispDetailPredictlTotalValue * 1.6;
          }
        }
      }
      
      this.ChartLineDetailOptions.scales.y.max = scaleYmaxValue //Math.round(scaleYmaxValue);
      // X軸のラベル設定
      this.chartLineDetailData.labels = xScaleValue;
      // 折れ線グラフの値設定
      this.chartLineDetailData.datasets = [{
        label: '予測値',
        data: predictlValueList,
        fill: false,
        borderColor: 'rgb(0, 255, 0)',
        borderDash: [4, 2],
        tension: 0.1,
        legend: {
          display: false,
        }
      },
      {
        label: '測定値',
        data: actualValueList,
        fill: false,
        borderColor: 'rgb(248, 171, 166)',
        tension: 0.1,
        legend: {
          display: 'none',
        }
      }]

    },

    /**
    * 全期間指定時の折れ線グラフ作成
    */
    async dispPeriodLineGraph() {
      console.log(`dispPeriodLineGraph`)
      // 指定期間のフォーマットを合わせる
      this.startTotalPeriod = DateUtil.getFormatString(this.startTotalPeriod, 'YYYYMM');
      this.endTotalPeriod = DateUtil.getFormatString(this.endTotalPeriod, 'YYYYMM');

      // 指定期間のチェック
      // 終了が開始より小さい場合はエラーとする。同じ月の場合はOKとする。
      if (this.endTotalPeriod < this.startTotalPeriod) {
        this.showBottomToast(`期間の終了は、開始月以下の月を指定してください。`, `warning`);
        return false;
      }
      // if (this.endTotalPeriod <= this.startTotalPeriod) {
      //   this.showBottomToast(`期間の終了は、開始月より直近の月を指定してください。`, `warning`);
      //   return false;
      // }

      // 燃費、エコ運転判定の値取得
      const co2SettingInfo = await CO2SettingModel.getCO2SettingbyProjectId(this.projectId);

      // 期間のラベル設定 （期間を算出）
      const periodValue = DateUtil.diff(this.endTotalPeriod, this.startTotalPeriod, "M");

      // CSV出力情報セット
      this.csvCO2Info.periodType = 1;
      this.csvCO2Info.startPeriod = this.startTotalPeriod;
      this.csvCO2Info.endPeriod = this.endTotalPeriod;
      this.csvCO2Info.mileageList = [];

      let xScaleValue = [];
      let actualValueList = [];
      let predictlValueList = [];
      let acturlTotalValue = 0;
      let predictlTotalValue = 0;
      let currentValue = null;
      for (let i=0; i<=periodValue; i++){
        // ---------------------------------
        // X方向の目盛り
        // ---------------------------------
        if (i==0) {
          currentValue = this.startTotalPeriod;
          xScaleValue.push(`${String(currentValue).substr(0, 4)}年${String(currentValue).substr(4, 2)}月`);
        } else {
          currentValue = DateUtil.addMonth(currentValue, 1);
          currentValue = DateUtil.getFormatString(currentValue, 'YYYYMM');
          xScaleValue.push(`${String(currentValue).substr(0, 4)}年${String(currentValue).substr(4, 2)}月`);
        }
        
        // ---------------------------------
        // CO2排出値値算(実測（測定）値、予測値)
        // ---------------------------------
        // 対象の累積走行距離レコード取得
        let mileageInfos = await MileageModel.getMileageListFromlsiStr1(this.projectId, currentValue);    
        // 実績（測定）値
        let actualValue = this.calcPeriodActualCO2(mileageInfos, co2SettingInfo);
        // 予測値
        let predictlValue = this.calcPeriodPredictCO2(mileageInfos, co2SettingInfo);

        // 実績（測定）値、予測値の累積
        acturlTotalValue = acturlTotalValue + actualValue;
        predictlTotalValue = predictlTotalValue + predictlValue;

        // 折れ線グラフ表示データとして格納
        actualValueList.push(acturlTotalValue);
        predictlValueList.push(predictlTotalValue);
        
        // CSV出力用
        this.csvCO2Info.mileageList.push({targetMonth: currentValue, mileageRecords: mileageInfos});

      }

      // 折れ線グラフ表示
      this.dispLineGraph(xScaleValue, actualValueList, predictlValueList);

      // 結果返却
      return true;
    },

    /**
    * CO2詳細ダイアログの更新ボタンクリック時
    */
    async onClickUpdateTotalPeriod() {

      // 処理中
      let loader = this.showLoader();

      try {
        // 折れ線グラフ作成
        if (this.rdoTotalPeriod == 0){
          await this.dispAllPeriodLineGraph();
        } else {
          await this.dispPeriodLineGraph();
        }
      } catch (e) {
        this.showBottomToast(`グラフ作成処理に失敗しました。${e.message}`, `warning`);
      } finally {
        if (loader) {
          this.hideLoader(loader);
        }
      }

    },

    /**
    * CO2詳細ダイアログ CSV出力ボタンから呼ばれるグラフ更新処理
    */
    async makeLineGraph() {

      // 処理中
      let loader = this.showLoader();
      
      let retValue = false;

      try {
        // 折れ線グラフ作成
        if (this.rdoTotalPeriod == 0){
          retValue = await this.dispAllPeriodLineGraph();
        } else {
          retValue = await this.dispPeriodLineGraph();
        }
      } catch (e) {
        this.showBottomToast(`グラフ作成処理に失敗しました。${e.message}`, `warning`);
      } finally {
        if (loader) {
          this.hideLoader(loader);
        }
      }
      return retValue;
    },

    
    /**
    * 累積走行距離レコード群から実績（測定）CO2排出量を算出
    */
    calcPeriodActualCO2(mileageRecords, co2SettingInfo) {

      let retValue = 0;

      // データがない場合は0を返却
      if (mileageRecords.length < 1) {
        console.log(`calcPeriodActualCO2 データなし`)
        return retValue;
      }

      // 輸送距離から最大積載量取得し、予測値の基準となる燃費を取得  
      let fuelValue = this.getFuel(co2SettingInfo, mileageRecords[0].mileage.maxCapacity);
      
      // 累積走行距離レコード数分処理
      for (let i=0; i<mileageRecords.length; i++) {
        // 実績（測定）CO2排出量を算出
        retValue = retValue + this.calcActualCO2(mileageRecords[i], fuelValue, co2SettingInfo)
        console.log(`calcPeriodActualCO2 ${JSON.stringify(mileageRecords[i])}`)
      }
      console.log(`calcPeriodActualCO2 retValue:${retValue}`)
      return retValue;
    },

    /**
    * 累積走行距離レコード群から予測CO2排出量を算出
    */
    calcPeriodPredictCO2(mileageRecords, co2SettingInfo) {

      let retValue = 0;

      // データがない場合は0を返却
      if (mileageRecords.length < 1) {
        return retValue;
      }

      // 輸送距離から最大積載量取得し、予測値の基準となる燃費を取得  
      let fuelValue = this.getFuel(co2SettingInfo, mileageRecords[0].mileage.maxCapacity);
      
      // 累積走行距離レコード数分処理
      for (let i=0; i<mileageRecords.length; i++) {
        // 予測CO2排出量を算出
        retValue = retValue + this.calcPredictCO2(mileageRecords[i], fuelValue)
      }

      return retValue;
    },


    /**
    * 運搬時間取得
    */
    async getDeliveryTime(){

      // 戻り値初期化
      let retDeliveryTime = 0;

      // 予定数量登録日の温度取得
      let targetDate = DateUtil.getFormatString(this.dateString, "YYYYMMDD");
      let planList = await PlanModel.getPlanListFromYMD(
        this.projectId,
        targetDate
      );

      let temperature = 0;
      if (planList.length > 0){
        temperature = planList[0].plan.temperature;

        // 取得した温度から
        for (let i=0; i<this.deliveryLimitList.length; i++){
          if (this.deliveryLimitList[i].lowTemperature <= temperature && 
              this.deliveryLimitList[i].HighTemperature > temperature) {
            retDeliveryTime = this.deliveryLimitList[i].transitTime;
            break;
          }
        }
      }
      return retDeliveryTime;
    },

    /**
    * 残時間（閾値）運搬時間の残り時間を表示する閾値（プロジェック設定）
    */
    async getProjectRemainingTime(){

      const user = await UserInfo.getUserInfo();
      let data = {
        pk: user.group,
        sk: `project#${this.projectId}`
      }
      const project = await PorjectModel.getProject(data);

      return project.project.remainingTime;

    },

    /**
    * 打設開始ボタンクリック時の処理 処理確認モーダル表示
    */
    async onClickPouringStart(row){

      // 確認ダイアログ表示
      this.confirmTitle = "打設開始時刻設定確認";
      this.confirmMessage = "打設開始時刻を現在の時間で設定しますか？";
      
      this.confirmDlgMode = 1;
      this.skDrive = row.skDrive; // driveレコードのsk保持
      this.modals.confirmDlg = true;
    },

    /**
    * 打設終了ボタンクリック時の処理 処理確認モーダル表示
    */
    async onClickPouringEnd(row){
      
      // 確認ダイアログ表示
      this.confirmTitle = "打設終了時刻設定確認";
      this.confirmMessage = "打設終了時刻を現在の時間で設定しますか？";
      
      this.confirmDlgMode = 2;
      this.skDrive = row.skDrive; // driveレコードのsk保持
      this.modals.confirmDlg = true;
    },
    
    /**
     * 打設開始、終了時刻の更新処理です。
     */
    async setPouringTime(mode) {

      let loader = null;

      try {

        // 処理中
        loader = this.showLoader();

        // 運行情報取得
        let driveInfo = await DriveModel.getDrive({sk: this.skDrive});

        // 現在の日時取得
        let nowDateTime = DateUtil.dateStringDateTime();

        // 更新フラグ
        let isUpdate = false;

        // 取得した運行情報の打設時間を現在の時刻にセット
        if (mode == 1) {
          // 打設開始時間
          if (driveInfo.drive.pouringStartTime){
            this.showBottomToast(`既に打設開始時間が登録されています。運行一覧を更新します。`, `info`);
          } else {
            driveInfo.drive.pouringStartTime = nowDateTime;
            isUpdate = true;
          }
        } else if (mode ==2) {
          // 打設終了時間
          if (driveInfo.drive.pouringEndTime){
            this.showBottomToast(`既に打設終了時間が登録されています。運行一覧を更新します。`, `info`);
          } else {
            driveInfo.drive.pouringEndTime = nowDateTime;
            isUpdate = true;
          }
        }

        // 打設時間更新処理
        if (isUpdate == true) {
          // DB更新
          await DriveModel.updateDrive(driveInfo);

          // 完了メッセージ表示
          if (mode == 1) {
            this.showBottomToast(`打設開始時間を登録しました。`, `info`);
          } else if (mode ==2) {
            this.showBottomToast(`打設終了時間を登録しました。`, `info`);
          }
        }

        // 運行表示を更新
        this.driveList = [];
        const sk = `${this.projectId}#${DateUtil.getFormatString(this.dateString, "YYYYMMDD")}`;
        this.driveList = await DriveModel.getDriveList(sk);
        this.deliveryList = await DriveModel.getDelivaryList(sk);
        await this.refreshTimeLine(sk);

      } finally {
        if (loader) {
          this.hideLoader(loader);
        }
      }        
    },

    /**
    * 積込開始ボタンクリック時の処理 処理確認モーダル表示
    */
    async onClickCargoStart(row){
      // 確認ダイアログ表示
      this.confirmTitle = "積込開始時刻設定確認";
      this.confirmMessage = "積込開始時刻を現在の時間で設定しますか？";
      
      this.confirmDlgMode = 3;
      this.skDrive = row.skDrive; // driveレコードのsk保持
      this.modals.confirmDlg = true;
    },

    /**
     * 積込終了ボタンクリック時の処理 処理確認モーダル表示
     */
    async onClickCargoEnd(row){
      //確認ダイアログ表示
      this.confirmTitle = "積込終了時刻設定確認";
      this.confirmMessage = "積込終了時刻を現在の時間で設定しますか？";

      this.confirmDlgMode = 4;
      this.skDrive = row.skDrive;
      this.modals.confirmDlg = true;
    },

    /**
     * 荷降開始ボタンクリック時の処理 処理確認モーダル表示
     */
    async onClickCargoUnloadingTime(row){
      //確認ダイアログ表示
      this.confirmTitle = "荷降開始時刻設定確認";
      this.confirmMessage = "荷降開始時刻を現在の時間で設定しますか？";
      
      this.confirmDlgMode = 5;
      this.skDrive = row.skDrive;
      this.modals.confirmDlg = true;
    },

    /**
     * 積込開始/終了,荷降時間の更新処理です。
     */
    async setCargoTime(mode){
      let loader = null;

      try {
        //処理中
        loader = this.showLoader();
        //運行情報取得
        let driveInfo = await DriveModel.getDrive({sk: this.skDrive});
        //現在の日時取得
        let nowDateTime = DateUtil.dateStringDateTime();
        //更新フラグ
        let isUpdate = false;        
        //取得した運行情報の打設時間を現在の時刻にセット
        if (mode == 3){
          //積込開始時間
          if (driveInfo.drive.pouringStartTime){
            this.showBottomToast(`既に積込開始時間が登録されています。運行一覧を更新します`, `info`);
          } else {
            driveInfo.drive.pouringStartTime = nowDateTime;
            isUpdate = true;
          }
        } else if (mode == 4){
          //積込終了時間
          if (driveInfo.drive.pouringEndTime){
            this.showBottomToast(`既に積込終了時間が登録されています。運行一覧を更新します`, `info`);
          } else {
            driveInfo.drive.pouringEndTime = nowDateTime;
            isUpdate = true;
          }
        } else if (mode == 5){
          //荷降時間
          if (driveInfo.drive.unloadingTime){
            this.showBottomToast(`既に荷降時間が登録されています。運行一覧を更新します`, `info`);
          } else {
            driveInfo.drive.unloadingTime = nowDateTime;
            isUpdate = true;
          }
        }
        //積込時間更新処理
        if (isUpdate == true) {
          //DB更新
          await DriveModel.updateDrive(driveInfo);
          //完了メッセージ表示
          if (mode == 3){
            this.showBottomToast(`積込開始時間を登録しました`, `info`);
          } else if (mode == 4){
            this.showBottomToast(`積込終了時間を登録しました`, `info`);
          } else if (mode ==5){
            this.showBottomToast(`荷降時間を登録しました`, `info`);
          }
        }

        //運行表示を更新
        this.driveList = [];
        const sk = `${this.projectId}#${DateUtil.getFormatString(this.dateString, "YYYYMMDD")}`;
        this.driveList = await DriveModel.getDriveList(sk);
        this.deliveryList = await DriveModel.getDelivaryList(sk);
        await this.refreshTimeLine(sk);
      } finally {
        if (loader){
          this.hideLoader(loader);
        }
      }
    },

    onClickSubMenu(index) {
      console.log(`index: ${index}`)
      switch (index) {
        case "1":
          this.isShowMoreInfoPanel_Actual = true;
          this.isShowMoreInfoPanel_CO2 = false;
          break;
        case "2":
          this.isShowMoreInfoPanel_Actual = false;
          this.isShowMoreInfoPanel_CO2 = true;
          break
        default:
          break;
      }

    },
    /**
     * 実績表示ボタン
     */
    onClickActual() {
      this.isShowMoreInfoPanel_Actual = true;
      this.isShowMoreInfoPanel_CO2 = false;
    },

    /**
     * CO2排出量ボタン
     */
    onClickCO2() {
      this.isShowMoreInfoPanel_Actual = false;
      this.isShowMoreInfoPanel_CO2 = true;
    },
    /**
     * 経過時間表示用
     * @param {*} str 
     * @param {*} index 
     */
    splitComma(str, index) {
      try {
        console.log(`splitComma ${str}`)
        if (!str) {
          return ""
        }
        let csv = str.split(",")
        if (csv.length >= index+1) {
          return csv[index]
        }
        return ""
      } catch (e) {
        console.log(e)
        return ""
      }
    },
  },
};
</script>
