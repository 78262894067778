<template>
  <div>
    <div class="card">
      <!-- ヘッダー -->
      <div class="row ml-0">
        <application-header
          :title="appTitle"
          :isDatePicker="false"
          :isNowTime="false"
          :isEditBase="false"
          :isEditVehicle="false"
          :isCreatePlan="false"
          :isDriveLog="false"
          :isOutputCsv="false"
          :isDriveVue="true"
          @driveVue="onClickDriveVue"
        >
        </application-header>
      </div>
      <div class="card-header border-0">
        <!-- メニュー 削除・編集・上へ・下へを有効 -->
        <div class="row ml-1">
            <table-tool-menu
                class="plannedMenu"
                :title="title"
                :addTitle="addTitle"
                :editTitle="editTitle"
                :deleteTitle="deleteTitle"
                :copyTitle="copyTitle"
                :isAdd="isAdd"
                :isDelete="true"                    
                :isEdit="isEdit"
                :isCopy="isCopy"
                :isSingleSelect="selectRow"
                :isMultiSelect="false"
                :processing="false"
                :isOrderMove="false" 
                :disableMoveDown="true"
                :disableMoveUp="true"
                @addNew="onAddPlanDay()"
                @edit="onEditPlanDay(selectedData)"
                @delete="onDeleteDay()"
                @copy="onCopyDay(selectedData)"
            ></table-tool-menu>
        </div>
      </div>

      <!-- 予定数量登録日一覧テーブル -->
      <div class="plan-list">
        <el-table
          ref="PlanTable"
          class="table-responsive align-items-center table-flush"
          header-row-class-name="app-th-row"
          select-on-indeterminate="true"
          :default-sort="{ prop: 'plan.createDate', order: 'descending' }"
          @sort-change="runSort"
          @select="handleSelectRow"
          @select-all="handleSelectAllRow"
          :data="pagedData"
        >

          <el-table-column v-if="isAdd" type="selection" align="left" min-width="120px"></el-table-column>
          <!-- 生コン用：登録日、温度 -->
          <el-table-column v-if="!isZando"
            label="登録日"
            min-width="200px"
            width="200px"
            prop="plan.createDate"
            sortable="'custom'"          
          >
            <template v-slot="{ row }">
              <b>
                <a href="#!" @click.prevent="onClickEditPlan(row)"
                  >{{ row.plan.createDate }}
                </a>
              </b>
            </template>
          </el-table-column>
          <el-table-column v-if="!isZando" label="温度（℃）" min-width="100px" prop="plan.temperature" sortable="'custom'"></el-table-column>

          <!-- 残土用：登録日 -->
          <el-table-column v-if="isZando"
            label="登録日"
            min-width="200px"
            width=""
            prop="plan.createDate"
            sortable="'custom'"          
          >
            <template v-slot="{ row }">
              <b>
                <a href="#!" @click.prevent="onClickEditPlan(row)"
                  >{{ row.plan.createDate }}
                </a>
              </b>
            </template>
          </el-table-column>

        </el-table>

        <div class="row mt-1">
          <div class="col">
            <div class="mt-1 d-flex justify-content-center">
              <el-pagination
                @current-change="setCurrent"
                :page-size="pagination.perPage"
                :current-page="pagination.currentPage"
                layout="prev, pager, next"
                :total="total">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>

      <!-- 予定登録日の追加・編集（モーダル） -->
      <modal v-model:show="modals.editDateDlg" size="sm" body-classes="p-0">
        <template v-slot:header>
          <h6 class="modal-title">{{editMode}}</h6>
        </template>
        <card
          type="secondary"
          header-classes="bg-transparent pb-5"
          body-classes="px-lg-2 py-lg-2"
          class="border-0 mb-0">

          <p v-if="isCopyCommnet">{{copyComment}} のデータをコピーします。</p>
          <p v-if="isCopyCommnet">新しい、日付を選択してください。</p>

          <base-input        
            label="登録日">
            <el-date-picker
              v-model="editDate" type="date" class="w-100" widht="375px"
              format="YYYY/MM/DD" value-format="YYYY-MM-DD"
              placeholder="日付を選択"
              :disabled-date="disabledDate"
              :picker-options="datePickerOptions"
              :shortcuts="shortcuts"  >              
            </el-date-picker>
          </base-input>

          <base-input v-if="!isZando" type="text" label="外気温（℃）">
            <template></template>
            <el-input
              placeholder="入力してください"
              v-model="editTemperature"
              @blur="eventTemperatureBlur"
            ></el-input>
          </base-input>

          <base-input v-if="!isZando" type="text" label="生コン運搬時間">
            <template></template>
            <h4 style="margin-left:10px">{{deliveryTime}}分</h4>
          </base-input>

        </card>
          <!-- キャンセル、更新ボタン -->
          <div class="row mt-2 mb-2 ml-2 mr-2">
            <div class="col-sm-5">
              <base-button type="secondary" block @click="modals.editDateDlg = false">キャンセル</base-button>
            </div>
            <div class="col-sm-2">
            </div>
            <div class="col-sm-5">
              <base-button type="primary" block @click="onClickSubmit">{{editButtonKind}}</base-button>
            </div>
          </div>
        </modal> 

        <!-- 予定日処理確認モーダル -->
        <modal v-model:show="modals.confirmDlg">
          <template v-slot:header>
              <h5 class="modal-title" id="modalLabel">{{confirmTitle}}</h5>
          </template>
          <div class="mb-5">
            {{confirmMessage}}
          </div>
          <div class="row mt-2 mb-2 ml-2 mr-2">
            <div class="col-sm-5">
              <base-button type="secondary" block @click="onClickConfirmCancel">キャンセル</base-button>
            </div>
            <div class="col-sm-2">
            </div>
            <div class="col-sm-5">
              <base-button v-if="actionMode != 1" type="primary" block @click="onClickConfirmOK">{{confirmOK}}</base-button>
              <base-button v-if="actionMode == 1" type="danger" block @click="onClickConfirmOK">{{confirmOK}}</base-button>
            </div>
          </div>
        </modal>

      </div>
  </div>
</template>

<style scoped>
  .card {
     /* 横スクロールバー非表示対策 */
    overflow-x: hidden;
    border-radius: 0;
  }
  .card-header {
    padding-top: 12px;
    padding-bottom: 12px;
  }  
  .plan-list {
    width: 100%;
    height: 100%;
  }

  :deep .el-pagination .btn-next .el-icon {
    font-size: 1.2rem;
  }

  :deep .el-pagination .btn-prev .el-icon {
    font-size: 1.2rem;
  }

  :deep .el-pager li {
    font-size: 1.2rem;
  }
</style>

<script>
import ApplicationHeader from '../components/Menu/ApplicationHeader.vue';
import TableToolMenu from "../components/Menu/TableToolMenu";
import Modal from "@/components/Modal";
import appLog from "@/appUtils/AppLog"
import DateUtil from "@/appUtils/DateUtil"
import plandModel from "@/appModel/Plan/PlanModel";
import volumeModel from "@/appModel/Volume/VolumeModel";
import DriveModel from "@/appModel/Drive/DriveModel";
import SortUtil from '@/appUtils/SortUtil';
import { useToast } from "vue-toastification";
import MonthTemperatureModel from "@/appModel/Temperature/MonthTemperatureModel";
import DeliveryLimitModel from "@/appModel/Temperature/DeliveryLimitModel";
import ValidSession from "../common/ValidSession.js";
import UseApps from "@/appViews/common/UseApps.js";
import Logger from "@/appViews/common/Logger.js";

export default {
  components: {
    ApplicationHeader,
    TableToolMenu,
    Modal,

  },
  mixins: [ValidSession, UseApps, Logger],
  data() {
    return {
      appTitle: "予定数量登録",
      title: "登録日一覧",
      addTitle: "登録日を追加",
      editTitle: "登録日を編集",
      deleteTitle: "登録日を削除",
      copyTitle: "登録日をコピー",
      planDates: [], 
      projectId: null,
      projectName: null,
      datestring: null,
      selectedData: null,
      selectedDatas: null,
      selectedDataCnt: 0,
      editBeforeDate: null,
      editDate: null,
      editTemperature: 0,
      rowID: null,
      CopyFlag: false,
      orgPlanid: null,
      pagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },      
      modals: {
        editDateDlg: false,
        confirmDlg: false,
      },
      isAdd: true,
      isEdit: true,
      isCopy: true,
      datePickerOptions: {
        disabledDate: this.disabledDate,        
      },      
      userAuthority: 1,
      repeatedHitsFlg: false, // 連打防止フラグ （運行情報画面へ遷移する際に使用）
      /**
       * 日付選択ショートカット
       */
      shortcuts: [
        {
          text: '今日',
          value: new Date(),
        },
        {
          text: '明日',
          value: (() => {
            const date = new Date()
            date.setTime(date.getTime() + 3600 * 1000 * 24)
            return date
          })(),
        },
        {
          text: '1週間後',
          value: (() => {
            const date = new Date()
            date.setTime(date.getTime() + 3600 * 1000 * 24 * 7)
            return date
          })(),
        }
      ],  
      confirmTitle: '',
      confirmMessage: '',
      confirmOK: '',
      actionMode: 0, // 0: 追加, 1: 削除, 2: 編集, 3: コピー
      editMode: '',
      editButtonKind: '',
      isCopyCommnet: false,
      monthTemperatureList: [],
      deliveryLimitList: [],
      deliveryTime: 0,
    };
  },
  // コンピュートプロパティ
  computed: {
    selectRow() {
      if (this.userAuthority == 0) {
        return false;
      }
      if (this.selectedDataCnt > 0) {
        return true;
      } else {
        return false;
      }
    },
    /***
     * ページング用のデータをかえす
     */
    pagedData() {
      return this.planDates.slice(this.from, this.to)
    },
    /**
     * ページング制御用
     */
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    /**
     * ページング制御用
     */
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    /**
     * ページング制御用
     */
    total() {
      return this.planDates.length;
    },
    vuename() {
      return "PlanList.vue"
    }
  },
  beforeCreate() {
    //インスタンスは生成されたがデータが初期化される前
  },
  created() {
    //インスタンスが生成され､且つデータが初期化された後
    this.infoLog(`created`, `Start Vuex:projectid(${this.$store.state.plan.projectid})、projectname(${this.$store.state.plan.projectname})、datestring(${this.$store.state.plan.datestring})、planid(${this.$store.state.plan.planid})、createdate(${this.$store.state.plan.createdate})`)
    this.init()
  },
  beforeMount() {
    //インスタンスが DOM 要素にマウントされる前
  },
  mounted() {
    //インスタンスが DOM 要素にマウントされた後
  },
  beforeUpdate() {
    //データは更新されたが DOM に適用される前
  },
  updated() {
    //データが更新され､且つ DOM に適用された後
  },
  beforeUnmount() {
    //Vue インスタンスが破壊される前
  },
  unmounted() {
    //Vue インスタンスが破壊された後
  },
  beforeRouteLeave() {
    // モーダルを非表示
    this.modals.editDateDlg = false;
    this.modals.confirmDlg = false;
    
    this.infoLog(`beforeRouteLeave`, `End`)
  },
  //ボタンイベントなどのメソッドはmethodsに
  methods: {
    /**
     * 日付選択で選択不可の日を指定します。
     */
    disabledDate(time) {
      // 当日より前の日付は選択不可とする
      const targetDate = new Date()
      targetDate.setTime(targetDate.getTime() - 3600 * 1000 * 24)
      return time.getTime() < targetDate;
    },    
    /**
     * 初期化処理
     */    
    async init() {

      let loader = this.showLoader();
      try {
        // プロジェクトタイプ取得
        if (this.isZando) {
          this.projectType = "1"; // 残土
        } else {
          this.projectType = "0"; // 生コン
        } 
        
        // プルジェクトIDに値が入っているかチェック
        this.projectId = this.$store.state.plan.projectid 
        this.projectName = this.$store.state.plan.projectname
        this.datestring = this.$store.state.plan.datestring      
        if (this.projectId == null || this.projectId == ""){
          this.showBottomToast(`予定数量登録一覧画面表示処理に失敗しました。（プロジェクトIDが不正）`, 'error').then(() => {
          })
          this.$router.push({
            path: "/projects",
          });        
        }
        // ユーザー権限設定（0:一般 1:管理者 2：スーパーユーザ―）
        this.userAuthority = this.$store.state.user.userType
        if (this.userAuthority == 0) {
          this.isAdd = false;
        }

        // タイトルをセット
        let navInfo = this.$store.state.navInfo
        navInfo.title = `${this.projectName} - ${this.$route.meta.title}`
        navInfo.description = ""
        this.$store.commit("setNavInfo", navInfo)
        
        // 予定数量登録日一覧取得
        await this.getPlanList();

        // 月別温度設定取得
        await this.getMonthTemperatureList();

        // 温度毎の運搬時間（分）の設定リスト取得
        this.deliveryLimitList = await DeliveryLimitModel.getDeliveryLimitList();

        // 連打防止
        this.repeatedHitsFlg = false

      } catch (e) {
        this.errorLog(`init`, this.parseErrorObject(e))
      } finally {
        this.hideLoader(loader)
      }
    },
    /**
     * 予定情報一覧取得（登録日カラム降順でソート）
     */
    async getPlanList(){

      // テーブル関連編集初期化
      this.selectedData = null;      
      this.selectedDatas = null;      
      this.selectedDataCnt = 0; 

      // プロジェクトIDから予定情報取得
      let listdata = await plandModel.getPlanList(this.projectId); 

      // 日付の表示形式を変更（YYYY/MM/DD）へ
      listdata.forEach(item => {
        item.plan.createDate = DateUtil.dateStringBase(item.plan.createDate, 'YYYY/MM/DD')
      });

      // ソートして一覧に表示（予定登録日の降順でソート）
      this.planDates = listdata.sort(function(a, b){
        return (a.plan.createDate < b.plan.createDate) ? 1: -1;
      }) 

    },
    /**
     * 月別温度設定一覧取得
     */
    async getMonthTemperatureList(){
      this.monthTemperatureList = await MonthTemperatureModel.getMonthTemperatureList();
    },
    /**
     * 指定した月の設定温度を取得する
     */
    async getMonthTemperature(trgetMonth){
      let retTemperature = 20;
      if (this.monthTemperatureList.length > 0){
        const target = this.monthTemperatureList.find(
          (temperature) => temperature.month === trgetMonth
        );
        retTemperature = target.temperature;
      }
      return retTemperature;
    },
    /**
     * 予定登録日の追加（モーダル画面表示)
     */       
    async onAddPlanDay(){
      // モーダル画面のタイトルとボタンのキャプション設定
      this.editMode = this.addTitle
      this.editButtonKind = "登録"
      
      // 新規登録のため、IDはNULLを設定（登録モード）
      const date = new Date();
      this.editDate = DateUtil.dateStringBase(date, 'YYYY-MM-DD');
      this.editTemperature = await this.getMonthTemperature(date.getMonth()); 
      this.rowID = null;
      this.CopyFlag = false
      this.isCopyCommnet = false
      
      // 外気温から運搬時間算出しセット
      this.setDeliveryTime();

      // モーダル画面表示
      this.modals.editDateDlg = true
    },
    /**
     * 予定登録日の編集（モーダル画面表示）
     */       
    async onEditPlanDay(row){

      // 運行情報に紐づく予定数量を削除しようとしていないかチェック
      let ret = await this.checkDriveInfo(row)
      if (ret == true){
        this.showBottomToast(`既に運行情報が登録されているため、登録日は編集できません。`, 'warning')
        return 
      }

      // モーダル画面のタイトルとボタンのキャプション設定
      this.editMode = this.editTitle
      this.editButtonKind = "更新"
      
      // 選択行の登録予定日、IDをセット
      this.editBeforeDate = row.plan.createDate;
      this.editDate = row.plan.createDate;
      this.editTemperature = row.plan.temperature;
      this.rowID = row.id
      this.CopyFlag = false
      this.isCopyCommnet = false
      
      // 外気温から運搬時間算出しセット
      this.setDeliveryTime();

      // モーダル画面表示
      this.modals.editDateDlg = true
    }, 
    /**
     * 予定登録日のコピー（モーダル画面表示）
     */       
    onCopyDay(row){
      // モーダル画面のタイトルとボタンのキャプション設定
      this.editMode = this.copyTitle
      this.editButtonKind = "コピー"
      
      // 選択行の登録予定日、IDをセット
      const date = new Date();
      this.editDate = DateUtil.dateStringBase(date, 'YYYY-MM-DD');
      this.editTemperature = row.plan.temperature;
      this.rowID = null
      this.CopyFlag = true
      this.isCopyCommnet = true
      this.copyComment = row.plan.createDate
      
      // コピー元の予定IDセット
      this.orgPlanid = String(row.sk).replace(`plan#${this.projectId}#`, '')

      // モーダル画面表示
      this.modals.editDateDlg = true
    },

    /**
     * 予定登録日追加・編集・コピー（モーダル画面の実行ボタン押下時）
     */       
    async onClickSubmit()
    {
      // 登録確認メッセージ
      let operation = null;
      let buttonLabel = '実行';
      if (this.rowID == null && this.CopyFlag == false) {
        operation = this.addTitle;
        this.actionMode = 0;
        buttonLabel = '追加';
      } else if (this.rowID != null) {
        operation = this.editTitle;
        this.actionMode = 2;
        buttonLabel = '編集';
      } else {
        operation = this.copyTitle;
        this.actionMode = 3;
        buttonLabel = 'コピー';
      }

      // 予定日処理確認モーダル表示
      this.confirmTitle = operation;
      this.confirmMessage = `${operation}します。よろしいですか？`;
      this.confirmOK = buttonLabel;
      this.modals.confirmDlg = true;
    },
    
    /**
     * 予定日処理確認モーダル キャンセルボタンクリックイベント
     */
    onClickConfirmCancel() {
      this.modals.confirmDlg = false;
    },

    /**
     * 予定日処理確認モーダル 実行ボタンクリックイベント
     */       
    async onClickConfirmOK() {
      // 予定日処理確認モーダル非表示
      this.modals.confirmDlg = false;

      // 削除モードの場合は削除処理を呼び出す
      if (this.actionMode == 1) {
        this.deleteDay();
        return;
      }

      // 登録確認メッセージ
      let operation = null
      if (this.rowID == null && this.CopyFlag == false)
        operation = this.addTitle
      else if (this.rowID != null)
        operation = this.editTitle
      else
        operation = this.copyTitle 

      try {

        // 登録年月日が指定されているかチェック
        if (this.editDate == null)
        {
          this.showBottomToast(`登録日の年月日を指定してください。`, 'warning') 
          return
        }

        // 過去日が指定されていないかチェック
        const today = new Date();
        const todayString = DateUtil.dateStringBase(today, 'YYYY-MM-DD');
        const dayDiff = DateUtil.diff(this.editDate, todayString, "d", false);
        if (dayDiff < 0) {
          this.showBottomToast(`過去でない日付を指定してください。`, 'warning');
          return;
        }

        // 予定登録日（モーダル画面で設定された値）
        let regCreateDate = DateUtil.dateStringBase(this.editDate, "YYYYMMDD")

        // 日付を変更している場合は既に登録済みかチェックする
        if (this.editBeforeDate != this.editDate) {
          // 既に登録済みの日付かチェック
          let ret = await this.validationCheck(regCreateDate)
          if (ret) {
            this.showBottomToast(`既に登録されている年月日です。\n別の年月日を指定してください。`, 'warning') 
            return
          }
        }

        if (this.projectType == "0"){
          // 外気温 数値かチェック
          if (isNaN(this.editTemperature)){
            this.showBottomToast(`外気温（℃）には数値を入力してください。`, 'warning')
            return;
          } 
        }
               

        // 新規登録時、コピー
        if (this.rowID == null)
        {
          // 登録用レコード作成
          let regData = await plandModel.getNewData(this.projectId)
          
          // 予定登録日設定
          regData.plan.createDate = regCreateDate
          regData.plan.temperature = this.editTemperature;

          // lsiStr1(キー作成：plan#プロジェクトID#yyyyMMDD)
          regData.lsiStr1 = `plan#${this.projectId}#${regCreateDate}`;
          
          // DB更新
          await plandModel.addPlan(regData);

          // コピー時は予定登録日に紐づく予定数量もコピー
          if (this.CopyFlag)
          {
            // 登録した予定ID取得
            const regPlanid = String(regData.sk).replace(`plan#${this.projectId}#`, '')

            // 予定数量取得（予定IDはコピー元の予定IDを指定）
            const searchKey = this.projectId + '#' + this.orgPlanid
            let listVolume = await volumeModel.getVolumeList(searchKey); 

            // 取得した予定数量数分、コピーデータ作成
            for (let i=0;i<listVolume.length;i++){

              // 登録用のレコード作成
              let regVolumeData = await volumeModel.getNewData(this.projectId, regPlanid, listVolume[i].volume.endAreaId)
              // lsiStr2(キー作成：volume#yyyyMMDD)
              regVolumeData.lsiStr2 = `volume#${regCreateDate}`
              
              // 値の登録（到着拠点Id、打設箇所、出荷数量（㎥）、規格、アラート設定、残台数アラート、岩種、積載量）
              regVolumeData.volume.endAreaId = listVolume[i].volume.endAreaId
              regVolumeData.volume.pouringPosition = listVolume[i].volume.pouringPosition
              regVolumeData.volume.totalVolume = listVolume[i].volume.totalVolume
              regVolumeData.volume.grade = listVolume[i].volume.grade
              regVolumeData.volume.settingAlert = listVolume[i].volume.settingAlert
              regVolumeData.volume.numberAlert = listVolume[i].volume.numberAlert
              regVolumeData.volume.rockTypeId = listVolume[i].volume.rockTypeId
              regVolumeData.volume.loadCapacity = listVolume[i].volume.loadCapacity             
              
              // DB更新
              await volumeModel.addVolume(regVolumeData)
            }
          }

          // ログ出力
          if (this.CopyFlag){
            this.infoLog("onClickConfirmOK", `【Copy】Plan： pk[${regData.pk}]、sk[${regData.sk}]、登録日 [${regData.plan.createDate}]`)            
          } else {
            this.infoLog("onClickConfirmOK", `【Insert】Plan： pk[${regData.pk}]、sk[${regData.sk}]、登録日 [${regData.plan.createDate}]`)            
          }
          
        // 編集時
        } else {
          
          // 更新対象の行情報をセット
          let editData = this.selectedData;

          // 予定登録日、lsiStr1(キー作成：plan#プロジェクトID#yyyyMMDD)を設定
          editData.plan.createDate = regCreateDate;
          editData.plan.temperature = this.editTemperature;
          editData.lsiStr1 = `plan#${this.projectId}#${regCreateDate}`;

          // DB更新
          await plandModel.updatePlan(editData);
          
          // ログ出力
          this.infoLog("onClickConfirmOK", `【Update】 Plan： pk[${editData.pk}]、sk[${editData.sk}]、登録日 [${editData.plan.createDate}]`)

        }

        // モーダル画面を閉じる
        this.modals.editDateDlg = false
        // 一覧情報再取得
        this.getPlanList();
        // 完了メッセージ
        this.showBottomToast(`${operation}処理が完了しました。`, 'info')

      } catch (e) {
        //エラー外メッセージ表示
        this.showBottomToast(`${operation}処理に失敗しました。`, 'error')
        this.errorLog(`onClickConfirmOk`, this.parseErrorObject(e))
        
      } 

    },

    /**
     * 登録日の重複チェック
     * @param createDate 登録日
     * @return true:重複チェックエラー、false：重複なし
     */
    async validationCheck(createDate){

      // 戻り値初期化
      let retValue = true

      // プロジェクトID、予定IDを指定して取得   
      let listPlan = await plandModel.getPlanList(this.projectId); 

      // 到着地点ID、打設箇所がデータじ分がないかチェック
      let retList = await listPlan.filter((list) => list.plan.createDate === createDate);
      if (retList.length == 0){
        retValue = false
      } 

      return retValue
    },

    /**
     * 削除対象の予定数量に紐づく運行情報の有無をチェック
     * @paramas row 削除対象の予定数量情報
     * @return True：紐づく運行情報あり、False：紐づく運行情報なし
     */     
    async checkDriveInfo(row){

      let retValue = false 

      // 登録日に紐づく予定数量取得
      let sk = `${this.projectId}#${row.plan.id}`
      let listVolume = await volumeModel.getVolumeList(sk); 

      for (let i=0; i<listVolume.length; i++){
        // 予定数量情報から運行情報取得
        let createDate = String(row.plan.createDate).replace('/', '').replace('/', '')
        sk = `${this.projectId}#${createDate}#${listVolume[i].volume.endAreaId}`  
        let driveList = await DriveModel.getDriveList(sk)

        // 該当レコードの有無をチェック
        for (let j=0; j<driveList.length; j++){
          // 打設箇所が一致すれば該当レコードありと判断
          if (listVolume[i].volume.pouringPosition == driveList[j].drive.pouringPosition) {
            retValue = true
            break
          }
        }
      }
      // 結果を返却
      return retValue
    },

    /**
     * 削除ボタンクリックイベント
     */           
    async onDeleteDay() {
      try {
        
        // 運行情報に紐づく登録日を削除しようとしていないかチェック
        let judgeFlg = false
        for (let i=0; i<this.selectedDatas.length; i++){
          // チェック処理
          let ret = await this.checkDriveInfo(this.selectedDatas[i])
          if (ret == true){
            judgeFlg = true
            break
          }
        }

        // 確認メッセージ設定
        let msgStr = null
        if (judgeFlg == true){
          msgStr = 'データが存在します。選択した登録日を削除します。よろしいですか？'
        } else {
          msgStr = '選択した登録日を削除します。よろしいですか？'
        }

        // 予定日処理確認モーダル表示
        let operation = this.deleteTitle;
        this.confirmTitle = operation;
        this.confirmMessage = msgStr;
        this.confirmOK = '削除';
        this.actionMode = 1;
        this.modals.confirmDlg = true;
        
      } catch (e) {
        //エラー外メッセージ表示
        this.showBottomToast(`予定登録日の削除処理に失敗しました。`, 'error');
        this.errorLog(`onDeleteDay`, this.parseErrorObject(e))
      }
    },

    /**
     * 削除処理
     */           
    async deleteDay() {
      try {     
        for (let i=0; i<this.selectedDatas.length; i++){

          // 削除処理（登録日の削除）
          await plandModel.deletePlan(this.selectedDatas[i]);

          // 削除した予定ID取得
          const delPlanid = String(this.selectedDatas[i].sk).replace(`plan#${this.projectId}#`, '')

          // 予定数量取得（予定IDは削除した予定IDを指定）
          const searchDelKey = this.projectId + '#' + delPlanid
          let listDelVolume = await volumeModel.getVolumeList(searchDelKey); 

          // 取得した予定数量数分、データを削除
          for (let volCnt=0;volCnt<listDelVolume.length;volCnt++){
            
            // 削除処理（予定数量）
            await volumeModel.deleteVolume(listDelVolume[volCnt])

            // ログ出力
            this.infoLog(`deleteDay`, `【Delete】 Plan： pk[${this.selectedDatas[i].pk}]、sk[${this.selectedDatas[i].sk}]、登録日 [${this.selectedDatas[i].plan.createDate}]`)
          }

        }
        // 一覧情報再取得
        this.getPlanList();
        // 完了メッセージ
        this.showBottomToast(`予定登録日の削除処理が完了しました。`, 'info')

      } catch (e) {
        //エラー外メッセージ表示
        this.showBottomToast(`予定登録日の削除処理に失敗しました。`, 'error')
        this.errorLog(`deleteDay`, this.parseErrorObject(e))
      }         
    },
    /**
     * 予定登録日ラベルクリック時の処理（予定数量画面へ遷移）
     */               
    async onClickEditPlan(row) {

      // storeに予定ID登録
      let planid = String(row.sk).replace(`plan#${this.projectId}#`, '')

      // storeに登録（プロジェクトID、予定ID、予定登録日）
      let plan = this.$store.state.plan
      plan.projectid = this.projectId
      plan.projectname = this.projectName
      plan.datestring = this.datestring
      plan.planid = planid
      plan.createdate = row.plan.createDate
      await this.$store.commit("setPlan", plan)

      // 予定数量画面へ遷移
      
      this.$router.push({
        path: this.getRoutePath(`planEdit`),
      }); 
    },
    /**
     * テーブルのチェックボックス列選択時（全て）
     */               
    handleSelectAllRow(selection) {

      // TODO 権限が閲覧ユーザーの場合はチェックＯＮさせない
      if (this.userAuthority == 0){
        this.$refs.PlanTable.clearSelection()
        this.selectedData = null
        return 
      }

      // 選択行数をセット
      this.selectedDataCnt = selection.length
      // 選択行数が1件の場合は、編集とコピーボタン表示 
      if (this.selectedDataCnt == 1){
        this.isEdit = true; this.isCopy = true
      } else {
        this.isEdit = false; this.isCopy = false
      }
      // 選択行をセット
      this.selectedDatas = selection
    },  
    /**
     * テーブルの行選択時の処理
     */       
    handleSelectRow(selection, row) {

      // TODO 権限が閲覧ユーザーの場合はチェックＯＮさせない
      if (this.userAuthority == 0){
        this.$refs.PlanTable.clearSelection()
        this.selectedData = null
        return 
      }

      // 選択行数をセット
      this.selectedDataCnt = selection.length  
      // 選択行数が1件の場合は、編集とコピーボタン表示 
      if (this.selectedDataCnt == 1){
        this.isEdit = true; this.isCopy = true
      } else {
        this.isEdit = false; this.isCopy = false
      }

      // チェックＯＮ、ＯＦＦ
      if (this.selectedData == row) {
        // 同一業の場合はチェックOFF
        this.selectedData = null;
      } else {
        this.selectedData = row;
      }

      // 1件の時は、選択行にセット（チェックＯＦＦした結果、別の行の1件がカレント行になるように）
      if (selection.length == 1){
        this.selectedData = selection[0]
      }

      // 選択行保持
      this.selectedDatas = selection
    },    
    /**
     * トーストでメッセージを表示
     */
    showBottomToast(message, type) {
      this.runToast(message, 'bottom-center', type)
    },
    /**
     * トーストでメッセージを表示（処理）
     */
    runToast(message, pos, type) {

      const toast = useToast();
      toast[type](message, {
        hideProgressBar: true,
        icon: false,
        toastClassName: ["custome-toast-class"],
        closeButton: false,
        position: pos
      });
    },
    /**
     * ソートを実行します。
     */
    async runSort(column) {
      await this.changeTableSort(column);
    },
    /**
     * テーブルのソート順を変更します。
     */
    async changeTableSort(column){

      if (column === false) {
        return;
      }

      // フィールド名とソート種別を取得
      let fieldName = column.prop;
      let sortingType = column.order;

      if (!fieldName) {
        return;
      }
      
      // 日本語ソート
      let isKana = false;
      if (fieldName == "plan.createDate") {
        isKana = true;
      }
      // ソート実行
      this.planDates = SortUtil.sort(this.planDates, fieldName, sortingType, isKana);
    },        
    /**
     * 処理中インジケーターを表示します。
     * @returns 表示したインジケーター
     */
    showLoader() {
      return this.$loading.show({
        container: null,
        canCancel: false,
        color: "#003C9C",
        width: 64,
        height: 64,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        isFullPage: true,
        zIndex: 9999,
      })
    },
    clickCancel() {
      this.$router.push({
        path: "/Projects",
        name: "Projects",        
      });  
    },
    /**
     * 処理中インジケーターを閉じます。
     * @paramas {Object} loader 表示したインジケーター
     */
    hideLoader(loader) {
      loader.hide();
    },
    /**
     * ページ変更時のハンドラ
     */
    setCurrent(newPage) {
      this.pagination.currentPage = newPage
    },    
    /**
     * 運行情報画面ボタンクリック
     */
    async onClickDriveVue(){

      // 連打防止
      if (this.repeatedHitsFlg) return
      this.repeatedHitsFlg = true

      // store(vuex)に値をセット
      let store = this.$store.state.timeline;
      store.projectid = this.projectId;
      store.projectname = this.projectName;
      store.datestring = this.datestring

      await this.$store.commit("setTimeline", store);      

      // 車両一覧画面表示
      this.$router.push({
        path: this.getRoutePath('timeline'),
      }); 
    }, 
    /**
     * 外気温 Blurイベント
     */
    eventTemperatureBlur() {
      this.setDeliveryTime();
    },
    /**
     * 外気温 から 運搬時間算出
     */
    setDeliveryTime() {
      if (!isNaN(this.editTemperature)) {
        // 外気温から運搬時間算出
        for (let i=0; i<this.deliveryLimitList.length; i++){
          if (this.deliveryLimitList[i].lowTemperature <= this.editTemperature && 
              this.deliveryLimitList[i].HighTemperature > this.editTemperature) {
            this.deliveryTime = this.deliveryLimitList[i].transitTime;
            break;
          }
        }
      } else {
        // 外気温が数値でない場合は0をセット
        this.deliveryTime = 0;
      }
    },
    /**
     * コンソール出力のみ。
     */
    debugLog(funcName, message) {
      try {
        this.base_debugLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e)
      }
    },
    /**
     * AmplifyのAPI経由でS3にINFOログが残る
     */
    infoLog(funcName, message) {
      try {
        this.base_infoLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e)
      }
    },
    /**
     * AmplifyのAPI経由でS3にERRORログが残る
     */
    errorLog(funcName, message) {
      try {
        this.base_errorLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラー破棄
        console.log(e)
      }
    },
  },
};
</script>